var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-200 w-96"},[(_vm.isLoading)?_c('Loading'):_c('div',[_c('div',{staticClass:"md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"},[_c('div',{staticClass:"text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4"},[_vm._v(" Manage Roles ")]),(_vm.isManagePermission)?_c('button',{staticClass:"flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm",on:{"click":function () { return _vm.openRoleModal(null); }}},[_c('span',{staticClass:"material-icons m-auto mr-1 text-base"},[_vm._v("add")]),_c('span',{staticClass:"text-sm"},[_vm._v("Add Role")])]):_vm._e()]),_c('div',{staticClass:"dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2"},[(_vm.isManagePermission && _vm.isReadPermission)?_c('div',{staticClass:"flex flex-row mt-4"},[_c('div',{staticClass:"flex flex-col w-1/2 mx-2"},[_c('label',{staticClass:"text-gray-500 ml-1 mb-1"},[_vm._v("Menu")]),_c('v-select',{staticClass:"text-sm bg-white",attrs:{"placeholder":"Select menu","value":_vm.selectedMenu,"options":_vm.menus},on:{"input":_vm.setSelectedMenu}})],1),(!(_vm.menuItems.length === 1 && !_vm.menuItems[0].link))?_c('div',{staticClass:"flex flex-col w-1/2 mx-2"},[_c('label',{staticClass:"text-gray-500 ml-1 mb-1"},[_vm._v("Sub Menu")]),_c('v-select',{staticClass:"text-sm bg-white",attrs:{"placeholder":"Select menu item","value":_vm.selectedMenuItem,"options":_vm.menuItems},on:{"input":_vm.setSelectedMenuItem}})],1):_vm._e()]):_vm._e(),(_vm.isManagePermission && _vm.isReadPermission)?_c('div',{staticClass:"mt-2 overflow-x-auto"},[_c('draggable',{staticClass:"dragArea flex flex-row p-3 items-center justify-center overflow-x-auto",style:(_vm.permissions.length > 4
              ? _vm.permissions.length > 7
                ? 'padding-left: 84rem; padding-right: 84rem;'
                : 'padding-left: 10rem;'
              : ''),attrs:{"list":_vm.permissions,"clone":_vm.clone,"options":{
            group: { name: 'people', pull: 'clone', put: false },
          }},on:{"end":_vm.dropEnd}},_vm._l((_vm.permissions),function(element,index){return _c('div',{key:index,staticClass:"mr-4",attrs:{"data-id":element.id}},[_c('PermissionCard',{attrs:{"data":element}})],1)}),0)],1):_vm._e(),(_vm.isReadPermission)?_c('div',{staticClass:"flex flex-col m-auto p-auto mt-2"},[_c('h6',{staticClass:"flex py-1 lg:px-5 md:px-10 px-5 lg:mx-0 md:mx-20 mx-5 font-semibold text-gray-600"},[_vm._v(" Roles ")]),_c('div',{staticClass:"flex overflow-x-scroll pb-5"},[_c('div',{staticClass:"flex flex-nowrap lg:ml-0 md:ml-20 ml-10"},_vm._l((_vm.roles),function(role,roleIndex){return _c('div',{key:roleIndex,staticClass:"inline-block px-3"},[_c('div',{staticClass:"w-64 max-w-xs rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"},[_c('div',[_c('h3',{staticClass:"bg-gray-700 text-white px-2 py-1 cursor-pointer",on:{"click":function () { return _vm.openRoleModal(role.id); }}},[_vm._v(" "+_vm._s(role.name)+" ")]),_c('draggable',{staticClass:"dragArea px-2 overflow-auto",style:(_vm.isReadPermission && !_vm.isManagePermission
                        ? 'height: 30rem'
                        : 'height: 20rem;'),attrs:{"list":role.permissions,"options":{ group: 'people' },"data-box":role.id}},[(!_vm.isRoleLoading)?_c('LiquorTree',{attrs:{"data":role.menus},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var node = ref.node;
return _c('span',{staticClass:"tree-text"},[(!node.hasChildren())?[_c('div',{on:{"click":function () { return _vm.openRolePermissionModal(role, node.data); }}},[_c('i',{staticClass:"material-icons"}),_vm._v(" "+_vm._s(node.text)+" ")])]:[_c('i',{class:[
                              node.expanded()
                                ? 'ion-android-folder-open'
                                : 'ion-android-folder' ]}),_vm._v(" "+_vm._s(node.text)+" ")]],2)}}],null,true)}):_vm._e()],1)],1)])])}),0)])]):_c('NotAuthorized',{staticClass:"h-screen bg-white"})],1)]),(_vm.isRoleModalVisible && _vm.isManagePermission)?_c('RoleModal',{attrs:{"roleId":_vm.selectedRole.id,"selectedRole":_vm.selectedRole},on:{"success":_vm.onSuccess,"close":_vm.closeRoleModal}}):_vm._e(),(_vm.isRolePermissionModalVisible)?_c('RolePermissionModal',{attrs:{"selectedRole":_vm.selectedRole,"selectedRolePermission":_vm.selectedRolePermission,"isManagePermission":_vm.isManagePermission},on:{"success":_vm.onSuccess,"close":_vm.closeRolePermissionModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }