<template>
  <date-range-picker
    class="text-sm rounded-full"
    ref="picker"
    :opens="popupAlign || 'left'"
    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy ' }"
    :minDate="null"
    :maxDate="null"
    :singleDatePicker="false"
    :timePicker="false"
    :timePicker24Hour="false"
    :showWeekNumbers="true"
    :showDropdowns="true"
    :autoApply="false"
    :ranges="defaultRanges"
    v-model="dateRange"
    @update="updateValues"
    :linkedCalendars="true"
  >
    <template v-slot:input="picker">
      <span class="text-gray-400 rounded-full">
        {{
          (picker.startDate &&
            `${picker.startDate.getDate()} ${
              months[picker.startDate.getMonth()]
            } ${picker.startDate.getFullYear()}`) | date
        }}
        {{ picker.startDate ? '-' : 'Date Range' }}
        {{
          (picker.endDate &&
            `${picker.endDate.getDate()} ${
              months[picker.endDate.getMonth()]
            } ${picker.endDate.getFullYear()}`) | date
        }}
      </span>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';

export default {
  name: 'DateRangePickerInput',
  components: { DateRangePicker },
  data() {
    let d = new Date();
    var quarter = Math.floor(d.getMonth() / 3);
    var Q1_startDate = new Date(d.getFullYear(), 0, 1);
    var Q1_endDate = new Date(d.getFullYear(), Q1_startDate.getMonth() + 3, 0);
    var Q2_startDate = new Date(d.getFullYear(), 3, 1);
    var Q2_endDate = new Date(d.getFullYear(), Q2_startDate.getMonth() + 3, 0);
    var Q3_startDate = new Date(d.getFullYear(), 6, 1);
    var Q3_endDate = new Date(d.getFullYear(), Q3_startDate.getMonth() + 3, 0);
    var Q4_startDate = new Date(d.getFullYear(), 9, 1);
    var Q4_endDate = new Date(d.getFullYear(), Q4_startDate.getMonth() + 3, 0);
    var startDate = new Date(d.getFullYear(), quarter * 3, 1);
    var endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 3,
      0
    );
    return {
      dateRange: { startDate, endDate },
      defaultDateRange: { startDate, endDate },
      defaultRanges: {
        'Quarter 1': [Q2_startDate, Q2_endDate],
        'Quarter 2': [Q3_startDate, Q3_endDate],
        'Quarter 3': [Q4_startDate, Q4_endDate],
        'Quarter 4': [Q1_startDate, Q1_endDate],
        'This Year': [
          new Date(d.getFullYear(), 0, 1),
          new Date(d.getFullYear(), 12, 0),
        ],
        'Previous Year': [
          new Date(d.getFullYear() - 1, 0, 1),
          new Date(d.getFullYear() - 1, 12, 0),
        ],
      },
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    };
  },
  props: ['popupAlign'],
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : '';
    },
  },
  methods: {
    updateValues() {
      this.$emit('updateDateRange', this.dateRange);
    },
  },
  mounted() {
    let rangeElement = document.getElementsByClassName('reportrange-text');
    rangeElement[0].style['border-radius'] = '0.3rem';
  },
};
</script>

<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
</style>
