<template>
  <div class="">
    <div
      class="overflow-x-hidden h-full overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-end items-center flex"
    >
      <div class="relative w-auto max-w-xs max-h-screen">
        <!--content-->
        <div
          class="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen"
        >
          <!--header-->
          <div
            class="flex flex-col justify-center p-5 border-b border-solid border-blueGray-200 rounded-t bg-gray-200"
          >
            <div class="flex flex-row items-start justify-between">
              <div></div>
              <img
                v-if="userDetails.image"
                class="object-cover w-20 h-20 border-2 border-white rounded-full dark:border-indigo-400"
                alt="Avatar"
                :src="baseImageUrl + userDetails.image"
              />
              <div
                v-else
                class="object-cover bg-gray-50 md:w-20 md:h-20 w-8 h-8 border-l border-t border-r border-b border-blue-400 rounded-full dark:border-indigo-400 md:ml-2 cursor-pointer"
              >
                <span
                  class="flex mt-2.5 items-center justify-center text-base font-thin text-gray-400 text-4xl"
                  >{{
                    getInitials(userDetails.first_name, userDetails.last_name)
                  }}</span
                >
              </div>
              <span class="material-icons cursor-pointer" @click="toggleModal"
                >close</span
              >
            </div>

            <h3 class="mt-3 font-semibold text-center">
              {{ userDetails.employee_id }} -
              {{
                userDetails.first_name
                  ? userDetails.first_name[0].toUpperCase() +
                    userDetails.first_name.substring(1)
                  : ''
              }}
              {{
                userDetails.last_name
                  ? userDetails.last_name[0].toUpperCase() +
                    userDetails.last_name.substring(1)
                  : ''
              }}
            </h3>
            <span class="text-sm text-blue-500 text-center">{{
              userDetails.email
            }}</span>
            <span class="text-sm text-center">{{
              userDetails.designation
            }}</span>
            <div class="flex flex-row mt-5 items-center justify-center">
              <!-- <button
                class="bg-blue-500 text-white mx-4 active:bg-green-600 font-bold uppercase text-xs px-4 py-1 shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
              >
                <div class="flex flex-row items-center justify-between">
                  <span class="font-thin"> My Account </span>
                </div>
              </button> -->
              <button
                class="bg-gray-200 text-red-500 mx-4 active:bg-green-600 font-bold uppercase text-xs px-4 py-1 outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                @click="logoutUser"
              >
                <div class="flex flex-row items-center justify-between">
                  <span class="font-thin"> Sign Out </span>
                </div>
              </button>
            </div>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto"></div>
          <!--footer-->
          <div
            class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
          ></div>
        </div>
      </div>
    </div>
    <div
      v-if="$store.state.settings.openRightSideBar"
      class="opacity-50 fixed inset-0 z-40 bg-white"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RightModal',
  data() {
    return {
      baseImageUrl: '',
    };
  },
  computed: mapState('user', ['userDetails']),
  methods: {
    toggleModal() {
      this.$store.commit('settings/toggleRightSideBar');
    },
    logoutUser() {
      this.$store.commit('settings/toggleRightSideBar');
      this.$store.dispatch('user/logoutUser');
      this.$router.push('/signin');
    },
    getInitials(firstName, lastName) {
      const firstNameIntial =
        firstName && firstName[0] && firstName[0].toUpperCase();
      const lastNameInitial =
        lastName && lastName[0] && lastName[0].toUpperCase();
      return (firstNameIntial || '') + (lastNameInitial || '');
    },
  },
  created() {
    this.baseImageUrl = process.env.VUE_APP_BASE_IMAGE_URL;
  },
};
</script>
