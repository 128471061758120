




























import Vue from 'vue';
import RequirementPieChart from './RequirementPieChart.vue';

export default Vue.extend({
  name: 'RequirementChart',
  components: {
    RequirementPieChart,
  },
  data() {
    return {
      isLoading: true,
      backgroundColors: {
        Joined: '#8dcc85',
        'Offered and yet to join': '#e6ef59',
        'Candidate selected': '#f2de48',
        'Candidate submitted': '#f9e22b',
        'On hold': '#e3dbe7',
        'Interview scheduled': '#ffc107',
        'Candidate rejected': '#f29c9b',
        'Declined offer or not joined': '#F56C6B',
      } as any,
      pieData: {
        labels: [],
        datasets: [
          {
            label: 'Profiles',
            backgroundColor: [],
            data: [],
          },
        ],
      } as any,
      chartOptions: {
        hoverBorderWidth: 20,
      },
    };
  },
  props: ['chartData', 'totalProfiles'],
  watch: {
    chartData: function (newVal, _oldVal) {
      this.pieChart(newVal);
    },
  },
  methods: {
    pieChart(chartData: any) {
      this.pieData.labels = Object.keys(chartData);
      this.pieData.datasets[0].data = Object.values(chartData);
      this.pieData.datasets[0].backgroundColor = Object.keys(chartData).map(
        (data: any) => this.backgroundColors[data]
      );
      this.isLoading = false;
    },
  },
});
