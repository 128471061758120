<template>
  <div
    v-if="project.length > 0"
    tabindex="0"
    class="z-10 border-l-2 overflow-auto"
    style="width: 50%"
  >
    <div :key="project.code" v-for="project in project">
      <div class="border-b-2 bg-gray-100 h-24">
        <button class="float-right text-gray-300" @click="closeDetails()">
          <span class="material-icons">close</span>
        </button>

        <p class="font-bold text-sm pt-8">
          {{ project.name }}
        </p>
        <span class="inline-block text-gray-500 text-xs">
          {{ project.code }}</span
        >
      </div>
      <div class="border-b-2 h-28 pl-4 pt-4">
        <p class="text-sm font-bold text-left">
          <span class="pb-1 border-gray-300 border-b-2">Project Details</span>
        </p>
        <p class="text-left pt-3 text-gray-500 text-xs">
          {{ project.practice }}
        </p>
        <p class="text-left pt-2 text-gray-500 text-xs">
          {{
            globalDateFormat(project.start_date) +
            ' - ' +
            globalDateFormat(project.end_date)
          }}
        </p>
      </div>
      <div class="pl-4 pt-2 pb-2">
        <p class="font-thin text-sm font-bold text-left pb-4">
          <span class="pb-1 border-gray-300 border-b-2"
            >Project Managers(s)</span
          >
        </p>
        <div v-if="project.project_managers.length > 0">
          <div
            :key="manager.id"
            v-for="manager in project.project_managers"
            class="flex flex-row pb-3"
          >
            <img
              v-if="manager.image"
              :src="baseImageUrl + manager.image"
              class="rounded-full h-10 w-10 items-center justify-center"
            />
            <div
              v-else
              style="border-radius: 50%"
              class="h-10 w-10 border-gray-400 border-2 bg-gray-200 flex items-center justify-center text-base font-thin text-gray-500 text-xs rounded-full"
            >
              {{ getInitials(manager.first_name, manager.last_name) }}
            </div>
            <div class="flex flex-col">
              <router-link :to="`/manage/users/${manager.id}`"
                ><span
                  @click="getProfile"
                  class="text-sm text-blue-400 float-left pl-2"
                  >{{ manager.first_name + ' ' + manager.last_name }}
                </span></router-link
              >
              <span class="text-xs pl-2">{{ manager.email }}</span>
            </div>
          </div>
        </div>
        <div v-else class="pl-2 flex flex-row text-gray-500 text-sm">
          No Manager(s) Assigned
        </div>
      </div>
      <div class="absolute bottom-0 ml-32 pb-4">
        <router-link :to="`/manage/projects/${project.id}`">
          <button
            @click="getProfile"
            class="relative inline-flex bg-green-500 focus:outline-none border border-transparent hover:bg-green-400 rounded text-white px-5 h-8 flex items-center text-sm"
          >
            <span class="text-sm">View Project</span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchProjectDetails',
  data() {
    return {
      project: [],
      managerDetail: [],
    };
  },
  props: ['projectDetail', 'projectManager'],
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
    ...mapState('user', ['userDetails']),
  },
  methods: {
    getInitials(firstName, lastName) {
      const firstNameIntial =
        firstName && firstName[0] && firstName[0].toUpperCase();
      const lastNameInitial =
        lastName && lastName[0] && lastName[0].toUpperCase();
      return (firstNameIntial || '') + (lastNameInitial || '');
    },
    getProfile() {
      this.$emit('getProfile');
    },
    closeDetails() {
      this.$emit('close');
    },
  },
  created() {
    this.baseImageUrl = process.env.VUE_APP_BASE_IMAGE_URL;
    this.project = this.projectDetail;
  },
};
</script>
