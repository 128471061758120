<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        Contractor Data
      </div>

      <div class="flex flex-row items-center">
        <!-- Bulk Upload  template-->
        <div class="hidden md:block" v-if="isUploadPermission">
          <label
            @click="downloadCsvTemplate"
            class="w-44 mr-2 flex flex-col items-center px-0 py-1 b-1 border-green-500 text-green-500 rounded-lg tracking-wide border border-blue cursor-pointer hover:bg-green-400 hover:text-white"
          >
            <div
              class="flex flex-row items-center justify-between"
              v-tippy
              content="Download bulk upload empty template"
            >
              <span class="material-icons m-auto text-xl">cloud_download</span>
              <span class="text-sm leading-normal ml-3">Download template</span>
            </div>
          </label>
        </div>
        <!-- Add New END -->
        <!-- Bulk Upload -->
        <div class="hidden md:block" v-if="isUploadPermission">
          <UploadFormDataButton
            v-tippy
            content="Choose .csv file to upload users"
            :label="'Upload Bulk User'"
            @uploadFile="uploadFile"
          />
        </div>
        <!-- Add New -->
        <div class="hidden md:block">
          <div v-if="isManagePermission" class="lg:ml-6 flex items-center">
            <button
              @click="openContractorDataModal(null)"
              class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
            >
              <span class="material-icons m-auto mr-1 text-base">add</span>
              <span class="text-sm"> Add New</span>
            </button>
          </div>
        </div>
        <!-- Add New END -->
        <div></div>
      </div>
    </div>

    <CustomFilter
      v-if="showCustomFilter && (isReadPermission || isExportPermission)"
      :filterBy="filterBy"
      @resetFilter="resetFilter"
      @setContractorStatus="setContractorStatus"
      @toggleFilter="toggleFilter"
    />
    <!-- Top Bar END-->
    <div
      class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
    >
      <div class="flex flex-row py-4 px-4 items-center justify-between">
        <!-- Icons -->
        <div class="flex items-center">
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
        </div>
        <!-- Icons END -->

        <div class="flex flex-row items-center">
          <!-- Search Input -->
          <div class="relative text-gray-600">
            <input
              v-tippy
              :content="'Search Vendor/Resource/Client Name'"
              v-model="query"
              @input="setQuery"
              type="search"
              name="search"
              placeholder="Search"
              class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
            />
            <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
              <span class="material-icons md:text-2xl text-sm text-gray-400"
                >search</span
              >
            </button>
          </div>
          <!-- Search END -->
          <span
            v-if="isReadPermission || isExportPermission"
            class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-6"
            @click="toggleFilter"
            >filter_alt</span
          >
          <!-- Search END -->
        </div>
      </div>

      <!-- Table -->
      <Loading v-if="isLoading" class="bg-white" style="height: 26rem" />
      <div v-else class="flex flex-col" style="height: 26rem">
        <div
          class="flex-grow overflow-auto"
          v-if="isReadPermission && contractor_data.length > 0"
        >
          <table
            id="contractor_table"
            class="relative w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
          >
            <thead class="bg-gray-400">
              <tr class="text-gray-100 text-left">
                <th></th>
                <th
                  class="font-semibold text-xs uppercase px-4 py-2"
                  :key="index"
                  v-for="(column, index) in columns"
                >
                  {{ column }}
                </th>
                <th
                  class="font-semibold text-xs uppercase px-4 py-2"
                  v-if="isManagePermission"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
              <template v-for="(contractor, index) in contractor_data">
                <tr
                  :key="index"
                  :class="[
                    { opened: opened.includes(contractor.id) },
                    opened.includes(contractor.id)
                      ? 'bg-green-100 open_td'
                      : '',
                    index % 2 === 1 ? 'bg-gray-100' : '',
                  ]"
                  class="px-4 py-2"
                  @click="toggle(contractor.id)"
                >
                  <td class="px-4 py-2 text-xs">
                    <span
                      style="float: right"
                      class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-blue-500"
                    >
                      <p
                        v-if="opened.includes(contractor.id)"
                        style="color: red"
                      >
                        expand_less
                      </p>
                      <p v-else style="color: blue">expand_more</p>
                    </span>
                  </td>
                  <td
                    class="px-4 py-2 text-xs"
                    style="
                      max-width: 150px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :content="contractor.vendor"
                    v-tippy
                  >
                    <p class="">{{ contractor.vendor || '-' }}</p>
                  </td>
                  <td
                    class="px-4 py-2 text-xs"
                    v-if="contractor.resource_user_id > 0"
                  >
                    <p class="">
                      {{ contractor.resource_user_id_emp_id || '-' }}
                    </p>
                  </td>
                  <td class="px-4 py-2 text-xs" v-else>
                    <p class="">-</p>
                  </td>
                  <td
                    class="px-4 py-2 text-xs"
                    v-if="contractor.resource_user_id !== null"
                  >
                    <router-link
                      :to="`/manage/users/${contractor.resource_user_id}`"
                    >
                      <p
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                      >
                        {{ contractor.resource_user_id_full_name }}
                      </p>
                    </router-link>
                    <p class="text-gray-500 text-xs tracking-wide">
                      {{ contractor.resource_user_id_email }}
                    </p>
                  </td>
                  <td class="px-4 py-2 text-xs" v-else>
                    <p class="">{{ contractor.resource }}</p>
                  </td>
                  <td
                    class="px-4 py-2 text-xs"
                    style="
                      max-width: 150px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :content="contractor.client"
                    v-tippy
                  >
                    <p class="">{{ contractor.client }}</p>
                  </td>
                  <td class="px-4 py-2 text-xs">
                    <p class="">{{ contractor.timesheets }}</p>
                  </td>
                  <td class="px-4 py-2 text-xs">
                    <router-link
                      :to="`/manage/users/${contractor.account_manager_id}`"
                    >
                      <p
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                      >
                        {{ contractor.account_manager_name }}
                      </p>
                    </router-link>
                    <p class="text-gray-500 text-xs tracking-wide">
                      {{ contractor.account_manager_email }}
                    </p>
                  </td>
                  <td
                    class="px-4 py-2 text-xs"
                    style="
                      max-width: 150px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :content="contractor.role"
                    v-tippy
                  >
                    <p class="">{{ contractor.role }}</p>
                  </td>
                  <td class="px-4 py-2 text-xs">
                    <p class="text-center">
                      {{ contractor.vendor_rate || '-' }}
                    </p>
                  </td>
                  <td class="px-4 py-2 text-xs" v-if="isManagePermission">
                    <p align="center">
                      <span
                        @click="() => openContractorDataModal(contractor)"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-blue-500"
                      >
                        edit</span
                      >
                      <span
                        @click="() => onDelete(contractor)"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-red-500"
                      >
                        <i class="material-icons">delete</i>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr
                  :key="contractor.id"
                  :index="contractor.id"
                  v-if="opened.includes(contractor.id)"
                  class="open_content"
                >
                  <td colspan="1"></td>
                  <td colspan="1" class="text-right px-1 py-1">
                    <p class="px-1 py-1"><b>Start Date</b></p>
                    <p class="px-1 py-1"><b>Payment Term</b></p>
                    <p class="px-1 py-1"><b>Comment</b></p>
                    <p class="px-1 py-1"><b>Vendor Contact Person Name</b></p>
                    <p class="px-1 py-1"><b>Resource Personal email ID</b></p>
                  </td>
                  <td colspan="1"></td>
                  <td colspan="2" class="text-left px-1 py-1">
                    <p class="px-1 py-1">
                      {{
                        (contractor.start_date &&
                          globalDateFormat(contractor.start_date)) ||
                        '-'
                      }}
                    </p>
                    <p class="px-1 py-1">
                      {{ contractor.payment_term || '-' }}
                    </p>
                    <p
                      class="px-1 py-1"
                      style="
                        max-width: 150px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      :content="contractor.comment"
                      v-tippy
                    >
                      {{ contractor.comment || '-' }}
                    </p>
                    <p class="px-1 py-1">
                      {{ contractor.vendor_contact_name || '-' }}
                    </p>
                    <p class="px-1 py-1">
                      {{ contractor.resource_email || '-' }}
                    </p>
                  </td>
                  <td colspan="1" class="text-right px-1 py-1">
                    <p class="px-1 py-1"><b>Client Rate</b></p>
                    <p class="px-1 py-1"><b>End Date</b></p>
                    <p class="px-1 py-1"><b>Folder Link</b></p>
                    <p class="px-1 py-1"><b>Critical Hire</b></p>
                    <p class="px-1 py-1"><b>Vendor Contact email ID</b></p>
                  </td>
                  <td colspan="1"></td>
                  <td colspan="2" class="text-left px-1 py-1">
                    <p class="px-1 py-1">{{ contractor.client_rate || '-' }}</p>
                    <p class="px-1 py-1">
                      {{
                        (contractor.end_date &&
                          globalDateFormat(contractor.end_date)) ||
                        '-'
                      }}
                    </p>
                    <p
                      class="px-1 py-1 text-blue-500 cursor-pointer"
                      v-if="contractor.folder_link"
                    >
                      <a :href="contractor.folder_link" target="_blank">
                        {{ contractor.folder_link }}
                      </a>
                    </p>
                    <p class="px-1 py-1" v-else>-</p>
                    <p class="px-1 py-1">
                      <span
                        v-if="contractor.critical_hire == 't'"
                        class="text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full"
                      >
                        Yes
                      </span>
                      <span
                        v-else
                        class="text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full"
                      >
                        No
                      </span>
                    </p>
                    <p class="px-1 py-1">
                      {{ contractor.vendor_contact_email || '-' }}
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!-- Table END -->
        <NotAuthorized v-else-if="!isReadPermission" />
        <NoRecordFound v-else />
      </div>
    </div>
    <div>
      <Pagination
        class="mt-2"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchContractorData"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination -->
    <ConfirmAlertBox
      v-show="isConfirmDeleteModalVisible"
      modalHeadline="Remove Contractor Data?"
      deleteMessage="Do you want to remove the contractor data?"
      @deleteRecordEvent="removeContractor"
      @close="closeConfirmDeleteModal"
    ></ConfirmAlertBox>
    <ContractorDataModal
      v-if="isContractorDataModalVisible"
      :contractor="contractor"
      :isEdit="isEdit"
      @close="closeContractorDataModal"
      @success="success"
    ></ContractorDataModal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import ContractorDataModal from '@/components/finance/ContractorDataModal.vue';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import _debounce from 'lodash/debounce';
import UploadFormDataButton from '@/components/common/UploadFormDataButton.vue';

export default Vue.extend({
  name: 'ContractorData',
  data() {
    return {
      isContractorDataModalVisible: false,
      isConfirmDeleteModalVisible: false,
      query: '',
      columns: [
        'Vendor Name',
        'Emp ID',
        'Resource Name',
        'Client Name',
        'Timesheets',
        'Account Manager',
        'Role',
        'Vendor Rate',
      ],
      filterBy: {
        contractor_status: { label: 'Active', value: 'Active' },
      },
      account_managers: [],
      contractor_data: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      isTableLoading: true,
      opened: [],
      contractor: [],
      selectedContractor: null,
      isEdit: null,
      allMembers: [],
      selectedMember: null,

      isFormValid: false,
      trigger: 0,
      isValueChanged: false,
      groups: [{ start: 0, end: 19, name: '' }],
      showCustomFilter: false,
      nonce: '',
    };
  },
  components: {
    Pagination,
    Loading,
    ContractorDataModal,
    ConfirmAlertBox,
    ExportButtons,
    NoRecordFound,
    CustomFilter,
    UploadFormDataButton,
  },
  methods: {
    toggleFilter() {
      this.showCustomFilter = !this.showCustomFilter;
    },
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchContractorData(1);
    },
    downloadCsvTemplate() {
      const csvText =
        'Vendor Name,Resource Name,Resource Employee ID,Client Name,Timesheets,Account Manager Employee ID,Role,Vendor Rate,Client Rate,Start Date(YYYY-MM-DD),End Date(YYYY-MM-DD),Payment Term,Comment,Folder Link,Critical Hire\n';
      const anchor = document.createElement('a');
      anchor.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText);
      anchor.target = '_blank';
      anchor.download = 'bulkUploadTemplate.csv';
      anchor.click();
    },
    uploadFile: function (formData) {
      axios
        .post('/contractors/bulk', formData)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg || 'Successfully created',
            alertType: 'success',
          });
          this.fetchContractorData(1);
        })
        .catch((error) => {
          const { data } = error.response;
          console.log('data.msg', data.msg);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
            alertDuration: 8,
          });
          console.log('ERR while BulkUpload', error);
        });
    },
    openContractorDataModal(contractor) {
      if (contractor != null) {
        this.isEdit = true;
        this.contractor = contractor;
        this.isContractorDataModalVisible = true;
      } else {
        this.isEdit = false;
        this.contractor = [];
        this.isContractorDataModalVisible = true;
      }
    },
    success() {
      this.isContractorDataModalVisible = false;
      this.isLoading = true;
      this.fetchContractorData(1);
    },
    closeContractorDataModal() {
      this.isContractorDataModalVisible = false;
    },
    onDelete(contractor) {
      this.selectedContractor = contractor;
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    onContratorDataUpdate() {
      this.isLoading = true;
      this.fetchContractorData((_isContractorDataLoaded) => {
        this.isLoading = false;
      });
    },
    removeContractor() {
      const { selectedContractor } = this;
      if (selectedContractor && selectedContractor.id) {
        const payload = {
          contractor_id: selectedContractor.id,
        };
        axios
          .post(`/contractors/remove_contractor`, payload)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.closeConfirmDeleteModal();
            this.fetchContractorData(1);
          })
          .catch((error) => {
            console.error('ERR while removeContractorData', error);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Something went wrong. Please try again',
              alertType: 'error',
            });
          });
      }
    },
    onSuccess(data, isEdit) {
      if (isEdit) {
        this.allMembers.forEach((member) => {
          if (member.id === this.selectedMember.id) {
            member.project_user_details = data;
          }
        });
        this.closeContractorDataModal();
      } else {
        this.$emit('onMembersUpdate');
        this.closeContractorDataModal();
      }
    },
    fetchAccountManagers(callback) {
      axios
        .get(`/contractors/users`)
        .then((response) => {
          this.account_managers = this.filterAccountManagers(response.data);
          callback(true);
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchUsers', error);
          callback();
        });
    },
    filterAccountManagers(account_managers) {
      const managers = [];
      account_managers.forEach((user) => {
        managers.push({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
        });
      });
      return managers;
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchContractorData(1);
      }
    },
    setContractorStatus(option) {
      this.filterBy.contractor_status = option.value;
      this.fetchContractorData(1);
    },
    exportToCSV() {
      const payload = this.getContractorDataPayload();
      axios
        .post(`/contractors/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Contractor_list_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchContractorData', error);
        });
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Contractors: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#contractor_table',
      });
      doc.save(`Contractor_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchContractorData(1);
    },
    getContractorDataPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.contractor_status) {
        payload.status = filterBy.contractor_status.value;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchContractorData(page) {
      this.nonce = Date.now().toString();
      this.isTableLoading = true;
      const { pageSize } = this;
      const payload = this.getContractorDataPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/contractors/all?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, meta, contractor_data } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.contractor_data = contractor_data;
          this.isLoading = false;
          this.isTableLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.contractor_data = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchContractorData', error);
        });
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userId', 'isReadUserPermission']),
  },
  created() {
    this.fetchContractorData = _debounce(this.fetchContractorData, 400);
    this.isReadPermission = this.validatePermission([
      'finance.contractor.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'finance.contractor.manage',
    ]);
    this.isExportPermission = this.validatePermission([
      'finance.contractor.export',
    ]);
    this.isUploadPermission = this.validatePermission([
      'finance.contractor.upload',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchContractorData(1);
    } else {
      this.isLoading = false;
    }
  },
});
</script>

<style scoped>
.open_content {
  width: 100%;
  transition: 1s width ease-in-out;
}
</style>
