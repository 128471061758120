<template>
  <dl class="accordion box" role="presentation">
    <AccordionItem
      v-for="item in content"
      :multiple="multiple"
      :item="item"
      :groupId="groupId"
      :key="item.id"
      @on-update="$emit('on-update')"
    >
    </AccordionItem>
  </dl>
</template>

<script>
import Vue from 'vue';
import AccordionItem from './accordion-item.vue';

export default Vue.extend({
  props: {
    content: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupId: null,
    };
  },
  components: {
    AccordionItem,
  },
  mounted() {
    this.groupId = this.$el.id;
  },
});
</script>

<style scoped>
.accordion {
  padding: 0;
}
.accordion div:not(:last-child) {
  border-bottom: 1px solid rgba(10, 10, 10, 0.1);
}
.accordion div:last-child .accordion-item-details {
  border-radius: 5px;
}
</style>
