<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            class="absolute inset-0 bg-gray-500 opacity-75"
            @click="$emit('close')"
          ></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Update Member:
                  <span class="text-gray-400 ml-2">{{ projectName }}</span>
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="flex-grow overflow-auto w-full">
            <form-generator
              :triggerSubmit="trigger"
              :formFields="formFields"
              :numberOfColumns="1"
              :groups="groups"
              @onFieldChange="onFieldChange"
              @onFormSubmit="onFormSubmit"
            />
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click.prevent="trigger++"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';
import RegEx from '@/components/inputs/RegEx';
import moment from 'moment';

import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'EditMemberModal',
  components: {
    FormGenerator,
  },
  data() {
    return {
      groups: [{ start: 0, end: 12, name: '' }],
      trigger: 0,
      formFields: [
        {
          name: 'email',
          label: 'User',
          required: false,
          type: 'text',
          inputDisabled: true,
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'billability',
          label: 'Billability',
          required: true,
          type: 'select',
          inputDisabled: false,
          options: [
            { label: 'Billable', value: 'Billable' },
            { label: 'Non Billable', value: 'Non Billable' },
            { label: 'Shadow-Budgeted', value: 'Shadow-Budgeted' },
            { label: 'Shadow-Non Budgeted', value: 'Shadow-Non Budgeted' },
          ],
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'start_user_date',
          label: 'Start Date',
          required: true,
          type: 'date',
          minValue: '2014-01-01',
          defaultValue: '2014-01-01',
          validations: {
            required,
          },
        },
        {
          name: 'end_user_date',
          label: 'End Date',
          required: true,
          type: 'date',
          minValue: '2014-01-01',
          defaultValue: '2014-01-01',
          validations: {
            required,
          },
        },
        {
          name: 'allocation',
          label: '% of Allocation',
          required: true,
          type: 'select',
          options: [
            { label: '25%', value: 25 },
            { label: '50%', value: 50 },
            { label: '75%', value: 75 },
            { label: '100%', value: 100 },
          ],
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'hourly_rate',
          label: 'Hourly Rate',
          required: true,
          type: 'text',
          defaultValue: '',
          inputDisabled: false,
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.HOURLY_RATE_DECIMAL_PRECISION),
            required,
          },
        },
        {
          name: 'project_user_status',
          label: 'Status',
          switchLabels: ['Active', 'Inactive'],
          required: true,
          type: 'switch',
          defaultValue: true,
          validations: {
            required,
          },
        },
        {
          name: 'notify_pmo',
          label: 'Notify PMO',
          switchLabels: ['Yes', 'No'],
          required: false,
          type: 'switch',
          defaultValue: false,
        },
      ],
      billableTypes: ['Billable', 'Shadow-Budgeted'],
      users: [],
      selectedUser: {},
      payload: {
        notify_pmo: false,
        project_user_status: true,
      },
      users_with_more_alloction: [],
      is_allocation_changed: false,
      is_user_reactive_in_project: false,
      is_end_date_modified: false,
    };
  },
  props: {
    projectName: {
      type: String,
    },
    projectId: {
      type: Number,
      required: true,
    },
    member: {
      type: Object,
    },
    defaultProject: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    onFieldChange(field, value, allValues) {
      this.payload = Object.assign({}, this.payload, { [field.name]: value });
      if (field.name === 'start_user_date') {
        this.formFields[2].minValue = value;
        this.formFields[3].minValue = value;
      } else if (field.name === 'billability') {
        if (!this.billableTypes.includes(allValues.billability.value)) {
          allValues.hourly_rate = '0';
        } else {
          allValues.hourly_rate = '';
        }
        this.formFields.forEach((child) => {
          if (child.name === 'hourly_rate') {
            child.inputDisabled = !this.billableTypes.includes(value.value);
          }
        });
      } else if (field.name === 'allocation') {
        this.is_allocation_changed = true;
      } else if (field.name === 'project_user_status') {
        if (
          value === false &&
          this.payload.end_user_date > moment().format('YYYY-MM-DD')
        ) {
          this.payload.end_user_date = moment().format('YYYY-MM-DD');
          this.is_end_date_modified = true;
        }
        this.is_user_reactive_in_project = value;
      } else if (field.name === 'end_user_date') {
        if (value <= moment().format('YYYY-MM-DD')) {
          this.payload.project_user_status = false;
          this.is_end_date_modified = true;
        }
      }
    },
    setSelectedBillability(billability) {
      this.payload.billability = billability;
    },
    close() {
      this.$emit('close');
    },
    async updateMember(callback) {
      this.disableModelActions = true;
      if (
        this.is_allocation_changed ||
        this.is_user_reactive_in_project ||
        !this.is_user_reactive_in_project
      ) {
        await this.checkUsersTotalAllocation();
      }
      if (this.users_with_more_alloction.length > 0) {
        let users = this.users_with_more_alloction.map((user) => user.name);
        this.$confirm(
          `User Allocation  for ${users.join(
            ','
          )} is more than 100%. Do you want to continue?`
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.editMember(callback);
          }
        });
      } else {
        this.editMember(callback);
      }
    },

    async editMember(callback) {
      this.disableModelActions = true;
      let { payload } = this;
      payload.billability = payload.billability.value;
      if (!this.billableTypes.includes(payload.billability)) {
        payload.hourly_rate = '0';
      }
      payload.allocation = payload.allocation.value;
      axios
        .post(`/manage/projects/edit_user`, payload)
        .then((response) => {
          const message =
            payload.project_user_status === false && this.is_end_date_modified
              ? 'Modified the End date to Current date and saved successfully.'
              : response.data.msg;
          this.$emit('on-update');
          this.$store.dispatch('settings/showAlert', {
            alertMessage: message,
            alertType: 'success',
          });
          callback(response.data.data);
        })
        .catch((error) => {
          const { data = {} } = error.response;
          console.error('ERR while addManager', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
    async checkUsersTotalAllocation() {
      let { payload } = this;
      payload.allocation =
        payload.project_user_status == false ? 0 : payload.allocation;
      let user_allocation = [];
      user_allocation.push({
        id: payload.user_id,
        allocation: payload.allocation.value,
        project_id: payload.project_id,
      });
      await axios
        .get(`/manage/projects/users_total_allocation/`, {
          params: {
            payload: user_allocation,
          },
        })
        .then((response) => {
          this.users = response.data.data;
          this.users_with_more_alloction = [];
          this.users.forEach((user) => {
            if (user.allocation > 100) {
              this.users_with_more_alloction.push(user);
            }
          });
          this.disableModelActions = false;
        })
        .catch((error) => {
          this.disableModelActions = false;
          const { data = {} } = error.response;
          console.error('ERR while addManager', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },

    validateHourlyRate(billabilityValue, hourly_rate) {
      if (this.billableTypes.includes(billabilityValue)) {
        if (hourly_rate === '0') {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Hourly Rate should be greater than 0',
            alertType: 'error',
          });
          return false;
        }
      }
      return true;
    },

    onFormSubmit(isValid, _values) {
      let { payload } = this;
      const isValidHourlyRate = this.validateHourlyRate(
        payload.billability.value,
        payload.hourly_rate
      );
      if (!isValidHourlyRate) return;
      if (isValid) {
        this.updateMember((values) => {
          if (values && values.length > 0) {
            this.$emit('success', values);
          }
        });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please fill all mandatory fields',
          alertType: 'error',
        });
      }
    },
    convertResponseToPayload(project_user_details, email) {
      let { start_user_date, end_user_date } = project_user_details;
      const payload = project_user_details;
      payload.start_user_date =
        (start_user_date && start_user_date.substring(0, 10)) || '';
      payload.end_user_date =
        (end_user_date && end_user_date.substring(0, 10)) || '';

      this.formFields.forEach((formField) => {
        // update Default for all input type
        if (formField.name === 'hourly_rate') {
          formField.inputDisabled = !this.billableTypes.includes(
            payload['billability']
          );
          if (
            this.billableTypes.includes(payload['billability']) &&
            payload['hourly_rate'] < 1
          ) {
            payload['hourly_rate'] = '';
          }
        }
        if (formField.name === 'email') {
          formField.defaultValue = email;
        } else {
          formField.defaultValue = payload[formField.name];
        }
        // Update Default for select type
        if (formField.type === 'select') {
          // Update Default for single select type
          formField.defaultValue = formField.options.find(
            (option) => option.value === payload[formField.name]
          );
        }
      });
    },
  },
  created() {
    this.payload = { ...this.member.project_user_details };
    this.payload.billability = {
      label: this.payload.billability,
      value: this.payload.billability,
    };
    this.payload.allocation = {
      label: this.payload.allocation,
      value: this.payload.allocation,
    };
    if (this.defaultProject) {
      this.formFields[1].inputDisabled = true;
      this.formFields[4].inputDisabled = true;
    } else {
      this.formFields[1].inputDisabled = false;
    }
    this.payload.email = this.member.email;
    this.convertResponseToPayload(
      this.member.project_user_details,
      this.member.email
    );
  },
});
</script>
