<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  View Member:
                  <span class="text-gray-400 ml-2">{{ projectName }}</span>
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="flex-grow overflow-auto w-full">
            <form-generator
              :triggerSubmit="trigger"
              :formFields="formFields"
              :numberOfColumns="1"
              :groups="groups"
            />
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';

export default Vue.extend({
  name: 'ViewMemberModal',
  components: {
    FormGenerator,
  },
  data() {
    return {
      groups: [{ start: 0, end: 12, name: '' }],
      trigger: 0,
      formFields: [
        {
          name: 'email',
          label: 'User',
          required: false,
          type: 'text',
          inputDisabled: true,
          defaultValue: this.member.email,
        },
        {
          name: 'billability',
          label: 'Billability',
          type: 'text',
          inputDisabled: true,
          defaultValue: this.member.project_user_details.billability,
        },
        {
          name: 'start_user_date',
          label: 'Start Date',
          type: 'text',
          defaultValue: this.member.project_user_details.start_user_date,
        },
        {
          name: 'end_user_date',
          label: 'End Date',
          type: 'text',
          defaultValue: this.member.project_user_details.end_user_date,
          inputDisabled: true,
        },
        {
          name: 'allocation',
          label: '% of Allocation',
          type: 'text',
          defaultValue: this.member.project_user_details.allocation,
          inputDisabled: true,
        },
        {
          name: 'hourly_rate',
          label: 'Hourly Rate',
          required: true,
          type: 'text',
          defaultValue: this.member.project_user_details.hourly_rate,
          inputDisabled: true,
        },
        {
          name: 'project_user_status',
          label: 'Status',
          switchLabels: ['Active', 'Inactive'],
          required: true,
          type: 'switch',
          defaultValue: this.member.project_user_details.project_user_status,
          inputDisabled: true,
        },
        {
          name: 'notify_pmo',
          label: 'Notify PMO',
          switchLabels: ['Yes', 'No'],
          required: false,
          type: 'switch',
          defaultValue: this.member.project_user_details.notify_pmo,
          inputDisabled: true,
        },
      ],
    };
  },
  props: {
    projectName: {
      type: String,
    },
    projectId: {
      type: Number,
      required: true,
    },
    member: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>
