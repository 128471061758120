<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Detailed Log Report
        </div>
        <div>
          <DateRangePickerInput
            v-if="isReadPermission"
            ref="homeDateRangePicker"
            @updateDateRange="setFilterByDateRange"
            :startDate="params && params.from_date"
            :endDate="params && params.to_date"
            popupAlign="center"
          />
        </div>
        <div class="flex flex-row items-center">
          <div class="mr-2">
            <input
              type="checkbox"
              v-model="check"
              true-value="true"
              false-value="false"
              @change="setFilterByIncludeAdmin"
            />
            <label class="text-xs text-gray-700 mt-4" style="margin-left: 10px"
              >Include Admin Users</label
            >
          </div>
          <div v-if="isExportPermission" class="relative text-gray-600">
            <button
              @click="exportToExcel"
              class="w-full text-gray-500 border border-gray-500 hover:bg-gray-500 hover:text-white px-4 py-1 tracking-wide text-white transition-colors duration-200 transform rounded-sm focus:outline-none"
            >
              Export
            </button>
          </div>
        </div>
      </div>
      <!-- Top Bar END-->
      <AgGridTable
        v-if="isReadPermission"
        ref="detailLogReport"
        :perPage="perPage"
        :columnDefs="columnDefs"
        :url="url"
        :params="params"
        :enumValues="enumValues"
        :excelFileName="'Detailed Log Report.xlsx'"
        :defaultFilter="defaultFilter"
      />
      <NotAuthorized v-else />
    </div>
  </div>
</template>

<script>
import AgGridTable from '@/components/tables/AgGridTable.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import moment from 'moment';

export default {
  name: 'DetailLogReport',
  components: {
    AgGridTable,
    DateRangePickerInput,
    NotAuthorized,
  },
  data() {
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    return {
      totalHours: 0,
      isReadPermission: false,
      isExportPermission: false,
      perPage: 50,
      dateRange: {},
      check: false,
      columnDefs: [
        {
          field: 'employee_id',
          headerName: 'Employee ID',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          editable: false,
          pinned: 'left',
        },
        {
          field: 'username',
          headerName: 'Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'location',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'project_name',
          headerName: 'Project',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'employment_type',
          headerName: 'Emp Type',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'project_practice',
          headerName: 'Project Practice',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'user_practice',
          headerName: 'User Practice',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'date',
          headerName: 'Date',
          sortable: true,
        },
        {
          field: 'billability',
          sortable: true,
          headerName: 'Account',
        },
        {
          field: 'project_status',
          headerName: 'Project Status',
          sortable: true,
          filter: 'agTextColumnFilter',
          colId: 'project_status',
          type: ['enumColumn.status'],
          filterParams: {
            buttons: ['apply', 'reset'],
          },
          cellRenderer: (params) => {
            let bgColor = '';
            switch (params.value) {
              case 'Active':
                bgColor =
                  'text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full';
                break;
              case 'Inactive':
                bgColor =
                  'text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full';
                break;
              default:
            }
            return (
              params.value && `<span class="${bgColor}">${params.value}</span>`
            );
          },
        },
        {
          field: 'user_status',
          headerName: 'User Status',
          sortable: true,
          filter: 'agTextColumnFilter',
          type: ['enumColumn.status'],
          colId: 'user_status',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
          cellRenderer: (params) => {
            let bgColor = '';
            switch (params.value) {
              case 'Active':
                bgColor =
                  'text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full';
                break;
              case 'Inactive':
                bgColor =
                  'text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full';
                break;
              default:
            }
            return (
              params.value && `<span class="${bgColor}">${params.value}</span>`
            );
          },
        },
        {
          field: 'hours',
          headerName: 'Hours',
        },
        {
          field: 'issue_name',
          headerName: 'Category',
        },
        {
          field: 'description',
          headerName: 'Description',
        },
        {
          field: 'jira_id',
          headerName: 'Issue/Task ID',
        },
        {
          field: 'sow',
          headerName: 'Sow',
        },
        {
          field: 'primary_product',
          headerName: 'Primary Product',
        },

        {
          field: 'delivery_method',
          headerName: 'Delivery Method',
        },
        {
          field: 'external_id',
          headerName: 'External Id',
        },
      ],
      url: '/reports/detail_log_report',
      enumValues: {
        status: {
          Active: true,
          Inactive: false,
        },
      },
      params: {
        from_date: moment().startOf('month').toDate(),
        to_date: moment().toDate(),
        admin: false,
      },
      defaultFilter: {
        user_status: {
          filterType: 'text',
          type: 'enum.true',
        },
        project_status: {
          filterType: 'text',
          type: 'enum.true',
        },
      },
    };
  },

  created() {
    this.isReadPermission = this.validatePermission([
      'reports.detailed_logs.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.detailed_logs.export',
    ]);
  },
  methods: {
    setFilterByIncludeAdmin() {
      const admin = this.check;
      this.params = { ...this.params, admin };
    },
    exportToExcel() {
      this.$refs.detailLogReport.exportToExcel();
    },
    setFilterByDateRange(dateRange) {
      const from_date = moment(dateRange.startDate).format('YYYY-MM-DD');
      const to_date = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.params = { ...this.params, from_date, to_date };
    },
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
