<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Add Client
                </h3>
              </div>
              <button
                @click="addRow"
                class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
              >
                <span class="material-icons m-auto mr-1 text-base">add</span>
                <span class="text-sm">Add Row</span>
              </button>
            </div>
          </div>
          <hr />
          <div class="overflow-auto h-64 max-h-96">
            <table
              id="clients_table"
              class="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-scroll"
            >
              <thead class="bg-white">
                <tr class="text-gray-600 text-left">
                  <th
                    class="font-semibold text-xs uppercase px-0 py-2 text-left"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    <span :class="index === 0 ? 'pl-3' : 'pl-3'">
                      {{ column }} </span
                    ><span v-if="index === 0" style="color: red">*</span>
                  </th>
                </tr>
              </thead>
              <Loading v-if="isLoading" />
              <tbody
                v-else
                class="divide-y divide-gray-200 text-xs text-gray-700"
              >
                <tr
                  :key="rowIndex"
                  v-for="(rowData, rowIndex) in rows"
                  class="py-2"
                >
                  <td
                    :class="colData.width"
                    class="px-0 text-xs py-2 items-start"
                    style="vertical-align: top"
                    v-for="colData in rowData"
                    :key="colData.name"
                  >
                    <TextInput
                      :value="payload[rowIndex][colData.name]"
                      :name="colData.name"
                      :type="colData.type"
                      :required="colData.required"
                      :disabled="colData.disabled"
                      :validations="colData.validations || null"
                      :label="colData.label"
                      :isEdit="false"
                      :aligned="'vertical'"
                      :errorLabel="colData.errorLabel"
                      @onChange="(e) => onChange(e, rowIndex)"
                    />
                  </td>
                  <span
                    v-if="rows.length > 1"
                    @click="() => removeRow(rowIndex)"
                    class="material-icons align-middle mt-4 mr-2 text-2xl text-gray-500 cursor-pointer hover:text-red-500"
                    >highlight_remove</span
                  >
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click="save"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TextInput from '@/components/inputs/TextInput.vue';
import Loading from '@/components/common/Loading.vue';
import RegEx from '@/components/inputs/RegEx.ts';

export default {
  name: 'AddClientModal',
  components: {
    TextInput,
    Loading,
  },
  data() {
    return {
      columns: ['Client Name', 'Description'],
      isLoading: true,
      rows: [],
      defaultRow: [
        {
          name: 'client_name',
          label: '',
          errorLabel: 'Client Name',
          required: true,
          type: 'text',
          width: 'w-80',
          validations: { regex: RegEx.ATLEAST_ONE_CHAR },
        },
        {
          name: 'description',
          label: '',
          required: false,
          type: 'text',
          width: 'w-80',
        },
      ],
      payload: [],
    };
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    addRow() {
      this.rows.push(this.defaultRow);
      this.payload.push({
        name: '',
        description: '',
        status: true,
      });
    },
    removeRow(index) {
      this.payload.splice(index, 1);
      this.rows.splice(index, 1);
    },
    onChange(e, rowIndex) {
      this.payload[rowIndex][e.target.name] =
        typeof e.target.value === 'string'
          ? e.target.value.replace(/\s+/g, ' ').trim()
          : e.target.value;
    },
    onSelect(option, name, rowIndex) {
      this.payload[rowIndex][name] = option;
    },
    setSelectedBillability(billability) {
      this.payload.billability = billability;
    },
    close() {
      this.$emit('close');
    },
    validateInputs(callback) {
      const errorTextClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600 px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm';
      const inputDivs = document.getElementsByClassName('text_error');
      const selectDivs = document.getElementsByClassName('select_error');
      if (inputDivs.length > 0 || selectDivs.length > 0) {
        let i;

        for (i = 0; i < inputDivs.length; i++) {
          inputDivs[i].className = errorTextClass;
        }
        callback(false);
      } else {
        callback(true);
      }
    },
    createNewClient(callback) {
      let { payload } = this;
      payload.forEach((data) => {
        payload.name = data.client_name;
        payload.status = true;
        payload.description = data.description;
      });
      axios
        .post(`/manage/clients`, {
          payload,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(true);
        })
        .catch((error) => {
          console.error('ERR while createNewClient', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: error.response.data.msg,
            alertType: 'error',
          });
          console.log('Response', error);
          callback();
        });
    },
    save() {
      this.validateInputs((isValid) => {
        if (isValid) {
          this.createNewClient((data) => {
            if (data) {
              this.$emit('success', data);
            }
          });
        } else {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Please fill all mandatory fields',
            alertType: 'error',
          });
        }
      });
    },
  },
  created() {
    this.rows.push(this.defaultRow);
    this.payload.push({
      client_name: '',
      description: '',
      status: true,
    });
    this.isLoading = false;
  },
};
</script>
