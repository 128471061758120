<template>
  <div class="flex w-full items-center justify-center bg-grey-lighter">
    <label
      :class="
        isUploading
          ? 'bg-green-300 cursor-not-allowed'
          : 'bg-green-500 cursor-pointer hover:bg-green-400 hover:text-white'
      "
      class="w-56 flex flex-col items-center px-2 py-1 text-white rounded-lg tracking-wide border border-blue"
    >
      <div v-if="isUploading" class="flex flex-row justify-center items-center">
        <vue-loaders-ball-spin-fade-loader
          scale="0.35"
        ></vue-loaders-ball-spin-fade-loader>
        <span class="text-sm leading-normal ml-3">Uploading...</span>
      </div>
      <div v-else class="flex flex-row items-center justify-between">
        <span class="material-icons m-auto text-xl">cloud_upload</span>
        <span class="text-sm leading-normal ml-3">{{ label }}</span>
        <form enctype="multipart/form-data">
          <input
            type="file"
            class="hidden"
            @change="readFile"
            :accept="'file'"
            ref="imageUploader"
          />
        </form>
      </div>
    </label>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'UploadFormDataButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      default: '.csv',
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
  },
  // setup() {
  //   const imageUploader = ref(null);
  // },
  methods: {
    readFile(event) {
      var file = event.target.files[0];
      this.$refs.imageUploader.value = '';
      let formData = new FormData();
      formData.append('file', file);
      this.$emit('uploadFile', formData);
    },
  },
});
</script>

<style scoped>
.vue-loaders.ball-spin-fade-loader {
  border: none !important;
}
</style>
>
