<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div
        v-for="(group, groupIndex) in groups"
        :key="groupIndex"
        :class="group.noBottomMargin ? '' : 'mb-12'"
      >
        <p class="px-4 text-red-500 mb-3" v-if="notice">{{ notice }}</p>
        <h5 class="px-4 text-blue-500 uppercase mb-3">{{ group.name }}</h5>
        <ul
          :style="gridStyle(group.numberOfColumns || numberOfColumns)"
          class="card-list"
        >
          <li
            :key="formIndex"
            v-for="(formField, formIndex) in formFields.slice(
              group.start,
              group.end
            )"
            class="card-item"
            :style="
              formField.name === 'address'
                ? 'grid-column: 2/span 2;'
                : formField.type === 'checkbox' && !formField.visibility
                ? 'display: none'
                : ''
            "
            :class="
              formField.type === 'switch' ? 'flex items-center w-full' : ''
            "
          >
            <div
              v-if="formField && Object.keys(formField) && !formField.hide"
              class="input-container w-full"
            >
              <div
                v-if="formField.child && formField.child.length > 0"
                class="flex flex-col"
              >
                <div class="flex flex-row">
                  <label
                    class="child-input-label"
                    :style="
                      ['select'].includes(formField.type)
                        ? 'top: -12.0px; font-size: 13px;'
                        : ''
                    "
                    >{{ formField.label
                    }}<span
                      v-if="
                        formField.validations &&
                        formField.validations.hasOwnProperty('required')
                      "
                      style="color: red; margin-left: 1px"
                      >*</span
                    ></label
                  >
                  <div
                    :key="childIndex"
                    v-for="(child, childIndex) in formField.child"
                    class="flex flex-row"
                  >
                    <input
                      :key="child.name"
                      :type="child.type"
                      :name="child.name"
                      :style="child.type === 'date' ? 'padding: 6px 0 5px' : ''"
                      :max="
                        ['date', 'number'].includes(child.type)
                          ? child.maxValue
                          : ''
                      "
                      :min="
                        ['date', 'number'].includes(child.type)
                          ? child.minValue
                          : ''
                      "
                      v-model.trim="$v.inputValidations[child.name].$model"
                      @input="setName(child, $event.target.value)"
                      :class="child.type === 'number' ? 'number_input' : ''"
                      class="px-4 h-8 py-0 border w-full rounded-sm focus:outline-none text-gray-600 text-sm"
                    />
                    <span
                      :class="
                        childIndex < formField.child.length - 1 ? 'mr-3' : ''
                      "
                      class="text-sm text-gray-700 border h-8 rounded-r px-3 pt-1 bg-gray-300 whitespace-no-wrap"
                      >{{ child.label }}</span
                    >
                  </div>
                </div>
                <span
                  v-if="formField.child.length > 0"
                  class="invalid-feedback mt-1.5"
                >
                  {{
                    formField.child
                      .map((c) => generateErrorMessage(c))
                      .filter((i) => !!i)
                      .toString()
                  }}
                </span>
              </div>
              <div
                v-else
                :class="formField.prepandText ? 'flex flex-row' : ''"
                class="mb-2"
              >
                <ejs-multiselect
                  v-if="
                    formField.type &&
                    ['select'].includes(formField.type) &&
                    formField.multiple &&
                    !formField.email
                  "
                  style="font-size: 15px"
                  :id="formField.name"
                  :dataSource="formField.options"
                  :showSelectAll="formField.selectAll"
                  :disabled="formField.inputDisabled"
                  selectAllText="Select All"
                  mode="CheckBox"
                  :fields="fields"
                  v-model.trim="$v.inputValidations[formField.name].$model"
                  @change="setName(formField, $event.value)"
                ></ejs-multiselect>
                <v-select
                  v-if="
                    formField.type &&
                    ['select'].includes(formField.type) &&
                    !formField.multiple &&
                    !formField.email
                  "
                  :key="formField.name"
                  style="
                    border: 0;
                    border-bottom: 1px solid #555;
                    font-size: 15px;
                  "
                  :disabled="formField.inputDisabled"
                  :options="formField.options"
                  :name="formField.name"
                  :multiple="formField.multiple"
                  :placeholder="formField.placeholder"
                  append-to-body
                  v-model.trim="$v.inputValidations[formField.name].$model"
                  @input="(option) => setName(formField, option)"
                >
                  <template slot="option" slot-scope="option">
                    <span class="fa" :class="option.icon"></span>
                    {{ option.label }}
                  </template>
                </v-select>

                <ejs-multiselect
                  v-if="
                    formField.type &&
                    ['select'].includes(formField.type) &&
                    formField.multiple &&
                    formField.email
                  "
                  style="font-size: 15px"
                  :id="formField.name"
                  :showDropDownIcon="true"
                  :dataSource="formField.options"
                  :showSelectAll="formField.selectAll"
                  :disabled="formField.inputDisabled"
                  :allowFiltering="true"
                  :sortOrder="Ascending"
                  selectAllText="Select All"
                  :fields="fields"
                  :itemTemplate="iTemplate"
                  v-model.trim="$v.inputValidations[formField.name].$model"
                  @change="setName(formField, $event.value)"
                ></ejs-multiselect>
                <!-- <div class="flex flex-row"> -->
                <span
                  v-if="formField.prepandText"
                  class="text-sm text-gray-700 border mt-2 rounded-l px-3 pt-1 bg-gray-300 whitespace-no-wrap"
                  >{{ formField.prepandText }}</span
                >
                <input
                  v-if="
                    formField.type &&
                    !['select', 'switch', 'text_area', 'checkbox'].includes(
                      formField.type
                    ) &&
                    $v.inputValidations[formField.name]
                  "
                  :key="formField.name"
                  :type="formField.type"
                  :name="formField.name"
                  :disabled="formField.inputDisabled"
                  :style="formField.type === 'date' ? 'padding: 6px 0 5px' : ''"
                  :placeholder="formField.type === 'date' ? 'dd-mm-yyyy' : ''"
                  :max="
                    ['date', 'number'].includes(formField.type)
                      ? formField.maxValue
                      : ''
                  "
                  :min="
                    ['date', 'number'].includes(formField.type)
                      ? formField.minValue
                      : ''
                  "
                  :onkeydown="formField.type === 'date' ? 'return true' : ''"
                  :class="formField.type === 'number' ? 'number_input' : ''"
                  v-model.trim="$v.inputValidations[formField.name].$model"
                  @input="setName(formField, $event.target.value)"
                />
                <!-- </div> -->
                <input
                  v-if="
                    formField.type &&
                    !['select', 'switch', 'text_area', 'checkbox'].includes(
                      formField.type
                    ) &&
                    !$v.inputValidations[formField.name]
                  "
                  :key="formField.name"
                  :type="formField.type"
                  :name="formField.name"
                  :disabled="formField.inputDisabled"
                  :placeholder="formField.type === 'date' ? 'dd-mm-yyyy' : ''"
                  :max="
                    ['date', 'number'].includes(formField.type)
                      ? formField.maxValue
                      : ''
                  "
                  :min="
                    ['date', 'number'].includes(formField.type)
                      ? formField.minValue
                      : ''
                  "
                  :onkeydown="formField.type === 'date' ? 'return false' : ''"
                  :class="formField.type === 'number' ? 'number_input' : ''"
                  @input="setName(formField, $event.target.value)"
                />
                <textarea
                  v-if="formField.type && formField.type === 'text_area'"
                  :key="formField.name"
                  :type="formField.type"
                  :name="formField.name"
                  :disabled="formField.inputDisabled"
                  @input="setName(formField, $event.target.value)"
                  v-model="$v.inputValidations[formField.name].$model"
                />
                <input
                  v-if="formField.type && formField.type === 'checkbox'"
                  style="
                    border: 0;
                    border-bottom: 1px solid #555;
                    background: transparent;
                    padding: 6px 0 5px;
                    font-size: 15px;
                    color: #222;
                    margin-left: 0px;
                    display: flex;
                    width: 3%;
                  "
                  :key="formField.name"
                  :type="formField.type"
                  :name="formField.name"
                  :disabled="formField.inputDisabled"
                  :checked="formField.checked"
                  @change="setName(formField, $event.target.checked)"
                  v-model="$v.inputValidations[formField.name].$model"
                />
                <div
                  v-if="
                    formField.type &&
                    formField.type === 'switch' &&
                    !['select'].includes(formField.type)
                  "
                  :class="
                    formField.aligned === 'vertical'
                      ? 'flex-col py-2'
                      : 'flex-row justify-between py-3'
                  "
                  class="flex w-full whitespace-nowrap"
                >
                  <span
                    v-if="formField.label"
                    class="text-sm text-gray-500"
                    style="font-size: 13px"
                    >{{ formField.label || ''
                    }}<span
                      v-if="
                        formField.validations &&
                        formField.validations.hasOwnProperty('required')
                      "
                      class="text-red-500 ml-1"
                      >*</span
                    ></span
                  >
                  <div
                    @click="
                      () =>
                        setName(
                          formField,
                          !$v.inputValidations[formField.name].$model
                        )
                    "
                    class="min-w-24 w-28 h-6 flex items-center justify-between bg-gray-300 rounded-full p-1 duration-300 ease-in-out cursor-pointer"
                    :class="{
                      'bg-green-400':
                        $v.inputValidations[formField.name].$model &&
                        !formField.inputDisabled,
                      'bg-green-200':
                        $v.inputValidations[formField.name].$model &&
                        formField.inputDisabled,
                    }"
                  >
                    <span
                      v-if="$v.inputValidations[formField.name].$model"
                      class="text-xs text-white mx-2"
                      >{{
                        (formField.switchLabels && formField.switchLabels[0]) ||
                        'Yes'
                      }}</span
                    >
                    <div
                      class="bg-white w-6 h-5 rounded-full shadow-md transform duration-300 ease-in-out"
                      :class="{
                        'translate-x-1':
                          $v.inputValidations[formField.name].$model,
                      }"
                    ></div>
                    <span
                      v-if="!$v.inputValidations[formField.name].$model"
                      class="text-xs text-right mx-2"
                      :class="{
                        'text-gray-400': formField.inputDisabled,
                        'text-gray-500': !formField.inputDisabled,
                      }"
                      >{{
                        (formField.switchLabels && formField.switchLabels[1]) ||
                        'No'
                      }}</span
                    >
                  </div>
                </div>
                <label
                  class="flex flex-row justify-between w-full"
                  :style="
                    ['select'].includes(formField.type)
                      ? 'top: -12.0px; font-size: 13px;'
                      : ['checkbox'] == formField.type
                      ? 'top: -2px; margin-left: 20px; font-size: 12px;'
                      : ''
                  "
                  v-if="!['switch'].includes(formField.type)"
                >
                  <span>
                    {{ formField.label
                    }}<span
                      v-if="
                        formField.validations &&
                        formField.validations.hasOwnProperty('required')
                      "
                      style="color: red; margin-left: 1px"
                      >*</span
                    >
                  </span>
                  <span v-if="formField.secondaryLabel" class="italic">{{
                    formField.secondaryLabel
                  }}</span>
                </label>
                <span
                  class="invalid-feedback mt-2"
                  :style="formField.multiple ? 'top: 30px' : ''"
                >
                  {{ formField.validations && generateErrorMessage(formField) }}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'FormGenerator',
  data: () => {
    return {
      iTemplate:
        '<div class="flex flex-col"><span class="h-5">${ label }</span><span class="text-sm italic text-gray-400" v-if="email">${email}</span></div>',
      fields: { text: 'label', value: 'value' },
      inputValidations: {},
      submitted: false,
    };
  },
  validations() {
    let valids = {};
    let values = {};
    this.formFields.forEach((formField) => {
      valids[formField.name] = formField.validations || {};
      if (formField.child) {
        formField.child.forEach((formFieldChild) => {
          valids[formFieldChild.name] = formFieldChild.validations || {};
          values[formFieldChild.name] = formFieldChild.defaultValue;
        });
      }
      values[formField.name] = formField.defaultValue;
    });
    this.inputValidations = Object.assign({}, this.inputValidations, values);
    return { inputValidations: valids };
  },
  props: ['formFields', 'numberOfColumns', 'triggerSubmit', 'groups', 'notice'],
  watch: {
    triggerSubmit: function (_newVal, _oldVal) {
      const parsedJSON = JSON.parse(JSON.stringify(this.inputValidations));
      Object.keys(parsedJSON).forEach((inputName) => {
        // filter Single Select Value
        if (
          parsedJSON[inputName] &&
          parsedJSON[inputName].hasOwnProperty('value')
        ) {
          parsedJSON[inputName] = parsedJSON[inputName].value;
        }
      });
      this.$emit('onFormSubmit', this.handleSubmit(), parsedJSON);
    },
  },
  methods: {
    gridStyle(col) {
      return {
        gridTemplateColumns: `repeat(${col}, minmax(100px, 1fr))`,
      };
    },
    generateErrorMessage(formField) {
      const selectedInputValidation = this.$v.inputValidations[formField.name];
      const messages = {
        required: `${formField.label} is required`,
        minLength: `${formField.label} must have at least ${
          selectedInputValidation.$params.minLength &&
          selectedInputValidation.$params.minLength.min
        } characters`,
        maxLength: `${formField.label} Max length is ${
          selectedInputValidation.$params.maxLength &&
          selectedInputValidation.$params.maxLength.max
        } characters`,
        minValueDate: `Invalid ${formField.label}`,
        maxValueDate: `Invalid ${formField.label}`,
        regex: `Invalid ${formField.label}`,
        email: `Invalid Email`,
        minValue: `Please enter higher value`,
        maxValue: `Please enter lower value`,
      };
      if (this.submitted) {
        for (const msg of Object.keys(messages)) {
          if (
            typeof selectedInputValidation[msg] !== 'undefined' &&
            !selectedInputValidation[msg]
          ) {
            return messages[msg];
          }
        }
      }
      return '';
    },
    setName(field, value) {
      const { name } = field;
      this.inputValidations[name] = value;
      if (this.$v.inputValidations[name]) {
        this.$v.inputValidations[name].$touch();
      }
      this.$emit('onFieldChange', field, value, this.inputValidations);
    },
    handleSubmit(_e) {
      this.submitted = true;
      this.$v.inputValidations.$touch();
      if (this.$v.inputValidations.$invalid) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    const multidropdowns = document.getElementsByClassName('e-control-wrapper');
    const items = document.getElementsByClassName('vs__dropdown-toggle');
    const number_inputs = document.getElementsByClassName('number_input');
    items.forEach((item) => {
      item.style['border'] = 0;
      item.style['padding'] = '4.5px 0 4.5px';
    });
    multidropdowns.forEach((item) => {
      item.style['margin-bottom'] = '-13px';
      item.style['border-color'] = '#555';
    });
    number_inputs.forEach((item) => {
      item.oninput = function () {
        const max = parseInt(item.max);
        const min = parseInt(item.min);
        if (parseInt(item.value) > max) {
          item.value = max;
        }
        if (parseInt(item.value) < min) {
          item.value = min;
        }
      };
      item.addEventListener('keypress', (evt) => {
        if (
          (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
          evt.which > 57
        ) {
          evt.preventDefault();
        }
      });
    });
  },
};
</script>

<style scoped>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
.vs__selected {
  margin: 4px -4px 0 !important;
}
/* #vs1__combobox {
   border: 0 !important;
} */
.vs__dropdown-toggle {
  padding: 4.5px 0 4.5px;
}
.input-container {
  position: relative;
  margin-bottom: 0px;
}
.input-container .invalid-feedback {
  font-size: 11px;
  color: red;
  position: absolute;
  bottom: -16px;
  left: 0px;
  /* pointer-event: none; */
  transition: all 0.5s ease-in-out;
}
.input-container label {
  position: absolute;
  top: -12px;
  left: 0px;
  font-size: 12px;
  color: #888;
  /* pointer-event: none; */
  transition: all 0.5s ease-in-out;
}
.input-container input {
  border: 0;
  border-bottom: 1px solid #555;
  background: transparent;
  width: 100%;
  padding: 8px 0 5px 2px;
  font-size: 15px;
  color: #222;
  /* margin-top: 10px; */
}
.input-container textarea {
  border: 0;
  border-bottom: 1px solid #555;
  background: transparent;
  width: 100%;
  padding: 8px 0 5px 2px;
  font-size: 15px;
  color: #222;
  margin-top: 8px;
}
.invalid-feedback {
  font-size: 11px;
  color: red;
}
.input-container input:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid blue;
}
.input-container textarea:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid blue;
}
.btn {
  color: #fff;
  background-color: #e74c3c;
  outline: none;
  border: 0;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-top: 50px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}
/*.btn:after{
	content:"";
	position:absolute;
	background:rgba(0,0,0,0.50);
	top:0;
	right:0;
	width:100%;
	height:100%;
}*/
.child-input-label {
  top: -14px !important;
  font-size: 12px !important;
}
/* .input-container input:focus ~ label,
.input-container input:valid ~ label {
  top: -12px;
  font-size: 12px;
} */
/* GGGGGGGGGGGGGGGGG */
.card-list {
  display: grid;
  grid-gap: 1em;
}
.card-item {
  /* background-color: dodgerblue; */
  padding: 1em 1em 0 1em;
}
body {
  background: #20262e;
  padding: 20px;
  font-family: Helvetica;
}
#app {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s;
}
ul {
  list-style-type: none;
}
input[type='color'] {
  -webkit-appearance: none;
  height: 32px;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  border: none;
}
input[type='date']:after {
  font-family: 'Material Icons';
  content: 'calendar_today';
  color: #555;
  padding-right: 180px;
  padding-top: 20px;
  font-weight: 900;
}
input[type='date']:after {
  padding: 0 20px;
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
