<template>
  <div>
    <div>
      <div
        class="md:h-16 h-12 bg-white flex flex-row shadow-sm items-center justify-between text-left px-5 sticky top-0 z-10"
      >
        <div class="font-bold md:text-md md:text-sm text-xs">
          Welcome back,
          {{ userFullName }}
        </div>
        <div class="flex flex-row items-center">
          <DateRangePickerInput
            v-if="isReadPermission"
            ref="homeDateRangePicker"
            @updateDateRange="setFilterByDateRange"
            :startDate="dateRange && dateRange.startDate"
            :endDate="dateRange && dateRange.endDate"
            :minDate="minDate"
            :maxDate="maxDate"
            popupAlign="left"
          />
        </div>
      </div>
      <Loading class="mt-2" v-if="isLoading" style="height: 80vh" />

      <div
        v-else
        class="md:grid md:grid-cols-5 md:gap-1 md:place-items-start flex flex-col"
      >
        <div class="col-span-2 w-full px-1 md:px-0">
          <BasicInfo
            v-if="isReadPermission || isManagePermission"
            class="md:mt-3 mt-2 w-full"
            :userDetails="userInfo"
            :moreButton="true"
          />
          <ReportingStructure
            v-if="isReadPermission"
            class="md:mt-5 mt-2 w-full"
          />
        </div>
        <div class="col-span-3 w-full md:pl-8 md:pr-5 px-1">
          <Accordion
            v-if="isReadPermission"
            class="md:mt-3 mt-2"
            :activeProjects="activeProjects"
            :defaultProjects="defaultProjects"
            :inActiveProjects="inActiveProjects"
            :totalProjectHours="totalProjectHours"
            :totalProjectCount="totalProjectCount"
          />
          <TotalBurnDownHours
            v-if="isReadPermission"
            class="mt-4 w-full"
            :projects="projects"
            :isLoading="isLoading"
            :key="componentKey"
          />
          <SourceStats v-if="isReadPermission" class="mt-4 w-full mb-14" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import BasicInfo from '@/components/home/BasicInfo.vue';
import ReportingStructure from '@/components/home/ReportingStructure.vue';
import TotalBurnDownHours from '@/components/home/TotalBurnDownHours.vue';
import { mapState } from 'vuex';
import axios from 'axios';
import { mapGetters } from 'vuex';
import Accordion from '@/components/home/Accordion2.vue';
import Loading from '@/components/common/Loading.vue';
import SourceStats from '@/components/home/SourceStats.vue';
import moment from 'moment';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';

export default Vue.extend({
  name: 'Home',
  data: () => ({
    isLoading: true,
    projects: [],
    defaultProjects: [],
    activeProjects: [],
    inActiveProjects: [],
    totalProjectHours: 0,
    totalProjectCount: 0,
    userInfo: {},
    dateRange: {},
    componentKey: 0,
    minDate: '',
    maxDate: '',
    ptoHolidayProjects: ['WSPTO', 'WSHOLIDAY'],
  }),
  components: {
    BasicInfo,
    ReportingStructure,
    Accordion,
    TotalBurnDownHours,
    Loading,
    SourceStats,
    DateRangePickerInput,
  },
  computed: {
    ...mapGetters('user', ['userId', 'isAdmin', 'userFullName']),
    ...mapState('user', ['userDetails']),
  },
  methods: {
    toggleRightSideBar() {
      this.$store.commit('settings/toggleRightSideBar');
    },
    fetchProjects(callback) {
      axios
        .get(`/manage/projects/user`, {
          params: {
            user_id: this.userId,
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
          },
        })
        .then((response) => {
          const { projects, non_billing_project_codes } = response.data;
          const defaultProjects = [];
          const activeProjects = [];
          const inActiveProjects = [];
          let totalProjectHours = 0;
          let totalProjectCount = 0;
          projects.forEach((project) => {
            const {
              name,
              practice,
              allocation,
              start_date,
              end_date,
              hours,
              billability,
              id,
            } = project;
            const period = `${this.globalDateFormat(
              start_date
            )} - ${this.globalDateFormat(end_date)}`;
            const filteredProject = {
              id,
              name,
              practice,
              allocation,
              period,
              hours,
              billability,
            };
            if (
              !project.project_user_status &&
              !this.ptoHolidayProjects.includes(project.code)
            ) {
              inActiveProjects.push(filteredProject);
            } else {
              if (
                non_billing_project_codes.includes(project.code) ||
                this.ptoHolidayProjects.includes(project.code)
              ) {
                defaultProjects.push(filteredProject);
              } else {
                activeProjects.push(filteredProject);
              }
            }
            totalProjectHours += project.hours ? parseFloat(project.hours) : 0;
            totalProjectCount += project.count ? parseInt(project.count) : 0;
          });
          this.projects = projects;
          this.defaultProjects = defaultProjects;
          this.activeProjects = activeProjects;
          this.inActiveProjects = inActiveProjects;
          this.totalProjectHours =
            totalProjectHours == 0
              ? 0
              : parseFloat(totalProjectHours).toFixed(2);
          this.totalProjectCount = totalProjectCount;
          callback();
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetching projects', error);
          callback();
        });
    },
    fetchUsers(callback) {
      axios
        .get(`/manage/users/${this.userId}`)
        .then((response) => {
          this.userInfo = response.data;
          callback();
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
          callback();
        });
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.dateRange = { startDate, endDate };
      this.fetchProjects(() => {
        this.isLoading = false;
        this.componentKey += 1;
      });
    },
  },
  created() {
    const startDate = moment().startOf('year').toDate();
    const endDate = moment().endOf('year').toDate();
    this.dateRange = { startDate, endDate };
    this.isReadPermission = this.validatePermission(['home.read']);
    this.isManagePermission = this.validatePermission(['home.manage']);
    this.fetchUsers(() => {
      this.fetchProjects(() => {
        this.isLoading = false;
        this.minDate = moment(this.userInfo.joining_date).toDate();
        this.maxDate = moment().toDate();
      });
    });
  },
});
</script>
