<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Interview List
                </h3>
              </div>

              <button
                type="button"
                @click="close"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
            <div>
              <AgGridTable
                v-if="isReadPermission"
                ref="profileInterviewList"
                :perPage="perPage"
                :columnDefs="columnDefs"
                :url="url"
                :enumValues="enumValues"
              />
              <NotAuthorized v-else />
              <TechnicalFeedbackModal
                  :interview_obj="interview_obj"
                  v-if="isTechnicalModalVisible"
                  @close="closeFeedbackModal"
                  @cancel="onCancel"
                />
                <HrFeedbackModal
                  :interview_obj="interview_obj"
                  v-if="isHrViewModalVisible"
                  @close="closeFeedbackModal"
                  @cancel="onCancel"
                />
                <ManagerFeedbackModal
                  :interview_obj="interview_obj"
                  v-if="isManagerViewModalVisible"
                  @close="closeFeedbackModal"
                  @cancel="onCancel"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AgGridTable from '@/components/tables/AgGridTable.vue';
import { EditButtonCellRenderer } from '@/components/tables/renderers';
import TechnicalFeedbackModal from '@/components/recruitment/requirementList/TechnicalFeedbackModal.vue';
import HrFeedbackModal from '@/components/recruitment/requirementList/HrFeedbackModal.vue';
import ManagerFeedbackModal from '@/components/recruitment/requirementList/ManagerFeedbackModal.vue';
import CustomToolTip from './CustomToolTip.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileInterviewList',
  data() {
    return {
      isReadPermission: false,
      isManagePermission: false,
      isHrViewModalVisible: false,
      isTechnicalModalVisible: false,
      isManagerViewModalVisible: false,
      interview_obj: null,
      rowData: null,
      url: 'recruitment/interviews/paginate/profile/',
      perPage: 50,
      columnDefs: [
        {
          field: 'interview_obj',
          headerName: 'Feedback',
          cellRenderer: 'scoreCardRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.interview_obj = field;
              if (field.round_type === 'Technical') {
                this.isTechnicalModalVisible = !this.isTechnicalModalVisible;
              }
              if (field.round_type === 'Managerial') {
                this.isManagerViewModalVisible =
                  !this.isManagerViewModalVisible;
              }
              if (field.round_type === 'HR') {
                this.isHrViewModalVisible = !this.isHrViewModalVisible;
              }
            },
          },
        },
        {
          field: 'r_id',
          headerName: 'Req ID',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'job_role',
          headerName: 'Job Role',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },

        {
          field: 'first_name',
          headerName: 'First Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'last_name',
          headerName: 'Last Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'round_type',
          headerName: 'Interview Type',
          sortable: true,
          filter: 'agTextColumnFilter',
          colId: 'round_type',
          type: ['enumColumn.interview_type'],
          filterParams: {
            buttons: ['apply', 'reset'],
          },
          cellRenderer: (params) => {
            let bgColor = '';
            switch (params.value) {
               case 'Technical':
                bgColor =
                  'text-yellow-800 bg-yellow-200 px-2 py-1 text-sm rounded-full';
                break;
              case 'HR':
                bgColor =
                  'text-red-800 bg-red-200 px-2 py-1 text-sm rounded-full';
                break;
              case 'Managerial':
                bgColor =
                  'text-green-800 bg-green-200 px-2 py-1 text-sm rounded-full';
                break;
              default:
            }
            return (
              params.value && `<span class="${bgColor}">${params.value}</span>`
            );
          },
        },
        {
          field: 'round_number',
          headerName: 'Interview Round',
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'date',
          headerName: 'Interview Date & Time',
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue;
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
          },
        },
        {
          field: 'duration',
          headerName: 'Interview Duration',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'mode',
          headerName: 'Interview Mode',
          filter: 'agTextColumnFilter',
           sortable: true,
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'interviewer',
          headerName: 'Interviewer',
          minWidth: 250,
          maxWidth: 500,
          tooltipComponent: 'customTooltip',
          tooltipField: 'interviewer',
          tooltipComponentParams: { color: '#ffffff' },
        },

        {
          field: 'pros',
          headerName: 'Pros',
          tooltipComponent: 'customTooltip',
          tooltipField: 'pros',
          tooltipComponentParams: { color: '#ffffff' },
        },
        {
          field: 'cons',
          headerName: 'Cons',
          tooltipComponent: 'customTooltip',
          tooltipField: 'cons',
          tooltipComponentParams: { color: '#ffffff' },
        },
        {
          field: 'interview_status',
          headerName: 'Status',
          filter: 'agTextColumnFilter',
          minWidth: 250,
          maxWidth: 400,
          sortable: true,
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          cellRenderer: (params) => {
            let bgColor = '';
             switch (params.value) {
              case 'Rejected':
                bgColor =
                  'text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full';
                break;
              case 'On Hold':
                bgColor =
                  'text-yellow-800 bg-yellow-200 font-semibold px-2 py-0 rounded-full';
                break;
              case 'Selected For Next Round':
                bgColor =
                  'text-blue-800 bg-blue-200 font-semibold px-2 py-0 rounded-full';
                break;
              case 'Selected For Final Round':
                bgColor =
                  'text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full';
                break;
              default:
            }
            return (
              params.value && `<span class="${bgColor}">${params.value}</span>`
            );
          },
        },
        {
          field: 'feedback',
          headerName: 'Rating',
          filter: 'agTextColumnFilter',
          sortable: true,
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
      ],

      enumValues: {},
    };
  },

  props: {
    profileId: {
      type: Number,
    },
  },

  components: {
    AgGridTable,
    scoreCardRenderer: EditButtonCellRenderer,
    TechnicalFeedbackModal,
    ManagerFeedbackModal,
    HrFeedbackModal,
    customTooltip: CustomToolTip,
  },
  computed: {
    ...mapGetters('user', ['userId', 'isAdmin', 'userFullName']),
  },
  methods: {
    close() {
      this.$emit('close');
    },

    onCancel() {
      this.isHrViewModalVisible = false;
      this.isTechnicalModalVisible = false;
      this.isManagerViewModalVisible = false;      
    },

    closeFeedbackModal() {
      this.isHrViewModalVisible = false;
      this.isTechnicalModalVisible = false;
      this.isManagerViewModalVisible = false;
      this.$refs.profileInterviewList.updateDataSource();
    },
  },
  created() {
    this.isReadPermission = this.validatePermission([
      'recruitment.candidates_list.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'recruitment.candidates_list.manage',
    ]);

    this.url = this.url + this.profileId;
  },
};
</script>