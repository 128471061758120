<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ selectedRole.name }}:
                  <span class="text-gray-400 ml-2">{{
                    selectedRolePermission.name || ''
                  }}</span>
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="text-xs px-4 py-4 text-center">
            {{ selectedRolePermission.description }}
          </div>

          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              v-if="selectedRole.id && isManagePermission"
              type="button"
              @click="onDelete"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-700 text-base font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Delete
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ConfirmAlertBox
        v-if="isConfirmDeleteModalVisible && isManagePermission"
        modalHeadline="Remove permission?"
        deleteMessage="Are you sure?"
        @deleteRecordEvent="removeRolePermission"
        @close="closeConfirmDeleteModal"
      ></ConfirmAlertBox>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';

export default {
  name: 'RolePermissionModal',
  components: {
    ConfirmAlertBox,
  },
  data() {
    return {
      isConfirmDeleteModalVisible: false,
    };
  },
  props: {
    selectedRolePermission: {
      name: String,
      description: String,
      required: true,
    },
    selectedRole: {
      required: true,
    },
    isManagePermission: {
      required: false,
    },
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    onDelete() {
      this.isConfirmDeleteModalVisible = true;
    },
    removeRolePermission() {
      const { selectedRole, selectedRolePermission } = this;
      if (selectedRole && selectedRole.id) {
        axios
          .delete(
            `/authorization/roles/${selectedRole.id}/permissions/${selectedRolePermission.id}`
          )
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.closeConfirmDeleteModal();
            this.$emit('success');
            // this.fetchPractices(this.paginationMeta.current_page);
          })
          .catch((error) => {
            console.error('ERR while removeRolePermission', error);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Something went wrong. Please try again',
              alertType: 'error',
            });
          });
      }
    },
  },
};
</script>
