




































































import Vue from 'vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import moment from 'moment';
import axios from 'axios';

export default Vue.extend({
  name: 'detailedPerformanceReport',
  data() {
    return {
      startDate: moment().startOf('year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      isReadPermission: true,
      recruiters: [],
      selectedRecruiters: [],
      fields: { text: 'label', value: 'value' },
      isLoading: false,
      isExportPermission: true,
    };
  },
  components: { DateRangePickerInput },
  methods: {
    setFilterByDateRange(dateRange: any) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.startDate = startDate;
      this.endDate = endDate;
    },
    setRecruiters(option: any) {
      this.selectedRecruiters = option.value || [];
    },
    getRecruiters() {
      axios
        .get('/manage/users/recruiter_users')
        .then((res) => {
          const { data } = res;
          this.recruiters = data;
        })
        .catch(() => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
        });
    },
    generateReport() {
      const { startDate, endDate, selectedRecruiters } = this;
      if (selectedRecruiters.length <= 0) {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please select atleast one recruiter',
          alertType: 'error',
        });
        return;
      }
      this.isLoading = true;
      this.$store.dispatch('settings/showAlert', {
        alertMessage: 'Report is being exported. please wait',
        alertType: 'success',
      });
      const generated_on = moment().format('YYYY-MM-DD');

      axios
        .get('/recruitment/requirements/recruiter_detailed_performance', {
          params: {
            from_date: startDate,
            to_date: endDate,
            recruiters: selectedRecruiters,
            generated_on,
          },
        })
        .then((response) => {
          this.isLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link: any = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `recruiter_detailed_performance${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
        });
    },
  },
  created() {
    this.getRecruiters();

    this.isReadPermission = (this as any).validatePermission([
      'recruitment.overall_performance.read',
    ]);
    this.isExportPermission = (this as any).validatePermission([
      'recruitment.overall_performance.export',
    ]);
  },
});
