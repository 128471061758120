<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Overall Log Report
        </div>
        <CustomFilter
          v-if="showOverallReportFilter"
          :filterBy="filterBy"
          :projectPractices="projectPractices"
          @resetFilter="resetFilter"
          @setFilterByProjectPractices="setFilterByProjectPractices"
          @setFilterByUserStatus="setFilterByUserStatus"
          @setFilterByProjectUserStatus="setFilterByProjectUserStatus"
          @setFilterByProjectStatus="setFilterByProjectStatus"
          @setFilterByProjects="setFilterByProjects"
          @setFilterByAdmin="setFilterByAdmin"
          @toggleFilter="toggleFilter"
          :projectOptions="projectOptions"
          :projectOptionMeta="projectOptionMeta"
          @loadMore="loadMore"
          @searchQuery="searchQuery"
          :adminUser="adminUser"
          :user_status="user_status"
          :project_status="project_status"
          :project_user_status="project_user_status"
        />
        <DateRangePickerInput
          v-if="isReadPermission"
          ref="overallLogDateRangePicker"
          @updateDateRange="setFilterByDateRange"
          :startDate="filterBy.dateRange && filterBy.dateRange.startDate"
          :endDate="filterBy.dateRange && filterBy.dateRange.endDate"
          popupAlign="left"
        />
      </div>
      <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
        <div class="flex py-4 px-4 justify-between items-center">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :excel="true"
            @exportToExcel="exportToExcel"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div class="flex flex-row items-center">
            <!-- Search Input -->
            <div
              class="relative text-gray-600"
              v-if="isReadPermission"
              style="margin-right: 30px"
            >
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              v-if="isReadPermission"
              class="material-icons text-2xl cursor-pointer select-none text-gray-500"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <Loading v-if="isTableLoading" style="height: 26rem" />

        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && logs.length > 0"
          >
            <table
              ref="table"
              id="consolidated"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400 text-left"
                  >
                    PROJECT NAME
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <template v-for="(log, index) in logs">
                  <tr
                    :key="log.id"
                    class="py-2"
                    :class="[
                      { opened: opened.includes(log.id) },
                      index % 2 == 1 ? 'bg-gray-50' : 'bg-white',
                    ]"
                    @click="toggle(log.id)"
                  >
                    <td
                      class="text-xs p-4 font-semibold text-blue-500 cursor-pointer"
                    >
                      <router-link
                        v-if="isReadProjectPermission"
                        :to="`/manage/projects/${log.id}`"
                      >
                        <b>{{ log.project_name }}</b>
                      </router-link>
                      <b v-else>{{ log.project_name }}</b>
                      <span
                        style="float: right"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-blue-500"
                      >
                        <p v-if="opened.includes(log.id)">expand_less</p>
                        <p v-else>expand_more</p>
                      </span>
                    </td>
                  </tr>
                  <tr
                    colspan="3"
                    :key="log.id"
                    v-if="opened.includes(log.id)"
                    :class="index % 2 == 1 ? 'bg-gray-50' : 'bg-white'"
                  >
                    <div v-if="log.users.length > 0" class="overflow-auto">
                      <table
                        ref="table"
                        id="overall_log_report"
                        class="relative w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300"
                      >
                        <tr :class="index % 2 == 1 ? 'bg-gray-50' : 'bg-white'">
                          <template v-for="(header, headerIndex) in log.header">
                            <td
                              :key="log.id + '-' + headerIndex"
                              class="text-xs text-center p-2 text-gray-100 bg-gray-400"
                              style="border: 1px solid white"
                            >
                              <span
                                v-if="
                                  header == 'User Name' ||
                                  header == 'Total' ||
                                  header == 'Total Duration'
                                "
                              >
                                <b>{{ header }}</b></span
                              >
                              <span v-else>
                                <b>{{ globalDateFormat(header) }}</b>
                              </span>
                            </td>
                          </template>
                        </tr>
                        <template v-for="(user, userIndex) in log.users">
                          <tr
                            :key="userIndex"
                            :class="
                              userIndex % 2 == 1 ? 'bg-gray-50' : 'bg-white'
                            "
                          >
                            <td
                              v-if="isReadUserPermission"
                              class="text-xs p-2 font-semibold text-blue-500"
                              style="border-right: 1px solid lightgray"
                            >
                              <router-link :to="`/manage/users/${user.id}`">
                                <p
                                  class="text-xs font-semibold text-blue-500 cursor-pointer"
                                >
                                  {{ user.name }}
                                </p>
                              </router-link>
                              <p class="text-gray-400 text-xs tracking-wide">
                                {{ user.email }}
                              </p>
                            </td>
                            <td
                              v-else
                              class="text-xs p-2"
                              style="border-right: 1px solid lightgray"
                            >
                              <p class="text-xs font-semibold">
                                {{ user.name }}
                              </p>
                              <p class="text-gray-400 text-xs tracking-wide">
                                {{ user.email }}
                              </p>
                            </td>
                            <td
                              class="text-xs p-2 text-center"
                              style="border-right: 1px solid lightgray"
                            >
                              {{ user.total_log }}
                            </td>
                            <template v-for="(log_data, index) in user.logs">
                              <td
                                :key="index"
                                class="text-xs p-2 text-center"
                                style="border-right: 1px solid lightgray"
                              >
                                {{ log_data }}
                              </td>
                            </template>
                          </tr>
                        </template>
                        <tr>
                          <template v-for="(total_data, index) in log.total">
                            <td
                              :key="index"
                              class="text-xs text-center p-2 text-gray-100 bg-gray-400"
                              style="border: 1px solid white"
                            >
                              <b>{{ total_data }}</b>
                            </td>
                          </template>
                        </tr>
                      </table>
                    </div>
                    <div
                      v-else
                      class="text-xs p-4 font-semibold text-black-500"
                    >
                      No Records Found
                    </div>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
      </div>
      <!-- Pagination -->
      <div class="mt-3">
        <Pagination
          class="mt-2"
          v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchOverallLogReport"
          @setPageSize="setPageSize"
        />
      </div>
    </div>
    <!-- Pagination End-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import 'jspdf-autotable';
import Loading from '@/components/common/Loading.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import Pagination from '@/components/common/Pagination.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'OverallLogReport',
  components: {
    Loading,
    DateRangePickerInput,
    CustomFilter,
    Pagination,
    NotAuthorized,
    NoRecordFound,
    ExportButtons,
  },
  data() {
    const startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    return {
      statuses: [
        { label: 'All', value: '' },
        { label: 'Active', value: true },
        { label: 'Inactive', value: false },
      ],
      showOverallReportFilter: false,
      overall_log_data: [],
      projectPractices: [],
      projectOptions: [],
      projectOptionMeta: {},
      adminUser: 'No',
      user_status: { label: 'Active', value: 'true' },
      project_status: { label: 'Active', value: 'true' },
      project_user_status: { label: 'Active', value: 'true' },
      logs: [],
      filterBy: {
        projectPractices: [],
        projects: [],
        user_status: { label: 'Active', value: 'true' },
        project_status: { label: 'Active', value: 'true' },
        project_user_status: { label: 'Active', value: 'true' },
        dateRange: { startDate, endDate },
        adminUser: false,
      },
      query: '',
      isTableLoading: true,
      opened: [],
      open: true,
      paginationMeta: {},
      pageSize: 30,
      defaultFilterBy: {},
      isReadPermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
  },
  created() {
    this.fetchOverallLogReport = _debounce(this.fetchOverallLogReport, 400);
    this.isReadPermission = this.validatePermission([
      'reports.overall_logs.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.overall_logs.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };

    if (this.isReadPermission) {
      this.fetchProjectPractices();
      this.fetchProjects(1);
      this.fetchOverallLogReport(1);
    } else {
      this.isTableLoading = false;
    }
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      const { defaultDateRange } = this.$refs.overallLogDateRangePicker;
      this.$refs.overallLogDateRangePicker.dateRange = defaultDateRange;
      this.setFilterByDateRange(defaultDateRange);
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    toggleFilter() {
      this.showOverallReportFilter = !this.showOverallReportFilter;
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchOverallLogReport(1);
      }
    },
    loadMore(page) {
      this.fetchProjects(page);
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchOverallLogReport(1);
    },
    setFilterByAdmin(e) {
      this.filterBy.adminUser = e.target.value;
      this.fetchOverallLogReport(1);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.projectOptions = [];
      this.fetchProjects(1);
      this.fetchOverallLogReport(1);
    },
    setFilterByUserPractices(option) {
      this.filterBy.userPractices = option;
      this.fetchOverallLogReport(1);
    },
    setFilterByUserStatus(option) {
      this.filterBy.user_status = option;
      this.fetchOverallLogReport(1);
    },
    setFilterByProjectUserStatus(option) {
      this.filterBy.project_user_status = option;
      this.fetchOverallLogReport(1);
    },
    setFilterByProjectStatus(option) {
      this.filterBy.project_status = option;
      this.projectOptions = [];
      this.fetchProjects(1);
      this.fetchOverallLogReport(1);
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchOverallLogReport(1);
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchOverallLogReport(1);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchProjects(1, true, query);
      }
    },
    fetchProjectPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getProjectPayload() {
      const { filterBy } = this;
      const payload = {};
      const projectPractices = filterBy.projectPractices.map(
        (practice) => practice.label
      );
      if (projectPractices.length > 0) {
        payload.practice = projectPractices;
      }
      if (filterBy.project_status) {
        payload.project_status = filterBy.project_status.value;
      }
      return payload;
    },
    fetchProjects(page, _isSearch = false, _query = null) {
      const payload = this.getProjectPayload();
      axios
        .get(`/manage/projects/project_list_overall_log_report`, {
          params: {
            projects: payload.projects,
            project_status: payload.project_status,
            practice: payload.practice,
          },
        })
        .then((response) => {
          const projectOptions = response.data.projects.map((project) => {
            return { label: project.name, value: project.id };
          });
          this.projectOptions = projectOptions;
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    getLogPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (filterBy.user_status) {
        payload.user_status = filterBy.user_status.value;
      }
      if (filterBy.project_user_status) {
        payload.project_user_status = filterBy.project_user_status.value;
      }
      if (filterBy.project_status) {
        payload.project_status = filterBy.project_status.value;
      }
      if (filterBy.dateRange) {
        payload.date_range = JSON.stringify(filterBy.dateRange);
      }
      if (query) {
        payload.search = query;
      }
      payload.admin = filterBy.adminUser;
      return payload;
    },
    fetchOverallLogReport(page) {
      this.nonce = Date.now().toString();
      const { pageSize } = this;
      const payload = this.getLogPayload();
      axios
        .get(
          `/reports/overall_log_report?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              project_status: payload.project_status,
              project_practice: payload.practice,
              user_status: payload.user_status,
              project_user_status: payload.project_user_status,
              date_range: payload.date_range,
              projects: payload.projects,
              search: payload.search,
              admin_user: payload.admin,
              nonce: this.nonce,
            },
          }
        )
        .then((response) => {
          const { nonce, meta, logs } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.logs = logs;
          this.isTableLoading = false;
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Overall Log Report', error);
          this.isTableLoading = false;
        });
    },
    exportToExcel() {
      const payload = this.getLogPayload();
      axios
        .get(`/reports/download_overall_log_report_excel`, {
          params: {
            project_status: payload.project_status,
            project_practice: payload.projectPractices,
            user_status: payload.user_status,
            project_user_status: payload.project_user_status,
            date_range: payload.date_range,
            projects: payload.projects,
            search: payload.search,
            admin_user: payload.admin,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Overall_Log_Report.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Overall Log Report', error);
        });
    },
  },
};
</script>

<style scoped>
.opened {
  background-color: gray;
}
</style>
