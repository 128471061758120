<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen min-w-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-1/3"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Official Info
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <div
            class="overflow-auto h-70 max-h-96 px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
          >
            <div class="sm:flex sm:items-start flex flex-col w-full">
              <label class="leading-loose">Experience</label>
              <div class="flex flex-row">
                <div class="flex flex-col">
                  <div class="flex flex-row">
                    <input
                      v-model="expYear"
                      @change="setExpYear"
                      type="number"
                      :max="80"
                      :min="0"
                      @keydown="handleKeyPress"
                      class="px-4 h-8 py-0 border w-full rounded-sm focus:outline-none text-gray-600 text-sm number_input"
                    />
                    <span
                      class="text-sm text-gray-700 border h-8 rounded-r px-3 pt-1 bg-gray-300 whitespace-no-wrap mr-3"
                    >
                      Years
                    </span>
                  </div>
                  <span
                    class="invalid-feedback mt-2"
                    v-if="!$v.expYear.maxValue || !$v.expYear.minValue"
                  >
                    Invalid Year
                  </span>
                </div>

                <div class="flex flex-col">
                  <div class="flex flex-row">
                    <input
                      v-model="expMonth"
                      @change="setExpMonth"
                      type="number"
                      :max="11"
                      :min="0"
                      @keydown="handleKeyPress"
                      class="px-4 h-8 py-0 border w-full rounded-sm focus:outline-none text-gray-600 text-sm number_input"
                    />
                    <span
                      class="text-sm text-gray-700 border h-8 rounded-r px-3 pt-1 bg-gray-300 whitespace-no-wrap mr-3"
                    >
                      Months
                    </span>
                  </div>
                  <span
                    class="invalid-feedback mt-2"
                    v-if="!$v.expMonth.maxValue || !$v.expMonth.minValue"
                  >
                    Invalid Month
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click="updateUserInfo"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Update
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import moment from 'moment';
import { maxValue, minValue } from 'vuelidate/lib/validators';

export default {
  name: 'EditOfficialInfoModal',
  components: {},
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expYear: null,
      expMonth: null,
      selectedPrimarySkills: [],
      selectedAdditionalSkills: [],
      certifications: [],
      selectedCertifications: [],
      skills: [],
    };
  },
  validations: {
    expYear: {
      maxValue: maxValue(80),
      minValue: minValue(0),
    },
    expMonth: {
      maxValue: maxValue(11),
      minValue: minValue(0),
    },
  },
  created() {
    // Extract total experience into exp_months and exp_years
    const experience = this.userDetails.user_experience.split(' ');
    this.expYear = experience ? experience[0] : 0;
    this.expMonth = experience ? experience[2] : 0;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setExpYear(e) {
      this.expYear = e.target.value;
    },
    setExpMonth(e) {
      this.expMonth = e.target.value;
    },
    setValues() {
      this.selectedPrimarySkills = this.primarySkills;
      this.selectedAdditionalSkills = this.additionalSkills;
    },
    updateUserInfo() {
      const params = {
        id: this.userDetails.id,
        exp_year: this.expYear,
        exp_month: this.expMonth,
      };
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('updateUserInfo', params);
      }
    },
    handleKeyPress(e) {
      const { keyCode, shiftKey } = e;
      // enter, shift, period, decimal point, add, subtract, dash
      const excludeKeyCodes = [13, 16, 190, 110, 107, 109, 189];
      if (excludeKeyCodes.includes(keyCode) || shiftKey) {
        e.preventDefault();
        return;
      }
    },
  },
};
</script>
<style scoped>
.invalid-feedback {
  font-size: 11px;
  color: red;
}
.invalid-feedback {
  font-size: 11px;
  color: red;
  bottom: -16px;
  left: 0px;
  /* pointer-event: none; */
  transition: all 0.5s ease-in-out;
}
</style>
