<template lang="html">
  <div
    class="fixed z-50 inset-0 overflow-y-auto overflow-x-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen min-w-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true" @click="$emit('close')"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white sm:text-sm text-xs rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="">Export Weekly Logs</div>
          <hr class="my-3" />
          <div
            class="sm:flex sm:items-start flex flex-col w-full overflow-y-auto overflow-x-auto"
          >
            <!-- Modal Body -->
            <div class="flex sm:flex-row flex-col items-center py-2">
              <div class="flex flex-col w-full">
                <label class="leading-loose"
                  >Select Week <span id="mandatory">*</span></label
                >
                <v-select
                  :value="selectedweek"
                  :options="weeks"
                  @input="setWeek"
                  class="w-60 mt-1 text-gray-400 text-sm max-w-full max-h-8 whitespace-no-wrap"
                ></v-select>
              </div>
            </div>

            <div class="flex flex-col py-2 w-full">
              <label class="leading-loose"
                >Select Project <span id="mandatory">*</span></label
              >
              <v-select
                :value="selectedproject"
                :options="projects"
                @input="setProject"
                class="w-60 mt-1 text-gray-400 text-sm max-w-full max-h-8 whitespace-no-wrap"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-2 py-3 sm:px-6 flex flex-row justify-end">
          <button
            @click="toggleModal"
            class="w-16 py-2 mr-3 bg-white-400 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent shadow-lg"
          >
            CLOSE
          </button>
          <button
            @click="save"
            class="p-4 bg-blue-400 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent shadow-lg"
          >
            GENERATE REPORT
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WeeklyLogReport',
  components: {},
  data() {
    return {
      selectedweek: null,
      selectedproject: null,
    };
  },
  props: ['projects', 'weeks'],
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    setWeek(e) {
      this.selectedweek = e;
    },
    setProject(e) {
      this.selectedproject = e;
    },
    toggleModal() {
      this.$emit('toggleUserFilter');
    },
    save() {
      if (this.selectedweek != null && this.selectedproject != null) {
        const week = this.selectedweek;
        const id = this.selectedproject.id;
        this.$emit('generateReport', {
          week,
          id,
        });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Select both project and weeks.',
          alertType: 'error',
        });
      }
    },
  },
};
</script>
