



































import Vue from 'vue';
import WorkHoursPieChart from './WorkHoursPieChart.vue';

export default Vue.extend({
  name: 'WorkHours',
  components: {
    WorkHoursPieChart,
  },
  data() {
    return {
      isLoading: true,
      chartOptions: {
        hoverBorderWidth: 20,
        legend: {
          display: true,
        },
        tooltips: {
          callbacks: {
            // override the label(tooltip) to show text as "label hours hr(s) "
            label: function (tooltipItem: any, data: any) {
              return (
                data['labels'][tooltipItem['index']] +
                ': ' +
                data['datasets'][0]['data'][tooltipItem['index']] +
                ' hr(s)'
              );
            },
          },
        },
      } as any,
      chartData: {} as any,
      maxHeight: 370 as any,
      isDataPresent: false,
    };
  },
  props: ['members'],
  methods: {
    prepareData() {
      const chartData: any = {
        hoverBackgroundColor: 'red',
        hoverBorderWidth: 10,
        labels: [] as string[],
        datasets: [
          {
            label: 'Projects',
            backgroundColor: [] as string[],
            data: [] as number[],
          },
        ],
      };
      this.members.forEach((member: any) => {
        chartData.labels.push(member.name);
        chartData.datasets[0].backgroundColor.push(member.color);
        chartData.datasets[0].data.push(
          member.work_hours ? parseInt(member.work_hours) : 0
        );
        if (member.work_hours && parseInt(member.work_hours)) {
          this.isDataPresent = true;
        }
      });
      this.chartData = chartData;
      this.isLoading = false;
      if (this.chartData.labels.length < 15) {
        this.maxHeight = 370;
        this.chartOptions.legend.display = true;
      } else if (
        this.chartData.labels.length > 15 &&
        this.chartData.labels.length < 25
      ) {
        this.maxHeight = 500;
        this.chartOptions.legend.display = true;
      } else if (this.chartData.labels.length > 25) {
        this.maxHeight = 370;
        this.chartOptions.legend.display = false;
      }
    },
  },
  mounted() {
    this.prepareData();
  },
});
