<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          style="width: 60vh"
          class="inline-block align-bottom bg-white w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 pb-4 md:mt-3 mt-2 text-left transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div
                v-if="isEdit"
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
              >
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Certification
                </h3>
              </div>
              <div v-else class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Add Certification
                </h3>
              </div>
              <div class="flex flex-row px-3 py-2"></div>
            </div>
          </div>

          <hr />
          <div class="flex flex-col mt-2 bg-white pt-2 overflow-auto">
            <form-generator
              :triggerSubmit="trigger"
              :formFields="formFields"
              :numberOfColumns="1"
              :groups="groups"
              @onFieldChange="onFieldChange"
              @onFormSubmit="onFormSubmit"
            />
          </div>
          <div
            class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
            v-if="!isLoading"
          >
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
            <button
              v-if="!isEdit"
              type="button"
              @click="reset"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Reset
            </button>
            <button
              @click.prevent="trigger++"
              class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-700 rounded text-white px-4 flex items-center text-sm"
            >
              <span v-if="isEdit" class="text-sm"> Update </span>
              <span v-else class="text-sm"> Submit </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';
import { required } from 'vuelidate/lib/validators';
import RegEx from '@/components/inputs/RegEx';

export default {
  name: 'AddCertification',
  components: {
    FormGenerator,
  },
  data() {
    return {
      isFormValid: false,
      trigger: 0,
      isValueChanged: false,
      isLoading: true,
      groups: [{ start: 0, end: 19, name: '' }],
      account_managers: [],
      category: [],
      certification_id: 0,
      payload: {},
      formFields: [
        {
          name: 'category_id',
          label: 'Category Name',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'certification_name',
          label: 'Certification Name',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR_OR_DIGIT),
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  props: ['certification', 'isEdit'],
  methods: {
    onFieldChange(_field, _value, _allValues) {
      this.isValueChanged = true;
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        if (this.isEdit) {
          this.editCertification(values);
        } else {
          this.createNewCertification(values);
        }
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
    },
    convertResponseToPayload(data, callback) {
      const payload = data;
      this.certification_id = data.id;
      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = payload[formField.name];
        // Update Default for select type
        if (formField.name === 'category_id') {
          formField.defaultValue = formField.options.find(
            (option) => option.value === payload.category_id
          );
        }
        if (formField.type === 'select') {
          if (formField.multiple) {
            // Update Default for single select type
            formField.defaultValue = formField.options.find(
              (option) => option.value === payload[formField.name]
            );
          }
        }
      });
      callback(data);
    },
    close() {
      this.$emit('close');
    },
    reset() {
      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = '';
        // Update Default for select type
        if (formField.name === 'category_id') {
          formField.defaultValue = null;
        }
        if (formField.type === 'select') {
          if (formField.multiple) {
            // Update Default for single select type
            formField.defaultValue = null;
          }
        }
      });
    },
    fetchCategory() {
      axios
        .get(`/manage/certifications/all_category`)
        .then((response) => {
          const category = response.data.category.map((cat) => {
            return {
              label: cat.category_name,
              value: cat.id,
            };
          });
          this.setOptions('category_id', 'options', category);
          if (this.isEdit && this.certification != []) {
            this.convertResponseToPayload(this.certification, (payloadRes) => {
              this.payload = payloadRes;
            });
            this.isLoading = false;
          } else {
            this.convertResponseToPayload(this.certification, (payloadRes) => {
              this.payload = payloadRes;
            });
            this.isLoading = false;
          }
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchCategories', error);
        });
    },
    convertSelectOptions() {
      let editCertificationPayload = JSON.parse(JSON.stringify(this.payload));

      // converting option object to its value
      Object.keys(editCertificationPayload).forEach((keyName) => {
        if (['category_id'].includes(keyName)) {
          editCertificationPayload[keyName] =
            editCertificationPayload[keyName].value || '';
        }
      });
      return editCertificationPayload;
    },
    createNewCertification(payload) {
      axios
        .post('/manage/certifications/add_certification', payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.$emit('success');
        })
        .catch((error) => {
          console.error('ERR while createNewCertification', error.message);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: error.response.data.msg,
            alertType: 'error',
          });
        });
    },
    editCertification(payload) {
      payload.certification_id = this.certification_id;
      axios
        .post(`/manage/certifications/update_certification`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.$emit('success');
        })
        .catch((error) => {
          console.error('ERR while editContractorData', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: error.response.data.msg,
            alertType: 'error',
          });
        });
    },
  },
  created() {
    this.fetchCategory();
  },
};
</script>
