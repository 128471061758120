<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Candidates List
        </div>
      </div>
      <!-- Top Bar END-->
      <AgGridTable
        v-if="isReadPermission"
        ref="candidateList"
        :perPage="perPage"
        :columnDefs="columnDefs"
        :url="url"
        :enumValues="enumValues"
      />
      <NotAuthorized v-else />
      <ProfileInterviewList
        :profileId="profileId"
        v-if="isProfileInterViewListViewModalVisible"
        @close="closeViewProfileInterviewListModal"
      />
    </div>
  </div>
</template>

<script>
/*eslint-disable  vue/no-unused-components*/
import Vue from 'vue';
import AgGridTable from '@/components/tables/AgGridTable.vue';
import ProfileInterviewList from './ProfileInterviewList.vue';
import { ViewButtonCellRenderer } from '@/components/tables/renderers';
import { DownloadButtonCellRenderer } from '@/components/tables/renderers';
import axios from 'axios';

export default Vue.extend({
  name: 'CandidatesList',
  data() {
    return {
      isProfileInterViewListViewModalVisible: false,
      isReadPermission: false,
      isManagePermission: false,
      isInterviewModalOpen: false,
      profileId: null,
      url: 'recruitment/profiles/all',
      perPage: 50,
      columnDefs: [
        {
          field: 'profile_obj',
          headerName: 'Feedback',
          cellRenderer: 'downloadBtnCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.exportCandidateFeedback(field);
            },
          },
        },

        {
          field: 'id',
          headerName: 'Interviews',
          cellRenderer: 'viewBtnCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.isProfileInterViewListViewModalVisible =
                !this.isProfileInterViewListViewModalVisible;
              this.profileId = field;
            },
          },
        },
        {
          field: 'r_id',
          headerName: 'Req ID',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'job_role',
          headerName: 'Job Role',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'first_name',
          headerName: 'First Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'last_name',
          headerName: 'Last Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'relevant_experience',
          headerName: 'Relevant Experience',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'skill_set',
          colId: 'profiles.skill_set',
          headerName: 'Relevant Skills',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'current_ctc',
          headerName: 'Current Pay',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'expected_rate',
          headerName: 'Expected Pay',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'available_from',
          headerName: 'Availability',
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'offered_date',
          headerName: 'Offered Date',
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
      ],
      enumValues: {
        status: {
          Joined: 6,
          'Offered and yet to join': 4,
          'Candidate selected': 2,
          'Candidate submitted': 0,
          'On hold': 7,
          'Interview scheduled': 1,
          'Candidate rejected': 3,
          'Declined offer or not joined': 5,
        },
      },
    };
  },
  components: {
    AgGridTable,
    ProfileInterviewList,
    viewBtnCellRenderer: ViewButtonCellRenderer,
    downloadBtnCellRenderer: DownloadButtonCellRenderer,
  },
  methods: {
    closeViewProfileInterviewListModal() {
      this.isProfileInterViewListViewModalVisible = false;
    },
    exportCandidateFeedback(profile_obj) {
      const reportName = profile_obj.first_name + '_' + profile_obj.last_name;
      axios
        .get(`recruitment/interviews/export_interviews_feedback_excel`, {
          params: {
            profileId: profile_obj.id,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', reportName + '.xlsx');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Score Card', error);
        });
    },
  },
  created() {
    this.isReadPermission = this.validatePermission([
      'recruitment.candidates_list.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'recruitment.candidates_list.manage',
    ]);
    this.isManagePermission = this.validatePermission([
      'recruitment.candidates_list.export',
    ]);
  },
});
</script>
