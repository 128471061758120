<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Users
        </div>

        <div v-if="isUploadPermission" class="flex flex-row items-center">
          <!-- Upload bulk users -->
          <div class="flex flex-row">
            <label
              @click="downloadCsvTemplate"
              class="w-44 mr-2 flex flex-col items-center px-0 py-1 b-1 border-green-500 text-green-500 rounded-lg tracking-wide border border-blue cursor-pointer hover:bg-green-400 hover:text-white"
            >
              <div
                class="flex flex-row items-center justify-between"
                v-tippy
                content="Download bulk upload empty template"
              >
                <span class="material-icons m-auto text-xl"
                  >cloud_download</span
                >
                <span class="text-sm leading-normal ml-3"
                  >Download template</span
                >
              </div>
            </label>
            <div class="w-44">
              <UploadFormDataButton
                v-tippy
                content="Choose .csv file to upload users"
                :label="'Upload Bulk User'"
                :isUploading="isUploading"
                @uploadFile="uploadFile"
              />
            </div>
          </div>

          <!-- Add User -->
          <!-- <div class="hidden md:block">
            <div class="lg:ml-6 flex items-center">
              <router-link :to="`/manage/users/form/create`">
                <button
                  class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 flex items-center text-sm"
                >
                  <span class="material-icons m-auto mr-2">person_add</span>

                  <span class="text-sm">Add User</span>
                </button>
              </router-link>
            </div>
          </div> -->
          <!-- Add User END -->
          <!-- Add User -->
          <!-- <div>
            <div class="block md:hidden ml-2">
              <div class="lg:ml-6 flex items-center">
                <router-link :to="`/manage/users/form/create`">
                  <button
                    class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 flex items-center md:text-sm text-xs"
                  >
                    <span class="material-icons md:text-2xl text-base md:mr-2"
                      >person_add</span
                    >

                    <span class="md:text-sm text-xs">Add User</span>
                  </button>
                </router-link>
              </div>
            </div>
          </div> -->
          <!-- Add User END -->
        </div>
      </div>
      <!-- Top Bar END-->
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
      >
        <CustomFilter
          v-if="showUserFilter && isReadPermission"
          :filterBy="filterBy"
          :userPractices="userPractices"
          :certificationCategories="certificationCategories"
          :emptypes="emptypes"
          :teams="teams"
          @resetFilter="resetFilter"
          @setFilterByCertificationCategories="
            setFilterByCertificationCategories
          "
          @setFilterByUserStatus="setFilterByUserStatus"
          @setFilterByUserPractices="setFilterByUserPractices"
          @setFilterByLocation="setFilterByLocation"
          @toggleFilter="toggleFilter"
          @setFilterByEmpType="setFilterByEmpType"
          @setFilterByConfigUser="setFilterByConfigUser"
          @setFilterByTeams="setFilterByTeams"
          :user_status="user_status"
        />

        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div v-if="isReadPermission" class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-6"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <Loading v-if="isLoading" class="h-full" style="height: 68vh" />

        <!-- Table -->
        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && users.length > 0"
          >
            <table
              id="users_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr class="text-left">
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(user, index) in users"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td class="px-4 text-xs">
                    <p class>{{ user.employee_id }}</p>
                  </td>
                  <td class="px-4 py-2">
                    <div>
                      <router-link :to="`/manage/users/${user.id}`">
                        <p
                          class="text-xs font-semibold text-blue-500 cursor-pointer"
                        >
                          {{ user.first_name + ' ' + user.last_name }}
                        </p>
                      </router-link>
                      <p class="text-gray-400 text-xs tracking-wide">
                        {{ user.email }}
                      </p>
                    </div>
                  </td>
                  <td class="px-4">
                    <p class>{{ user.designation }}</p>
                  </td>
                  <td class="px-4">
                    <router-link
                      :to="`/manage/users/${user.reporting_manager_id}`"
                    >
                      <p
                        class="text-xs font-semibold text-blue-500 cursor-pointer"
                      >
                        {{ user.reporting_to }}
                      </p>
                    </router-link>
                  </td>
                  <td class="px-2">
                    <p class>{{ user.reporting_to_email }}</p>
                  </td>
                  <td class="px-4">
                    <p class>
                      {{ user.employment_type_name }}
                    </p>
                  </td>
                  <td class="px-4">
                    <span
                      v-if="user.status"
                      class="text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full"
                      >Active</span
                    >
                    <span
                      v-else
                      class="text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full"
                      >Inactive</span
                    >
                  </td>
                  <td class="px-2">
                    <p class>{{ user.department }}</p>
                  </td>
                  <td class="px-4">
                    <p class>{{ user.practice }}</p>
                  </td>
                  <td class="px-4">
                    <p class>{{ user.team }}</p>
                  </td>
                  <td class="px-2">
                    <p class>{{ user.location }}</p>
                  </td>
                  <td class="px-2">
                    <p class>{{ user.external_id }}</p>
                  </td>
                  <td class="px-2">
                    <p class>{{ user.user_experience }}</p>
                  </td>
                  <td
                    v-if="user.comments"
                    class="px-2"
                    v-tippy
                    :content="user.comments"
                  >
                    <p
                      style="
                        max-width: 250px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ user.comments }}
                    </p>
                  </td>
                  <td v-else></td>
                  <td class="px-2">
                    <span
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                      :content="getCertifications(user.certifications)"
                      v-tippy
                    >
                      <p class="text-xs text-blue-500 whitespace-nowrap">
                        {{
                          user.certifications[0] &&
                          user.certifications[0].certification_name
                        }}
                      </p>
                    </span>
                  </td>
                  <td class="px-4">
                    <router-link
                      :to="`/manage/users/${user.timesheet_manager_id}`"
                    >
                      <p
                        class="text-xs font-semibold text-blue-500 cursor-pointer"
                      >
                        {{ user.timesheet_manager }}
                      </p>
                    </router-link>
                  </td>
                  <td class="px-2">
                    <p class>{{ user.timesheet_manager_email }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
      <div v-if="isReadPermission">
        <Pagination
          class="mt-3"
          v-if="Object.keys(paginationMeta).length > 0"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchUsers"
          @setPageSize="setPageSize"
        />
      </div>
      <!-- Pagination -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';
import UploadFormDataButton from '@/components/common/UploadFormDataButton.vue';

export default {
  name: 'Users',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    NoRecordFound,
    ExportButtons,
    NotAuthorized,
    UploadFormDataButton,
  },
  data() {
    return {
      showUserFilter: false,
      locations: [
        { label: 'All', value: 'ALL' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'USA', value: 'USA' },
        { label: 'Mexico', value: 'Mexico' },
      ],
      userPractices: [],
      certificationCategories: [],
      user_status: { label: 'Active', value: 'true' },
      emptypes: [],
      filterBy: {
        userPractices: [],
        user_status: { label: 'Active', value: 'true' },
        location: [],
        certificationCategories: [],
        emptypes: [],
        configUser: false,
        teams: [],
      },
      query: '',
      columns: [
        'Emp ID',
        'Name',
        'Designation',
        'Reporting Manager',
        'Reporting Manager Email',
        'Emp Type',
        'Status',
        'Department',
        'Practice',
        'Team',
        'Location',
        'Ext ID',
        'Exp',
        'Comments',
        'Certifications',
        'Timesheet Manager',
        'Timesheet Manager Email',
      ],
      users: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      defaultFilterBy: {},
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      isUploadPermission: false,
      nonce: '',
      teams: [],
      isUploading: false,
    };
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchUsers(1);
    },
    toggleFilter() {
      this.showUserFilter = !this.showUserFilter;
    },
    printTable() {
      const newWindow = window.open('');
      newWindow.document.write(
        document.getElementById('users_table').outerHTML
      );
      newWindow.print();
      newWindow.close();
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Users: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#users_table',
      });
      doc.save(`Users_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setFilterByUserPractices(option) {
      this.filterBy.userPractices = option;
      this.fetchUsers(1);
    },
    setFilterByCertificationCategories(options) {
      this.filterBy.certificationCategories = options;
      this.fetchUsers(1);
    },
    setFilterByUserStatus(option) {
      this.filterBy.user_status = option;
      this.fetchUsers(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchUsers(1);
    },
    setFilterByEmpType(options) {
      this.filterBy.emptypes = options;
      this.fetchUsers(1);
    },
    setFilterByConfigUser(e) {
      this.filterBy.configUser = e.target.value;
      this.fetchUsers(1);
    },
    setFilterByTeams(option) {
      this.filterBy.teams = option;
      this.fetchUsers(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchUsers(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchUsers(1);
    },
    fetchPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.userPractices = response.data.map((practice) => {
            return {
              label: practice.practice_name,
              value: practice.practice_name,
            };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
    fetchFilterOptions() {
      axios
        .get(`/manage/users/filter_options`)
        .then((response) => {
          this.certificationCategories = response.data.categories.map(
            (cate) => {
              return { label: cate.category_name, value: cate.id };
            }
          );
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
    fetchEmpTypes() {
      axios
        .get('/manage/emp_types?status=true')
        .then((res) => {
          const { data } = res;
          this.emptypes = data.map((emp) => {
            return { value: emp.id, label: emp.emp_type };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchTeams() {
      axios
        .get('/manage/teams')
        .then((res) => {
          this.teams = res.data.map((team) => {
            return { value: team.id, label: team.name };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCertifications(certifications) {
      let certificationNames =
        (certifications &&
          certifications.map((cert) => cert.certification_name)) ||
        [];
      return certificationNames.join(', ');
    },
    getUsersPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.location.length > 0) {
        payload.location = filterBy.location;
      }
      if (filterBy.userPractices.length > 0) {
        payload.practice = filterBy.userPractices;
      }
      if (filterBy.certificationCategories.length > 0) {
        payload.categories = filterBy.certificationCategories;
      }
      if (filterBy.user_status) {
        payload.status = filterBy.user_status.value;
      }
      if (query) {
        payload.search = query;
      }
      if (filterBy.emptypes) {
        payload.emptypes = filterBy.emptypes;
      }
      if (filterBy.teams) {
        payload.teams = filterBy.teams;
      }
      payload.configUser = filterBy.configUser;
      return payload;
    },
    fetchUsers(page) {
      this.nonce = Date.now().toString();
      this.isLoading = true;
      const { pageSize } = this;
      const payload = this.getUsersPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/users/all?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, meta, users } = response.data;
          if (this.nonce !== nonce) return;
          this.paginationMeta = meta;
          this.users = users;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.users = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
    exportToCSV() {
      const payload = this.getUsersPayload();
      axios
        .post(`/manage/users/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Users_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },

    downloadCsvTemplate() {
      const csvText =
        'S.No,EmployeeID,First Name,Last Name,Email ID,Title,Date of joining,Date of Exit,Reporting To,Department,Mobile Phone,Birth Date,Employee Status,Employee Type,Marital Status,Employee Role,Gender,Location Name,Practice,Team,Created by,Created Date,Modified by,Modified Date,Type Of Separation,Reason For Separation,Personal Email\n';
      const anchor = document.createElement('a');
      anchor.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText);
      anchor.target = '_blank';
      anchor.download = 'Users.csv';
      anchor.click();
    },
    uploadFile: function (formData) {
      this.isUploading = true;
      axios
        .post('/manage/users/import', formData)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg || 'Successfully created',
            alertType: 'success',
          });
          this.isUploading = false;
          this.fetchUsers(1);
        })
        .catch((error) => {
          const { data } = error.response;
          console.log('data.msg', data.msg);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
            alertDuration: 8,
          });
          this.isUploading = false;
          console.log('ERR while BulkUpload', error);
        });
    },
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchUsers = _debounce(this.fetchUsers, 400);
    this.isReadPermission = this.validatePermission(['manage.users.read']);
    this.isManagePermission = this.validatePermission(['manage.users.manage']);
    this.isExportPermission = this.validatePermission(['manage.users.export']);
    this.isUploadPermission = this.validatePermission(['manage.users.upload']);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchPractices();
      this.fetchFilterOptions();
      this.fetchUsers(1);
      this.fetchEmpTypes();
      this.fetchTeams();
    } else {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
