

























































import Vue from 'vue';
import OfficialInfo from '@/components/home/OfficialInfo.vue';
import PersonalInfo from '@/components/home/PersonalInfo.vue';
import Skills from '@/components/home/Skills.vue';
import axios from 'axios';
import UserNameAvatar from '@/components/manage/users/UserNameAvatar.vue';
import Loading from '@/components/common/Loading.vue';

export default Vue.extend({
  name: 'UserMoreDetails',
  data: () => ({
    isLoading: true,
    userId: null,
    userDetails: null,
  }),
  components: {
    OfficialInfo,
    UserNameAvatar,
    Loading,
    PersonalInfo,
    Skills,
  },
  methods: {
    updateUserDetails(userDetails: any) {
      this.userDetails = userDetails;
    },
    fetchUsers() {
      axios
        .get(`/manage/users/${this.userId}`)
        .then((response) => {
          this.userDetails = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
  },
  created() {
    this.userId = (this.$router as any).history.current.params.id;
    if (this.userId) {
      this.fetchUsers();
    }
  },
});
