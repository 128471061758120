<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Timesheet Log Report
        </div>
        <div>
          <DateRangePickerInput
            v-if="isReadPermission"
            ref="timesheetlogPicker"
            @updateDateRange="setFilterByDateRange"
            :startDate="dateRange && dateRange.from_date"
            :endDate="dateRange && dateRange.to_date"
            popupAlign="center"
            :customRanges="ranges"
            :setMaxDate="true"
          />
        </div>
        <div></div>
        <div class="flex flex-row items-center">
          <div class="mr-2">
            <input
              type="checkbox"
              v-model="check"
              true-value="true"
              false-value="false"
              @change="setFilterByDayWise"
            />
            <label class="text-xs text-gray-700 mt-4" style="margin-left: 10px"
              >Show Day-Wise Log Hours</label
            >
          </div>
          <div v-if="isExportPermission" class="relative text-gray-600">
            <button
              @click="exportToExcel"
              class="w-full text-gray-500 border border-gray-500 hover:bg-gray-500 hover:text-white px-4 py-1 tracking-wide text-white transition-colors duration-200 transform rounded-sm focus:outline-none"
            >
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar END-->
    <AgGridTable
      v-if="isReadPermission"
      ref="timesheetLogReport"
      :perPage="perPage"
      :columnDefs="columnDefs"
      :url="url"
      :params="params"
      :enumValues="enumValues"
      :excelFileName="'Timesheet Log Report.xlsx'"
      :defaultFilter="defaultFilter"
    />
    <NotAuthorized v-else />
    <p>
      Note -Timesheet status for a user will remain same for all projects
      collectively and it depents on Total no of hours logged by the user
      against total no of available hours.
    </p>
  </div>
</template>

<script>
import AgGridTable from '@/components/tables/AgGridTable.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'TimesheetLogReport',
  components: {
    AgGridTable,
    DateRangePickerInput,
    NotAuthorized,
  },
  data() {
    let today = new Date();
    return {
      showFilter: false,
      isReadPermission: false,
      isExportPermission: false,
      perPage: 50,
      check: false,
      ranges: {
        Today: [new Date(), new Date()],
        'This Week': [
          moment().startOf('week').toDate(),
          moment().endOf('week').toDate(),
        ],
        'Last Week': [
          moment().subtract(1, 'week').startOf('week').toDate(),
          moment().subtract(1, 'week').endOf('week').toDate(),
        ],
        'Last 7 Days': [moment().subtract(7, 'd').toDate(), moment().toDate()],
        'Last 30 Days': [
          moment().subtract(29, 'd').toDate(),
          moment().toDate(),
        ],
        'This month': [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
        'Last month': [
          moment().subtract(1, 'months').startOf('month').toDate(),
          moment().subtract(1, 'months').endOf('month').toDate(),
        ],
      },
      columnDefs: [
        {
          field: 'employee_id',
          headerName: 'Employee ID',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          editable: false,
          pinned: 'left',
        },
        {
          field: 'employee_name',
          headerName: 'Employee Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'emp_type',
          headerName: 'Emp Type',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'location',
          headerName: 'Location',
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'practice',
          headerName: 'User Practice',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'user_status',
          headerName: 'User Status',
          sortable: true,
          filter: 'agTextColumnFilter',
          colId: 'user_status',
          type: ['enumColumn.status'],
          filterParams: {
            buttons: ['apply', 'reset'],
          },
          cellRenderer: (params) => {
            let bgColor = '';
            switch (params.value) {
              case 'Active':
                bgColor =
                  'text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full';
                break;
              case 'Inactive':
                bgColor =
                  'text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full';
                break;
              default:
            }
            return (
              params.value && `<span class="${bgColor}">${params.value}</span>`
            );
          },
        },
        {
          field: 'department_name',
          headerName: 'Department Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'project_name',
          headerName: 'Project',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'project_status',
          headerName: 'Project Status',
          sortable: true,
          filter: 'agTextColumnFilter',
          colId: 'project_status',
          type: ['enumColumn.status'],
          filterParams: {
            buttons: ['apply', 'reset'],
          },
          cellRenderer: (params) => {
            let bgColor = '';
            switch (params.value) {
              case 'Active':
                bgColor =
                  'text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full';
                break;
              case 'Inactive':
                bgColor =
                  'text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full';
                break;
              default:
            }
            return (
              params.value && `<span class="${bgColor}">${params.value}</span>`
            );
          },
        },
        {
          field: 'project_practice',
          headerName: 'Project Practice',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'client_name',
          headerName: 'Client Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'allocation',
          headerName: '% Allocation',
        },
        {
          field: 'working_hours_for_the_period',
          headerName: 'Available Hours',
        },
        {
          field: 'project_hours',
          headerName: 'Logged Hours',
        },
        {
          field: 'timesheet_status',
          headerName: 'Timesheet Status',
        },
        {
          field: 'project_manager',
          headerName: 'Project Manager',
        },
        {
          field: 'reporting_to',
          headerName: 'Reporting Manager',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'reporting_to_email',
          headerName: 'Reporting Manager Email',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'timesheet_manager',
          headerName: 'Timesheet Manager',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'timesheet_manager_email',
          headerName: 'Timesheet Manager Email',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
          },
        },
        {
          field: 'joining_date',
          headerName: 'Joining Date',
          sortable: true,
        },
      ],
      enumValues: {
        status: {
          Active: true,
          Inactive: false,
        },
      },
      url: '/reports/timesheet_log_report',
      daywise: true,
      dateRange: {
        from_date: moment().subtract(1, 'week').startOf('week').toDate(),
        to_date: moment().subtract(1, 'week').endOf('week').toDate(),
      },
      params: {
        from_date: moment()
          .subtract(1, 'week')
          .startOf('week')
          .format('YYYY-MM-DD'),
        to_date: moment()
          .subtract(1, 'week')
          .endOf('week')
          .format('YYYY-MM-DD'),
        daywise: false,
      },
      defaultFilter: {
        user_status: {
          filterType: 'text',
          type: 'enum.true',
        },
        project_status: {
          filterType: 'text',
          type: 'enum.true',
        },
      },
    };
  },
  methods: {
    setFilterByDayWise() {
      const daywise = this.check;
      this.params = { ...this.params, daywise };
    },
    exportToExcel() {
      this.$refs.timesheetLogReport.exportToExcel();
    },
    setFilterByDateRange(dateRange) {
      const from_date = moment(dateRange.startDate).format('YYYY-MM-DD');
      const to_date = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.params = { ...this.params, from_date, to_date };
    },
  },
  created() {
    this.isReadPermission = this.validatePermission([
      'reports.timesheet_log.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.timesheet_log.export',
    ]);
    this.defaultFilterBy = { ...this.params };
  },
};
</script>
