<template>
  <div class="bg-gray overflow-x-hidden w-96">
    <Loading v-if="isLoading" />
    <!-- Top Bar -->
    <div
      v-else
      class="md:h-16 h-12 bg-white flex flex-row shadow-sm items-center justify-between text-left px-5 sticky top-0 z-10"
    >
      <div class="flex flex-row items-center justify-start">
        <span
          class="material-icons text-black md:text-xl text-sm mr-3 cursor-pointer"
          @click="onCancel"
          >arrow_back</span
        >
        <span>
          {{ isLoading ? '' : isEdit ? `${username}` : 'Add New User' }}
        </span>
      </div>
      <div class="flex flex-row px-3 py-2">
        <button
          v-if="isManagePermission"
          @click.prevent="trigger++"
          class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-700 rounded text-white px-5 h-8 flex items-center text-sm"
        >
          <span class="text-sm">Submit</span>
        </button>
      </div>
    </div>
    <div
      v-if="!isLoading"
      class="flex flex-col mt-2 bg-white pt-2"
      style="height: 88vh"
    >
      <div class="flex-grow overflow-auto pb-24">
        <form-generator
          :triggerSubmit="trigger"
          :formFields="formFields"
          :numberOfColumns="3"
          :groups="groups"
          @onFieldChange="onFieldChange"
          @onFormSubmit="onFormSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';
import RegEx from '@/components/inputs/RegEx';
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import FormGenerator from '@/components/common/FormGenerator.vue';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'CreateUser',
  components: {
    Loading,
    FormGenerator,
  },
  data() {
    return {
      username: '',
      isFormValid: false,
      trigger: 0,
      isLoading: true,
      isValueChanged: false,
      countryCodesMap: {
        USA: '+1',
        Mexico: '+52',
        Chennai: '+91',
        Hyderabad: '+91',
      },
      skillOptions: [],
      groups: [
        { start: 0, end: 6, name: 'Official Info' },
        { start: 6, end: 14, name: 'Personal Info' },
        { start: 14, end: -1, name: 'Other Info' },
      ],
      formFields: [
        // {
        //   name: "first_name",
        //   label: "First Name",
        //   required: true,
        //   type: "text",
        //   defaultValue: "",
        //   validations: {
        //     required,
        //     regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
        //   },
        // },
        // {
        //   name: "last_name",
        //   label: "Last Name",
        //   required: true,
        //   type: "text",
        //   defaultValue: "",
        //   validations: {
        //     required,
        //     regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
        //   },
        // },
        // {
        //   name: "employee_id",
        //   label: "Employee ID",
        //   required: true,
        //   type: "text",
        //   defaultValue: "",
        //   validations: {
        //     required,
        //     regex: (value) => this.validateRegex(value, RegEx.EMP_ID),
        //   },
        // },
        // {
        //   name: "designation",
        //   label: "Designation",
        //   required: false,
        //   type: "text",
        //   defaultValue: "",
        //   validations: {
        //     required,
        //     regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
        //   },
        // },
        // {
        //   name: "emp_type_id",
        //   label: "Employment Type",
        //   required: true,
        //   type: "select",
        //   defaultValue: "",
        //   options: [],
        //   validations: {
        //     required,
        //   },
        // },
        // {
        //   name: "joining_date",
        //   label: "Joining Date",
        //   required: true,
        //   type: "date",
        //   defaultValue: "",
        //   maxValue: moment().format("YYYY-MM-DD"),
        //   minValue: "2014-01-01",
        //   validations: {
        //     required,
        //     minValueDate: (value) => this.validateMinDate(value, "2014-01-01"),
        //     maxValueDate: (value) => this.validateMinDate(value, null),
        //   },
        // },
        // {
        //   name: "department_id",
        //   label: "Department",
        //   required: true,
        //   type: "select",
        //   defaultValue: "",
        //   options: [],
        //   validations: {
        //     required,
        //   },
        // },
        // {
        //   name: "practice",
        //   label: "Practice",
        //   required: true,
        //   type: "select",
        //   defaultValue: "",
        //   options: [],
        //   validations: {
        //     required,
        //   },
        // },
        // {
        //   name: "reporting_manager_id",
        //   label: "Reporting to",
        //   required: false,
        //   type: "select",
        //   defaultValue: "",
        //   options: [],
        //   validations: {},
        // },
        // {
        //   name: "location",
        //   label: "Work Location",
        //   required: true,
        //   type: "select",
        //   defaultValue: "",
        //   options: [
        //     { label: "Hyderabad", value: "Hyderabad" },
        //     { label: "Chennai", value: "Chennai" },
        //     { label: "USA", value: "USA" },
        //     { label: "Mexico", value: "Mexico" },
        //   ],
        //   validations: {
        //     required,
        //   },
        // },
        // {
        //   name: "mobile_number",
        //   label: "Mobile Number",
        //   prepandText: "+91",
        //   type: "number",
        //   defaultValue: "",
        //   minValue: 0,
        //   validations: {
        //     regex: (value) => this.validateRegex(value, RegEx.MOBILE),
        //   },
        // },
        // {
        //   name: "email",
        //   label: "Email",
        //   required: true,
        //   type: "text",
        //   defaultValue: "",
        //   validations: {
        //     email,
        //     required,
        //   },
        // },
        {
          name: 'external_id',
          label: 'External ID',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR_OR_DIGIT),
          },
        },
        {
          name: 'is_config',
          label: 'Config User?',
          required: false,
          type: 'select',
          defaultValue: { label: 'No', value: false },
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'experience',
          label: 'Total  Experience',
          type: 'text',
          required: false,
          defaultValue: '',
          child: [
            {
              name: 'exp_year',
              label: 'Years',
              type: 'number',
              defaultValue: '',
              maxValue: 80,
              minValue: 0,
              validations: {},
            },
            {
              name: 'exp_month',
              label: 'Months',
              type: 'number',
              defaultValue: '',
              maxValue: 12,
              minValue: 0,
              validations: {},
            },
          ],
        },
        {
          name: 'comments',
          label: 'Comments',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'roles',
          label: 'Roles',
          hide: !this.validatePermission('manage.roles.manage'),
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
          multiple: true,
          validations: {
            required,
          },
        },
        {
          name: 'timesheet_manager_id',
          label: 'Timesheet Manager',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'gender',
          label: 'Gender',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ],
        },
        {
          name: 'blood_group',
          label: 'Blood Group',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'A+', value: 'A+' },
            { label: 'A-', value: 'A-' },
            { label: 'B+', value: 'B+' },
            { label: 'B-', value: 'B-' },
            { label: 'AB+', value: 'AB+' },
            { label: 'AB-', value: 'AB-' },
            { label: 'O+', value: 'O+' },
            { label: 'O-', value: 'O-' },
          ],
        },
        {
          name: 'dob',
          label: 'Date Of Birth',
          required: false,
          type: 'date',
          defaultValue: '',
          maxValue: moment().subtract(18, 'year').format('YYYY-MM-DD'),
          minValue: '1950-01-01',
        },
        {
          name: 'emergency_contact',
          label: 'Emergency Contact',
          prepandText: '+',
          required: false,
          type: 'number',
          defaultValue: '',
          minValue: 0,
          validations: {
            minLength: minLength(10),
            maxLength: maxLength(13),
            regex: (value) =>
              this.validateRegex(value, RegEx.PHONE_NUMBERS_CHARACTER),
          },
        },
        {
          name: 'address',
          label: 'Address',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'country',
          label: 'Country',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'state',
          label: 'State',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'city',
          label: 'City',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'primary_skill_set',
          label: 'Primary Skill Set(s)',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
          multiple: true,
        },
        {
          name: 'additional_skills',
          label: 'Secondary Skill Set(s)',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
          multiple: true,
        },
        {
          name: 'performance',
          label: 'Performance',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            {
              label: 'Significantly exceed expectations',
              value: 'Significantly exceed expectations',
            },
            { label: 'Exceeds expectations', value: 'Exceeds expectations' },
            { label: 'Meets Expectation', value: 'Meets Expectation' },
            {
              label: 'Partially meets expectations',
              value: 'Partially meets expectations',
            },
            { label: 'Needs Improvement', value: 'Needs Improvement' },
          ],
        },
        {
          name: 'certifications',
          label: 'Certifications',
          required: false,
          type: 'select',
          defaultValue: [],
          options: [],
          multiple: true,
        },
      ],
      payload: {},
      isEdit: false,
      modalHeadline: 'Leaving this page will discard unsaved changes.',
      deleteMessage: 'Are you sure you want to leave this page?',
      isGoingBack: false,
      isManagePermission: false,
    };
  },
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onFieldChange(field, value, allValues) {
      this.isValueChanged = true;
      if (field.name === 'location' && allValues.location) {
        ['emergency_contact', 'mobile_number'].forEach((input_field) => {
          this.setOptions(
            input_field,
            'prepandText',
            this.countryCodesMap[allValues.location.value]
          );
        });
        this.setOptions;
      }
      if (field.name === 'country') {
        this.fetchStates(value.value, (_states) => {
          allValues.state = '';
          allValues.city = '';
        });
      }
      if (field.name === 'state') {
        this.fetchCities(value.value, value.country, (_cities) => {
          allValues.city = '';
        });
      }
      if (field.name === 'primary_skill_set') {
        this.filterSkillOptions(value, 'additional_skills');
      }
      if (field.name === 'additional_skills') {
        this.filterSkillOptions(value, 'primary_skill_set');
      }
    },
    onFormSubmit(isValid, values) {
      values['primary_skill_set'] = values['primary_skill_set']
        .toString()
        .toLowerCase();
      values['additional_skills'] = values['additional_skills']
        .toString()
        .toLowerCase();

      if (isValid) {
        if (this.isEdit) {
          this.editUser(values);
        } else {
          this.createNewUser(values);
        }
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
    },
    convertResponseToPayload(data, callback) {
      this.setOptions(
        'mobile_number',
        'prepandText',
        this.countryCodesMap[data.location]
      );

      // filter role ids
      data.roles = data.roles.map((role) => role.id);

      // Extract experience into exp_months and exp_years
      // const experience = moment(data.experience);
      // const today = moment();
      // const experienceMonths = today.diff(experience, 'month');
      // data['exp_year'] = today.diff(experience, 'year');
      // data['exp_month'] = experienceMonths % 12;
      const experience = data.user_experience.split(' ');
      data['exp_year'] = experience ? experience[0] : 0;
      data['exp_month'] = experience ? experience[2] : 0;

      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = data[formField.name];

        // update Default for input type which has child
        if (formField.child) {
          formField.child.forEach((fc) => (fc.defaultValue = data[fc.name]));
        }

        // Update Default for date type
        if (formField.type === 'date' && data[formField.name]) {
          formField.defaultValue = data[formField.name].substring(0, 10);
        }

        // Update Default for select type
        if (formField.type === 'select') {
          if (formField.multiple) {
            // Update Default for multi select type
            if (formField.name === 'certifications' && data.certifications) {
              formField.defaultValue = data.certifications.map((cert) => {
                return cert.id;
              });
            }
            if (
              ['primary_skill_set', 'additional_skills'].includes(
                formField.name
              )
            ) {
              formField.defaultValue = data[formField.name]
                ? data[formField.name].split(',').map((skill) => skill.trim())
                : [];
              this.filterSkillOptions(
                formField.defaultValue,
                formField.name === 'additional_skills'
                  ? 'primary_skill_set'
                  : 'additional_skills'
              );
            }
          } else {
            // Update Default for single select type
            formField.defaultValue = formField.options.find(
              (option) => option.value === data[formField.name]
            );
          }
        }
      });
      callback(data);
    },
    createNewUser(payload) {
      payload.status = true;
      axios
        .post(`/manage/users`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.$router.push('/manage/users');
        })
        .catch((error) => {
          let errMsg = 'Something went wrong. Please try again';
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            errMsg = error.response.data.msg[0];
          }
          this.$store.dispatch('settings/showAlert', {
            alertMessage: errMsg,
            alertType: 'error',
          });
        });
    },
    editUser(payload) {
      const params = this.$router.history.current.params;
      this.isGoingBack = true;
      axios
        .put(`/manage/users/${params.id}`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.$router.back();
        })
        .catch((error) => {
          this.isGoingBack = false;
          let errMsg = 'Something went wrong. Please try again';
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            errMsg = error.response.data.msg[0];
          }
          this.$store.dispatch('settings/showAlert', {
            alertMessage: errMsg,
            alertType: 'error',
          });
        });
    },
    filterSkillOptions(selectedOptionValue, field) {
      const filteredOptions = this.skillOptions.filter(
        (so) => !selectedOptionValue.includes(so.value)
      );
      this.setOptions(field, 'options', filteredOptions);
    },
    fetchDepartments(callback) {
      axios
        .get(`/manage/departments`)
        .then((response) => {
          const departments = response.data.map((department) => {
            return { label: department.name, value: department.id };
          });
          this.setOptions('department_id', 'options', departments);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchDepartments', error);
        });
    },
    fetchPractices(callback) {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          const practices = response.data.map((practice) => {
            return {
              label: practice.practice_name,
              value: practice.practice_name,
            };
          });
          this.setOptions('practice', 'options', practices);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchPractices', error);
        });
    },
    fetchUsers(callback) {
      const params = this.$router.history.current.params;
      const id = params.id ? params.id : '';
      axios
        .get(
          `/manage/users/reporting_managers_options${
            id ? '?user_id=' + id : ''
          }`
        )
        .then((response) => {
          const users = response.data.users.map((user) => {
            return {
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
          this.setOptions('reporting_manager_id', 'options', users);
          callback(true);
        })
        .catch((error) => {
          console.error('ERR while fetchUsers', error);
          callback();
        });
    },
    fetchSkills(callback) {
      axios
        .get(`/manage/skills`)
        .then((response) => {
          const skills = response.data.map((skill) => {
            return {
              label: skill.skill_name,
              value: skill.skill_name.toLowerCase(),
              id: skill.id,
            };
          });
          this.skillOptions = skills;
          ['primary_skill_set', 'additional_skills'].forEach((field) => {
            this.setOptions(field, 'options', skills);
          });
          callback(true);
        })
        .catch((error) => {
          console.error('ERR while fetchSkills', error);
          callback();
        });
    },
    fetchCountries(callback) {
      axios
        .get(`/manage/users/countries`)
        .then((response) => {
          const countries = response.data.countries;
          const filteredCountries = Object.keys(countries).map(
            (countryCode) => {
              return {
                label: countries[countryCode],
                value: countryCode,
              };
            }
          );
          this.setOptions(
            'country',
            'options',
            this.sortArray(filteredCountries, 'label')
          );
          callback(true);
        })
        .catch((error) => {
          console.error('ERR while fetchCountries', error);
          callback();
        });
    },
    fetchStates(countryCode, callback) {
      axios
        .get(`/manage/users/states?country=${countryCode}`)
        .then((response) => {
          const states = response.data.states;
          const filteredStates = Object.keys(states).map((stateCode) => {
            return {
              label: states[stateCode],
              value: stateCode,
              country: countryCode,
            };
          });
          this.setOptions(
            'state',
            'options',
            this.sortArray(filteredStates, 'label')
          );
          callback(filteredStates);
        })
        .catch((error) => {
          console.error('ERR while fetchStates', error);
          callback([]);
        });
    },
    fetchCities(stateCode, countryCode, callback) {
      axios
        .get(`/manage/users/cities?country=${countryCode}&state=${stateCode}`)
        .then((response) => {
          const cities = response.data.cities;
          const filteredCities = cities.map((city) => {
            return {
              label: city,
              value: city,
            };
          });
          this.setOptions(
            'city',
            'options',
            this.sortArray(filteredCities, 'label')
          );
          callback(filteredCities);
        })
        .catch((error) => {
          console.error('ERR while fetchCities', error);
          callback();
        });
    },
    fetchUser(userId, callback) {
      axios
        .get(`/manage/users/${userId}`)
        .then((response) => {
          this.username = response.data
            ? response.data.first_name + ' ' + response.data.last_name
            : '';
          callback(response.data);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUser', error);
          callback();
        });
    },
    fetchCertifications(callback) {
      axios
        .get(`/manage/certifications`)
        .then((response) => {
          const filteredCertifications = response.data.map((certification) => {
            return {
              label: certification.certification_name,
              value: certification.id,
            };
          });
          this.setOptions('certifications', 'options', filteredCertifications);
          callback(true);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUser', error);
          callback();
        });
    },
    fetchRoles(callback) {
      if (this.validatePermission(['manage.roles.manage'])) {
        axios
          .get(`/authorization/roles`)
          .then((response) => {
            const options = response.data.roles.map((role) => {
              return {
                label: role.name,
                value: role.id,
                id: role.id,
              };
            });
            this.setOptions('roles', 'options', options);
            callback(response.data);
          })
          .catch((error) => {
            // handle error
            console.error('ERR while fetchRoles', error);
            callback();
          });
      } else {
        callback();
      }
    },
    fetchEmpType(callback) {
      axios
        .get(`/manage/emp_types/all?status=true`)
        .then((response) => {
          const emptypes = response.data.emp.map((emptype) => {
            return {
              label: emptype.emp_type,
              value: emptype.id,
            };
          });
          this.setOptions('emp_type_id', 'options', emptypes);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchPractices', error);
        });
    },
    fetchTimesheetManager(callback) {
      axios
        .get(`/manage/users/timesheet_manager`)
        .then((response) => {
          const managers = response.data.users.map((manager) => {
            return {
              label: manager.first_name + ' ' + manager.last_name,
              value: manager.id,
            };
          });
          this.setOptions('timesheet_manager_id', 'options', managers);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchPractices', error);
        });
    },
    onCancel() {
      if (this.isEdit && this.isValueChanged) {
        this.$confirm(
          'Leaving this page will discard unsaved changes. Are you sure?'
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.isGoingBack = true;
            this.$router.back();
          } else {
            this.isGoingBack = false;
            return;
          }
        });
      } else {
        this.$router.back();
      }
    },
  },
  created() {
    this.isManagePermission = this.validatePermission(['manage.users.manage']);
    const params = this.$router.history.current.params;
    // this.fetchDepartments((isDepartmentLoaded) => {
    //   this.fetchPractices((isPracticeLoaded) => {
    //     this.fetchUsers((isUserLoaded) => {
    //       this.fetchEmpType((isEmptypeLoaded) => {});
    //     });
    //   });
    // });

    this.fetchSkills((_isSkillsLoaded) => {
      this.fetchCertifications((_isCertificationsLoaded) => {
        this.fetchTimesheetManager((_isTimesheetManagerLoaded) => {
          this.fetchCountries((_isCountriesLoaded) => {
            this.fetchRoles((_roles) => {
              if (params.id) {
                this.isEdit = true;
                this.fetchUser(params.id, (userData) => {
                  if (userData.country) {
                    this.fetchStates(userData.country, (_states) => {
                      if (userData.state) {
                        this.fetchCities(
                          userData.state,
                          userData.country,
                          (_cities) => {
                            this.convertResponseToPayload(
                              userData,
                              (_payloadRes) => {
                                this.isLoading = false;
                              }
                            );
                          }
                        );
                      } else {
                        this.convertResponseToPayload(
                          userData,
                          (_payloadRes) => {
                            this.isLoading = false;
                          }
                        );
                      }
                    });
                  } else {
                    this.convertResponseToPayload(userData, (_payloadRes) => {
                      this.isLoading = false;
                    });
                  }
                });
              } else {
                this.isLoading = false;
              }
            });
          });
        });
      });
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isEdit && !this.isGoingBack && this.isValueChanged) {
      const isConfirmed = await this.$confirm(
        'Leaving this page will discard unsaved changes. Are you sure?'
      );
      if (isConfirmed) {
        next();
      } else {
        return;
      }
    } else {
      next();
    }
  },
});
</script>
<style scoped>
/deep/ input[name='emergency_contact']::-webkit-inner-spin-button,
input[name='emergency_contact']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
