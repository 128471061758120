<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Personal Info
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="flex-grow overflow-auto w-full h-80">
            <form-generator
              :triggerSubmit="trigger"
              :formFields="formFields"
              :numberOfColumns="1"
              :groups="groups"
              @onFieldChange="onFieldChange"
              @onFormSubmit="onFormSubmit"
            />
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click.prevent="trigger++"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Update
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import FormGenerator from '@/components/common/FormGenerator.vue';
import RegEx from '@/components/inputs/RegEx';
import { minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'EditPersonalInfoModal',
  components: {
    FormGenerator,
  },
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      trigger: 0,
      groups: [{ start: 0, end: 8, name: '' }],
      formFields: [
        {
          name: 'gender',
          label: 'Gender',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Male', value: 'MALE' },
            { label: 'Female', value: 'FEMALE' },
          ],
        },
        {
          name: 'blood_group',
          label: 'Blood Group',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'A+', value: 'A+' },
            { label: 'A-', value: 'A-' },
            { label: 'B+', value: 'B+' },
            { label: 'B-', value: 'B-' },
            { label: 'AB+', value: 'AB+' },
            { label: 'AB-', value: 'AB-' },
            { label: 'O+', value: 'O+' },
            { label: 'O-', value: 'O-' },
          ],
        },
        {
          name: 'dob',
          label: 'Date Of Birth',
          required: false,
          type: 'date',
          defaultValue: '',
          maxValue: moment().subtract(18, 'year').format('YYYY-MM-DD'),
          minValue: '1950-01-01',
        },
        {
          name: 'emergency_contact',
          label: 'Emergency Contact',
          prepandText: '+',
          required: false,
          type: 'number',
          defaultValue: '',
          minValue: 0,
          validations: {
            minLength: minLength(12),
            maxLength: maxLength(16),
            regex: (value) =>
              this.validateRegex(value, RegEx.PHONE_NUMBERS_CHARACTER),
          },
        },
        {
          name: 'address_personal',
          label: 'Address',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'country',
          label: 'Country',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'state',
          label: 'State',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'city',
          label: 'City',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
      ],
    };
  },
  created() {
    this.fetchCountries((_isCountriesLoaded) => {
      if (this.userDetails.country) {
        this.fetchStates(this.userDetails.country, (_states) => {
          if (this.userDetails.state) {
            this.fetchCities(
              this.userDetails.state,
              this.userDetails.country,
              (_cities) => {
                this.convertResponseToPayload(this.userDetails);
              }
            );
          } else {
            this.convertResponseToPayload(this.userDetails);
          }
        });
      } else {
        this.convertResponseToPayload(this.userDetails);
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onFieldChange(field, value, allValues) {
      if (field.name === 'country') {
        this.fetchStates(value.value, (_states) => {
          allValues.state = '';
          allValues.city = '';
        });
      }
      if (field.name === 'state') {
        this.fetchCities(value.value, value.country, (_cities) => {
          allValues.city = '';
        });
      }
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        this.updateUserInfo(values);
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
    },
    convertResponseToPayload(data) {
      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = data[formField.name];
        // Update Default for date type
        if (formField.type === 'date' && data[formField.name]) {
          formField.defaultValue = data[formField.name].substring(0, 10);
        }
        // Update Default for select type
        if (formField.type === 'select') {
          // Update Default for single select type
          formField.defaultValue = formField.options.find(
            (option) => option.value === data[formField.name]
          );
        }
      });
    },

    fetchCountries(callback) {
      axios
        .get(`/manage/users/countries`)
        .then((response) => {
          const countries = response.data.countries;
          const filteredCountries = Object.keys(countries).map(
            (countryCode) => {
              return {
                label: countries[countryCode],
                value: countryCode,
              };
            }
          );
          this.setOptions(
            'country',
            'options',
            this.sortArray(filteredCountries, 'label')
          );
          callback(true);
        })
        .catch((error) => {
          console.error('ERR while fetchCountries', error);
          callback();
        });
    },
    fetchStates(countryCode, callback) {
      axios
        .get(`/manage/users/states?country=${countryCode}`)
        .then((response) => {
          const states = response.data.states;
          const filteredStates = Object.keys(states).map((stateCode) => {
            return {
              label: states[stateCode],
              value: stateCode,
              country: countryCode,
            };
          });
          this.setOptions(
            'state',
            'options',
            this.sortArray(filteredStates, 'label')
          );
          callback(filteredStates);
        })
        .catch((error) => {
          console.error('ERR while fetchStates', error);
          callback([]);
        });
    },
    fetchCities(stateCode, countryCode, callback) {
      axios
        .get(`/manage/users/cities?country=${countryCode}&state=${stateCode}`)
        .then((response) => {
          const cities = response.data.cities;
          const filteredCities = cities.map((city) => {
            return {
              label: city,
              value: city,
            };
          });
          this.setOptions(
            'city',
            'options',
            this.sortArray(filteredCities, 'label')
          );
          callback(filteredCities);
        })
        .catch((error) => {
          console.error('ERR while fetchCities', error);
          callback();
        });
    },
    updateUserInfo(values) {
      const params = Object.assign({}, values, { id: this.userDetails.id });
      this.$emit('updateUserInfo', params);
    },
  },
};
</script>

<style scoped>
.invalid-feedback {
  font-size: 11px;
  color: red;
}
.invalid-feedback {
  font-size: 11px;
  color: red;
  bottom: -16px;
  left: 0px;
  /* pointer-event: none; */
  transition: all 0.5s ease-in-out;
}

input[type='date']:after {
  font-family: 'Material Icons';
  content: 'calendar_today';
  color: #555;
  padding: 0 5px;
  font-weight: 900;
}
input[type='date']::-webkit-calendar-picker-indicator {
  bottom: 0;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 50px;
  width: auto;
}
/deep/ input[name='emergency_contact']::-webkit-inner-spin-button,
input[name='emergency_contact']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
