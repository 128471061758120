<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        My Project Approvals
      </div>
      <DateRangePickerInput
        ref="myProjectApprovalDateRangePicker"
        @updateDateRange="setFilterByDateRange"
        :startDate="filterBy.dateRange && filterBy.dateRange.startDate"
        :endDate="filterBy.dateRange && filterBy.dateRange.endDate"
        popupAlign="left"
      />
    </div>
    <!-- Top Bar END-->
    <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
      <CustomFilter
        v-if="showApprovalFilter"
        :filterBy="filterBy"
        @resetFilter="resetFilter"
        @toggleFilter="toggleFilter"
        @setFilterByProjects="setFilterByProjects"
        @setFilterByUsers="setFilterByUsers"
        @setFilterByWorkLogStatus="setFilterByWorkLogStatus"
        :projectOptionMeta="projectOptionMeta"
        :projectOptions="projectOptions"
        :userOptions="userOptions"
        :workLogStatus="workLogStatus"
      />

      <div class="flex py-4 px-4 justify-between items-center">
        <!-- Icons -->

        <div class="flex items-center">
          <button
            v-if="isManagePermission"
            class="bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 flex items-center text-sm"
            @click="submit('approve'); allSelected = false"
          >
            <span class="text-sm">Submit</span>
          </button>
          <button
            v-if="isManagePermission"
            class="bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 ml-2 flex items-center text-sm"
            @click="submit('approveAll'); allSelected = false"
          >
            <span class="text-sm">Approve All</span>
          </button>
          <button
            v-if="isManagePermission"
            class="bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 ml-2 flex items-center text-sm"
            @click="recall(); allSelected = false"
          >
            <span class="text-sm">Recall</span>
          </button>
        </div>
        <!-- Icons END -->
        <div class="flex flex-row items-center">
          <!-- Search Input -->
          <div class="relative text-gray-600" style="margin-right: 30px">
            <input
              v-model="query"
              @input="setQuery"
              type="search"
              name="serch"
              placeholder="Search"
              class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
            />
            <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
              <span class="material-icons md:text-2xl text-sm text-gray-400"
                >search</span
              >
            </button>
          </div>
          <!-- Search END -->
          <span
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleFilter"
            >filter_alt</span
          >
        </div>
      </div>
      <Loading v-if="isLoading" style="height: 68vh" />
      <div v-else>
        <!-- Table -->
        <div class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && reports.length > 0"
          >
            <table
              ref="table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    v-if="isManagePermission"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    <input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                    />
                  </th>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    style="z-index: 1"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="rowIndex"
                  v-for="(report, rowIndex) in reports"
                  class="py-2"
                  :class="rowIndex % 2 === 1 ? 'bg-gray-100' : ''"
                  style="height: 30px"
                >
                  <td
                    v-if="isManagePermission"
                    class="px-4 text-xs text-center"
                  >
                    <input
                      type="checkbox"
                      @click="selected(rowIndex)"
                      :value="rowIndex"
                      v-model="rowSelect.includes(rowIndex)"
                    />
                  </td>
                  <td class="px-4 text-xs" v-if="isReadProjectPermission">
                    <router-link
                      :to="`/manage/projects/${report.user_specific_data.selected_projects}`"
                    >
                      <p
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                      >
                        {{ report.projects }}
                      </p>
                    </router-link>
                  </td>
                  <td class="px-4 text-xs" v-else>
                    <p
                      class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                    >
                      {{ report.projects }}
                    </p>
                  </td>
                  <td class="px-4 text-xs" v-if="isReadUserPermission">
                    <router-link :to="`/manage/users/${report.user.id}`">
                      <p
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                      >
                        {{
                          report.user.first_name + ' ' + report.user.last_name
                        }}
                      </p>
                    </router-link>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ report.user.email }}
                    </p>
                  </td>
                  <td class="px-4 text-xs" v-else>
                    <p
                      class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                    >
                      {{ report.user.first_name + ' ' + report.user.last_name }}
                    </p>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ report.user.email }}
                    </p>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <p class>
                      {{ globalDateFormat(report.user_specific_data.from) }}
                    </p>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <p class>
                      {{ globalDateFormat(report.user_specific_data.to) }}
                    </p>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <p class>
                      {{ report.logs }}
                    </p>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <v-select
                      :name="'status'"
                      :value="status[rowIndex] || report.status || 'Submitted'"
                      @input="(option) => changeWorkLogStatus(option, rowIndex)"
                      :disabled="!rowSelect.includes(rowIndex)"
                      :placeholder="'Select Status'"
                      :options="workLogstatus"
                      class="px-4 py-1 rounded-sm focus:outline-none text-gray-600 text-sm"
                    ></v-select>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <input
                      :value="comments[rowIndex] || report.comments"
                      :placeholder="'Enter Comments'"
                      @input="(e) => onTextInputChange(e, rowIndex)"
                      class="px-4 py-1 border rounded-sm focus:outline-none text-gray-600 text-sm"
                    />
                  </td>
                  <td class="px-4 text-xs text-center">
                    <button
                      class="material-icons"
                      style="color: gray"
                      @click="() => openMyApprovalModal(report)"
                    >
                      insert_drive_file
                    </button>
                    <button
                      class="material-icons"
                      style="color: gray"
                      @click="() => generateExcel(report)"
                    >
                      file_download
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
      </div>
      <!-- Table END -->
    </div>

    <!-- Pagination -->
    <div>
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchApprovalData"
        @setPageSize="setPageSize"
      />
    </div>
    <LogDetailsModal
      v-if="isMyApprovalModalVisible"
      :logs="logs"
      :userName="userName"
      :projectName="projectName"
      :fromDate="fromDate"
      :toDate="toDate"
      @close="closeMyApprovalModal"
    ></LogDetailsModal>
  </div>
  <!-- Pagination End-->
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import LogDetailsModal from '@/components/timesheet/MyProjectApprovals/LogDetailsModal.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'MyProjectApprovals',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    NoRecordFound,
    DateRangePickerInput,
    LogDetailsModal,
  },
  data() {
    const startDate = moment()
      .subtract(1, 'week')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    const endDate = moment().startOf('week').format('YYYY-MM-DD');
    return {
      rejectedBg: [],
      showApprovalFilter: false,
      projectOptions: [],
      userOptions: [],
      isMyApprovalModalVisible: false,
      projectOptionMeta: {},
      status: [],
      logs: [],
      userName: '',
      projectName: '',
      comments: [],
      fortNightLogs: [],
      workLogStatus: { label: 'All', value: 'All' },
      fromDate: null,
      toDate: null,
      filterBy: {
        projects: [],
        users: [],
        workLogStatus: { label: 'All', value: 'All' },
        dateRange: { startDate, endDate },
      },
      workLogstatus: [
        { label: 'Submitted', value: 'submitted', disabled: true },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Approved', value: 'approved' },
      ],
      allSelected: false,
      rowSelect: [],
      checked: true,
      query: '',
      columns: [
        'Project Name',
        'User Name',
        'Start Date',
        'End Date',
        'Total Hours',
        'Approval Status',
        'Comments',
        'Action',
      ],
      reports: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      defaultFilterBy: {},
      payload: [],
      isReadPermission: false,
      isManagePermission: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
  },
  created() {
    this.rowSelect = [];
    this.fetchApprovalData = _debounce(this.fetchApprovalData, 400);
    this.defaultFilterBy = { ...this.filterBy };
    this.isReadPermission = this.validatePermission([
      'timesheet.my_project_approvals.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'timesheet.my_project_approvals.manage',
    ]);
    if (this.isReadPermission) {
      this.fetchApprovalData(1);
      this.fetchProjectOptions(1);
      this.fetchUserOptions(1);
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    selectAll() {
      this.rowSelect = [];
      if (!this.allSelected) {
        this.checked = true;
        this.reports.forEach((item, index) => {
          this.rowSelect.push(index);
          this.payload.push(item);
        });
      } else {
        this.checked = false;
      }
    },
    selected(index) {
      this.checked = false;
      if (this.rowSelect.includes(index)) {
        this.rowSelect = this.rowSelect.filter((i) => i != index);
      } else {
        this.rowSelect.push(index);
      }
    },
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      const { defaultDateRange } = this.$refs.myProjectApprovalDateRangePicker;
      this.$refs.myProjectApprovalDateRangePicker.dateRange = defaultDateRange;
      this.setFilterByDateRange(defaultDateRange);
      this.fetchApprovalData(1);
    },
    toggleFilter() {
      this.showApprovalFilter = !this.showApprovalFilter;
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchUserOptions(1);
      this.fetchApprovalData(1);
    },
    setFilterByUsers(option) {
      this.filterBy.users = option;
      this.fetchApprovalData(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchApprovalData(1);
      }
    },
    setFilterByWorkLogStatus(option) {
      this.filterBy.workLogStatus = option;
      this.fetchApprovalData(1);
    },
    loadMore(page) {
      this.fetchApprovalData(page);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchProjectOptions(1, true, query);
      }
    },
    changeWorkLogStatus(option, index) {
      this.status[index] = option;
      if (this.rowSelect.includes(index)) {
        this.rowSelect = this.rowSelect.filter((i) => i !== index);
        this.rowSelect.push(index);
      }
      if (option.value === 'rejected' || option.value === 'approved') {
        let payload = this.reports[index];
        payload = Object.assign({}, payload, { status: option.value });
        this.payload.push(payload);
      }
    },
    onTextInputChange(e, rowIndex) {
      this.comments[rowIndex] = e.target.value;
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchApprovalData(1);
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchApprovalData(1);
    },
    getUsersPayload() {
      const { filterBy, query } = this;
      const payload = {};
      const users = filterBy.users.map((user) => user.value);
      const status = filterBy.workLogStatus;
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (users.length > 0) {
        payload.users = users;
      }
      if (status) {
        payload.status = status.value;
      }
      if (query) {
        payload.search = query;
      }
      if (filterBy.dateRange) {
        payload.date_range = JSON.stringify(filterBy.dateRange);
      }
      return payload;
    },
    fetchApprovalData(page) {
      this.isLoading = true;
      const { pageSize } = this;
      this.nonce = Date.now().toString();
      const payload = this.getUsersPayload();
      axios
        .get(
          `/timesheet/weekly_worklog_approvals/get_project_approvals?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              project_id: payload.projects,
              users_id: payload.users,
              status: payload.status,
              search: payload.search,
              nonce: this.nonce,
              date_range: payload.date_range,
            },
          }
        )
        .then((response) => {
          if (this.nonce !== response.data.nonce) return;
          this.paginationMeta = response.data.meta;
          this.reports = response.data.result;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    fetchProjectOptions(page, isSearch = false, query = null) {
      const { pageSize } = this;

      let payload = {};
      if (query) {
        payload.search = query;
      }
      axios
        .get(
          `/timesheet/work_logs/project_list?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`
        )
        .then((response) => {
          this.projectOptionMeta = response.data.meta;
          const projectOptions = response.data.projects.map((project) => {
            return { label: project.name, value: project.id };
          });
          this.projectOptions = isSearch
            ? projectOptions
            : this.projectOptions.concat(projectOptions);
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    fetchUserOptions() {
      const payload = this.getUsersPayload();
      axios
        .get(`/timesheet/work_logs/users_list`, {
          params: {
            project_id: payload.projects,
          },
        })
        .then((response) => {
          this.userOptions = this.filterUsers(response.data.users);
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchUsers', error);
        });
    },
    filterUsers(users) {
      const filteredUsers = [];
      users.forEach((user) => {
        filteredUsers.push({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
        });
      });
      return filteredUsers;
    },
    openMyApprovalModal(report) {
      this.logs = report.user_log_details;
      this.userName = report.user.first_name + ' ' + report.user.last_name;
      this.projectName = report.projects;
      this.fromDate = report.user_specific_data.from;
      this.toDate = report.user_specific_data.to;
      this.isMyApprovalModalVisible = true;
    },
    closeMyApprovalModal() {
      this.isMyApprovalModalVisible = false;
    },
     getPayloadData(value) {
      const comments = this.comments.filter((e) => e);
      let payloadData = [];
      this.payload.forEach((data, index) => {
        let payload = {};
        payload.status = value == 'approveAll' ? 'approved' : data.status;
        payload.comments = comments[index] ? comments[index] : '';
        payload.from = data.user_specific_data.from;
        payload.to = data.user_specific_data.to;
        payload.selected_projects = data.user_specific_data.selected_projects;
        payload.user = data.user_specific_data.user;
        payloadData.push(payload);
      });
      return payloadData;
    },
    recallPayloadData() {
      let payloadData = [];
      for (let i = 0; i < this.rowSelect.length; i++) {
        let payload = {};
        let data = this.reports[this.rowSelect[i]];
        payload.status = data.status;
        payload.comments = data.comments;
        payload.from = data.user_specific_data.from;
        payload.to = data.user_specific_data.to;
        payload.selected_projects = data.user_specific_data.selected_projects;
        payload.user = data.user_specific_data.user;
        payloadData.push(payload);
      }
      return payloadData;
    },
    submit(value) {
      const payload = this.getPayloadData(value);
      axios
        .post(`/timesheet/weekly_worklog_approvals/save_worklog_status`, {
          payload,
        })
        .then(() => {
          this.rowSelect = [];
          this.fetchApprovalData(1);
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    recall() {
      const payload = this.recallPayloadData();
      axios
        .post(`/timesheet/weekly_worklog_approvals/recall_worklog_status`, {
          payload,
        })
        .then(() => {
          this.rowSelect = [];
          this.fetchApprovalData(1);
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    generateExcel(report) {
      axios
        .get(`/timesheet/weekly_worklog_approvals/excel_weekly_logs`, {
          params: {
            user_id: report.user_specific_data.user,
            project_id: report.user_specific_data.selected_projects,
            userName: report.user.first_name + ' ' + report.user.last_name,
            projectName: report.projects,
            fromDate: report.user_specific_data.from,
            toDate: report.user_specific_data.to,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${report.user.first_name + ' ' + report.user.last_name}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Log Details', error);
        });
    },
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
