<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Histories
        </div>

        <div class="flex flex-row items-center">
          <div class="bg-white mr-0 text-sm">
            <nav class="flex flex-col sm:flex-row">
              <button
                v-if="validatePermission(['manage.histories.read'])"
                @click="selectedTab = 'users'"
                :class="
                  selectedTab === 'users'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Users
              </button>
              <button
                v-if="validatePermission(['manage.histories.read'])"
                @click="selectedTab = 'projects'"
                :class="
                  selectedTab === 'projects'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Projects
              </button>
              <button
                v-if="validatePermission(['manage.histories.read'])"
                @click="selectedTab = 'projects_users'"
                :class="
                  selectedTab === 'projects_users'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Project Members
              </button>
            </nav>
          </div>
          <div></div>
        </div>
      </div>
      <!-- Top Bar END-->
      <div
        v-if="
          selectedTab === 'users' &&
          validatePermission(['manage.histories.read'])
        "
      >
        <UserHistoryTab class="w-full" />
      </div>
      <div
        v-else-if="
          selectedTab === 'projects' &&
          validatePermission(['manage.histories.read'])
        "
      >
        <ProjectHistoryTab class="w-full" />
      </div>
      <div
        v-else-if="
          selectedTab === 'projects_users' &&
          validatePermission(['manage.histories.read'])
        "
      >
        <ProjectUserHistoryTab class="w-full" />
      </div>

      <NotAuthorized v-else />
      <!-- Pagination -->
    </div>
  </div>
</template>

<script>
import UserHistoryTab from '@/components/manage/histories/UserHistoryTab.vue';
import ProjectHistoryTab from '@/components/manage/histories/ProjectHistoryTab.vue';
import ProjectUserHistoryTab from '@/components/manage/histories/ProjectUserHistoryTab.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';

export default {
  name: 'Histories',
  components: {
    UserHistoryTab,
    NotAuthorized,
    ProjectHistoryTab,
    ProjectUserHistoryTab,
  },
  data() {
    return {
      selectedTab: 'users',
    };
  },
};
</script>
