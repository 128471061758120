import moment from 'moment';
import store from '../store';
import axios from 'axios';

export default {
  methods: {
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    globalDateFormat: (date) =>
      moment(date.substring(0, 10)).format('DD MMM YYYY'),
    globalDateFormatWithDay: (date) =>
      moment(date.substring(0, 10)).format('dddd, DD MMM YYYY'),
    globalDateTimeFormat: (date) => moment(date).format('DD MMM YYYY HH:mm:ss'),
    validatePermission: (fieldPermissions) =>
      store.state.user.permissions.some((userPermission) =>
        fieldPermissions.includes(userPermission)
      ),
    setUserData: (callback) => {
      if (store.getters['user/isLoggedIn']) {
        axios
          .get('/user_data')
          .then((res) => {
            store.commit('user/setUserData', res.data);
            callback(res.data);
          })
          .catch((err) => {
            console.error('Error while fetching User data', err);
            callback();
          });
      } else {
        callback();
      }
    },
    maxValueDate: (_date) => (value) => value < new Date().toISOString(),
    validateRegex: (value, pattern) => (value ? pattern.test(value) : true),
    validateMinDate: (value, date) =>
      value
        ? date
          ? value > new Date(date).toISOString()
          : new Date(date).toISOString()
        : true,
    validateMaxDate: (value, date) =>
      value
        ? date
          ? value < new Date(date).toISOString()
          : new Date(date).toISOString()
        : true,
    sortArray: (array, key) =>
      array.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0)),
    validateMinValueText: (value, minValue) =>
      value ? parseFloat(value) < minValue : true,
    validateMaxValueText: (value, maxValue) =>
      value ? parseFloat(value) > maxValue : true,
    updateQueryParams(params) {
      this.$router.push({ path: this.$route.path, query: { ...params } });
    },
    isWeekendIncluded(startDate, endDate) {
      while (startDate <= endDate) {
        var day = moment(startDate).day();
        if (day === 0 || day === 6) {
          return true;
        }
        startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
      }
      return false;
    },
  },
};
