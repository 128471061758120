<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            class="absolute inset-0 bg-gray-500 opacity-75"
            @click="$emit('close')"
          ></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Add Members:
                  <span class="text-gray-400 ml-2">{{ projectName }}</span>
                </h3>
              </div>
              <button
                @click="addRow"
                class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
              >
                <span class="material-icons m-auto mr-1 text-base">add</span>

                <span class="text-sm">Add Row</span>
              </button>
            </div>
          </div>

          <hr />

          <div class="overflow-auto h-64 max-h-96">
            <table
              id="projects_table"
              class="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead class="bg-white">
                <tr class="text-gray-600 text-left">
                  <th
                    class="font-semibold text-xs uppercase px-0 py-2 text-left"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    <span :class="index === 0 ? 'pl-3' : 'pl-3'">
                      {{ column }}
                    </span>
                  </th>
                </tr>
              </thead>
              <Loading v-if="isLoading" />
              <tbody
                v-else
                class="divide-y divide-gray-200 text-xs text-gray-700"
              >
                <tr
                  :key="rowIndex"
                  v-for="(rowData, rowIndex) in rows"
                  class="py-2"
                >
                  <td
                    :style="colData.style"
                    class="px-0 text-xs py-2 items-start"
                    v-for="colData in rowData"
                    :key="colData.name"
                  >
                    <TextInput
                      v-if="
                        ['text', 'date', 'number', 'color'].includes(
                          colData.type
                        )
                      "
                      :value="payload[rowIndex][colData.name]"
                      :name="colData.name"
                      :type="colData.type"
                      :required="colData.required"
                      :disabled="colData.disabled"
                      :validations="colData.validations || null"
                      :label="colData.label"
                      :maxValue="colData.maxValue"
                      :minValue="colData.minValue"
                      :isEdit="true"
                      :aligned="'vertical'"
                      :errorLabel="errorLabel"
                      @onChange="(e) => onChange(e, rowIndex)"
                    />
                    <SelectInput
                      :value="payload[rowIndex][colData.name]"
                      v-if="colData.type === 'select'"
                      :name="colData.name"
                      :required="colData.required"
                      :label="colData.label"
                      :options="colData.options"
                      :multiple="colData.multiple"
                      :aligned="'vertical'"
                      :disabled="colData.disabled"
                      :placeholder="colData.placeholder"
                      @onChange="
                        (option, name) => onSelect(option, name, rowIndex)
                      "
                    />
                    <SwitchInputWithLabel
                      v-if="colData.type === 'switch'"
                      :value="payload[rowIndex][colData.name]"
                      :name="colData.name"
                      :switchLabels="colData.switchLabels"
                      :required="colData.required"
                      :label="colData.label"
                      :isEdit="true"
                      :aligned="'vertical'"
                      @onChange="(e) => onChange(e, rowIndex)"
                    />
                  </td>
                  <span
                    v-if="rows.length > 1"
                    @click="() => removeRow(rowIndex)"
                    class="material-icons align-middle mt-4 mr-2 text-2xl text-gray-500 cursor-pointer hover:text-red-500"
                    >highlight_remove</span
                  >
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="sm:flex sm:items-start sm:justify-between mt-3 bg-gray-50"
          >
            <div class="flex flex-row w-60 items-center mt-4">
              <label
                class="leading-loose whitespace-normal text-gray-400 text-sm mr-1 ml-3 w-full"
                >Notify PMO:</label
              >
              <SwitchInputWithLabel
                :value="notify_pmo"
                :isEdit="true"
                :switchLabels="['Yes', 'No']"
                :aligned="'vertical'"
                class="py-2"
                @onChange="pmoSwitchChange"
              />
            </div>
            <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                :disabled="disableModelActions"
                type="button"
                @click="save"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
              <button
                :disabled="disableModelActions"
                type="button"
                @click="close"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import TextInput from '@/components/inputs/TextInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import SwitchInputWithLabel from '@/components/inputs/SwitchInputWithLabel.vue';
import Loading from '@/components/common/Loading.vue';
import * as _ from 'lodash';
import moment from 'moment';
export default {
  name: 'AddMemberModal',
  components: {
    TextInput,
    SwitchInputWithLabel,
    SelectInput,
    Loading,
  },
  data() {
    return {
      columns: [
        'User',
        'Billability',
        'Start Date',
        'End Date',
        '% Allocation',
        'Hourly Rate',
        'Status',
        '',
      ],
      isLoading: true,
      rows: [],
      errorLabel: '',
      defaultRow: [
        {
          name: 'user',
          label: '',
          placeholder: 'Select User',
          required: true,
          type: 'select',
          options: [],
          style: 'width: 17rem; max-width: 17rem; min-width: 17rem;',
        },
        {
          name: 'billability',
          label: '',
          required: true,
          type: 'select',
          placeholder: 'Select Billability',
          disabled: false,
          options: [
            { label: 'Billable', value: 'Billable' },
            { label: 'Non Billable', value: 'Non Billable' },
            { label: 'Shadow-Budgeted', value: 'Shadow-Budgeted' },
            { label: 'Shadow-Non Budgeted', value: 'Shadow-Non Budgeted' },
          ],
          style: 'width: 17rem; max-width: 17rem; min-width: 17rem;',
        },
        {
          name: 'start_user_date',
          label: '',
          required: true,
          type: 'date',
          minValue: '2014-01-01',
        },
        {
          name: 'end_user_date',
          label: '',
          required: true,
          type: 'date',
          minValue: '2014-01-01',
        },
        {
          name: 'allocation',
          label: '',
          required: true,
          type: 'select',
          options: [
            { label: '25%', value: 25 },
            { label: '50%', value: 50 },
            { label: '75%', value: 75 },
            { label: '100%', value: 100 },
          ],
        },
        {
          name: 'hourly_rate',
          label: '',
          required: false,
          disabled: false,
          type: 'number',
          step: '.01',
          maxValue: 100000000,
          minValue: 1,
        },
        {
          name: 'project_user_status',
          label: '',
          switchLabels: ['Active', 'Inactive'],
          value: true,
          required: true,
          type: 'switch',
        },
      ],
      billableTypes: ['Billable', 'Shadow-Budgeted'],
      payload: [],
      notify_pmo: true,
      disableModelActions: false,
      users: [],
      users_with_more_alloction: [],
    };
  },
  props: {
    projectName: {
      type: String,
    },
    projectId: {
      type: Number,
      required: true,
    },
    defaultProject: {
      type: Boolean,
    },
    projectEndDate: {
      type: String,
    },
    projectStartDate: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('user', []),
    hourlyRateIndex() {
      return this.defaultRow.findIndex((form) => form.name === 'hourly_rate');
    },
  },
  methods: {
    addRow() {
      this.rows.push(this.defaultRow);
      let billability = {};
      if (this.defaultProject) {
        billability = { label: 'Non Billable', value: 'Non Billable' };
      }
      this.payload.push({
        project_user_status: true,
        allocation: '1',
        hourly_rate: 0,
        hourly_company_rate: null,
        start_user_date: '',
        end_user_date: '',
        billability: billability,
      });
    },
    removeRow(index) {
      this.payload.splice(index, 1);
      this.rows.splice(index, 1);
    },
    onChange(e, rowIndex) {
      this.payload[rowIndex][e.target.name] = e.target.value;
      if (e.target.name === 'start_user_date') {
        this.defaultRow[3].minValue = e.target.value;
      }
      if (e.target.name === 'end_user_date') {
        if (
          moment(e.target.value)
            .utc()
            .diff(moment(this.projectEndDate).utc().format('YYYY-MM-DD')) > 0
        ) {
          this.$store.dispatch('settings/showAlert', {
            alertMessage:
              "Project Member's end date should not be grater than the project's end date " +
              moment(this.projectEndDate).format('YYYY-MM-DD'),
            alertType: 'error',
          });
          e.target.value = '';
          this.errorLabel = 'End Date';
        }
      }
      if (e.target.name === 'start_user_date') {
        if (
          moment(e.target.value)
            .utc()
            .diff(moment(this.projectStartDate).utc().format('YYYY-MM-DD')) < 0
        ) {
          this.$store.dispatch('settings/showAlert', {
            alertMessage:
              "Project Member's start date should not be less than the project's start date " +
              moment(this.projectStartDate).format('YYYY-MM-DD'),
            alertType: 'error',
          });
          e.target.value = '';
          this.errorLabel = 'Start Date';
        }
      }
    },
    pmoSwitchChange(e) {
      this.notify_pmo = e.target.value;
    },
    onSelect(option, name, rowIndex) {
      if (name === 'billability') {
        this.rows[rowIndex][this.hourlyRateIndex].disabled =
          !this.billableTypes.includes(option.value);
        if (this.billableTypes.includes(option.value)) {
          this.rows[rowIndex][this.hourlyRateIndex].required = true;
        } else {
          this.rows[rowIndex][this.hourlyRateIndex].required = false;
        }
      }
      this.payload[rowIndex][name] = option;
    },
    setSelectedBillability(billability) {
      this.payload.billability = billability;
    },
    close() {
      this.$emit('close');
    },
    validateInputs(callback) {
      const errorTextClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600 px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm';
      const inputDivs = document.getElementsByClassName('text_error');
      const selectDivs = document.getElementsByClassName('select_error');
      if (inputDivs.length > 0 || selectDivs.length > 0) {
        let i;

        for (i = 0; i < inputDivs.length; i++) {
          inputDivs[i].className = errorTextClass;
        }

        let selectDiv;
        for (selectDiv in selectDivs) {
          if (selectDivs[parseInt(selectDiv)]) {
            selectDivs[parseInt(selectDiv)].getElementsByTagName(
              'div'
            )[0].style['border-color'] = 'red';
          }
        }
        callback(false);
      } else {
        callback(true);
      }
    },
    async addMember(callback) {
      this.disableModelActions = true;
      await this.checkUsersTotalAllocation();
      if (this.users_with_more_alloction.length > 0) {
        console.log(this.users_with_more_alloction.length);
        let users = this.users_with_more_alloction.map((user) => user.name);
        this.$confirm(
          `User Allocation  for ${users.join(
            ','
          )} is more than 100%. Do you want to continue?`
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.addUser(callback);
          }
        });
      } else {
        this.addUser(callback);
      }
    },

    async addUser(callback) {
      this.disableModelActions = true;
      let { payload, projectId } = this;
      const payloadData = _.cloneDeep(payload);
      payloadData.forEach((data) => {
        data.user_id = data.user.value;
        data.billability = data.billability.value;
        data.allocation = data.allocation.value;
        if (!this.billableTypes.includes(data.billability)) {
          data.hourly_rate = '0';
        }
      });
      await axios
        .post(`/manage/projects/add_user?project_id=${projectId}`, {
          payload: payloadData,
          notify_pmo: this.notify_pmo,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.disableModelActions = false;
          callback(response.data);
        })
        .catch((error) => {
          this.disableModelActions = false;
          const { data = {} } = error.response;
          console.error('ERR while addManager', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
    async checkUsersTotalAllocation() {
      let { payload, projectId } = this;
      const payloadData = _.cloneDeep(payload);
      let user_allocation = [];
      payloadData.forEach((data) => {
        user_allocation.push({
          id: data.user.value,
          allocation: data.allocation.value,
          project_id: projectId,
        });
      });
      await axios
        .get(`/manage/projects/users_total_allocation/`, {
          params: {
            payload: user_allocation,
          },
        })
        .then((response) => {
          this.users = response.data.data;
          this.users_with_more_alloction = [];
          this.users.forEach((user) => {
            if (user.allocation > 100) {
              this.users_with_more_alloction.push(user);
            }
          });
          this.disableModelActions = false;
        })
        .catch((error) => {
          this.disableModelActions = false;
          const { data = {} } = error.response;
          console.error('ERR while addManager', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },
    save() {
      this.validateInputs((isValid) => {
        if (isValid) {
          this.addMember((data) => {
            if (data) {
              this.$emit('success', data);
            }
          });
        } else {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Please fill all mandatory fields',
            alertType: 'error',
          });
        }
      });
    },
    userNotAssignedOnProject(callback) {
      axios
        .get(`/manage/users/not_project_members?project_id=${this.projectId}`)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          console.error('ERR while userNotAssignedOnProject', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
  },
  created() {
    this.userNotAssignedOnProject((data) => {
      this.defaultRow[3].minValue = this.defaultRow[2].maxValue;
      this.defaultRow[0].options = data;
      this.rows.push(this.defaultRow);
      let billability = {};
      if (this.defaultProject) {
        billability = { label: 'Non Billable', value: 'Non Billable' };
        this.defaultRow[1].disabled = true;
      }
      this.payload.push({
        project_user_status: true,
        allocation: '1',
        hourly_rate: 0,
        hourly_company_rate: null,
        start_user_date: '',
        end_user_date: '',
        billability: billability,
      });
      this.isLoading = false;
    });
  },
};
</script>
