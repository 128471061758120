<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Project Assignment
        </div>
      </div>
      <!-- Top Bar END-->
      <ProjectTab
        class="w-full"
        :showProjectAssignmentFilter="showProjectAssignmentFilter"
        @toggleFilter="toggleFilter"
      />
      <!-- Pagination -->
    </div>
  </div>
</template>

<script>
import ProjectTab from '@/components/pmo/project_assignment/ProjectTab.vue';

export default {
  name: 'ProjectAssignment',
  components: {
    ProjectTab,
  },
  data() {
    return {
      showProjectAssignmentFilter: false,
    };
  },
  methods: {
    toggleFilter() {
      this.showProjectAssignmentFilter = !this.showProjectAssignmentFilter;
    },
  },
};
</script>
