<template>
  <div
    v-if="userProfile.length > 0"
    tabindex="0"
    class="relative border-l-2"
    style="width: 50%"
  >
    <div :key="i" v-for="(detail, i) in userProfile">
      <div
        class="border-b-2 bg-gray-100 flex flex-col items-center justify-center h-52 relative"
      >
        <button
          tabindex="0"
          class="absolute top-1 right-1 text-gray-400"
          @click="closeDetails()"
        >
          <span class="material-icons">close</span>
        </button>
        <img
          v-if="detail.image"
          :src="baseImageUrl + detail.image"
          class="h-20 w-20 rounded-full"
        />
        <div
          v-else
          class="h-20 w-20 border-gray-400 border-2 bg-gray-200 flex items-center justify-center font-thin text-gray-500 text-3xl rounded-full"
        >
          {{ getInitials(detail.first_name, detail.last_name) }}
        </div>
        <span class="font-semibold text-sm mt-3">
          {{ detail.first_name + ' ' + detail.last_name }}
        </span>
        <span class="font-thin text-xs">
          {{ detail.designation + ' - ' + detail.practice }}</span
        >
        <span class="font-thin text-xs"> {{ detail.email }}</span>
      </div>
      <div class="border-b-2 pl-4 pt-2">
        <p class="font-thin text-sm font-bold text-left pb-4">
          <span class="pb-1 border-gray-300 border-b-2">Profile</span>
        </p>
        <div class="flex flex-row">
          <span
            class="align-middle material-icons text-gray-300"
            style="font-size: 1.2em"
            >smartphone</span
          >
          <span class="text-gray-500 text-sm pl-3">
            {{ detail.mobile_number ? detail.mobile_number : 'No Contact' }}
          </span>
          <span
            class="pl-16 align-middle material-icons text-gray-300"
            style="font-size: 1.2em"
            >place</span
          >
          <span class="text-gray-500 text-sm pl-3">
            {{ detail.location }}
          </span>
        </div>
        <p class="pl-1 pb-4 pt-3 text-left">
          <span
            class="align-middle material-icons text-gray-300"
            style="font-size: 1.2em; width: 20px"
            >table</span
          >
          <span class="text-gray-500 text-sm pl-2">
            {{ detail.department.name }}
          </span>
        </p>
      </div>
      <div class="pl-4 pt-2 pb-2">
        <p class="text-sm font-bold text-left pb-4">
          <span class="pb-1 border-gray-300 border-b-2">Reporting To</span>
        </p>
        <div>
          <div v-if="detail.manager_id" class="flex flex-row">
            <img
              v-if="detail.manager_image"
              :src="baseImageUrl + detail.manager_image"
              class="rounded-full h-10 w-10 items-center justify-center"
            />
            <div
              v-else
              style="border-radius: 50%"
              class="h-10 w-10 border-gray-400 border-2 bg-gray-200 flex items-center justify-center text-base font-thin text-gray-500 text-xs rounded-full"
            >
              {{
                getInitials(detail.manager_first_name, detail.manager_last_name)
              }}
            </div>
            <div class="flex flex-col">
              <router-link :to="`/manage/users/${detail.manager_id}`"
                ><span
                  @click="getProfile"
                  class="text-sm text-blue-400 float-left pl-2"
                  >{{
                    detail.manager_first_name + ' ' + detail.manager_last_name
                  }}
                </span>
              </router-link>
              <span class="text-xs pl-2">{{ detail.manager_email }}</span>
            </div>
          </div>
          <div v-else class="pl-2 flex flex-row text-gray-500 text-sm">
            No Reporting Manager Assigned
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 ml-32 pb-4">
        <router-link :to="`/manage/users/${detail.id}`">
          <button
            @click="getProfile"
            class="bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 flex items-center text-sm"
          >
            <span class="text-sm">View Profile</span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchUserDetails',
  data() {
    return {
      userProfile: [],
    };
  },
  props: ['userDetail', 'reportingManager'],
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
    ...mapState('user', ['userDetails']),
  },
  methods: {
    getInitials(firstName, lastName) {
      const firstNameIntial =
        firstName && firstName[0] && firstName[0].toUpperCase();
      const lastNameInitial =
        lastName && lastName[0] && lastName[0].toUpperCase();
      return (firstNameIntial || '') + (lastNameInitial || '');
    },
    getProfile() {
      this.$emit('getProfile');
    },
    closeDetails() {
      this.$emit('close');
    },
  },
  created() {
    this.baseImageUrl = process.env.VUE_APP_BASE_IMAGE_URL;
    this.userProfile = this.userDetail;
  },
};
</script>
