var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"fixed z-10 inset-0 overflow-y-auto mr-12 ml-12"},[_c('div',{staticClass:"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_c('div',{staticClass:"fixed inset-0 transition-opacity",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"absolute inset-0 bg-gray-500 opacity-75",on:{"click":function($event){return _vm.$emit('close')}}})]),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block align-bottom bg-white rounded-lg text-center overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full sm:w-full",attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[_c('div',{staticClass:"bg-white px-4 pt-5 sm:py-6 sm:px-2 sm:pb-4"},[_c('div',{staticClass:"sm:flex sm:items-start sm:justify-between"},[_c('div',{staticClass:"mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"},[_c('nav',{staticClass:"flex flex-col sm:flex-row"},[(
                    _vm.validatePermission(['manage.projects.histories.read'])
                  )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'projects_users'
                      ? 'text-blue-500 border-blue-500 border-b-2'
                      : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'projects_users'}}},[_vm._v(" Projects Members ")]):_vm._e(),(
                    _vm.validatePermission(['manage.projects.histories.read'])
                  )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'projects'
                      ? 'text-blue-500 border-blue-500 border-b-2'
                      : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'projects'}}},[_vm._v(" Projects ")]):_vm._e()])]),_c('div',{staticClass:"flex flex-row px-3 py-2"},[_c('button',{staticClass:"mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-2 sm:w-auto sm:text-sm h-8 items-center",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v(" Cancel ")])])])]),_c('div',{staticClass:"bg-gray-200 flex flex-col w-full text-left pr-5 pl-5"},[_c('div',[(
                _vm.selectedTab === 'projects' &&
                _vm.validatePermission(['manage.projects.histories.read'])
              )?_c('div',[_c('ProjectHistoryTab',{staticClass:"w-full",attrs:{"projectId":_vm.projectId}})],1):(
                _vm.selectedTab === 'projects_users' &&
                _vm.validatePermission(['manage.projects.histories.read'])
              )?_c('div',[_c('ProjectUserHistoryTab',{staticClass:"w-full",attrs:{"projectId":_vm.projectId}})],1):_c('NotAuthorized')],1),_c('br')])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }