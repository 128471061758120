const state = () => ({
  userDetails: {},
  token: localStorage.getItem('auth_token') || '',
  menus: {},
  permissions: [],
  isUserDataLoading: false,
});

// getters
const getters = {
  userFullName: (state: any) =>
    state.userDetails &&
    state.userDetails.first_name[0].toUpperCase() +
      state.userDetails.first_name.substring(1) +
      '  ' +
      (state.userDetails.last_name
        ? state.userDetails.last_name[0].toUpperCase() +
          state.userDetails.last_name.substring(1)
        : ''),
  userId: (state: any) => (state.userDetails && state.userDetails.id) || null,
  isAdmin: (state: any) =>
    (state.userDetails && state.userDetails.admin) || false,
  isLoggedIn: (state: any) => (state.token ? true : false),
  isReadUserPermission: (state: any) =>
    state.permissions.includes('manage.users.read'),
  isReadProjectPermission: (state: any) =>
    state.permissions.includes('manage.projects.read'),
  userLocation: (state: any) =>
    (state.userDetails && state.userDetails.location) || null,
  isSearchUser: (state: any) =>
    state.permissions.includes('manage.users.read') ||
    state.permissions.includes('manage.users.manage'),
  isSearchProject: (state: any) =>
    state.permissions.includes('manage.projects.read') ||
    state.permissions.includes('manage.projects.manage'),
  isProjectManager: (state: any) =>
    (state.userDetails && state.userDetails.project_manager) || false,
};

// actions
const actions = {
  logoutUser({ commit }: any) {
    localStorage.removeItem('auth_token');
    commit('setUserDetails', {});
    commit('setToken', '');
    commit('setMenus', {});
    commit('setPermissions', []);
  },
  loginUser({ commit }: any, data: any) {
    const { auth_token } = data;
    localStorage.setItem('auth_token', auth_token);
    commit('setToken', auth_token);
    commit('setUserData', data);
  },
  userToken({ commit }: any, token: string) {
    localStorage.setItem('auth_token', token);
    commit('setToken', token);
  },
  // TODO: Avoid storing user, menus and permissions into localstorage instead fetch on refresh
};

// mutations
const mutations = {
  setUserDetails(state: any, userDetails: any) {
    state.userDetails = userDetails;
  },
  setToken(state: any, token: string) {
    state.token = token;
  },
  setMenus(state: any, menus: any) {
    state.menus = menus;
  },
  setPermissions(state: any, permissions: any) {
    state.permissions = permissions;
  },
  setIsUserDataLoading(state: any, isLoading: boolean) {
    state.isUserDataLoading = isLoading;
  },
  setUserData(state: any, data: any) {
    const { user, auth_data } = data;
    state.userDetails = user;
    state.menus = auth_data.menus;
    state.permissions = auth_data.permissions;
    state.isUserDataLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
