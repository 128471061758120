<template>
  <div class="bg-gray-200 w-96 flex flex-col" id="project-history">
    <AgGridTable
      :perPage="perPage"
      :columnDefs="columnDefs"
      :url="url"
      :params="params"
    />
    <div class="flex flex-row ml-4 font-semibold">
      <div class="text-sm text-gray-800">
        <div id="test" class="codes" style="background-color: #4ed399"></div>
        <span> New Record </span>
      </div>
      <div class="text-sm text-gray-800 pl-5">
        <div id="test" class="codes" style="background-color: #ed8756"></div>
        <span> Update </span>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable  vue/no-unused-components*/
import moment from 'moment';
import AgGridTable from '@/components/tables/AgGridTable.vue';
import {
  FieldNameCellRenderer,
  ChangedFromCellRenderer,
  ChangedToCellRenderer,
} from '@/components/tables/renderers';
export default {
  name: 'ProjectHistoryTab',
  components: {
    AgGridTable,
    FieldNameCellRenderer,
    ChangedFromCellRenderer,
    ChangedToCellRenderer,
  },
  data() {
    return {
      perPage: 10,
      columnDefs: [
        {
          field: 'project_name',
          headerName: 'Projects',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          cellStyle: (params) => {
            return (
              params.data && {
                backgroundColor:
                  JSON.parse(params.data.data).action === 'create'
                    ? '#34D399'
                    : '#ED8756',
              }
            );
          },
          cellRenderer: (params) => {
            return (
              params.value &&
              `<a href="/manage/projects/${
                params.data.project_id
              }" class="text-blue-500">${
                params.data.project_name
                  ? params.data.project_name
                  : 'Project Id' + ' ' + params.data.project_id
              }</a>`
            );
          },
          minWidth: 150,
        },
        {
          headerName: 'Field Name',
          cellRenderer: 'FieldNameCellRenderer',
          wrapText: true,
          autoHeight: true,
          minWidth: 225,
          cellRendererParams: {
            tabName: 'projects',
          },
        },
        {
          headerName: 'Changed From',
          cellRenderer: 'ChangedFromCellRenderer',
          minWidth: 225,
          cellRendererParams: {
            tabName: 'projects',
          },
        },
        {
          headerName: 'Changed To',
          cellRenderer: 'ChangedToCellRenderer',
          minWidth: 225,
          cellRendererParams: {
            tabName: 'projects',
          },
        },
        {
          field: 'updated_by',
          headerName: 'Updated By Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          cellRenderer: (params) => {
            return (
              params.value &&
              `<a href="/manage/users/${params.data.orchestrated_by_id}" class="text-blue-500">${params.value}</a>`
            );
          },
          minWidth: 150,
        },
        {
          field: 'updated_by_email',
          headerName: 'Updated By Email',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          minWidth: 225,
        },
        {
          field: 'updated_at',
          headerName: 'Updated Date',
          sortable: true,
          cellRenderer: (params) => {
            return (
              params.value &&
              moment(params.value).format('DD MMM YYYY HH:mm:ss')
            );
          },
          minWidth: 175,
        },
      ],
      pageSize: 30,
      url: '/manage/projects/project_histories',
      params: {},
    };
  },
  created() {
    let historyType = 'projects';
    let projId = this.projectId ? this.projectId : null;
    this.params = { ...this.params, historyType, projId };
  },
  props: ['projectId'],
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
.bg-orange-700 {
  background-color: #ed8756;
}
.codes {
  width: 10px;
  height: 10px;
  border: 1px;
  float: left;
  margin-right: 10px;
  margin-left: 2px;
  margin-top: 5px;
}
#project-history >>> .ag-row-position-absolute {
  position: unset !important;
  transform: translateY(0px) !important;
}
#project-history >>> .ag-center-cols-viewport {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 100% !important;
}
</style>
