







































































































































































































































import ExportButtons from '@/components/common/ExportButtons.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import Pagination from '@/components/common/Pagination.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import TransparentLoader from '@/components/common/TransparentLoader.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import axios from 'axios';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

import Vue from 'vue';
export default Vue.extend({
  name: 'OverallUtilization',
  components: {
    ExportButtons,
    CustomFilter,
    Pagination,
    NoRecordFound,
    TransparentLoader,
    NotAuthorized,
  },
  data() {
    return {
      details: [],
      columns: [
        'Name',
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
        'AGG YEAR',
      ],
      monthNumbers: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        'agg',
      ],
      showFilter: false,
      query: '',
      isLoading: true,
      paginationMeta: {},
      pageSize: 30,
      filterBy: {
        user_status: { label: 'Active', value: 'true' },
        userPractices: [],
        location: [],
      },
      userPractices: [],
      userStatus: { label: 'Active', value: 'true' },
      selectedYear: moment().year(),
      currentYear: moment().year(),
      isReadPermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  methods: {
    resetFilter() {
      this.filterBy = {
        user_status: { label: 'Active', value: 'true' },
        userPractices: [],
        location: [],
      };
      this.populateOverallUtilization(1);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    createPDF() {
      const doc: any = new jsPDF('l');
      doc.text(
        `Overall Utilization Report: ${moment().format('LLLL')}`,
        15,
        10
      );
      doc.autoTable({
        html: '#overall_utilization_table',
      });
      doc.save(
        `Overall_Utilization_Report_${moment().format('yyyy_MM_DD')}.pdf`
      );
    },
    exportToCSV() {
      const user_status =
        this.filterBy.user_status.value != ''
          ? this.filterBy.user_status.value
          : '';
      const { location, userPractices: user_practices } = this.filterBy;
      const search = this.query || null;
      const generated_on = moment().format('LLLL');
      this.isLoading = true;
      axios
        .get('reports/utilization_csv', {
          params: {
            year: this.selectedYear,
            page_size: this.pageSize,
            user_status: user_status,
            location,
            user_practices,
            search,
            generated_on,
          },
          responseType: 'blob',
        })
        .then((response) => {
          this.isLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link: any = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Overall Utilization Details_${moment().format('yyyy_MM_DD')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          this.isLoading = false;
          // handle error
          console.error('ERR while fetchCsvData', error);
        });
    },
    setQuery(e: any) {
      this.query = e.target.value;
      this.populateOverallUtilization(1);
    },
    setPageSize(value: any) {
      this.pageSize = value;
      this.populateOverallUtilization(1);
    },
    setFilterByLocation(option: any) {
      this.filterBy.location = option;
      this.populateOverallUtilization(1);
    },
    setFilterByUserPractices(option: any) {
      this.filterBy.userPractices = option;
      this.populateOverallUtilization(1);
    },
    setFilterByUserStatus(option: any) {
      this.filterBy.user_status = option;
      this.populateOverallUtilization(1);
    },
    goToPrev() {
      this.selectedYear--;
      (this as any).updateQueryParams({ year: this.selectedYear });
      this.filterBy.location = [];
      this.populateOverallUtilization(1);
    },
    goToNext() {
      this.selectedYear++;
      (this as any).updateQueryParams({ year: this.selectedYear });
      this.filterBy.location = [];
      this.populateOverallUtilization(1);
    },
    populateOverallUtilization(page: number) {
      this.nonce = Date.now().toString();
      this.isLoading = true;
      const user_status =
        this.filterBy.user_status.value != ''
          ? this.filterBy.user_status.value
          : '';
      const { location, userPractices: user_practices } = this.filterBy;
      const search = this.query || null;
      axios
        .get('reports/utilization_table', {
          params: {
            year: this.selectedYear,
            page_size: this.pageSize,
            page,
            user_status: user_status,
            location,
            user_practices,
            search,
            nonce: this.nonce,
          },
        })
        .then((res) => {
          this.isLoading = false;
          const { data } = res;
          const { overall_utilization, meta, nonce } = data || {};
          if (this.nonce === nonce) {
            this.details = overall_utilization;
            this.paginationMeta = meta;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
        });
    },
    fetchUserPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.userPractices = response.data.map((d: any) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUserPractices', error);
        });
    },
  },
  created() {
    this.populateOverallUtilization = _debounce(
      this.populateOverallUtilization,
      400
    );
    const query: any = this.$route.query;
    const thisYear = moment().year();
    if (query && query.year) {
      const year = parseInt(query.year);
      if (year <= thisYear) {
        this.selectedYear = year;
      } else {
        (this as any).updateQueryParams({ year: thisYear });
      }
    }
    this.isReadPermission = (this as any).validatePermission([
      'pmo.overall_utilization.read',
    ]);
    this.isExportPermission = (this as any).validatePermission([
      'pmo.overall_utilization.export',
    ]);
    if (this.isReadPermission) {
      this.populateOverallUtilization(1);
      this.fetchUserPractices();
    } else {
      this.isLoading = false;
    }
  },
});
