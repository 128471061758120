<template>
  <div class="bg-gray-200 w-96 overflow-hidden">
    <img src="@/assets/under_construction.png" class="h-screen w-full" />
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction',
};
</script>
