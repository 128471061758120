<template>
  <div class="bg-gray overflow-x-hidden w-96">
    <Loading v-if="isLoading" />
    <!-- Top Bar -->
    <div
      v-else
      class="md:h-16 h-12 bg-white flex flex-row shadow-sm items-center justify-between text-left px-5 sticky top-0 z-10"
    >
      <div class="flex flex-row items-center justify-start">
        <span
          class="material-icons text-black md:text-xl text-sm mr-3 cursor-pointer"
          @click="onCancel"
          >arrow_back</span
        >
        <span>
          {{
            isLoading
              ? ''
              : isEdit
              ? `Edit Requirement (${payload.r_id})`
              : 'Add New Requirement'
          }}
        </span>
      </div>
      <div class="flex flex-row px-3 py-2">
        <div class="pr-3">
            <button
              type="button"
              @click="onCancel"
              class="mt-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-5 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm px-5 h-8 flex items-center text-sm"
            >
              Cancel
            </button>
          </div>
        <button
          @click.prevent="trigger++"
          class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-700 rounded text-white px-5 h-8 flex items-center text-sm"
        >
          <span class="text-sm">{{ isEdit ? 'Update' : 'Submit' }}</span>
        </button>
      </div>
    </div>

    <div
      v-if="!isLoading"
      class="flex flex-col mt-2 bg-white pt-2"
      style="height: 88vh"
    >
      <div class="flex-grow overflow-auto pb-24">
        <form-generator
          :triggerSubmit="trigger"
          :formFields="isEdit ? editFields : formFields"
          :numberOfColumns="2"
          :groups="groups"
          @onFieldChange="onFieldChange"
          @onFormSubmit="onFormSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';
import RegEx from '@/components/inputs/RegEx';
import axios from 'axios';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';
import { required, minLength } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'CreateUser',
  components: {
    Loading,
    FormGenerator,
  },
  data() {
    return {
      username: '',
      isFormValid: false,
      trigger: 0,
      isLoading: true,
      isValueChanged: false,
      groups: [{ start: 0, end: 19, name: '' }],
      formFields: [
        {
          name: 'job_role',
          label: 'Job Role',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'skill_set',
          label: 'Skill Set',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'location',
          label: 'Location',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.LOCATION_CHARACTER),
          },
        },
        {
          name: 'location_group',
          label: 'Location Group',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            {
              label: 'On Shore',
              value: 0,
            },
            {
              label: 'Off Shore',
              value: 1,
            },
            {
              label: 'Near Shore',
              value: 2,
            },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'practice',
          label: 'Practice',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'priority',
          label: 'Priority',
          required: true,
          type: 'select',
          defaultValue: '',
          options: Array.from({ length: 11 }, (_, i) => {
            return { value: i.toString(), label: i.toString() };
          }).reverse(),
          validations: {
            required,
          },
        },
        {
          name: 'experience',
          label: 'Experience',
          type: 'text',
          defaultValue: '',
          required: true,
          validations: {
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.EXPERIENCE_DECIMAL_PRECISION),
          },
        },
        {
          name: 'pay_per_hour',
          label: 'Rate Per Hour',
          type: 'text',
          defaultValue: '',
          required: true,
          validations: {
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.DECIMAL_PRECISION),
          },
        },
        {
          name: 'no_of_positions',
          label: 'No Of Positions',
          type: 'text',
          defaultValue: '',
          required: true,
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.TWO_DIGIT_ONLY),
          },
        },
        {
          name: 'opportunity_type',
          label: 'Requirement Type',
          required: false,
          type: 'select',
          defaultValue: 'Full Time',
          options: [
            {
              label: 'Full Time',
              value: 0,
            },
            {
              label: 'Contract',
              value: 1,
            },
          ],
        },
        {
          name: 'client',
          label: 'Projects',
          type: 'text',
          required: true,
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'created_by',
          label: 'Created By',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'requested_by',
          label: 'Hiring Manager',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'recruiter_assigned',
          label: 'Recruiter Assigned',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'description',
          label: 'Job Description',
          type: 'text',
          required: true,
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
            minLength: minLength(100),
          },
        },
      ],
      editFields: [
        {
          name: 'r_id',
          label: 'Requirement ID',
          type: 'text',
          inputDisabled: true,
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'created_at',
          label: 'Created Date',
          inputDisabled: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'status',
          label: 'Status',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            {
              label: 'Open',
              value: 0,
            },
            {
              label: 'Close',
              value: 1,
            },
            {
              label: 'Requirement Closed By Client',
              value: 2,
            },
            {
              label: 'Requirement Closed By Manager',
              value: 3,
            },
            {
              label: 'On Hold',
              value: 4,
            },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'job_role',
          label: 'Job Role',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'skill_set',
          label: 'Skill Set',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'location',
          label: 'Location',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.LOCATION_CHARACTER),
          },
        },
        {
          name: 'location_group',
          label: 'Location Group',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            {
              label: 'On Shore',
              value: 0,
            },
            {
              label: 'Off Shore',
              value: 1,
            },
            {
              label: 'Near Shore',
              value: 2,
            },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'practice',
          label: 'Practice',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'priority',
          label: 'Priority',
          required: true,
          type: 'select',
          defaultValue: '',
          options: Array.from({ length: 11 }, (_, i) => {
            return { value: i.toString(), label: i.toString() };
          }).reverse(),
          validations: {
            required,
          },
        },
        {
          name: 'experience',
          label: 'Experience',
          type: 'text',
          defaultValue: '',
          required: true,
          validations: {
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.EXPERIENCE_DECIMAL_PRECISION),
          },
        },
        {
          name: 'pay_per_hour',
          label: 'Rate Per Hour',
          type: 'text',
          defaultValue: '',
          required: true,
          validations: {
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.DECIMAL_PRECISION),
          },
        },
        {
          name: 'no_of_positions',
          label: 'No Of Positions',
          type: 'text',
          defaultValue: '',
          required: true,
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.TWO_DIGIT_ONLY),
          },
        },
        {
          name: 'opportunity_type',
          label: 'Requirement Type',
          required: false,
          type: 'select',
          defaultValue: 'Full Time',
          options: [
            {
              label: 'Full Time',
              value: 0,
            },
            {
              label: 'Contract',
              value: 1,
            },
          ],
        },
        {
          name: 'client',
          label: 'Projects',
          type: 'text',
          required: true,
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'created_by',
          label: 'Created By',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'requested_by',
          label: 'Hiring Manager',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'recruiter_assigned',
          label: 'Recruiter Assigned',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'description',
          label: 'Job Description',
          type: 'text',
          required: true,
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
            minLength: minLength(100),
          },
        },
      ],
      payload: {},
      isEdit: false,
      modalHeadline: 'Leaving this page will discard unsaved changes.',
      deleteMessage: 'Are you sure you want to leave this page?',
      isGoingBack: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    onFieldChange(_field, _value, _allValues) {
      this.isValueChanged = true;
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        if (this.isEdit) {
          this.editRequirement(values);
        } else {
          this.createNewRequirement(values);
        }
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    setOptions(fieldName, fieldAttr, data) {
      const { formFields, editFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
      editFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.editFields = editFields;
    },
    convertSelectOptions() {
      var status = '';
      var priority = '';
      if (this.isEdit) {
        status = this.payload.status ? this.payload.status.value : '';
        priority = this.payload.priority ? this.payload.priority.value : '';
      }
      let editRequirementPayload = JSON.parse(JSON.stringify(this.payload));
      editRequirementPayload.status = status;
      editRequirementPayload.priority = priority;

      // converting option object to its value
      Object.keys(editRequirementPayload).forEach((keyName) => {
        if (
          [
            'practice',
            'created_by',
            'location_group',
            'priority',
            'recruiter_assigned',
            'requested_by',
            'requirement_type',
          ].includes(keyName)
        ) {
          editRequirementPayload[keyName] =
            editRequirementPayload[keyName].value || '';
        }
      });
      return editRequirementPayload;
    },
    convertResponseToPayload(data, callback) {
      const payload = data;
      payload.created_at =
        (payload.created_at && payload.created_at.substring(0, 10)) || '';
      payload.priority = payload.priority.toString();
      this.editFields.forEach((editField) => {
        // update Default for all input type
        editField.defaultValue = payload[editField.name];

        // Update Default for select type
        if (editField.name === 'created_by') {
          editField.defaultValue = editField.options.find(
            (option) => option.value === payload.created_by
          );
        }
        if (editField.name === 'requested_by') {
          editField.defaultValue = editField.options.find(
            (option) => option.value === payload.requested_by
          );
        }
        if (editField.name === 'recruiter_assigned') {
          editField.defaultValue = editField.options.find(
            (option) => option.value === payload.recruiter_assigned
          );
        }
        if (editField.type === 'select') {
          if (editField.multiple) {
            // Update Default for single select type
            editField.defaultValue = editField.options.find(
              (option) => option.value === payload[editField.name]
            );
          }
        }
      });
      callback(data);
    },
    createNewRequirement(payload) {
      axios
        .post('/recruitment/requirements', payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.message,
            alertType: 'success',
          });
          this.$router.push('/recruitment/list');
        })
        .catch((error) => {
          console.error('ERR while createNewRequirement', error.message);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },
    editRequirement(payload) {
      //let editRequirementPayload = this.convertSelectOptions();
      this.isGoingBack = true;
      axios
        .put(`/recruitment/requirements/${this.RequirementId}`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.message,
            alertType: 'success',
          });
          this.$root.$router.push({
            path: '/recruitment/list/' + this.RequirementId,
          });
          // this.$router.push("/recruitment/list");
        })
        .catch((error) => {
          this.isGoingBack = true;
          console.error('ERR while editRequirement', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },
    onCancel() {
      if (this.isEdit && this.isValueChanged) {
        this.$confirm(
          'Leaving this page will discard unsaved changes. Are you sure?'
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.isGoingBack = true;
            this.$router.back();
          } else {
            this.isGoingBack = false;
            return;
          }
        });
      } else {
        this.$router.back();
      }
    },
    fetchRequirement(callback) {
      axios
        .get(
          `/recruitment/requirements/${this.RequirementId}?is_edit=${this.isEdit}`
        )
        .then((response) => {
          this.requirementDetails = response.data.requirement;
          this.convertResponseToPayload(
            response.data.requirement,
            (payloadRes) => {
              this.payload = payloadRes;
              callback(true);
            }
          );
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchRequirement', error);
          callback();
        });
    },
    fetchPractices(callback) {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          const practices = response.data.map((practice) => {
            return {
              label: practice.practice_name,
              value: practice.practice_name,
            };
          });
          this.setOptions('practice', 'options', practices);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchPractices', error);
        });
    },
    fetchUsers(callback) {
      axios
        .get(`/manage/users`)
        .then((response) => {
          const users = response.data.map((user) => {
            return {
              label: user.first_name + ' ' + user.last_name,
              value: user.email,
            };
          });
          this.setOptions('created_by', 'options', users);
          const hiringUsers = response.data.map((user) => {
            return {
              label: user.first_name + ' ' + user.last_name,
              value: user.id,
            };
          });
          this.setOptions('requested_by', 'options', hiringUsers);
          callback(true);
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchUsers', error);
          callback();
        });
    },
    fetchRecruiteUsers(callback) {
      axios
        .get('/manage/users/recruiter_users')
        .then((response) => {
          const users = response.data.map((user) => {
            return {
              label: user.label,
              value: user.email,
            };
          });
          this.setOptions('recruiter_assigned', 'options', users);
          callback(true);
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchRequirementUsers', error);
          callback();
        });
    },
  },
  created() {
    this.RequirementId = this.$router.history.current.params.id;
    this.fetchPractices((_isPracticeLoaded) => {
      this.fetchUsers((_isUsersLoaded) => {
        this.fetchRecruiteUsers((_isRecruiteUsersLoaded) => {
          if (this.RequirementId) {
            this.isEdit = true;
            this.fetchRequirement((_isProjectLoaded) => {
              this.isLoading = false;
            });
          } else {
            this.isLoading = false;
          }
        });
      });
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isEdit && !this.isGoingBack && this.isValueChanged) {
      const isConfirmed = await this.$confirm(
        'Leaving this page will discard unsaved changes. Are you sure?'
      );
      if (isConfirmed) {
        next();
      } else {
        return;
      }
    } else {
      next();
    }
  },
});
</script>
