



























































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ProjectsTable',
  props: ['columns', 'tableData'],
  computed: {
    ...mapGetters('user', ['isReadProjectPermission']),
  },
});
