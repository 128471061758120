
























































import Vue from 'vue';
import Alert from '@/components/alerts/Alert.vue'; // @ is an alias to /src
import ReducedSidebar from '@/components/layout/ReducedSidebar.vue';
import ExpandedSidebar from '@/components/layout/ExpandedSidebar.vue';
import RightSideBar from '@/components/common/RightSideBar.vue';
import SearchAutocomplete from '@/components/common/SearchAutocomplete.vue';
import UserNameAvatar from '@/components/manage/users/UserNameAvatar.vue';

import { mapGetters } from 'vuex';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'App',
  components: {
    Alert,
    ReducedSidebar,
    ExpandedSidebar,
    RightSideBar,
    SearchAutocomplete,
    UserNameAvatar,
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn', 'isAdmin']),
    ...mapState('settings', [
      'openRightSideBar',
      'selectedNav',
      'isSideBarExpanded',
      'hoverNav',
    ]),
    ...mapState('user', ['menus', 'userDetails']),
  },
  methods: {
    toggleRightSideBar() {
      this.$store.commit('settings/toggleRightSideBar');
    },
    hideSubNav() {
      this.$store.commit('settings/updateNavName', {
        hoverNav: '',
      });
    },
  },
});
