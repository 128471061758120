<template>
  <div class="bg-gray overflow-x-hidden w-96">
    <Loading v-if="isLoading" />
    <div v-else class="flex flex-col items-center">
      <!-- Top Bar -->
      <div
        class="w-full md:h-12 h-12 bg-white flex flex-row shadow-sm items-center justify-between text-left px-5 sticky top-0 z-10"
      >
        <div class="flex flex-row items-center justify-start">
          <span
            class="material-icons text-black md:text-xl text-sm mr-3 cursor-pointer"
            @click="onCancel"
            >arrow_back</span
          >
          <span>
            {{ isLoading ? '' : isEdit ? `${projectName}` : 'Add New Project' }}
          </span>
        </div>
        <div class="flex flex-row px-3 py-2">
          <button
            @click.prevent="trigger++"
            class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-700 rounded text-white px-5 h-8 flex items-center text-sm"
          >
            <span class="text-sm">Submit</span>
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col justify-center items-end bg-white mt-2 px-2 py-4 align-middle"
        v-if="!isLoading"
      >
        <div class="flex-grow overflow-auto w-full">
          <form-generator
            :triggerSubmit="trigger"
            :formFields="formFields"
            :numberOfColumns="2"
            :groups="groups"
            @onFieldChange="onFieldChange"
            @onFormSubmit="onFormSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';
import FormGenerator from '@/components/common/FormGenerator.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';

import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'CreateProject',
  data() {
    return {
      isLoading: true,
      projectName: '',
      groups: [{ start: 0, end: 16, name: '' }],
      formFields: [
        {
          name: 'name',
          label: 'Name',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'color',
          label: 'Color',
          required: true,
          type: 'color',
          defaultValue: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          validations: {
            required,
          },
        },
        {
          name: 'code',
          label: 'Code',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'practice',
          label: 'Practice',
          required: true,
          type: 'select',
          placeholder: 'Select Practice',
          options: [],
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'start_date',
          label: 'Start Date',
          required: true,
          type: 'date',
          minValue: '2014-01-01',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'end_date',
          label: 'End Date',
          required: true,
          type: 'date',
          minValue: '2014-01-01',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'description',
          label: 'Description',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'total_budget',
          label: 'Total Budget',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'sow',
          label: 'SOW',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'project_status',
          label: 'Status',
          switchLabels: ['Active', 'Inactive'],
          defaultValue: true,
          required: true,
          type: 'switch',
          validations: {
            required,
          },
        },
        {
          name: 'primary_product_id',
          label: 'Primary Product',
          type: 'select',
          placeholder: 'Select Product',
          options: [],
          defaultValue: '',
        },
        {
          name: 'pending_status',
          label: 'Pending Status',
          switchLabels: ['Yes', 'No'],
          defaultValue: false,
          required: false,
          type: 'switch',
        },

        {
          name: 'delivery_method_id',
          label: 'Delivery Method',
          type: 'select',
          placeholder: 'Select Method',
          options: [],
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'notify_timesheet',
          label: 'Notify Members for not filling Timesheet',
          switchLabels: ['Yes', 'No'],
          defaultValue: true,
          required: true,
          type: 'switch',
          validations: {
            required,
          },
        },
        {
          name: 'client_id',
          label: 'Client',
          type: 'select',
          placeholder: 'Select Client',
          options: [],
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'default',
          label: 'Default',
          switchLabels: ['Yes', 'No'],
          defaultValue: false,
          type: 'switch',
        },
      ],
      payload: {
        pending_status: false,
        project_status: true,
        notify_timesheet: true,
        default: false,
      },
      isEdit: false,
      projectDetails: {},
      projectId: null,
      isGoingBack: false,
      isDataChanged: false,
      isFormValid: false,
      trigger: 0,
      isDefaultChanged: false,
      isEndDateChanged: false,
    };
  },
  components: {
    Loading,
    FormGenerator,
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    async onFieldChange(field, value, allValues) {
      try {
        this.isDataChanged = true;
        if (field.name === 'project_status') {
          if (value === false && this.isEdit) {
            const isConfirmed = await this.$confirm(
              'Inactive current project also inactivate the members in this project. Do you want to continue?'
            );
            if (!isConfirmed) return;
          } else if (value === true) {
            if (
              !allValues.end_date ||
              moment(allValues.end_date).diff(moment()) < 0
            ) {
              this.$store.dispatch('settings/showAlert', {
                alertMessage: 'End date should be greater-than today',
                alertType: 'error',
              });
              return;
            }
          }
        }
        this.payload[field.name] = value;
        if (field.name === 'start_date') {
          this.formFields[5].minValue = value;
        }
        if (field.name === 'end_date') {
          this.isEndDateChanged = true;
          this.formFields[4].maxValue = value;
        }
        if (field.name === 'pending_status' && value === true) {
          this.formFields[9].defaultValue = true;
        }
        if (field.name === 'default' && value === true) {
          if (this.isEdit) {
            this.isDefaultChanged = true;
          }
          await this.$confirm(
            'Verify that the selected practice is belongs to this project. If not, change the practice and submit'
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        if (this.isEdit) {
          if (values.default && this.isDefaultChanged) {
            this.$confirm(
              'Changing this project to default will also change the billability type of users in this project as Non Billable.Do you want to continue?'
            ).then((isConfirmed) => {
              if (isConfirmed) {
                this.editProject(values);
              }
            });
          } else {
            this.editProject(values);
          }
        } else {
          this.createNewProject(values);
        }
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    createNewProject(payload) {
      let createProjectPayload = payload;
      if (
        moment(createProjectPayload.end_date).diff(moment()) < 0 &&
        createProjectPayload.project_status
      ) {
        this.payload.project_status = false;
        createProjectPayload.project_status = false;
      }
      if (
        moment(createProjectPayload.end_date).diff(moment()) > 0 &&
        !createProjectPayload.project_status
      ) {
        this.$store.dispatch('settings/showAlert', {
          alertMessage:
            "Kindly change the end date as today's date then change the status inactive and then submit the form",
          alertType: 'error',
        });
      } else {
        createProjectPayload.status = true;
        axios
          .post(`/manage/projects`, createProjectPayload)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.$router.push('/manage/projects');
          })
          .catch((error) => {
            let errMsg = 'Something went wrong. Please try again';
            if (
              error.response &&
              error.response.data &&
              error.response.data.msg
            ) {
              errMsg = error.response.data.msg;
            }
            this.$store.dispatch('settings/showAlert', {
              alertMessage: errMsg,
              alertType: 'error',
            });
          });
      }
    },
    async editProject(payload) {
      this.isGoingBack = true;
      const params = this.$router.history.current.params;
      if (
        moment(payload.end_date).diff(moment()) < 0 &&
        payload.project_status
      ) {
        try {
          const confirmed = await this.$confirm(
            `Projects end date has reached, projects status will be changed to Inactive.
              Inactive current project also inactivate the members in this project. Do you want to continue?`
          );
          if (confirmed) {
            this.payload.project_status = false;
            payload.project_status = false;
          } else {
            return;
          }
        } catch {
          return;
        }
      }
      if (
        moment(payload.end_date).diff(moment()) > 0 &&
        payload.project_status &&
        payload.default &&
        this.isEndDateChanged
      ) {
        try {
          const confirmed = await this.$fire({
            text: 'Would you like to apply the end date to all project members who are currently active?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          });
          if (confirmed.value) {
            payload.user_update = true;
          } else {
            payload.user_update = false;
          }
        } catch {
          return;
        }
      }
      if (
        moment(payload.end_date).diff(moment()) > 0 &&
        !payload.project_status
      ) {
        this.$store.dispatch('settings/showAlert', {
          alertMessage:
            "Kindly change the end date as today's date then change the status inactive and then submit the form",
          alertType: 'error',
        });
      } else {
        this.isLoading = true;
        axios
          .put(`/manage/projects/${params.id}`, payload)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.$router.push(`/manage/projects/${params.id}`);
          })
          .catch((error) => {
            this.isGoingBack = false;
            this.isLoading = false;
            let errMsg = 'Something went wrong. Please try again';
            if (
              error.response &&
              error.response.data &&
              error.response.data.msg
            ) {
              errMsg = error.response.data.msg;
            }
            this.$store.dispatch('settings/showAlert', {
              alertMessage: errMsg,
              alertType: 'error',
            });
          });
      }
    },

    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((colData) => {
        if (colData.name === fieldName) {
          colData.options = data;
        }
      });
      this.formFields = formFields;
    },
    fetchPractices(callback) {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          const practices = response.data.map((practice) => {
            return {
              label: practice.practice_name,
              value: practice.practice_name,
            };
          });
          this.setOptions('practice', 'options', practices);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchPractices', error);
        });
    },
    convertResponseToPayload(data, callback) {
      let { start_date, end_date } = data;
      const payload = data;
      payload.start_date = (start_date && start_date.substring(0, 10)) || '';
      payload.end_date = (end_date && end_date.substring(0, 10)) || '';
      delete payload.project_managers;
      delete payload.project_users;
      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = payload[formField.name];
        if (formField.name == 'primary_product_id') {
          formField.defaultValue = formField.options.find(
            (option) => option.value === payload.primary_product_id
          );
        }
        if (formField.name == 'delivery_method_id') {
          formField.defaultValue = formField.options.find(
            (option) => option.value === payload.delivery_method_id
          );
        }
        if (formField.name == 'client_id') {
          formField.defaultValue = formField.options.find(
            (option) => option.value === payload.client_id
          );
        }
        // Update Default for select type
        if (formField.type === 'select') {
          if (formField.multiple) {
            // Update Default for single select type
            formField.defaultValue = formField.options.find(
              (option) => option.label === payload[formField.name]
            );
          }
        }
      });
      callback(data);
    },
    fetchProject(callback) {
      axios
        .get(`/manage/projects/${this.projectId}`)
        .then((response) => {
          this.projectDetails = response.data;
          this.convertResponseToPayload(response.data, (payloadRes) => {
            this.formFields[4].minValue = payloadRes.start_date;
            this.formFields[6].maxValue = payloadRes.end_date;
            this.payload = payloadRes;
            this.projectName = payloadRes.name;
            callback(true);
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProject', error);
          callback();
        });
    },
    onCancel() {
      if (this.isEdit && this.isDataChanged) {
        this.$confirm(
          'Leaving this page will discard unsaved changes. Are you sure?'
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.isGoingBack = true;
            this.$router.back();
          } else {
            this.isGoingBack = false;
            return;
          }
        });
      } else {
        this.$router.back();
      }
    },
    fetchPrimaryProduct(callback) {
      axios
        .get(`/manage/primary_products`)
        .then((response) => {
          const primary_products = response.data.map((product) => {
            return {
              label: product.name,
              value: product.id,
            };
          });
          this.setOptions('primary_product_id', 'options', primary_products);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchPrimaryProduct', error);
        });
    },
    fetchDeliveryMethod(callback) {
      axios
        .get(`/manage/delivery_methods`)
        .then((response) => {
          const delivery_methods = response.data.map((method) => {
            return {
              label: method.name,
              value: method.id,
            };
          });
          this.setOptions('delivery_method_id', 'options', delivery_methods);
          callback(true);
        })
        .catch((error) => {
          callback();
          console.error('ERR while fetchDeliveryMethod', error);
        });
    },
    fetchClient(callback) {
      axios
        .get(`/manage/clients`)
        .then((response) => {
          const clients = response.data.map((client) => {
            return {
              label: client.client_name,
              value: client.id,
            };
          });
          this.setOptions('client_id', 'options', clients);
          callback(true);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProject', error);
          callback();
        });
    },
  },
  created() {
    this.projectId = this.$router.history.current.params.id;
    this.fetchPrimaryProduct();
    this.fetchDeliveryMethod();
    this.fetchClient();
    this.fetchPractices((_isPracticeLoaded) => {
      if (this.projectId) {
        this.fetchProject((_isProjectLoaded) => {
          this.isEdit = true;
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isEdit && !this.isGoingBack && this.isDataChanged) {
      const isConfirmed = await this.$confirm(
        'Leaving this page will discard unsaved changes. Are you sure?'
      );
      if (isConfirmed) {
        next();
      } else {
        return;
      }
    } else {
      next();
    }
  },
});
</script>
