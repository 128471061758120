<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div
                v-if="isEdit"
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
              >
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Profile:
                  <span class="text-gray-400 ml-2">{{ fullName }}</span>
                </h3>
              </div>
              <div v-else class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Add Profile:
                  <span class="text-gray-400 ml-2">{{}}</span>
                </h3>
              </div>
              <div class="flex flex-row px-3 py-2">
                <button
                  @click.prevent="trigger++"
                  class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-700 rounded text-white px-5 h-8 flex items-center text-sm"
                >
                  <span class="text-sm"> Update </span>
                </button>
                <button
                  type="button"
                  @click="openConfirmDeleteModal"
                  class="ml-2 flex-row bg-red-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-red-700 rounded text-white px-5 h-8 flex items-center text-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>

          <hr />
          <div class="flex flex-col mt-2 bg-white pt-2" style="height: 68vh">
            <Loading v-if="isLoading" class="h-full" />
            <div v-else class="flex-grow overflow-auto pb-24">
              <form-generator
                :triggerSubmit="trigger"
                :formFields="formFields"
                :numberOfColumns="4"
                :groups="groups"
                @onFieldChange="onFieldChange"
                @onFormSubmit="onFormSubmit"
              />
            </div>
          </div>
          <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3">
            <button
              type="button"
              @click="onCancel"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';
import RegEx from '@/components/inputs/RegEx';
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import FormGenerator from '@/components/common/FormGenerator.vue';

import { required, email, maxLength } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'EditProfileModal',
  components: {
    Loading,
    FormGenerator,
  },
  computed: {
    ...mapGetters('user', []),
    fullName() {
      return this.isLoading
        ? ''
        : '(' + this.payload.first_name + ' ' + this.payload.last_name + ')';
    },
  },
  props: {
    requirementName: {
      type: String,
    },
    requirementId: {
      type: String,
      required: true,
    },
    profileId: {
      type: Number,
    },
    isEdit: {
      type: Boolean,
    },
    selectedProfileData: {
      type: Object,
    },
  },
  data() {
    return {
      trigger: 0,
      isLoading: true,
      rows: [],
      username: '',
      isFormValid: false,
      isValueChanged: false,
      groups: [{ start: 0, end: 38, name: '' }],
      formFields: [
        {
          name: 'requirement_id',
          label: 'Req ID',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'first_name',
          label: 'First Name',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            required,
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'last_name',
          label: 'Last Name',
          type: 'text',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'experience',
          label: 'Total Experience',
          required: true,
          type: 'text',
          secondaryLabel: 'Maximum Length 50',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_DIGIT),
          },
        },
        {
          name: 'relevant_experience',
          label: 'Relevant Experience',
          required: true,
          type: 'text',
          secondaryLabel: 'Maximum Length 50',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_DIGIT),
          },
        },
        {
          name: 'status',
          label: 'Profile Status',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Candidate submitted', value: 0 },
            { label: 'Interview scheduled', value: 1 },
            { label: 'Candidate selected', value: 2 },
            { label: 'Candidate rejected', value: 3 },
            { label: 'Offered and yet to join', value: 4 },
            { label: 'Declined offer or not joined', value: 5 },
            { label: 'Joined', value: 6 },
            { label: 'On hold', value: 7 },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'notice_period',
          label: 'Notice Period(in Days)',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'offered_date',
          label: 'Offered Date',
          required: false,
          type: 'date',
          defaultValue: '',
          minValue: '2014-01-01',
        },
        {
          name: 'joining_date',
          label: 'Joining Date',
          required: false,
          type: 'date',
          defaultValue: '',
          minValue: '2014-01-01',
        },
        {
          name: 'currnet_organization',
          label: 'Current Organization',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR_OR_DIGIT),
          },
        },
        {
          name: 'resume',
          label: 'Resume Link',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'email',
          label: 'Email',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            email,
            required,
          },
        },
        {
          name: 'phone',
          label: 'Phone',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.PHONE_NUMBERS_CHARACTER),
          },
        },
        {
          name: 'source_id',
          label: 'Source',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Internal', value: 1 },
            { label: 'External', value: 2 },
            { label: 'Referal', value: 3 },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'hiring_location',
          label: 'Hiring Location',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Hyderabad', value: 'Hyderabad' },
            { label: 'Chennai', value: 'Chennai' },
            { label: 'USA', value: 'USA' },
            { label: 'Mexico', value: 'Mexico' },
            { label: 'Remote', value: 'Remote' },
          ],
        },
        {
          name: 'current_ctc',
          label: 'Current CTC/Rate',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'expected_rate',
          label: 'Expected CTC/Rate',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'source_description',
          label: 'Source Description',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'designation',
          label: 'Designation',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'skype_id',
          label: 'Skype',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'work_authorization',
          label: 'Work Authorization',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'ssn_number',
          label: 'SSN',
          required: true,
          secondaryLabel: 'Maximum Length 4(Only Digits)',
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.FOUR_DIGIT_ONLY),
          },
        },
        {
          name: 'willing_to_relocate',
          label: 'Willing To Relocate?',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'N/A', value: 'N/A' },
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
            { label: 'Remote + Travel(30%)', value: 'Remote + Travel(30%)' },
          ],
        },
        {
          name: 'skill_set',
          label: 'Skill Set',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'location',
          label: 'Current Location',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.LOCATION_CHARACTER),
          },
        },
        {
          name: 'visa_valid',
          label: 'Visa Validity',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'holding_project',
          label: 'Current Project',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'vacation_plans',
          label: 'Vacation Plans',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'linkedin_url',
          label: 'Linkedin URL',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'department_id',
          label: 'Department',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'project_planned',
          label: 'Project Planned',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'certification_url',
          label: 'Certification URL',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'rating',
          label: 'Rating',
          required: false,
          secondaryLabel: 'Give 1-10',
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.TWO_DIGIT_ONLY),
          },
        },
        {
          name: 'submitted_on',
          label: 'Submitted Date',
          required: false,
          type: 'date',
          defaultValue: moment().format('yyyy-MM-DD'),
          validations: {},
          minValue: '2014-01-01',
        },
        {
          name: 'comments',
          label: 'Comments/Notes',
          type: 'text',
          required: false,
          defaultValue: '',
          validations: {},
        },
        {
          name: 'resigned',
          label: 'Resigned',
          switchLabels: ['Yes', 'No'],
          type: 'switch',
          defaultValue: '',
        },
      ],
      checked: false,
      isEditProfileModalVisible: false,
      isDataChanged: false,
      modalHeadline: 'Leaving this page will discard unsaved changes.',
      deleteMessage: 'Are you sure you want to leave this page?',
      isGoingBack: false,
      departments: [],
      requirement_ids: [],
      payload: {
        resigned: false,
      },
    };
  },
  created() {
    this.fetchDepartments((_isDepartmentLoaded) => {
      this.fetchRequirementList((_isRequirementLoaded) => {
        this.convertResponseToPayload(
          this.selectedProfileData,
          (payloadRes) => {
            this.payload = payloadRes;
          }
        );
      });
    });
    this.payload.submitted_on = this.payload.submitted_on
      ? this.payload.submitted_on
      : moment().format('yyyy-MM-DD');
  },
  methods: {
    onFieldChange(_field, _value, _allValues) {
      this.isValueChanged = true;
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        this.editProfile(values);
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
    },
    onCancel() {
      if (this.isEdit && this.isDataChanged) {
        this.$confirm(
          'Leaving this popup will discard unsaved changes. Are you sure?'
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.isGoingBack = true;
            this.$emit('close');
          } else {
            this.isGoingBack = false;
            return;
          }
        });
      } else {
        this.$emit('close');
      }
    },
    onChange(e) {
      this.isDataChanged = true;
      this.payload[e.target.name] = e.target.value;
    },
    onSelect(option, name) {
      this.isDataChanged = true;
      this.payload[name] = option;
    },
    onCheck(e) {
      this.isDataChanged = true;
      this.checked = e.target.checked;
    },
    close() {
      this.$emit('close');
    },
    openConfirmDeleteModal() {
      this.$confirm('Remove Profile. Do you want to remove the Profile?').then(
        (isConfirmed) => {
          if (isConfirmed) {
            this.destroy();
          } else {
            return;
          }
        }
      );
    },
    validateInputs(callback) {
      const errorTextClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600 px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm';
      const inputDivs = document.getElementsByClassName('text_error');
      const selectDivs = document.getElementsByClassName('select_error');
      if (inputDivs.length > 0 || selectDivs.length > 0) {
        let i;
        for (i = 0; i < inputDivs.length; i++) {
          inputDivs[i].className = errorTextClass;
        }

        let selectDiv;
        for (selectDiv in selectDivs) {
          if (selectDivs[parseInt(selectDiv)]) {
            selectDivs[parseInt(selectDiv)].getElementsByTagName(
              'div'
            )[0].style['border-color'] = 'red';
          }
        }
        callback(true);
      } else {
        callback(true);
      }
    },
    convertResponseToPayload(data, callback) {
      const payload = data;
      this.checked = payload.resigned;
      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = payload[formField.name];
        // Update Default for select type
        if (formField.type === 'select') {
          if (formField.name === 'status') {
            if (formField.multiple) {
              // Update Default for single select type
              formField.defaultValue = formField.options.find(
                (option) => option.value === data[formField.name]
              );
            }
          } else {
            // Update Default for single select type
            formField.defaultValue = formField.options.find(
              (option) => option.value === data[formField.name]
            );
          }
        }
      });
      payload.submitted_on = payload.submitted_on
        ? payload.submitted_on
        : moment().format('yyyy-MM-DD');
      callback(payload);
    },
    fetchDepartments(callback) {
      this.isLoading = true;
      axios
        .get(`/manage/departments`)
        .then((response) => {
          const departments = response.data.map((department) => {
            return { label: department.name, value: department.id };
          });
          this.setOptions('department_id', 'options', departments);
          this.isLoading = false;
          callback(true);
        })
        .catch((error) => {
          callback();
          this.isLoading = false;
          console.error('ERR while fetchDepartments', error);
        });
    },
    fetchRequirementList(callback) {
      this.isLoading = true;
      axios
        .get(`recruitment/requirements/get_open_requirement`)
        .then((response) => {
          const requirement_ids = response.data.requirements.map(
            (requirement) => {
              return {
                label: requirement.r_id + ' ' + requirement.job_role,
                value: requirement.id,
              };
            }
          );
          this.setOptions('requirement_id', 'options', requirement_ids);
          this.isLoading = false;
          callback(true);
        })
        .catch((error) => {
          callback();
          this.isLoading = false;
          console.error('ERR while fetchRequirementList', error);
        });
    },
    convertSelectOptions() {
      const profile_status = this.payload.status.value;
      const hiring_location = this.payload.hiring_location
        ? this.payload.hiring_location.value
        : '';
      let editUserPayload = JSON.parse(JSON.stringify(this.payload));
      editUserPayload.status = profile_status;
      editUserPayload.hiring_location = hiring_location;
      // converting option object to its value
      Object.keys(editUserPayload).forEach((keyName) => {
        if (
          [
            'source_id',
            'holding_project',
            'vacation_plans',
            'department_id',
          ].includes(keyName)
        ) {
          editUserPayload[keyName] =
            (editUserPayload[keyName] && editUserPayload[keyName].value) || '';
        }
        if (['willing_to_relocate'].includes(keyName)) {
          editUserPayload[keyName] =
            (editUserPayload[keyName] && editUserPayload[keyName].label) || '';
        }
      });
      return editUserPayload;
    },
    editProfile(payload) {
      this.isGoingBack = true;
      payload.requirement_id = payload.requirement_id
        ? payload.requirement_id
        : this.requirementId;
      payload.profileId = this.profileId;
      axios
        .put(`recruitment/profiles/${this.profileId}`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.message,
            alertType: 'success',
          });
          this.$emit('close');
        })
        .catch((error) => {
          this.isGoingBack = false;
          console.error('ERR while UpdateProfile', error);
          let messages =
            (error.response && error.response.data.message.join(',')) || null;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: messages || 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },
    save() {
      this.validateInputs((isValid) => {
        if (isValid) {
          this.editProfile((data) => {
            if (data) {
              this.$emit('success', data);
            }
          });
        } else {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Please fill all mandatory fields',
            alertType: 'error',
          });
        }
      });
    },
    destroy() {
      axios
        .delete(`recruitment/profiles/${this.profileId}`)
        .then((response) => {
          this.$emit('close');
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
        })
        .catch((err) => {
          // handle error
          console.error('ERR while onDelete', err);
        });
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isEdit && !this.isGoingBack && this.isDataChanged) {
      const isConfirmed = await this.$confirm(
        'Leaving this popup will discard unsaved changes. Are you sure?'
      );
      if (isConfirmed) {
        next();
      } else {
        return;
      }
    } else {
      next();
    }
  },
});
</script>
