<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto mr-12 ml-12">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-center overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <nav class="flex flex-col sm:flex-row">
                  <button
                    v-if="
                      validatePermission(['manage.projects.histories.read'])
                    "
                    @click="selectedTab = 'projects_users'"
                    :class="
                      selectedTab === 'projects_users'
                        ? 'text-blue-500 border-blue-500 border-b-2'
                        : 'text-gray-600'
                    "
                    class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
                  >
                    Projects Members
                  </button>
                  <button
                    v-if="
                      validatePermission(['manage.projects.histories.read'])
                    "
                    @click="selectedTab = 'projects'"
                    :class="
                      selectedTab === 'projects'
                        ? 'text-blue-500 border-blue-500 border-b-2'
                        : 'text-gray-600'
                    "
                    class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
                  >
                    Projects
                  </button>
                </nav>
              </div>
              <div class="flex flex-row px-3 py-2">
                <button
                  type="button"
                  @click="close"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-2 sm:w-auto sm:text-sm h-8 items-center"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div class="bg-gray-200 flex flex-col w-full text-left pr-5 pl-5">
            <div>
              <div
                v-if="
                  selectedTab === 'projects' &&
                  validatePermission(['manage.projects.histories.read'])
                "
              >
                <ProjectHistoryTab class="w-full" :projectId="projectId" />
              </div>
              <div
                v-else-if="
                  selectedTab === 'projects_users' &&
                  validatePermission(['manage.projects.histories.read'])
                "
              >
                <ProjectUserHistoryTab class="w-full" :projectId="projectId" />
              </div>

              <NotAuthorized v-else />
              <!-- Pagination -->
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import ProjectHistoryTab from '@/components/manage/histories/ProjectHistoryTab.vue';
import ProjectUserHistoryTab from '@/components/manage/histories/ProjectUserHistoryTab.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';

export default {
  name: 'ProjectHistoryModal',
  components: {
    ProjectHistoryTab,
    ProjectUserHistoryTab,
    NotAuthorized,
  },
  data() {
    return {
      selectedTab: 'projects_users',
      id: null,
    };
  },
  props: ['projectId'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.id = this.projectId;
  },
};
</script>
