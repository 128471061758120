export default {
  template: `
    <div class="text-sm text-left" v-if="tabName && tabName=='users'">
        <span v-if="cellValue && cellValue.first_name">
            <p v-if="cellValue.first_name.from">
            {{ cellValue.first_name.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.last_name">
            <p v-if="cellValue.last_name.from">
            {{ cellValue.last_name.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.email">
            <p v-if="cellValue.email.from">
            {{ cellValue.email.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.practice">
            <p v-if="cellValue.practice.from">
                <router-link 
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'practices' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.practice.from }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.emp_type_name">
            <p v-if="cellValue.emp_type_name.from">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'emp_types' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.emp_type_name.from }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.team_name">
            <p v-if="cellValue.team_name.from">
            {{ cellValue.team_name.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.department_name">
            <p v-if="cellValue.department_name.from">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'departments' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.department_name.from }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.reporting_manager_name">
            <p v-if="cellValue.reporting_manager_name.from">
                <router-link
                    :to="'/manage/users/'+cellValue.reporting_manager_id.from"
                    class="text-blue-500"
                >
                    {{ cellValue.reporting_manager_name.from }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.status">
            <p v-if="cellValue.status.from != null">
            {{ cellValue.status.from ? 'Active' : 'Inactive' }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.employee_id">
            <p v-if="cellValue.employee_id.from">
            {{ cellValue.employee_id.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.joining_date">
            <p v-if="cellValue.joining_date.from">
            {{ globalDateFormat(cellValue.joining_date.from) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.dob">
            <p v-if="cellValue.dob.from">
            {{ globalDateFormat(cellValue.dob.from) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.gender">
            <p v-if="cellValue.gender.from">
            {{ cellValue.gender.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.experience">
            <p v-if="cellValue.experience.from">
            {{ globalDateFormat(cellValue.experience.from) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.blood_group">
            <p v-if="cellValue.blood_group.from">
            {{ cellValue.blood_group.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.mobile_number">
            <p v-if="cellValue.mobile_number.from">
            {{ cellValue.mobile_number.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.designation">
            <p v-if="cellValue.designation.from">
            {{ cellValue.designation.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.external_id">
            <p v-if="cellValue.external_id.from">
            {{ cellValue.external_id.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.location">
            <p v-if="cellValue.location.from">
            {{ cellValue.location.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.is_config">
            <p v-if="cellValue.is_config.from != null">
            {{ cellValue.is_config.from ? 'Yes' : 'No' }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.comments">
            <p v-if="cellValue.comments.from">
            {{ cellValue.comments.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.exit_date">
            <p v-if="cellValue.exit_date.from">
            {{ globalDateFormat(cellValue.exit_date.from) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.performance">
            <p v-if="cellValue.performance.from">
            {{ cellValue.performance.from }}
            </p>
            <p v-else>-</p>
        </span>
    </div>
    <div class="text-sm text-left" v-else-if="tabName && tabName=='projects'">
        <span v-if="cellValue && cellValue.name">
            <p v-if="cellValue.name.from">
            {{ cellValue.name.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.practice">
            <p v-if="cellValue.practice.from">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'practices' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.practice.from }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.client_name">
            <p v-if="cellValue.client_name.from">
                <router-link
                    :to="{
                        path: '/manage/configuration',
                        query: { tabSelected: 'clients' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.client_name.from }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.primary_product">
            <p v-if="cellValue.primary_product.from">
            {{ cellValue.primary_product.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.delivery_method">
            <p v-if="cellValue.delivery_method.from">
            {{ cellValue.delivery_method.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.start_date">
            <p v-if="cellValue.start_date.from">
            {{ globalDateFormat(cellValue.start_date.from) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.end_date">
            <p v-if="cellValue.end_date.from">
            {{ globalDateFormat(cellValue.end_date.from) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.project_status">
            <p v-if="cellValue.project_status.from != null">
            {{ cellValue.project_status.from ? 'Active' : 'Inactive' }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.code">
            <p v-if="cellValue.code.from">
            {{ cellValue.code.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.description">
            <p v-if="cellValue.description.from">
            {{ cellValue.description.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.total_budget">
            <p v-if="cellValue.total_budget.from">
            {{ cellValue.total_budget.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.sow">
            <p v-if="cellValue.sow.from">
            {{ cellValue.sow.from }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.default">
            <p v-if="cellValue.default.from">
            {{ cellValue.default.from ? 'Yes' : 'No' }}
            </p>
            <p v-else>-</p>
        </span>
    </div>
    <div v-else-if="tabName && tabName=='project-user'">
        <div v-if="cellValue && cellValue.user_type=='manager'">-</div>
        <div class="text-sm text-left" v-else>
            <span v-if="cellValue && cellValue.billability">
                <p v-if="cellValue.billability.from">
                {{ cellValue.billability.from }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.start_user_date">
                <p v-if="cellValue.start_user_date && cellValue.start_user_date.from">
                    {{ globalDateFormat(cellValue.start_user_date.from) }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.end_user_date">
                <p v-if="cellValue.end_user_date && cellValue.end_user_date.from">
                    {{ globalDateFormat(cellValue.end_user_date.from) }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.allocation">
                <p v-if="cellValue.allocation && cellValue.allocation.from">
                    {{ cellValue.allocation.from }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.project_user_status">
                <p v-if="cellValue.project_user_status != null">
                <span :class="cellValue.project_user_status.from">
                {{ cellValue.project_user_status.from ? 'Active' : 'Inactive' }}
                </span>
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.hourly_rate">
                <p v-if="cellValue.hourly_rate.from">
                {{ cellValue.hourly_rate.from }}
                </p>
                <p v-else>-</p>
            </span>
        </div>
    </div>
      `,
  data: function () {
    return {
      cellValue: null,
      tabName: null,
    };
  },
  methods: {},
  beforeMount() {
    if (this.params.data) {
      this.cellValue = JSON.parse(this.params.data.data);
      this.tabName = this.params.tabName;
    }
  },
};
