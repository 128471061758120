export default {
  template: `
    <div class="text-sm text-left font-semibold" v-if="tabName && tabName=='users'">
      <p v-if="cellValue && cellValue.first_name">First Name</p>
      <p v-if="cellValue && cellValue.last_name">Last Name</p>
      <p v-if="cellValue && cellValue.email">Email</p>
      <p v-if="cellValue && cellValue.practice">Practice</p>
      <p v-if="cellValue && cellValue.emp_type_name">
        Employment Type
      </p>
      <p v-if="cellValue && cellValue.team_name">Team</p>
      <p v-if="cellValue && cellValue.department_name">
        Department
      </p>
      <p v-if="cellValue && cellValue.reporting_manager_name">
        Reporting Manager
      </p>
      <p v-if="cellValue && cellValue.status">Status</p>
      <p v-if="cellValue && cellValue.employee_id">Employee Id</p>
      <p v-if="cellValue && cellValue.joining_date">
        Joining Date
      </p>
      <p v-if="cellValue && cellValue.dob">Date Of Birth</p>
      <p v-if="cellValue && cellValue.gender">Gender</p>
      <p v-if="cellValue && cellValue.experience">Experience</p>
      <p v-if="cellValue && cellValue.blood_group">Blood Group</p>
      <p v-if="cellValue && cellValue.mobile_number">
        Mobile Number
      </p>
      <p v-if="cellValue && cellValue.designation">Designation</p>
      <p v-if="cellValue && cellValue.external_id">External Id</p>
      <p v-if="cellValue && cellValue.location">Location</p>
      <p v-if="cellValue && cellValue.is_config">Is Config</p>
      <p v-if="cellValue && cellValue.comments">Comments</p>
      <p v-if="cellValue && cellValue.exit_date">Date of Exit</p>
      <p v-if="cellValue && cellValue.performance">Performance</p>
    </div>
    <div class="text-sm text-left font-semibold" v-else-if="tabName && tabName=='projects'">
      <p v-if="cellValue && cellValue.name">Name</p>
      <p v-if="cellValue && cellValue.practice">Practice</p>
      <p v-if="cellValue && cellValue.client_name">Client</p>
      <p v-if="cellValue && cellValue.primary_product">Primary Product</p>
      <p v-if="cellValue && cellValue.delivery_method">
      Delivery Method
      </p>
      <p v-if="cellValue && cellValue.start_date">Start Date</p>
      <p v-if="cellValue && cellValue.end_date">
      End Date
      </p>
      <p v-if="cellValue && cellValue.project_status">
      Status
      </p>
      <p v-if="cellValue && cellValue.code">Code</p>
      <p v-if="cellValue && cellValue.description">Description</p>
      <p v-if="cellValue && cellValue.total_budget">
      Total Budget
      </p>
      <p v-if="cellValue && cellValue.sow">SOW</p>
      <p v-if="cellValue && cellValue.default">Default Project</p>
    </div>
    <div v-else-if="tabName && tabName=='project-user'">
      <div v-if="cellValue && cellValue.user_type=='manager'">-</div>
      <div class="text-sm text-left font-semibold" v-else>
        <p v-if="cellValue && cellValue.billability">Billability</p>
        <p v-if="cellValue && cellValue.start_user_date">Start Date</p>
        <p v-if="cellValue && cellValue.end_user_date">End Date</p>
        <p v-if="cellValue && cellValue.allocation">Allocation</p>
        <p v-if="cellValue && cellValue.project_user_status">
        Project User Status
        </p>
        <p v-if="cellValue && cellValue.hourly_rate">Hourly Rate</p>
      </div>
    </div>
      `,
  data: function () {
    return {
      cellValue: null,
      tabName: null,
    };
  },
  methods: {},
  beforeMount() {
    if (this.params.data) {
      this.cellValue = JSON.parse(this.params.data.data);
      this.tabName = this.params.tabName;
    }
  },
};
