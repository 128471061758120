<template lang="html">
  <div v-if="alertShow" class="z-10">
    <div v-if="alertType === 'success'">
      <Success :message="alertMessage" @closeAlert="closeAlert" />
    </div>
    <div v-if="alertType === 'error'">
      <Error :message="alertMessage" @closeAlert="closeAlert" />
    </div>
    <div v-if="alertType === 'warning'">
      <Warning :message="alertMessage" @closeAlert="closeAlert" />
    </div>
    <div v-if="alertType === 'info'">
      <Info :message="alertMessage" @closeAlert="closeAlert" />
    </div>
    <div v-if="alertType === 'notification'">
      <Notification :message="alertMessage" @closeAlert="closeAlert" />
    </div>
  </div>
</template>

<script>
import Success from '@/components/alerts/Success.vue';
import Error from '@/components/alerts/Error.vue';
import Warning from '@/components/alerts/Warning.vue';
import Info from '@/components/alerts/Info.vue';
import Notification from '@/components/alerts/Notification.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Success,
    Error,
    Info,
    Warning,
    Notification,
  },
  computed: {
    ...mapState('settings', ['alertShow', 'alertMessage', 'alertType']),
  },
  props: ['type', 'message'],
  methods: {
    closeAlert() {
      this.$store.commit('settings/closeAlert');
    },
  },
};
</script>
