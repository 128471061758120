<template>
  <div class="bg-gray-200 w-96">
    <Loading v-if="isLoading" />

    <div v-else>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Manage Roles
        </div>
        <button
          v-if="isManagePermission"
          @click="() => openRoleModal(null)"
          class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
        >
          <span class="material-icons m-auto mr-1 text-base">add</span>
          <span class="text-sm">Add Role</span>
        </button>
      </div>
      <!-- Top Bar END-->
      <div class="dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2">
        <!-- <div class="min-h-screen bg-gray-100 space-y-12 py-10">
          <div class="container mx-auto">
            <div
              class="bg-white max-w-sm mx-auto rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition duration-500 transform hover:scale-105 cursor-pointer"
            >
              <div class="h-20 bg-red-500 flex items-center justify-between">
                <h1 class="text-white ml-4 border-2 py-2 px-4 rounded-full">
                  1
                </h1>
                <p class="mr-20 text-white text-lg">Agenda/Topic</p>
                <p class="mr-4 text-white font-thin text-lg">SAAS-0000</p>
              </div>
              <p class="py-6 text-lg tracking-wide ml-16">
                Description of what is done here
              </p>
              <hr >
              <div class="flex justify-between px-5 mb-2 text-sm text-gray-600">
                <p>Last Update</p>
                <p>3/08/2021</p>
              </div>
            </div>
          </div>

          <div class="container mx-auto">
            <div
              class="bg-white max-w-sm mx-auto rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition duration-500 transform hover:scale-105 cursor-pointer"
            >
              <div class="h-20 bg-green-500 flex items-center justify-between">
                <h1 class="text-white ml-4 border-2 py-2 px-4 rounded-full">
                  2
                </h1>
                <p class="mr-20 text-white text-lg">Agenda/Topic</p>
                <p class="mr-4 text-white font-thin text-lg">SAAS-0000</p>
              </div>
              <p class="py-6 text-lg tracking-wide ml-16">
                Description of what is done here
              </p>
              <hr >
              <div class="flex justify-between px-5 mb-2 text-sm text-gray-600">
                <p>Last Update</p>
                <p>3/08/2021</p>
              </div>
            </div>
          </div>

          <div class="container mx-auto">
            <div
              class="bg-white max-w-sm mx-auto rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition duration-500 transform hover:scale-105 cursor-pointer"
            >
              <div class="h-20 bg-purple-500 flex items-center justify-between">
                <h1 class="text-white ml-4 border-2 py-2 px-4 rounded-full">
                  3
                </h1>
                <p class="mr-20 text-white text-lg">Agenda/Topic</p>
                <p class="mr-4 text-white font-thin text-lg">SAAS-0000</p>
              </div>
              <p class="py-6 text-lg tracking-wide ml-16">
                Description of what is done here
              </p>
              <hr >
              <div class="flex justify-between px-5 mb-2 text-sm text-gray-600">
                <p>Last Update</p>
                <p>3/08/2021</p>
              </div>
            </div>
          </div>
        </div> -->

        <div
          v-if="isManagePermission && isReadPermission"
          class="flex flex-row mt-4"
        >
          <div class="flex flex-col w-1/2 mx-2">
            <label class="text-gray-500 ml-1 mb-1">Menu</label>
            <v-select
              placeholder="Select menu"
              class="text-sm bg-white"
              :value="selectedMenu"
              :options="menus"
              @input="setSelectedMenu"
            ></v-select>
          </div>
          <div
            class="flex flex-col w-1/2 mx-2"
            v-if="!(menuItems.length === 1 && !menuItems[0].link)"
          >
            <label class="text-gray-500 ml-1 mb-1">Sub Menu</label>
            <v-select
              placeholder="Select menu item"
              class="text-sm bg-white"
              :value="selectedMenuItem"
              :options="menuItems"
              @input="setSelectedMenuItem"
            ></v-select>
          </div>
        </div>

        <!-- Permissions -->
        <div
          v-if="isManagePermission && isReadPermission"
          class="mt-2 overflow-x-auto"
        >
          <draggable
            :list="permissions"
            :clone="clone"
            @end="dropEnd"
            :style="
              permissions.length > 4
                ? permissions.length > 7
                  ? 'padding-left: 84rem; padding-right: 84rem;'
                  : 'padding-left: 10rem;'
                : ''
            "
            class="dragArea flex flex-row p-3 items-center justify-center overflow-x-auto"
            :options="{
              group: { name: 'people', pull: 'clone', put: false },
            }"
          >
            <div
              :data-id="element.id"
              class="mr-4"
              v-for="(element, index) in permissions"
              :key="index"
            >
              <PermissionCard :data="element" />
            </div>
          </draggable>
        </div>

        <!-- Roles -->
        <div v-if="isReadPermission" class="flex flex-col m-auto p-auto mt-2">
          <h6
            class="flex py-1 lg:px-5 md:px-10 px-5 lg:mx-0 md:mx-20 mx-5 font-semibold text-gray-600"
          >
            Roles
          </h6>
          <div class="flex overflow-x-scroll pb-5">
            <div class="flex flex-nowrap lg:ml-0 md:ml-20 ml-10">
              <div
                :key="roleIndex"
                v-for="(role, roleIndex) in roles"
                class="inline-block px-3"
              >
                <div
                  class="w-64 max-w-xs rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                >
                  <div>
                    <h3
                      @click="() => openRoleModal(role.id)"
                      class="bg-gray-700 text-white px-2 py-1 cursor-pointer"
                    >
                      {{ role.name }}
                    </h3>
                    <draggable
                      :list="role.permissions"
                      :style="
                        isReadPermission && !isManagePermission
                          ? 'height: 30rem'
                          : 'height: 20rem;'
                      "
                      class="dragArea px-2 overflow-auto"
                      :options="{ group: 'people' }"
                      :data-box="role.id"
                    >
                      <LiquorTree v-if="!isRoleLoading" :data="role.menus">
                        <span class="tree-text" slot-scope="{ node }">
                          <template v-if="!node.hasChildren()">
                            <div
                              @click="
                                () => openRolePermissionModal(role, node.data)
                              "
                            >
                              <i class="material-icons"></i>
                              {{ node.text }}
                            </div>
                          </template>

                          <template v-else>
                            <i
                              :class="[
                                node.expanded()
                                  ? 'ion-android-folder-open'
                                  : 'ion-android-folder',
                              ]"
                            ></i>
                            {{ node.text }}
                          </template>
                        </span>
                      </LiquorTree>
                    </draggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotAuthorized class="h-screen bg-white" v-else />

        <!-- Roles -->

        <!-- Table END -->
      </div>
    </div>
    <RoleModal
      v-if="isRoleModalVisible && isManagePermission"
      :roleId="selectedRole.id"
      :selectedRole="selectedRole"
      @success="onSuccess"
      @close="closeRoleModal"
    ></RoleModal>
    <RolePermissionModal
      v-if="isRolePermissionModalVisible"
      :selectedRole="selectedRole"
      :selectedRolePermission="selectedRolePermission"
      :isManagePermission="isManagePermission"
      @success="onSuccess"
      @close="closeRolePermissionModal"
    ></RolePermissionModal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import axios from 'axios';
import PermissionCard from '@/components/authentication/PermissionCard.vue';
import RolePermissionCard from '@/components/authentication/RolePermissionCard.vue';
import RoleModal from '@/components/authentication/RoleModal.vue';
import RolePermissionModal from '@/components/authentication/RolePermissionModal.vue';
import LiquorTree from 'liquor-tree';
import Loading from '@/components/common/Loading.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';

export default {
  name: 'Roles',
  components: {
    draggable,
    PermissionCard,
    RoleModal,
    RolePermissionModal,
    LiquorTree,
    Loading,
    NotAuthorized,
  },
  data() {
    return {
      isLoading: true,
      isRoleModalVisible: false,
      isRolePermissionModalVisible: false,
      component: RolePermissionCard,
      menus: [],
      selectedMenu: null,
      menuItems: [],
      selectedMenuItem: null,
      permissions: [],
      selectedRole: { id: null },
      selectedRolePermission: { id: null },
      roles: [],
      isRoleLoading: false,
      options: {
        checkbox: false,
      },
      isReadPermission: false,
      isManagePermission: false,
    };
  },
  methods: {
    click: function (_item) {
      //   item.name = "IT GETS CLONED";
    },
    add: function () {
      this.permissions.push({
        name: 'Juan',
      });
    },
    clone: function (el) {
      return el;
    },
    replace: function () {
      this.permissions = [
        {
          name: 'Edgard',
        },
      ];
    },
    dropEnd: function (e) {
      const roleId = parseInt(e.to.getAttribute('data-box'));
      const permissionId = parseInt(e.item.getAttribute('data-id'));
      this.assignPermissionToRole(roleId, permissionId, (isAssigned, err) => {
        if (isAssigned) {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Successfully added',
            alertType: 'success',
          });
        } else {
          this.roles = this.roles.map((role) => {
            if (role.id === roleId) {
              const existingPermissions = role.permissions.filter(
                (perm) => perm.id === permissionId
              );
              role.permissions = role.permissions.filter(
                (perm) => perm.id != permissionId
              );
              if (existingPermissions.length > 1) {
                role.permissions.push(existingPermissions[0]);
              }
            }
            return role;
          });
          this.$store.dispatch('settings/showAlert', {
            alertMessage: err || 'Something went wrong.Please try again',
            alertType: 'error',
          });
        }
        this.onSuccess();
      });
    },
    setSelectedMenu(option) {
      this.selectedMenu = option;
      this.fetchMenuItems(option.value);
    },
    setSelectedMenuItem(option) {
      this.selectedMenuItem = option;
      this.setPermissions(option);
    },
    setPermissions(menuItem) {
      let { permissions } = this.menuItems.find(
        (item) => item.value === menuItem.value
      );
      // permissions = permissions.map((permission) => {
      //   // permission.active = false;
      //   return permission;
      // });
      this.permissions = permissions;
    },
    fetchMenus(callback) {
      axios
        .get(`/authorization/menus`)
        .then((response) => {
          const menus = response.data.map((d) => {
            return { label: d.name, value: d.id };
          });
          callback(menus);
        })
        .catch((error) => {
          console.error('ERR while fetchMenus', error);
          callback([]);
        });
    },
    fetchMenuItems(menuId) {
      axios
        .get(`/authorization/menu_items/${menuId}`)
        .then((response) => {
          this.menuItems = response.data;
          if (this.menuItems.length > 0) {
            this.setSelectedMenuItem(this.menuItems[0]);
          }
        })
        .catch((error) => {
          console.error('ERR while fetchMenuItems', error);
        });
    },
    fetchRolePermissions(callback) {
      this.isRoleLoading = true;
      axios
        .get(`/authorization/roles/permissions`)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          console.error('ERR while fetchRolePermissions', error);
          callback([]);
        });
    },
    assignPermissionToRole(roleId, permissionId, callback) {
      axios
        .post(`/authorization/roles/${roleId}/permissions/${permissionId}`)
        .then((response) => {
          callback(true, response.data);
        })
        .catch((error) => {
          let errMsg = 'Something went wrong. Please try again';
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errMsg = error.response.data.message[0];
          }
          console.error('ERR while assignPermissionToRole', error);
          callback(false, errMsg);
        });
    },
    openRoleModal(roleId) {
      if (this.isManagePermission) {
        if (roleId) {
          this.selectedRole = this.roles.find((role) => role.id === roleId);
        } else {
          this.selectedRole = { id: null };
        }
        this.isRoleModalVisible = true;
      }
    },
    closeRoleModal() {
      this.isRoleModalVisible = false;
    },
    openRolePermissionModal(role, rolePermission) {
      if (rolePermission && role.id) {
        this.selectedRolePermission = rolePermission;
        this.selectedRole = role;
      } else {
        this.selectedRolePermission = { id: null };
        this.selectedRole = { id: null };
      }
      this.isRolePermissionModalVisible = true;
    },
    closeRolePermissionModal() {
      this.isRolePermissionModalVisible = false;
    },
    onSuccess() {
      this.closeRoleModal();
      this.closeRolePermissionModal();
      this.fetchRolePermissions((roles) => {
        this.roles = roles;
        this.generateDataForTree();
        this.isRoleLoading = false;
        // this.fetchMenus((menus) => {
        //   this.menus = menus;
        //   if (menus.length > 0) {
        //     this.setSelectedMenu(menus[0]);
        //     this.fetchMenuItems(menus[0].value);
        //   }
        // });
      }); //reload
    },
    generateDataForTree() {
      this.roles.forEach((role) => {
        role.menus = [];
        role.permissions.forEach((p) => {
          let menu = role.menus.find((m) => m.text === p.menu);
          if (menu) {
            if (p.menu_item) {
              let menu_item = menu.children.find(
                (mi) => mi.text === p.menu_item
              );
              if (menu_item) {
                menu_item.children.push({ text: p.name, data: p });
              } else {
                menu.children.push({
                  text: p.menu_item,
                  children: [{ text: p.name, data: p }],
                });
              }
            } else {
              menu.children.push({ text: p.name, data: p });
            }
          } else {
            if (p.menu_item) {
              role.menus.push({
                text: p.menu,
                children: [
                  { text: p.menu_item, children: [{ text: p.name, data: p }] },
                ],
              });
            } else {
              role.menus.push({
                text: p.menu,
                children: [{ text: p.name, data: p }],
              });
            }
          }
        });
      });
    },
  },
  created() {
    this.isReadPermission = this.validatePermission(['manage.roles.read']);
    this.isManagePermission = this.validatePermission(['manage.roles.manage']);
    if (this.isReadPermission) {
      this.fetchRolePermissions((roles) => {
        this.roles = roles;
        this.fetchMenus((menus) => {
          this.menus = menus;
          if (menus.length > 0) {
            this.setSelectedMenu(menus[0]);
            this.fetchMenuItems(menus[0].value);
            this.generateDataForTree();
            this.isLoading = false;
            this.isRoleLoading = false;
          }
        });
      });
    } else {
      this.isLoading = false;
    }
  },
  // computed: {
  //   rolePermissions(permissions) {
  //     return permissions.filter(this.onlyUnique)
  //   },
  // },
  // watch: {
  //   isDragging(newValue) {
  //     if (newValue) {
  //       this.delayedDragging = true;
  //       return;
  //     }
  //     this.$nextTick(() => {
  //       this.delayedDragging = false;
  //     });
  //   },
  // },
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
