<template>
  <div class="">
    <div v-if="isSearchUser || isSearchProject" class="header">
      <div
        tabindex="0"
        class="z-40 panel"
        :style="{ width: focus ? '750px' : '350px' }"
      >
        <span class="search-icon material-icons">search</span>
        <input
          type="search"
          @input="onChange"
          @focus="handleFocus"
          v-model="search"
          placeholder="Search User/Project"
          class="search-box borderborder-slate-400 bg-gray-100 md:h-8 h-5 px-2 pr-10 rounded md:text-sm text-xs md:w-full focus:outline-none"
        />
        <div
          tabindex="0"
          v-if="
            isOpen &&
            ((users && users.length > 0) || (projects && projects.length > 0))
          "
          class="flex flex-row dropdown-content mt-2.5"
        >
          <div
            :class="{ active: active }"
            class="relative h-full overflow-auto pb-6"
            :style="{ width: active ? '50%' : '100%' }"
          >
            <ul class="link">
              <li
                :key="index"
                v-for="(user, index) in users"
                class="flex flex-row p-2 border-b-2 items-center"
                @click="viewProfile(user.id)"
              >
                <img
                  v-if="user.image"
                  :src="baseImageUrl + user.image"
                  class="rounded-full h-10 w-10 items-center justify-center"
                />
                <div
                  v-else
                  :style="{ width: active ? '13%' : '6%' }"
                  style="border-radius: 50%; height: 42px"
                  class="bg-gray-200 flex items-center justify-center text-base font-thin text-gray-500 text-sm rounded-full"
                >
                  {{ getInitials(user.first_name, user.last_name) }}
                </div>
                <div class="flex-grow flex flex-col items-start space-y-0.5">
                  <div class="ml-2 flex flex-row w-full items-start">
                    <span
                      :class="active ? 'w-full' : 'w-1/2'"
                      class="text-left"
                    >
                      <span class="text-gray-400 text-sm">{{
                        user.employee_id ? user.employee_id + ' - ' : ''
                      }}</span>
                      <span class="text-sm">{{
                        user.first_name + ' ' + user.last_name
                      }}</span>
                    </span>
                    <span
                      :class="user.status ? 'bg-green-200' : 'bg-red-200'"
                      class="status text-green-800 font-semibold px-2 py-0 text-xs rounded-full"
                      >{{ user.status == true ? 'Active' : 'Inactive' }}</span
                    >
                  </div>
                  <div class="ml-2 flex flex-row w-full items-start">
                    <span
                      class="designation text-sm text-gray-500 w-1/2 text-left"
                    >
                      {{ user.designation }}
                    </span>
                    <span
                      :class="active ? 'w-1/2' : ''"
                      class="flex flex-row items-center"
                    >
                      <span class="material-icons text-gray-300 text-sm"
                        >smartphone</span
                      ><span class="text-sm text-gray-600">{{
                        user.mobile_number ? user.mobile_number : 'No Contact'
                      }}</span>
                    </span>
                    <span
                      :class="user.status ? 'bg-green-200' : 'bg-red-200'"
                      class="statushide text-green-800 font-semibold px-2 py-0 text-xs rounded-full hidden"
                      >{{ user.status == true ? 'Active' : 'Inactive' }}</span
                    >
                  </div>
                </div>
              </li>
            </ul>
            <ul class="project-link">
              <li
                :key="projectIndex"
                v-for="(project, projectIndex) in projects"
                class="flex flex-col border-b-2 items-start py-2 mx-4"
                @click="viewProject(project.id)"
              >
                <div>
                  <span class="text-gray-600 text-sm">{{
                    project.code + ' -  '
                  }}</span>
                  <span class="text-sm">{{ project.name }}</span>
                </div>
                <div class="flex flex-row w-full">
                  <span class="text-sm text-gray-600 w-1/2 text-left">{{
                    project.practice
                  }}</span>
                  <span
                    :class="
                      project.project_status ? 'bg-green-200' : 'bg-red-200'
                    "
                    class="text-green-800 font-semibold px-2 py-0 text-xs rounded-full ml-8 flex items-center"
                    >{{
                      project.project_status == true ? 'Active' : 'Inactive'
                    }}</span
                  >
                </div>
              </li>
            </ul>
          </div>

          <SearchUserDetails
            v-if="useractive"
            :userDetail="userDetail"
            :key="componentKey"
            @close="closeDetails"
            @getProfile="getProfile"
          />
          <SearchProjectDetails
            v-if="projectactive"
            :projectDetail="projectDetails"
            :key="componentKey"
            @close="closeDetails"
            @getProfile="getProfile"
          />
          <div
            :style="{ width: active ? '50%' : '100%' }"
            class="absolute bottom-0 w-full bg-gray-100 border-t-2"
          >
            <div class="inline-flex text-blue-500 text-sm pl-2">
              <router-link :to="`/manage/users`" v-if="isSearchUser"
                ><span @click="getProfile">View More Users</span> </router-link
              ><span v-if="isSearchUser && isSearchProject">/</span>
              <router-link :to="`/manage/projects`" v-if="isSearchProject">
                <span @click="getProfile">View More Projects</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="focus"
      class="opacity-50 fixed inset-0 bg-white"
      @click="handleHideDiv"
    ></div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import axios from 'axios';
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
import SearchProjectDetails from '@/components/common/SearchProjectDetails.vue';
import SearchUserDetails from '@/components/common/SearchUserDetails.vue';

export default {
  name: 'SearchAutocomplete',
  data() {
    return {
      isOpen: false,
      users: [],
      projects: [],
      search: '',
      nonce: '',
      focus: false,
      active: false,
      projectactive: false,
      useractive: false,
      userDetail: [],
      projectDetails: [],
      componentKey: 0,
    };
  },
  components: {
    SearchProjectDetails,
    SearchUserDetails,
  },
  computed: {
    ...mapGetters('user', ['userId', 'isSearchUser', 'isSearchProject']),
    ...mapState('user', ['userDetails']),
  },
  methods: {
    toggleRightSideBar() {
      this.$store.commit('settings/toggleRightSideBar');
    },
    viewProfile(id) {
      this.active = true;
      this.projectactive = false;
      this.isOpen = true;
      this.projectDetails = [];
      this.userDetail = this.users.filter((item) => item.id == id);
      this.useractive = true;
      this.componentKey += 1;
    },
    viewProject(id) {
      this.closeDetails();
      this.active = true;
      this.projectactive = true;
      this.useractive = false;
      this.isOpen = true;
      this.userDetail = [];
      this.projectDetails = this.projects.filter((item) => item.id == id);
      this.componentKey += 1;
    },
    handleHideDiv() {
      this.isOpen = false;
      this.focus = false;
      this.useractive = false;
      this.projectactive = false;
      this.active = false;
    },
    handleFocus() {
      this.focus = true;
      this.isOpen = true;
    },
    getInitials(firstName, lastName) {
      const firstNameIntial =
        firstName && firstName[0] && firstName[0].toUpperCase();
      const lastNameInitial =
        lastName && lastName[0] && lastName[0].toUpperCase();
      return (firstNameIntial || '') + (lastNameInitial || '');
    },
    onChange() {
      this.nonce = Date.now().toString();
      axios
        .get(`/manage/users/get_search_data`, {
          params: {
            search: this.search,
            nonce: this.nonce,
            fetchUser: this.isSearchUser,
            fetchProject: this.isSearchProject,
          },
        })
        .then((response) => {
          const { nonce, users, projects } = response.data;
          if (this.nonce !== nonce) return;
          this.users = users;
          this.projects = projects;
        })

        .catch((error) => {
          this.isLoading = false;
          this.results = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchUsers', error);
        });
      this.isOpen = true;
    },
    getProfile() {
      this.isOpen = false;
      this.focus = false;
      this.useractive = false;
      this.projectactive = false;
      this.active = false;
    },
    closeDetails() {
      this.useractive = false;
      this.projectactive = false;
      this.active = false;
    },
  },
  created() {
    this.baseImageUrl = process.env.VUE_APP_BASE_IMAGE_URL;
  },
};
</script>

<style>
/* Dropdown content (hidden by default) */
.dropdown-content {
  background: #fff;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 42%);
  border-radius: 3px;
  box-sizing: border-box;
  position: fixed;
  display: none;
  top: 45px;
  width: 750px;
  bottom: 50px;
}

.header {
  height: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: center;
  top: 0px;
}
.header .panel {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 8px;
  width: 350px;
  transition: 0.4s ease-in-out width;
}
.header .panel:focus-within {
  width: 750px;
  border: 1px solid #9ca3af;
  border-radius: 5px;
}
.dropdown-content .active .link .designation {
  display: none;
}
.dropdown-content .active .link .status {
  display: none;
}
.dropdown-content .active .link .statushide {
  display: block;
}
.header .search-icon {
  position: absolute;
  font-size: 16px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  margin: 5px;
  top: 0;
  right: 2px;
  color: #9ca3af;
  visibility: visible;
  cursor: pointer;
  border: none;
}
</style>
