<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Configuration
        </div>

        <div class="flex flex-row items-center">
          <div class="bg-white mr-0 text-sm">
            <nav class="flex flex-col sm:flex-row">
              <button
                v-if="
                  validatePermission([
                    'manage.configurations.departments.read',
                    'manage.configurations.departments.manage',
                    'manage.configurations.departments.export',
                  ])
                "
                @click="selectedTab = 'departments'"
                :class="
                  selectedTab === 'departments'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Departments
              </button>
              <button
                v-if="
                  validatePermission([
                    'manage.configurations.practices.read',
                    'manage.configurations.practices.manage',
                    'manage.configurations.practices.export',
                  ])
                "
                @click="selectedTab = 'practices'"
                :class="
                  selectedTab === 'practices'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Practices
              </button>
              <button
                v-if="
                  validatePermission([
                    'manage.configurations.teams.read',
                    'manage.configurations.teams.manage',
                    'manage.configurations.teams.export',
                  ])
                "
                @click="selectedTab = 'teams'"
                :class="
                  selectedTab === 'teams'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Teams
              </button>
              <button
                v-if="
                  validatePermission([
                    'manage.configurations.emp_type.read',
                    'manage.configurations.emp_type.manage',
                    'manage.configurations.emp_type.export',
                  ])
                "
                @click="selectedTab = 'emp_types'"
                :class="
                  selectedTab === 'emp_types'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Employement Type
              </button>
              <button
                v-if="
                  validatePermission([
                    'manage.configurations.clients.read',
                    'manage.configurations.clients.manage',
                    'manage.configurations.clients.export',
                  ])
                "
                @click="selectedTab = 'client_tab'"
                :class="
                  selectedTab === 'client_tab'
                    ? 'text-blue-500 border-blue-500 border-b-2'
                    : 'text-gray-600'
                "
                class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
              >
                Client
              </button>
            </nav>
          </div>
          <div></div>
        </div>
      </div>
      <!-- Top Bar END-->
      <div
        v-if="
          selectedTab === 'departments' &&
          validatePermission([
            'manage.configurations.departments.read',
            'manage.configurations.departments.manage',
            'manage.configurations.departments.export',
          ])
        "
      >
        <DepartmentTab
          class="w-full"
          :showTalentPoolFilter="showTalentPoolFilter"
          @toggleFilter="toggleFilter"
        />
      </div>
      <div
        v-else-if="
          selectedTab === 'practices' &&
          validatePermission([
            'manage.configurations.practices.read',
            'manage.configurations.practices.manage',
            'manage.configurations.practices.export',
          ])
        "
      >
        <PracticeTab
          class="w-full"
          :showTalentPoolFilter="showTalentPoolFilter"
          @toggleFilter="toggleFilter"
        />
      </div>
      <div
        v-else-if="
          selectedTab === 'teams' &&
          validatePermission([
            'manage.configurations.teams.read',
            'manage.configurations.teams.manage',
            'manage.configurations.teams.export',
          ])
        "
      >
        <TeamsTab
          class="w-full"
          :showTalentPoolFilter="showTalentPoolFilter"
          @toggleFilter="toggleFilter"
        />
      </div>
      <div
        v-else-if="
          selectedTab === 'emp_types' &&
          validatePermission([
            'manage.configurations.emp_type.read',
            'manage.configurations.emp_type.manage',
            'manage.configurations.emp_type.export',
          ])
        "
      >
        <EmploymentTab
          class="w-full"
          :showTalentPoolFilter="showTalentPoolFilter"
          @toggleFilter="toggleFilter"
        />
      </div>
      <div
        v-else-if="
          validatePermission([
            'manage.configurations.clients.read',
            'manage.configurations.clients.manage',
            'manage.configurations.clients.export',
          ])
        "
      >
        <ClientTab
          class="w-full"
          :showTalentPoolFilter="showTalentPoolFilter"
          @toggleFilter="toggleFilter"
        />
      </div>
      <NotAuthorized v-else />
      <!-- Pagination -->
    </div>
  </div>
</template>

<script>
import DepartmentTab from '@/components/manage/configuration/DepartmentTab.vue';
import PracticeTab from '@/components/manage/configuration/PracticeTab.vue';
import TeamsTab from '@/components/manage/configuration/TeamsTab.vue'
import EmploymentTab from '@/components/manage/configuration/EmploymentTab.vue';
import ClientTab from '@/components/manage/configuration/ClientTab.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';

export default {
  name: 'Configuration',
  components: {
    DepartmentTab,
    PracticeTab,
    TeamsTab,
    EmploymentTab,
    ClientTab,
    NotAuthorized,
  },
  data() {
    return {
      selectedTab: 'departments',
      showProjectAssignmentFilter: false,
      showTalentPoolFilter: false,
    };
  },
  props: ['tabSelected'],
  methods: {
    toggleFilter() {
      if (this.selectedTab === 'departments') {
        this.showProjectAssignmentFilter = !this.showProjectAssignmentFilter;
      } else {
        this.showTalentPoolFilter = !this.showTalentPoolFilter;
      }
    },
  },
  created() {
    this.selectedTab = this.$route.query.tabSelected
      ? this.$route.query.tabSelected
      : this.selectedTab;
  },
};
</script>
