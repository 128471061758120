<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ issueId ? 'Edit' : 'Add' }} Category:
                  <span class="text-gray-400 ml-2">{{ projectName }}</span>
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="mx-3">
            <TextInput
              :value="issueNumber"
              :name="'issue_number'"
              :type="'text'"
              :required="true"
              :label="'Category'"
              :isEdit="true"
              :aligned="'horizontal'"
              :validations="validations"
              @onChange="onChange"
            />
          </div>

          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              @click="saveCategory"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import TextInput from '@/components/inputs/TextInput.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import RegEx from '@/components/inputs/RegEx.ts';

export default {
  name: 'CategoryModal',
  components: {
    TextInput,
  },
  data() {
    return {
      issueNumber: '',
      validations: { regex: RegEx.ATLEAST_ONE_CHAR },
    };
  },
  props: {
    projectName: {
      type: String,
    },
    defaultIssueNumber: {
      type: String,
      required: false,
    },
    issueId: {
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onChange(e) {
      this.issueNumber = e.target.value;
    },
    close() {
      this.$emit('close');
    },
    updateCategory(callback) {
      const { issueId, issueNumber } = this;
      axios
        .put(`/manage/issues/${issueId}`, {
          issue_number: issueNumber.replace(/\s+/g, ' ').trim().toUpperCase(),
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(response.data.data);
        })
        .catch((error) => {
          console.error('ERR while updateCategory', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
    createCategory(callback) {
      const { projectId, issueNumber } = this;
      axios
        .post(`/manage/issues`, {
          issue_number: issueNumber.replace(/\s+/g, ' ').trim().toUpperCase(),
          project_id: projectId,
          historical: false,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(response.data.data);
        })
        .catch((error) => {
          console.error('ERR while createCategory', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Category has been already taken',
            alertType: 'error',
          });
          callback();
        });
    },
    saveCategory() {
      const { issueNumber, issueId } = this;
      if (issueNumber) {
        if (this.validations.regex.test(issueNumber)) {
          if (issueId) {
            this.updateCategory((data) => {
              if (data) {
                this.$emit('success', data, true);
              }
            });
          } else {
            this.createCategory((data) => {
              if (data) {
                this.$emit('success', data, false);
              }
            });
          }
        } else {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Please enter valid category.',
            alertType: 'error',
          });
        }
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: "Category can't be empty.",
          alertType: 'error',
        });
      }
    },
  },
  created() {
    this.issueNumber = this.defaultIssueNumber;
  },
};
</script>
