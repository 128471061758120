export default {
  template: `
    <div class="text-sm text-left" v-if="tabName && tabName=='users'">
        <span v-if="cellValue && cellValue.first_name">
            <p v-if="cellValue.first_name.to">
            {{ cellValue.first_name.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.last_name">
            <p v-if="cellValue.last_name.to">
            {{ cellValue.last_name.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.email">
            <p v-if="cellValue.email.to">
            {{ cellValue.email.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.practice">
            <p v-if="cellValue.practice.to">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'practices' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.practice.to }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.emp_type_name">
            <p v-if="cellValue.emp_type_name.to">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'emp_types' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.emp_type_name.to }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.team_name">
            <p v-if="cellValue.team_name.to">
            {{ cellValue.team_name.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.department_name">
            <p v-if="cellValue.department_name.to">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'departments' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.department_name.to }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.reporting_manager_name">
            <p v-if="cellValue.reporting_manager_name.to">
                <router-link
                    :to="'/manage/users/'+cellValue.reporting_manager_id.to"
                    class="text-blue-500"
                >
                    {{ cellValue.reporting_manager_name.to }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.status">
            <p v-if="cellValue.status.to != null">
            {{ cellValue.status.to ? 'Active' : 'Inactive' }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.employee_id">
            <p v-if="cellValue.employee_id.to">
            {{ cellValue.employee_id.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.joining_date">
            <p v-if="cellValue.joining_date.to">
            {{ globalDateFormat(cellValue.joining_date.to) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.dob">
            <p v-if="cellValue.dob.to">
            {{ globalDateFormat(cellValue.dob.to) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.gender">
            <p v-if="cellValue.gender.to">
            {{ cellValue.gender.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.experience">
            <p v-if="cellValue.experience.to">
            {{ globalDateFormat(cellValue.experience.to) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.blood_group">
            <p v-if="cellValue.blood_group.to">
            {{ cellValue.blood_group.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.mobile_number">
            <p v-if="cellValue.mobile_number.to">
            {{ cellValue.mobile_number.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.designation">
            <p v-if="cellValue.designation.to">
            {{ cellValue.designation.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.external_id">
            <p v-if="cellValue.external_id.to">
            {{ cellValue.external_id.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.location">
            <p v-if="cellValue.location.to">
            {{ cellValue.location.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.is_config">
            <p v-if="cellValue.is_config.to != null">
            {{ cellValue.is_config.to ? 'Yes' : 'No' }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.comments">
            <p v-if="cellValue.comments.to">
            {{ cellValue.comments.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.exit_date">
            <p v-if="cellValue.exit_date.to">
            {{ globalDateFormat(cellValue.exit_date.to) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.performance">
            <p v-if="cellValue.performance.to">
            {{ cellValue.performance.to }}
            </p>
            <p v-else>-</p>
        </span>
    </div>
    <div class="text-sm text-left" v-else-if="tabName && tabName=='projects'">
        <span v-if="cellValue && cellValue.name">
            <p v-if="cellValue.name.to">
            {{ cellValue.name.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.practice">
            <p v-if="cellValue.practice.to">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'practices' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.practice.to }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.client_name">
            <p v-if="cellValue.client_name.to">
                <router-link
                    :to="{
                    path: '/manage/configuration',
                    query: { tabSelected: 'clients' },
                    }"
                    class="text-blue-500"
                >
                    {{ cellValue.client_name.to }}
                </router-link>
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.primary_product">
            <p v-if="cellValue.primary_product.to">
            {{ cellValue.primary_product.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.delivery_method">
            <p v-if="cellValue.delivery_method.to">
            {{ cellValue.delivery_method.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.start_date">
            <p v-if="cellValue.start_date.to">
            {{ globalDateFormat(cellValue.start_date.to) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.end_date">
            <p v-if="cellValue.end_date.to">
            {{ globalDateFormat(cellValue.end_date.to) }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.project_status">
            <p v-if="cellValue.project_status.to != null">
            {{ cellValue.project_status.to ? 'Active' : 'Inactive' }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.code">
            <p v-if="cellValue.code.to">
            {{ cellValue.code.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.description">
            <p v-if="cellValue.description.to">
            {{ cellValue.description.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.total_budget">
            <p v-if="cellValue.total_budget.to">
            {{ cellValue.total_budget.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.sow">
            <p v-if="cellValue.sow.to">
            {{ cellValue.sow.to }}
            </p>
            <p v-else>-</p>
        </span>
        <span v-if="cellValue && cellValue.default">
            <p v-if="cellValue.default.to">
            {{ cellValue.default.to ? 'Yes' : 'No' }}
            </p>
            <p v-else>-</p>
        </span>
    </div>
    <div v-else-if="tabName && tabName=='project-user'">
        <div v-if="cellValue && cellValue.user_type=='manager'">-</div>
        <div class="text-sm text-left" v-else>
            <span v-if="cellValue && cellValue.billability">
                <p v-if="cellValue.billability.to">
                {{ cellValue.billability.to }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.start_user_date">
                <p v-if="cellValue.start_user_date && cellValue.start_user_date.to">
                    {{ globalDateFormat(cellValue.start_user_date.to) }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.end_user_date">
                <p v-if="cellValue.end_user_date && cellValue.end_user_date.to">
                    {{ globalDateFormat(cellValue.end_user_date.to) }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.allocation">
                <p v-if="cellValue.allocation && cellValue.allocation.to">
                    {{ cellValue.allocation.to }}
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.project_user_status">
                <p v-if="cellValue.project_user_status != null">
                <span :class="cellValue.project_user_status.to">
                {{ cellValue.project_user_status.to ? 'Active' : 'Inactive' }}
                </span>
                </p>
                <p v-else>-</p>
            </span>
            <span v-if="cellValue && cellValue.hourly_rate">
                <p v-if="cellValue.hourly_rate.to">
                {{ cellValue.hourly_rate.to }}
                </p>
                <p v-else>-</p>
            </span>
        </div>
    </div>
      `,
  data: function () {
    return {
      cellValue: null,
      tabName: null,
    };
  },
  methods: {},
  beforeMount() {
    if (this.params.data) {
      this.cellValue = JSON.parse(this.params.data.data);
      this.tabName = this.params.tabName;
    }
  },
};
