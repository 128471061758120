<template>
  <div class="flex w-full items-center justify-center bg-grey-lighter">
    <label
      class="w-56 flex flex-col items-center px-2 py-1 bg-green-500 text-white rounded-lg tracking-wide border border-blue cursor-pointer hover:bg-green-400 hover:text-white"
    >
      <div class="flex flex-row items-center justify-between">
        <span class="material-icons m-auto text-xl">cloud_upload</span>
        <span class="text-sm leading-normal ml-3">{{ label }}</span>
      </div>
      <input
        type="file"
        class="hidden"
        @change="readFile"
        :accept="fileType"
        ref="imageUploader"
      />
    </label>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'UploadFileButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      default: '.csv',
    },
  },
  // setup() {
  //   const imageUploader = ref(null);
  // },
  methods: {
    readFile(event) {
      var file = event.target.files[0];
      this.$refs.imageUploader.value = '';
      this.$papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.$emit('onParsed', results.data);
        },
      });
    },
  },
});
</script>
