<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        Timesheet Log Status Report
      </div>

      <div class="flex flex-row items-center">
        <label class="text-xs text-gray-600 font-thin hidden sm:block"></label>
        <div v-if="isReadPermission" class="hidden sm:block">
          <div class="flex md:ml-3 mr-1">
            <WeekRangeInput
              ref="timesheetLogDateRangePicker"
              @updateDateRange="setFilterByDateRange"
              :startDate="filterBy.dateRange && filterBy.dateRange.startDate"
              :endDate="filterBy.dateRange && filterBy.dateRange.endDate"
            />
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <!-- Top Bar END-->
    <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
      <CustomFilter
        v-if="showFilter"
        :filterBy="filterBy"
        :projectPractices="projectPractices"
        :userPractices="userPractices"
        @resetFilter="resetFilter"
        @setFilterByProjectStatus="setFilterByProjectStatus"
        @setFilterByProjectPractices="setFilterByProjectPractices"
        @setFilterByUserPractices="setFilterByUserPractices"
        @setFilterByLocation="setFilterByLocation"
        @toggleFilter="toggleFilter"
        @setFilterByProjects="setFilterByProjects"
        :projectOptions="projectOptions"
        :project_status="project_status"
      />
      <!-- <Loading v-if="isLoading" class="h-full" /> -->
      <div>
        <div class="flex py-4 px-4 justify-between items-center">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :excelStatusLog="true"
            @exportToCSV="exportToCSV"
            @exportToExcelStatus="exportToExcelStatus"
          />
          <div v-else></div>
          <!-- Icons END -->
          <span
            v-if="isReadPermission"
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleFilter"
            >filter_alt</span
          >
        </div>
        <!-- Table -->
        <Loading v-if="isLoading" style="height: 68vh" />

        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            v-if="reports.length > 0 && isReadPermission"
            class="flex-grow overflow-auto"
          >
            <table
              id="users_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="colIndex + column"
                    rowspan="2"
                    v-for="(column, colIndex) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="reportIndex"
                  :class="reportIndex % 2 === 1 ? 'bg-gray-100' : ''"
                  v-for="(report, reportIndex) in reports"
                  class="py-2"
                  style="height: 30px"
                >
                  <td
                    class="px-4 py-2 text-xs"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>
                      <span
                        v-if="
                          report[0] == 'Complete:' || report[0] == 'Timely:'
                        "
                        class="font-bold"
                        >{{ report[0] }}</span
                      ><span v-else>{{ report[0] }}</span>
                    </p>
                  </td>
                  <td
                    class="px-4 py-2 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ report[1] }}</p>
                  </td>
                  <td
                    class="px-4 py-2 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ report[2] }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
    </div>

    <GenerateExcelModal
      v-if="isStatusLogModalVisible && isExportPermission"
      :payload="filterData"
      @close="closStatusLogModal"
    >
    </GenerateExcelModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import WeekRangeInput from '@/components/inputs/WeekRangeInput.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import GenerateExcelModal from '@/components/reports/TimesheetLogStatusReport/GenerateExcelModal.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'TimesheetLogStatusReport',
  components: {
    Loading,
    WeekRangeInput,
    CustomFilter,
    ExportButtons,
    GenerateExcelModal,
    NotAuthorized,
    NoRecordFound,
  },
  data() {
    const startDate = moment()
      .subtract(1, 'week')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    const endDate = moment()
      .subtract(1, 'week')
      .endOf('week')
      .format('YYYY-MM-DD');
    return {
      showFilter: false,
      totalHours: 0,
      locations: [
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'USA', value: 'USA' },
        { label: 'Mexico', value: 'Mexico' },
      ],
      projectPractices: [],
      projectOptions: [],
      projects: [],
      project_status: { label: 'Active', value: 'true' },
      filterBy: {
        projectPractices: [],
        projects: [],
        userPractices: [],
        status: null,
        location: [],
        project_status: { label: 'Active', value: 'true' },
        dateRange: { startDate, endDate },
      },
      query: '',
      columns: ['Description', 'No Of Users', 'Percentage(%)'],
      reports: [],
      pageSize: 30,
      isLoading: true,
      defaultFilterBy: {},
      isStatusLogModalVisible: false,
      filterData: {},
      isReadPermission: false,
      isExportPermission: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchProjectOptions = _debounce(this.fetchProjectOptions, 400);
    this.isReadPermission = this.validatePermission([
      'reports.timesheet_log_status.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.timesheet_log_status.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchProjectPractices();
      this.fetchUserPractices();
      this.fetchLogStatusReport(1);
      this.fetchProjectOptions(1);
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    resetFilter() {
      const { defaultDateRange } = this.$refs.timesheetLogDateRangePicker;
      this.$refs.timesheetLogDateRangePicker.dateRange = defaultDateRange;
      this.filterBy = { ...this.defaultFilterBy };
      this.setFilterByDateRange(defaultDateRange);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.projectOptions = [];
      this.fetchProjectOptions(1);
      this.fetchLogStatusReport(1);
    },
    setFilterByUserPractices(option) {
      this.filterBy.userPractices = option;
      this.fetchLogStatusReport(1);
    },

    setFilterByProjectStatus(option) {
      this.filterBy.project_status = option;
      this.projectOptions = [];
      this.fetchProjectOptions(1);
      this.fetchLogStatusReport(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchLogStatusReport(1);
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.startDate = startDate;
      this.endDate = endDate;
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchLogStatusReport(1);
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchLogStatusReport(1);
    },
    fetchUserPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          const practices = response.data.filter(
            (item) => item.practice_name != 'Operations'
          );
          this.userPractices = practices.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUserPractices', error);
        });
    },
    fetchProjectPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getPayload() {
      const { filterBy } = this;
      const payload = {};
      if (filterBy.location.length > 0) {
        payload.locations = filterBy.location;
      }
      if (filterBy.userPractices.length > 0) {
        payload.userPractices = filterBy.userPractices;
      }
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (filterBy.project_status) {
        payload.project_status = filterBy.project_status.value;
      }
      if (filterBy.dateRange) {
        payload.date_range = JSON.stringify(filterBy.dateRange);
      }
      return payload;
    },
    fetchLogStatusReport(_page) {
      this.isLoading = true;
      const payload = this.getPayload();
      axios
        .get(`/reports/timesheet_log_status`, {
          params: {
            location: payload.locations,
            projects: payload.projects,
            project_status: payload.project_status,
            project_practices: payload.practice,
            user_practices: payload.userPractices,
            date_range: payload.date_range,
          },
        })
        .then((response) => {
          this.reports = response.data.report_data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    fetchProjectOptions(page, isSearch = false, _query = null) {
      const payload = this.getPayload();
      axios
        .get(`/manage/projects/get_projects_statusLog`, {
          params: {
            projects: payload.projects,
            project_status: payload.project_status,
            practice: payload.practice,
          },
        })
        .then((response) => {
          this.projectOptionMeta = response.data.meta;
          const projectOptions = response.data.projects.map((project) => {
            return { label: project.name, value: project.id };
          });
          this.projectOptions = isSearch
            ? projectOptions
            : this.projectOptions.concat(projectOptions);
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    exportToCSV() {
      const payload = this.getPayload();
      axios
        .get(`/reports/download_timesheet_log_status_csv.csv`, {
          params: {
            location: payload.locations,
            projects: payload.projects,
            project_status: payload.project_status,
            project_practices: payload.practice,
            user_practices: payload.userPractices,
            date_range: payload.date_range,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Timesheet Log Status Report ${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    exportToExcelStatus() {
      const payload = this.getPayload();
      this.filterData = payload;
      this.isStatusLogModalVisible = true;
    },
    closStatusLogModal() {
      this.isStatusLogModalVisible = false;
    },
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
