












































import Vue from 'vue';
export default Vue.extend({
  name: 'SwitchInputWithLabel',
  data() {
    return {
      isValid: true,
      activeClass: 'focus:ring-gray-500 focus:border-gray-900 border-gray-300',
    };
  },
  props: [
    'label',
    'required',
    'name',
    'switchLabels',
    'value',
    'isEdit',
    'aligned',
    'disabled',
  ],
  methods: {
    validate(data: any) {
      let isValid = true;
      if (this.required && !data) {
        isValid = false;
      }
      return isValid;
    },
    onChange() {
      // const focusedClass =
      //   "focus:ring-gray-500 focus:border-gray-900 border-gray-300";
      // const errorClass =
      //   "focus:ring-red-500 focus:border-red-600  border-red-600";
      // this.activeClass = this.validate(e.target.value) ? "" : "switch_error";
      if (!this.disabled) {
        this.$emit('onChange', {
          target: { value: !this.value, name: this.name },
        });
      }
    },
  },
  created() {
    // this.activeClass += this.required && !this.value ? " switch_error" : "";
  },
});
