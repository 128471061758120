<template>
  <transition name="modal">
    <div
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative content-center">
        <div
          class="min-h-screen min-w-screen m-2 flex items-center justify-center"
        >
          <div class="flex flex-col shadow-xl">
            <div class="flex flex-col py-6 px-6 space-y-5 bg-white">
              <div>
                <input
                  type="radio"
                  id="hours"
                  value="hours"
                  style="margin-left: 20px"
                  v-model="picked"
                  checked
                />
                <label
                  class="text-sm text-gray-700 mt-4"
                  style="padding-left: 5px"
                  >Download users who have not submitted before Monday 5 pm
                  PDT</label
                ><br />
                <input
                  type="radio"
                  id="pending"
                  value="pending"
                  style="margin-left: 20px"
                  v-model="picked"
                />
                <label
                  class="text-sm text-gray-700 mt-4"
                  style="padding-left: 5px"
                  >Download users whose total hours less than 40hrs</label
                >
              </div>
              <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3">
                <button
                  type="button"
                  @click="GenerateExcel"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Generate
                </button>
                <button
                  type="button"
                  @click="close"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

export default {
  name: 'GenerateExcelModal',
  props: ['payload'],
  data() {
    return {
      picked: 'pending',
    };
  },
  methods: {
    GenerateExcel() {
      const payload = this.payload;
      axios
        .get(`/reports/timesheet_log_status_excel`, {
          params: {
            selected: this.picked,
            location: payload.locations,
            projects: payload.projects,
            project_status: payload.project_status,
            project_practices: payload.practice,
            user_practices: payload.userPractices,
            date_range: payload.date_range,
            admin_user: payload.adminUser,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Timesheet_Log_Status_Pending_User_Report.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.$emit('close');
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
