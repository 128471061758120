




























import Vue from 'vue';
import RequirementPieChart from '../requirementList/RequirementPieChart.vue';

export default Vue.extend({
  name: 'OfferedCandiateChart',
  components: {
    RequirementPieChart,
  },
  data() {
    return {
      isLoading: true,
      backgroundColors: {
        Joined: '#8dcc85',
        'Offered and yet to join': '#e6ef59',
        'Declined offer or not joined': '#F56C6B',
      } as any,
      pieData: {
        labels: [],
        datasets: [
          {
            label: 'Profiles',
            backgroundColor: [],
            data: [],
          },
        ],
      } as any,
      chartOptions: {
        hoverBorderWidth: 20,
      },
    };
  },
  props: ['chartData', 'totalProfiles'],
  watch: {
    chartData: function (newVal, _oldVal) {
      this.pieChart(newVal);
    },
  },
  created() {
    this.pieChart(this.chartData);
  },
  methods: {
    pieChart(_chartData: any) {
      const data = {};
      for (var key of Object.keys(this.backgroundColors)) {
        (data as any)[key] = this.chartData[key] || 0;
      }
      this.pieData.labels = Object.keys(data);
      this.pieData.datasets[0].data = Object.values(data);
      this.pieData.datasets[0].backgroundColor = Object.keys(data).map(
        (data: any) => this.backgroundColors[data]
      );
      this.isLoading = false;
    },
  },
});
