<template>
  <div class="bg-gray-200 w-96">
    <Loading v-if="isLoading" />

    <div v-else>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Projects
        </div>

        <div v-if="isReadPermission" class="hidden sm:block">
          <div class="flex md:ml-3 mr-4">
            <DateRangePickerInput
              ref="projectDateRangePicker"
              @updateDateRange="setFilterByDateRange"
              popupAlign="center"
              :startDate="filterBy.dateRange && filterBy.dateRange.startDate"
              :endDate="filterBy.dateRange && filterBy.dateRange.endDate"
            />
          </div>
        </div>

        <div
          v-if="isManagePermission"
          class="flex flex-row items-center justify-between"
        >
          <!-- Add Project -->
          <div class="hidden sm:block">
            <div class="lg:ml-6 flex items-center">
              <router-link :to="`/manage/projects/form/create`">
                <button
                  class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 flex items-center text-sm"
                >
                  <span class="material-icons m-auto mr-2">add</span>

                  <span class="text-sm">Add Project</span>
                </button>
              </router-link>
            </div>
          </div>
          <!-- Add Project END -->

          <div>
            <!-- Add Project -->
            <div class="block md:hidden ml-2">
              <div class="lg:ml-6 flex items-center">
                <router-link :to="`/manage/projects/form/create`">
                  <button
                    class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-8 flex items-center text-sm"
                  >
                    <span class="material-icons md:text-2xl text-base md:mr-2"
                      >add</span
                    >

                    <span class="md:text-sm text-xs">Add Project</span>
                  </button>
                </router-link>
              </div>
            </div>
            <!-- Add Project END -->
          </div>
        </div>
      </div>
      <!-- Top Bar END-->
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2"
      >
        <CustomFilter
          v-if="showProjectFilter"
          :filterBy="filterBy"
          :projectPractices="projectPractices"
          :projectOptionMeta="projectOptionMeta"
          :projectOptions="projectOptions"
          :projectStatuses="projectStatuses"
          @resetFilter="resetFilter"
          @searchQuery="searchQuery"
          @loadMore="loadMore"
          @setFilterByProjectStatus="setFilterByProjectStatus"
          @setFilterByProjectPractices="setFilterByProjectPractices"
          @setFilterByProjects="setFilterByProjects"
          @setFilterByDateRange="setFilterByDateRange"
          @toggleFilter="toggleFilter"
          :project_status="project_status"
        />

        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div v-if="isReadPermission" class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-6"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <!-- Table -->
        <Loading v-if="isTableLoading" style="height: 68vh" />
        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && projects.length > 0"
          >
            <table
              id="projects_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr class="text-left">
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(project, index) in projects"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td class="px-4 text-xs py-2">
                    <router-link :to="`/manage/projects/${project.id}`">
                      <p
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                      >
                        {{ project.name }}
                      </p>
                    </router-link>
                  </td>
                  <td class="px-4">
                    <p class>
                      {{
                        (project.start_date &&
                          globalDateFormat(project.start_date)) ||
                        ''
                      }}
                    </p>
                  </td>
                  <td class="px-4">
                    <p class>
                      {{
                        (project.end_date &&
                          globalDateFormat(project.end_date)) ||
                        ''
                      }}
                    </p>
                  </td>
                  <td class="px-4">
                    <p class>{{ project.practice }}</p>
                  </td>
                  <td class="px-4">
                    <span
                      v-if="project.pending_status == true"
                      class="text-yellow-800 bg-yellow-200 font-semibold px-2 py-0.5 rounded-full"
                      >Pending</span
                    >
                    <span
                      v-if="
                        project.pending_status != true &&
                        project.project_status != true
                      "
                      class="text-red-800 bg-red-200 font-semibold px-2 py-0.5 rounded-full"
                      >Inactive</span
                    >
                    <span
                      v-if="
                        project.pending_status != true &&
                        project.project_status == true
                      "
                      class="text-green-800 bg-green-200 font-semibold px-2 py-0.5 rounded-full"
                      >Active</span
                    >
                  </td>
                  <td class="px-4 whitespace-normal">
                    <span
                      :key="manegerIndex"
                      v-for="(manager, manegerIndex) in project.project_managers
                        .length > 3
                        ? project.project_managers.slice(0, 3)
                        : project.project_managers"
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 last:mr-0 mr-1"
                    >
                      <router-link
                        class="text-blue-500"
                        v-if="isReadUserPermission"
                        :to="`/manage/users/${manager.id}`"
                      >
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          {{ manager.name }}
                        </p>
                      </router-link>
                      <span v-else>{{ manager.name }},</span>
                    </span>
                    <span
                      v-if="project.project_managers.length > 3"
                      class="text-xs"
                      >...</span
                    >
                  </td>
                  <!-- <td class="px-4 whitespace-normal py-2">
                    <span
                      :key="projectUserIndex"
                      v-for="(projectUser, projectUserIndex) in project
                        .project_users.length > 3
                        ? project.project_users.slice(0, 3)
                        : project.project_users"
                      :content="getProjectMembers(project.project_users)"
                      v-tippy="{
                        maxWidth: 'fit-content',
                        interactive: true,
                        interactiveDebounce: 0,
                        followCursor: true,
                        flip: true,
                        flipBehavior: ['bottom'],
                        boundary: 'viewport',
                      }"
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 last:mr-0 mr-1"
                    >
                      <router-link
                        class="text-blue-500"
                        v-if="isReadUserPermission"
                        :to="`/manage/users/${projectUser.id}`"
                      >
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          {{ projectUser.name }}
                        </p>
                      </router-link>
                      <span v-else>{{ projectUser.name }},</span>
                    </span>
                    <span
                      v-if="project.project_users.length > 3"
                      class="text-xs"
                      >...</span
                    >
                  </td> -->
                  <td class="px-4">
                    <p class="text-wrap whitespace-normal">
                      {{ project.sow }}
                    </p>
                  </td>
                  <td class="px-4">
                    <p class="text-wrap whitespace-normal">
                      {{ project.primary_product }}
                    </p>
                  </td>
                  <td class="px-4">
                    <p class="text-wrap whitespace-normal">
                      {{ project.delivery_method }}
                    </p>
                  </td>
                  <td class="px-4">
                    <p class="text-wrap whitespace-normal">
                      {{ project.client }}
                    </p>
                  </td>
                  <td class="px-4">
                    <p class="text-wrap whitespace-normal" style="width: 250px">
                      {{ project.description }}
                    </p>
                  </td>
                  <td class="px-4">
                    <p class>{{ project.code }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
      <!-- Pagination -->
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchProjects"
        @setPageSize="setPageSize"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'Projects',
  components: {
    Pagination,
    Loading,
    DateRangePickerInput,
    CustomFilter,
    NoRecordFound,
    ExportButtons,
    NotAuthorized,
  },
  data() {
    let startDate = 'null';
    let endDate = 'null';
    return {
      showProjectFilter: false,
      projectPractices: [],
      projectStatuses: [
        { label: 'All', value: 'all' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
        { label: 'Pending', value: 'pending' },
      ],
      project_status: { label: 'Active', value: 'active' },
      filterBy: {
        projectPractices: [],
        project_status: { label: 'Active', value: 'active' },
        projects: [],
        dateRange: { startDate, endDate },
      },
      query: '',
      columns: [
        'Name',
        'Start Date',
        'End Date',
        'Project Practice',
        'Status',
        'Project Manager',
        /* 'Project Members', */
        'SOW',
        'Primary Product',
        'Delivery Method',
        'Client',
        'Description',
        'Code',
      ],
      projects: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      isTableLoading: true,
      projectOptions: [],
      projectOptionMeta: {},
      defaultFilterBy: { startDate: 'null', endDate: 'null' },
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      const { defaultDateRange } = this.$refs.projectDateRangePicker;
      this.$refs.projectDateRangePicker.dateRange = defaultDateRange;
      this.setFilterByDateRange(defaultDateRange);
    },
    toggleFilter() {
      this.showProjectFilter = !this.showProjectFilter;
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Projects: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#projects_table',
      });
      doc.save(`Projects_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.projectOptions = [];
      this.fetchProjects(1);
      this.fetchProjectOptions(1);
    },
    setFilterByProjectStatus(option) {
      this.filterBy.project_status = option;
      this.projectOptions = [];
      this.fetchProjects(1);
      this.fetchProjectOptions(1);
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchProjects(1);
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchProjects(1);
    },
    loadMore(page) {
      this.fetchProjectOptions(page);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchProjectOptions(1, true, query);
      }
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchProjects(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchProjects(1);
    },
    fetchProjectPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getProjectMembers(members) {
      const memeberNames =
        (members && members.map((member) => member.name)) || [];
      return memeberNames.join(', ');
    },
    getProjectsPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      if (filterBy.project_status) {
        payload.project_status = filterBy.project_status.value;
      }
      if (filterBy.dateRange) {
        payload.date_range = filterBy.dateRange;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchProjects(page) {
      this.nonce = Date.now().toString();
      this.isTableLoading = true;
      const { pageSize } = this;
      const payload = this.getProjectsPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/projects/all?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, meta, projects } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.projects = projects;
          this.isLoading = false;
          this.isTableLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.projects = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    fetchProjectOptions(page, _isSearch = false, _query = null) {
      const payload = this.getProjectsPayload();
      axios
        .get(`/manage/projects/get_projects`, {
          params: {
            projects: payload.projects,
            project_status: payload.project_status,
            practice: payload.practice,
          },
        })
        .then((response) => {
          const projectOptions = response.data.projects.map((project) => {
            return { label: project.name, value: project.id };
          });
          this.projectOptions = projectOptions;
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    exportToCSV() {
      const payload = this.getProjectsPayload();
      axios
        .post(`/manage/projects/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Projects_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while exportToCSV', error);
        });
    },
  },
  computed: {
    ...mapGetters('user', ['userId', 'isReadUserPermission']),
  },
  created() {
    this.fetchProjects = _debounce(this.fetchProjects, 400);
    this.isReadPermission = this.validatePermission(['manage.projects.read']);
    this.isManagePermission = this.validatePermission([
      'manage.projects.manage',
    ]);
    this.isExportPermission = this.validatePermission([
      'manage.projects.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchProjectPractices();
      this.fetchProjects(1);
      this.fetchProjectOptions(1);
    } else {
      this.isLoading = false;
      this.isTableLoading = false;
    }
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
