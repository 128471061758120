<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <Loading v-if="isLoading" class="mt-2" style="height: 32rem" />
    <div v-else class="pb-10">
      <!-- Top Bar -->
      <!-- Top Bar END-->
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
      >
        <CustomFilter
          v-if="showProjectAssignmentFilter"
          :filterBy="filterBy"
          :projectPractices="projectPractices"
          @resetFilter="resetFilter"
          @setFilterByAdmin="setFilterByAdmin"
          @setFilterByProjectPractices="setFilterByProjectPractices"
          @setFilterByLocation="setFilterByLocation"
          @toggleFilter="toggleFilter"
          :adminUser="adminUser"
        />

        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div v-if="isReadPermission" class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-3"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <Loading v-if="isTableLoading" style="height: 28rem" />
        <!-- Table -->

        <div v-else class="flex flex-col" style="height: 28rem">
          <div v-if="isReadPermission" class="flex-grow overflow-auto">
            <table
              id="departments_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr class="bg-gray-400 text-gray-100">
                  <th
                    class="text-left font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    Department/Roles
                  </th>
                  <th
                    id="emps"
                    class="font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    # of Emps
                  </th>
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="index"
                    :id="column"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(department, index) in departments"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : 'bg-white'"
                >
                  <td
                    :id="department.name"
                    class="px-4 py-2 text-xs font-semibold"
                  >
                    <p class>{{ department.name }}</p>
                  </td>
                  <td
                    class="text-center cursor-pointer hover:shadow-lg selected-cell"
                    @mouseover="() => mouseOver(department.name, 'emps')"
                    @mouseleave="() => mouseLeave(department.name, 'emps')"
                  >
                    <div
                      @click="
                        () =>
                          openUserDetailModal(
                            department.users,
                            '',
                            department.name,
                            false
                          )
                      "
                    >
                      {{ (department.users && department.users.length) || '' }}
                    </div>
                  </td>
                  <td
                    @mouseover="() => mouseOver(department.name, columnName)"
                    @mouseleave="() => mouseLeave(department.name, columnName)"
                    @click="
                      () =>
                        openUserDetailModal(
                          department.projectMap &&
                            department.projectMap[columnName]
                            ? department.projectMap[columnName].users
                            : [],
                          columnName,
                          department.name,
                          true
                        )
                    "
                    class="px-4 text-center cursor-pointer hover:shadow-lg selected-cell"
                    v-for="(columnName, columnIndex) in columns"
                    :key="columnIndex"
                  >
                    <div
                      v-if="
                        department.projectMap &&
                        department.projectMap[columnName]
                      "
                    >
                      {{
                        sumOfUsersUtilization(
                          department.projectMap[columnName].users
                        ) || ''
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-if="!isTableLoading" class="font-bold text-sm">
                  <td class="px-4 py-2 text-sm">TOTAL</td>
                  <td
                    @click="
                      () =>
                        openUserDetailModal(
                          allDepartmentUsers,
                          '',
                          'All Users',
                          false
                        )
                    "
                    class="text-center cursor-pointer"
                  >
                    {{ allDepartmentUsers.length || '' }}
                  </td>
                  <td
                    class="text-center cursor-pointer hover:shadow-lg"
                    @click="
                      () =>
                        openUserDetailModal(
                          departmentsByProject[columnName],
                          columnName,
                          '',
                          true
                        )
                    "
                    :key="columnIndex"
                    v-for="(columnName, columnIndex) in columns"
                  >
                    {{
                      departmentsByProject[columnName]
                        ? sumOfUsersUtilization(
                            departmentsByProject[columnName]
                          )
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else />
        </div>
        <!-- Table END -->
      </div>
      <div>
        <UserDetailModal
          v-if="isUserDetailModalVisible"
          :modalData="modalData"
          :isProjectSelected="isProjectSelected"
          @success="onSuccess"
          @close="closeUserDetailModal"
        ></UserDetailModal>
        <!-- <UserDetailModal /> -->
        <!-- <Pagination
          class="mt-2"
          v-if="Object.keys(paginationMeta).length > 0"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchDepartments"
          @setPageSize="setPageSize"
        />-->
      </div>
      <!-- Pagination -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import UserDetailModal from '@/components/pmo/project_assignment/UserDetailModal.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'ProjectTab',
  components: {
    Loading,
    CustomFilter,
    UserDetailModal,
    ExportButtons,
    NotAuthorized,
  },
  data() {
    return {
      isUserDetailModalVisible: false,
      isProjectSelected: true,
      modalData: {},
      isTableLoading: false,
      locations: [
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'USA', value: 'USA' },
        { label: 'Mexico', value: 'Mexico' },
      ],
      projectPractices: [],
      adminUser: 'No',
      filterBy: {
        projectPractices: [],
        adminUser: false,
        location: [],
      },
      query: '',
      columns: [
        'Emp ID',
        'Name',
        'Designation',
        'Reporting Manager',
        'Emp Type',
        'Status',
        'Practice',
        'Department',
        'Location',
        'Ext ID',
        'Exp',
      ],
      departments: [],
      departmentsByProject: {},
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      showProjectAssignmentFilter: false,
      allDepartmentUsers: [],
      defaultFilterBy: {},
      isReadPermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchDepartments(1);
    },
    mouseLeave(departmentName, projectName) {
      let departmentElement = document.getElementById(departmentName);
      departmentElement.style['color'] = 'inherit';
      departmentElement.style['background-color'] = 'inherit';
      // departmentElement.style["font-size"] = "inherit";
      let projectElement = document.getElementById(projectName);
      projectElement.style['color'] = 'inherit';
      projectElement.style['background-color'] = 'inherit';
      // projectElement.style["font-size"] = "inherit";
    },
    mouseOver(departmentName, projectName) {
      let departmentElement = document.getElementById(departmentName);
      departmentElement.style['color'] = 'white';
      departmentElement.style['background-color'] = 'deepskyblue';
      // departmentElement.style["font-size"] = "14px";
      let projectElement = document.getElementById(projectName);
      projectElement.style['color'] = 'white';
      projectElement.style['background-color'] = 'deepskyblue';
      // projectElement.style["font-size"] = "14px";
    },
    openUserDetailModal(users, projectName, departmentName, isProjectSelected) {
      if (users.length > 0) {
        this.modalData = { users, projectName, departmentName };
        this.isUserDetailModalVisible = true;
        this.isProjectSelected = isProjectSelected;
      }
    },
    closeUserDetailModal() {
      this.isUserDetailModalVisible = false;
    },
    onSuccess() {
      this.closeUserDetailModal();
    },
    toggleFilter() {
      this.showProjectAssignmentFilter = !this.showProjectAssignmentFilter;
    },
    printTable() {
      const newWindow = window.open('');
      newWindow.document.write(
        document.getElementById('departments_table').outerHTML
      );
      newWindow.print();
      newWindow.close();
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Project Assignment: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#departments_table',
      });
      doc.save(`Project_Assignment_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.fetchDepartments(1);
    },
    setFilterByAdmin(e) {
      this.filterBy.adminUser = e.target.value;
      this.fetchDepartments(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchDepartments(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchDepartments(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchDepartments(1);
    },
    fetchFilterOptions() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchFilterOptions', error);
        });
    },
    getDepartmentsPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.location.length > 0) {
        payload.location = filterBy.location;
      }
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      payload.admin = filterBy.adminUser;
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchDepartments(page) {
      this.nonce = Date.now().toString();
      this.isTableLoading = true;
      this.departmentsByProject = {};
      const { pageSize } = this;
      const payload = this.getDepartmentsPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/departments/department_projects?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce } = response.data;
          if (nonce !== this.nonce) return;
          let allDepartmentUsers = [];
          response.data.departments.forEach((department) => {
            let allUsers = {};
            department.projects.forEach((project) => {
              // for unique users
              project.users.forEach((user) => {
                allUsers[user.id] = user;
              });
              // allUsers = allUsers.concat(project.users);
              if (!department['projectMap']) {
                department['projectMap'] = {};
              }
              // to store total users by project
              department['projectMap'][project.name] = project;
              if (!this.departmentsByProject.hasOwnProperty(project.name)) {
                this.departmentsByProject[project.name] = [];
              }
              this.departmentsByProject[project.name] =
                this.departmentsByProject[project.name].concat(project.users);
            });
            allDepartmentUsers = allDepartmentUsers.concat(department.users);
          });
          this.allDepartmentUsers = allDepartmentUsers;
          this.columns = response.data.projects;
          // this.paginationMeta = response.data.meta;
          this.departments = response.data.departments;
          this.isLoading = false;
          this.isTableLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.isTableLoading = false;
          this.departments = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchDepartments', error);
        });
    },
    exportToCSV() {
      const payload = this.getDepartmentsPayload();
      axios
        .post(`/manage/departments/department_projects/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Projects_Assignment_Report_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchDepartments', error);
        });
    },
    sumOfUsersUtilization(users) {
      const { utilization } = users.reduce((a, b) => ({
        utilization: a.utilization + b.utilization,
      }));
      return utilization && utilization >= 0 ? utilization / 100 : '';
    },
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchDepartments = _debounce(this.fetchDepartments, 400);
    this.isReadPermission = this.validatePermission([
      'pmo.project_asignment.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'pmo.project_asignment.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    this.isLoading = false;
    this.isTableLoading = false;
    if (this.isReadPermission) {
      this.isLoading = true;
      this.isTableLoading = true;
      this.fetchFilterOptions();
      this.fetchDepartments(1);
    }
  },
};
</script>

<style scoped>
/* Align First row on Top */
table#departments_table thead tr:first-child th {
  position: sticky;
  top: 0;
  background-color: rgba(156, 161, 176, 255);
  color: rgba(236, 232, 235, 255);
  z-index: 1;
  border-right: 1px solid white;
}
/* Align First rows's first column on left */
/* table#departments_table thead tr:first-child th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
} */
/* Align Rest all row's first column on left */
/* table#departments_table tbody tr td:first-child {
  position: sticky;
  left: 0;
} */
/* Align Last row on bottom */
table#departments_table tbody tr:last-child td {
  position: sticky;
  bottom: 0;
  background-color: rgba(156, 161, 176, 255);
  color: rgba(236, 232, 235, 255);
  z-index: 1;
  border-right: 1px solid white;
}
/* Align Last rows's first column on left */
/* table#departments_table tbody tr:last-child td:first-child {
  position: sticky;
  left: 0;
  z-index:2;
} */

tbody tr td {
  border-right: 1px solid #e5e7eb;
}

.selected-cell:hover {
  font-size: 16px;
  background-color: deepskyblue;
  color: white;
}
</style>
