<template>
  <div
    :id="groupId + '-' + item.id"
    class="accordion-item"
    :class="{ 'is-active': item.active }"
  >
    <dt class="accordion-item-title">
      <button @click="toggle" class="accordion-item-trigger px-0">
        <h4 class="text-gray-600 ml-1">
          <span>{{ item.title }}</span>
          <span v-if="item.count >= 0" class="text-gray-400 ml-1">
            ({{ item.count }})
          </span>
        </h4>
        <span class="accordion-item-trigger-icon"></span>
      </button>
    </dt>
    <transition
      name="accordion-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <dd v-if="item.active" class="accordion-item-details">
        <div
          v-if="item.html"
          v-html="item.html"
          class="accordion-item-details-inner"
        ></div>
        <component
          v-if="item.component"
          :is="item.component"
          :props="item.props"
          @on-update="$emit('on-update')"
        ></component>
      </dd>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: ['item', 'multiple', 'groupId'],
  computed: {
    ...mapGetters('user', ['isReadUserPermission']),
  },
  methods: {
    toggle(event) {
      if (this.multiple) this.item.active = !this.item.active;
      else {
        this.$parent.$children.forEach((item) => {
          if (
            item.$el.id === event.currentTarget.parentElement.parentElement.id
          )
            item.item.active = !item.item.active;
          else item.item.active = false;
        });
      }
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },

    endTransition(el) {
      el.style.height = '';
    },
  },
});
</script>

<style scoped>
.accordion-item-trigger,
.accordion-item-details-inner {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.accordion-item-title {
  position: relative;
}
.accordion-item-title h4 {
  margin-bottom: 0;
  padding-right: 1.25rem;
}
.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
}
.accordion-item-trigger-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-right: 2px solid #363636;
  border-bottom: 2px solid #363636;
  transform: translateY(-2px) rotate(45deg);
  transition: transform 0.2s ease;
}
.is-active .accordion-item-trigger-icon {
  transform: translateY(2px) rotate(225deg);
}
.accordion-item-details {
  overflow: hidden;
  background-color: whitesmoke;
}
.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>
