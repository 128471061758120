<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900 text-center"
                  id="modal-headline"
                >
                  Name: {{ payload.userName }} <br />
                  Project Name: {{ payload.projectName }}
                  <br />
                  From: {{ globalDateFormat(payload.fromDate) }} To:
                  {{ globalDateFormat(payload.toDate) }}
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <!-- Table  -->
          <div class="overflow-auto">
            <table
              id="submit_table"
              class="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-scroll"
            >
              <thead class="bg-gray-400">
                <tr class="text-left text-gray-100">
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(log, index) in payload.logs"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                  style="height: 30px"
                >
                  <td class="px-4 text-xs">
                    <p class>{{ globalDateFormat(log.date) }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.hours }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.issue_number }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.jira_id }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.description }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LogDetailsModal',
  data() {
    return {
      payload: {
        logs: [],
        userName: null,
        projectName: null,
        fromDate: null,
        toDate: null,
      },
      columns: ['Date', 'Hours', 'Category', 'Jira#', 'Description'],
    };
  },
  props: ['logs', 'projectName', 'userName', 'fromDate', 'toDate'],
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.payload.fromDate = this.fromDate;
    this.payload.toDate = this.toDate;
    this.payload.logs = this.logs;
    this.payload.projectName = this.projectName;
    this.payload.userName = this.userName;
  },
};
</script>
