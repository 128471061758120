<template>
  <date-range-picker
    class="text-sm rounded-full"
    ref="picker"
    :opens="popupAlign || 'left'"
    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
    :minDate="null"
    :maxDate="null"
    :singleDatePicker="true"
    :timePicker="false"
    :timePicker24Hour="false"
    :showWeekNumbers="true"
    :showDropdowns="true"
    :autoApply="false"
    v-model="dateRange"
    @update="updateValues"
    :linkedCalendars="true"
    :ranges="ranges"
  >
    <template v-slot:input="picker">
      <span class="text-gray-400 rounded-full">
        {{
          (picker.startDate &&
            `${picker.startDate.getDate()} ${
              months[picker.startDate.getMonth()]
            } ${picker.startDate.getFullYear()}`)
            | weekStartDate
            | date
        }}
        {{ '-' }}
        {{
          (picker.endDate &&
            `${picker.endDate.getDate()} ${
              months[picker.endDate.getMonth()]
            } ${picker.endDate.getFullYear()}`)
            | weekEndDate
            | date
        }}
      </span>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import * as moment from 'moment';

export default {
  name: 'DateRangePickerInput',
  components: { DateRangePicker },
  data() {
    // pass startDate and endDate as "null"<string> to hide default date ranges.
    // pass custom startDate and endDate to show those as default else today and
    // - 29 days from today will choosed as endDate and startDate respectively
    let endDate =
      this.endDate === 'null'
        ? null
        : this.endDate
        ? new Date(this.endDate)
        : new Date();
    let startDate =
      this.startDate === 'null'
        ? null
        : this.startDate
        ? new Date(this.startDate)
        : new Date(moment().subtract(29, 'd').toDate());
    return {
      weekRange: {},
      dateRange: { startDate, endDate },
      defaultDateRange: { startDate, endDate },
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      ranges: {
        'Last Week': [
          moment().subtract(1, 'week').startOf('isoWeek').toDate(),
          moment().subtract(1, 'week').endOf('week').toDate(),
        ],
        'This Week': [
          moment().startOf('isoWeek').toDate(),
          moment().endOf('week').toDate(),
        ],
      },
    };
  },
  props: ['popupAlign', 'startDate', 'endDate'],
  filters: {
    dateCell(value) {
      let dt = new Date(value);
      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : '';
    },
    weekStartDate(val) {
      let start = val
        ? moment(val).startOf('isoWeek').format('D MMM YYYY')
        : moment().startOf('isoWeek').format('D MMM YYYY');
      return start ? start.toLocaleString() : '';
    },
    weekEndDate(val) {
      let end = val
        ? moment(val).endOf('week').format('D MMM YYYY')
        : moment().endOf('week').format('D MMM YYYY');
      return end ? end.toLocaleString() : '';
    },
  },
  methods: {
    updateValues() {
      let startDate = moment(this.dateRange.startDate)
        .startOf('isoWeek')
        .toDate();
      let endDate = moment(this.dateRange.endDate).endOf('week').toDate();
      this.dateRange = { startDate, endDate };
      this.$emit('updateDateRange', this.dateRange);
    },
  },
  mounted() {
    let rangeElement = document.getElementsByClassName('reportrange-text');
    rangeElement[0].style['border-radius'] = '0.3rem';
  },
};
</script>

<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
</style>
