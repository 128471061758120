<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <TransparentLoader v-if="isLoading" />
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        Allocation Distribution
      </div>
    </div>
    <!-- Top Bar END-->
    <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
      <CustomFilter
        v-if="showFilter"
        :filterBy="filterBy"
        :userPractices="userPractices"
        :projectOptions="projects"
        @resetFilter="resetFilter"
        @setFilterByUserPractices="setFilterByUserPractices"
        @setFilterByProjects="setFilterByProjects"
        @toggleFilter="toggleFilter"
      />
      <div class="flex py-4 px-4 justify-between items-center">
        <!-- Icons -->
        <ExportButtons
          v-if="isExportPermission"
          :excel="true"
          @exportToExcel="exportToExcel"
        />
        <div v-else></div>
        <div class="flex flex-row items-center">
          <span
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleFilter"
            >filter_alt</span
          >
        </div>
        <!-- Icons END -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ExportButtons from '@/components/common/ExportButtons.vue';
import TransparentLoader from '@/components/common/TransparentLoader.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';

export default {
  name: 'AllocationDistribution',
  components: {
    ExportButtons,
    TransparentLoader,
    CustomFilter,
  },
  data() {
    return {
      isLoading: false,
      isExportPermission: false,
      showFilter: false,
      filterBy: {
        userPractices: [],
        projects: [],
      },
      defaultFilterBy: {},
    };
  },
  computed: {},
  created() {
    this.isExportPermission = this.validatePermission([
      'reports.allocation_distribution.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    this.fetchUserPractices();
    this.fetchProjects();
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    setFilterByUserPractices(option) {
      this.filterBy.userPractices = option;
      // this.fetchUserPractices();
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      // this.fetchProjects();
    },
    fetchUserPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.userPractices = response.data.map((d) => {
            this.filterBy.userPractices.push(d.practice_name);
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUserPractices', error);
        });
    },
    fetchProjects(page) {
      axios
        .get(`/manage/projects/get_projects`)
        .then((response) => {
          this.projects = response.data['projects'].map((d) => {
            if (d.code != 'WSHOLIDAY' && d.code != 'WSPTO') {
              this.filterBy.projects.push(d.id);
              return { label: d.name, value: d.id };
            }
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    getUsersPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.userPractices.length > 0) {
        payload.userPractices = filterBy.userPractices;
      }
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      return payload;
    },
    exportToExcel() {
      if (this.isExportPermission) {
        const payload = this.getUsersPayload();
        this.isLoading = true;
        axios
          .post(
            `/reports/download_allocation_distribution_report_excel`,
            {
              practice: payload.userPractices,
              project: payload.projects,
            },
            {
              responseType: 'blob',
            }
          )
          .then((response) => {
            this.isLoading = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `Allocation_Distribution_Report.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            this.isLoading = false;
            // handle error
            console.error('Error while Fething Detail Log Report', error);
          });
      }
    },
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
