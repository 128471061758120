<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Utilization Details
        </div>
        <CustomFilter
          v-if="showUtilizationFilter"
          :filterBy="filterBy"
          :projectPractices="projectPractices"
          @resetFilter="resetFilter"
          @setFilterByProjectPractices="setFilterByProjectPractices"
          @setFilterByUserStatus="setFilterByUserStatus"
          @setFilterByProjectUserStatus="setFilterByProjectUserStatus"
          @setFilterByProjectStatus="setFilterByProjectStatus"
          @setFilterByProjects="setFilterByProjects"
          @setFilterByUsers="setFilterByUsers"
          @setFilterByLocation="setFilterByLocation"
          @setFilterByAdmin="setFilterByAdmin"
          @toggleFilter="toggleFilter"
          @loadMore="loadMore"
          :projectOptions="projectOptions"
          :adminUser="adminUser"
          :userOptions="userOptions"
          :user_status="user_status"
          :project_status="project_status"
          :project_user_status="project_user_status"
        />
        <DateRangePickerInput
          v-if="isReadPermission"
          ref="utilizationDateRangePicker"
          @updateDateRange="setFilterByDateRange"
          popupAlign="left"
          :startDate="filterBy.dateRange && filterBy.dateRange.startDate"
          :endDate="filterBy.dateRange && filterBy.dateRange.endDate"
        />
      </div>
      <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
        <div class="flex py-4 px-4 justify-between items-center">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div class="flex flex-row items-center" v-if="isReadPermission">
            <!-- Search Input -->
            <div class="relative text-gray-600" style="margin-right: 30px">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              class="material-icons text-2xl cursor-pointer select-none text-gray-500"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
          <div v-else></div>
        </div>
        <Loading v-if="isLoading && isReadPermission" style="height: 24.5rem" />

        <div v-else class="flex flex-col" style="height: 24.5rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && utilization_data.length > 0"
          >
            <table
              ref="table"
              id="utilization"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr id="first-header">
                  <th
                    style="border: 1px solid white"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    NAME
                  </th>
                  <th
                    style="border: 1px solid white"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    USER<br />PRACTICE
                  </th>
                  <th
                    style="border: 1px solid white"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    TYPE
                  </th>
                  <th
                    style="border: 1px solid white"
                    :colspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    TOTAL HOURS
                  </th>
                  <th
                    style="border: 1px solid white"
                    :colspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    PROJECT HOURS
                  </th>
                  <th
                    style="border: 1px solid white"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    PTO <br />HOURS
                  </th>
                  <th
                    style="border: 1px solid white"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    MIS-<br />MATCH
                  </th>
                  <th
                    style="border: 1px solid white"
                    :key="index"
                    v-for="(col, index) in column"
                    :colspan="4"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    {{ col }}
                  </th>
                </tr>
                <tr id="second-header">
                  <th
                    style="border: 1px solid white"
                    :key="col.value"
                    v-for="col in column1"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-1 text-gray-100 bg-gray-400"
                  >
                    {{ col.label }}
                  </th>
                  <th
                    style="border: 1px solid white"
                    :key="col.value"
                    v-for="col in column2"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-1 text-gray-100 bg-gray-400"
                  >
                    {{ col.label }}
                  </th>
                  <th
                    style="border: 1px solid white"
                    :key="col.value"
                    v-for="col in column3"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-1 text-gray-100 bg-gray-400"
                  >
                    {{ col.label.split(' ')[0] }}<br />{{
                      col.label.split(' ')[1]
                    }}
                  </th>
                  <th
                    style="border: 1px solid white"
                    :key="col.value"
                    v-for="col in column4"
                    rowspan="2"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-1 text-gray-100 bg-gray-400"
                  >
                    {{ col.label.split(' ')[0] }}<br />{{
                      col.label.split(' ')[1]
                    }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(utilization, index) in utilization_data"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td
                    :id="utilization.user_name"
                    class="px-4 text-xs py-2"
                    style="border: 1px solid #e5e7eb"
                    v-if="isReadUserPermission"
                  >
                    <router-link :to="`/manage/users/${utilization.user_id}`">
                      <p
                        class="text-xs font-semibold text-blue-500 cursor-pointer"
                      >
                        {{ utilization.user_name }}
                      </p>
                    </router-link>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ utilization.email }}
                    </p>
                  </td>
                  <td
                    v-else
                    class="px-4 text-xs py-2"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class="text-xs font-semibold">
                      {{ utilization.user_name }}
                    </p>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ utilization.email }}
                    </p>
                  </td>
                  <td class="px-4 text-xs" style="border: 1px solid #e5e7eb">
                    <p class>{{ utilization.user_practice }}</p>
                  </td>
                  <td class="px-4 text-xs" style="border: 1px solid #e5e7eb">
                    <p class>{{ utilization.user_emp_type }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.avail_hrs || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.proj_hrs || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.prac_proj_hrs || 0.0 }}</p>
                  </td>
                  <!--<td class="px-4 text-xs text-center" style="border: 1px solid #e5e7eb;">
                <p class>{{ utilization.prac_default_hrs }}</p>
              </td>-->
                  <td
                    class="px-4 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.default_hrs || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.pto_hrs || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.mismatch || '-' }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.overall_utilization >= 0.0 &&
                        utilization.overall_utilization < 25,
                      second:
                        utilization.overall_utilization >= 25 &&
                        utilization.overall_utilization < 50,
                      third:
                        utilization.overall_utilization >= 50 &&
                        utilization.overall_utilization < 75,
                      fourth:
                        utilization.overall_utilization >= 75 &&
                        utilization.overall_utilization <= 100,
                      fifth: utilization.overall_utilization > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.overall_utilization || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.overall_billable >= 0.0 &&
                        utilization.overall_billable < 25,
                      second:
                        utilization.overall_billable >= 25 &&
                        utilization.overall_billable < 50,
                      third:
                        utilization.overall_billable >= 50 &&
                        utilization.overall_billable < 75,
                      fourth:
                        utilization.overall_billable >= 75 &&
                        utilization.overall_billable <= 100,
                      fifth: utilization.overall_billable > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.overall_billable || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.overall_non_billable >= 0.0 &&
                        utilization.overall_non_billable < 25,
                      second:
                        utilization.overall_non_billable >= 25 &&
                        utilization.overall_non_billable < 50,
                      third:
                        utilization.overall_non_billable >= 50 &&
                        utilization.overall_non_billable < 75,
                      fourth:
                        utilization.overall_non_billable >= 75 &&
                        utilization.overall_non_billable <= 100,
                      fifth: utilization.overall_non_billable > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.overall_non_billable || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.overall_shadow >= 0.0 &&
                        utilization.overall_shadow < 25,
                      second:
                        utilization.overall_shadow >= 25 &&
                        utilization.overall_shadow < 50,
                      third:
                        utilization.overall_shadow >= 50 &&
                        utilization.overall_shadow < 75,
                      fourth:
                        utilization.overall_shadow >= 75 &&
                        utilization.overall_shadow <= 100,
                      fifth: utilization.overall_shadow > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.overall_shadow || 0.0 }}</p>
                  </td>

                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.practice_utilization >= 0.0 &&
                        utilization.practice_utilization < 25,
                      second:
                        utilization.practice_utilization >= 25 &&
                        utilization.practice_utilization < 50,
                      third:
                        utilization.practice_utilization >= 50 &&
                        utilization.practice_utilization < 75,
                      fourth:
                        utilization.practice_utilization >= 75 &&
                        utilization.practice_utilization <= 100,
                      fifth: utilization.practice_utilization > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.practice_utilization || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.practice_billable >= 0.0 &&
                        utilization.practice_billable < 25,
                      second:
                        utilization.practice_billable >= 25 &&
                        utilization.practice_billable < 50,
                      third:
                        utilization.practice_billable >= 50 &&
                        utilization.practice_billable < 75,
                      fourth:
                        utilization.practice_billable >= 75 &&
                        utilization.practice_billable <= 100,
                      fifth: utilization.practice_billable > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.practice_billable || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.practice_non_billable >= 0.0 &&
                        utilization.practice_non_billable < 25,
                      second:
                        utilization.practice_non_billable >= 25 &&
                        utilization.practice_non_billable < 50,
                      third:
                        utilization.practice_non_billable >= 50 &&
                        utilization.practice_non_billable < 75,
                      fourth:
                        utilization.practice_non_billable >= 75 &&
                        utilization.practice_non_billable <= 100,
                      fifth: utilization.practice_non_billable > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.practice_non_billable || 0.0 }}</p>
                  </td>
                  <td
                    class="px-4 text-xs text-center"
                    :class="{
                      first:
                        utilization.practice_shadow >= 0.0 &&
                        utilization.practice_shadow < 25,
                      second:
                        utilization.practice_shadow >= 25 &&
                        utilization.practice_shadow < 50,
                      third:
                        utilization.practice_shadow >= 50 &&
                        utilization.practice_shadow < 75,
                      fourth:
                        utilization.practice_shadow >= 75 &&
                        utilization.practice_shadow <= 100,
                      fifth: utilization.practice_shadow > 100,
                    }"
                    style="border: 1px solid #e5e7eb"
                  >
                    <p class>{{ utilization.practice_shadow || 0.0 }}</p>
                  </td>
                </tr>
                <tr
                  class="py-2 hover:bg-gray-100 font-bold text-sm text-center"
                  height="40px"
                  style="background: #337ab7; color: white"
                >
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>Overall Utilization Detail</p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>-</p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>-</p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>{{ total_list.total_avail_hrs || '0.0' }}</p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>{{ total_list.total_proj_hrs || '0.0' }}</p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>{{ total_list.total_prac_proj_hrs || '0.0' }}</p>
                  </td>
                  <!--<td class="px-4 text-xs border-t-2 border-gray-600">
              <p class>{{ total_list.total_default_hrs }}</p>
            </td>-->
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>{{ total_list.total_default_hrs || '0.0' }}</p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>{{ total_list.total_pto_hrs || '0.0' }}</p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>-</p>
                  </td>

                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>
                      {{ total_list.total_overall_utilization || '0.0' }}
                    </p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>
                      {{ total_list.total_overall_billable || '0.0' }}
                    </p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>
                      {{ total_list.total_overall_non_billable || '0.0' }}
                    </p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>{{ total_list.total_overall_shadow || '0.0' }}</p>
                  </td>

                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>
                      {{ total_list.total_practice_utilization || '0.0' }}
                    </p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>
                      {{ total_list.total_practice_billable || '0.0' }}
                    </p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>
                      {{ total_list.total_practice_non_billable || '0.0' }}
                    </p>
                  </td>
                  <td
                    class="px-4 text-xs border-2 border-gray-600"
                    style="border-color: white"
                  >
                    <p class>{{ total_list.total_practice_shadow || '0.0' }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
      </div>
      <div
        class="flex flex-row justify-between mt-3 mx-3"
        v-if="isReadPermission"
      >
        <div class="flex flex-row items-center justify-center">
          <div class="text-sm text-gray-800">
            0-25:
            <div
              id="test"
              class="codes"
              style="background-color: #e06600"
            ></div>
          </div>
          <div class="text-sm text-gray-800">
            26-50:
            <div
              id="test"
              class="codes"
              style="background-color: #f0b200"
            ></div>
          </div>
          <div class="text-sm text-gray-800">
            51-75:
            <div
              id="test"
              class="codes"
              style="background-color: #d9f700"
            ></div>
          </div>
          <div class="text-sm text-gray-800">
            76-100:
            <div
              id="test"
              class="codes"
              style="background-color: #59de00"
            ></div>
          </div>
          <div class="text-sm text-gray-800">
            101+:
            <div
              id="test"
              class="codes"
              style="background-color: #00ad00"
            ></div>
          </div>
        </div>
        <div class="text-xs italic text-gray-600">
          Note: Work logs for Default, PTO and Holiday are not considered for
          Utilization
        </div>
      </div>
      <!-- Pagination -->
      <div>
        <Pagination
          class="mt-2"
          v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchUtilizationDetails"
          @setPageSize="setPageSize"
        />
      </div>
    </div>
    <!-- Pagination End-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Loading from '@/components/common/Loading.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import Pagination from '@/components/common/Pagination.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'UtilizationDetails',
  components: {
    Loading,
    DateRangePickerInput,
    CustomFilter,
    Pagination,
    NotAuthorized,
    NoRecordFound,
    ExportButtons,
  },
  data() {
    const weekNumber = moment().week() - moment().startOf('month').week() + 1;
    const eDate =
      moment().isoWeekday() == 7
        ? moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')
        : moment().startOf('week').format('YYYY-MM-DD');
    const startDate =
      weekNumber == 1
        ? moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD');
    const endDate =
      weekNumber == 1
        ? moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        : eDate;
    return {
      location: [
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'USA', value: 'USA' },
        { label: 'Mexico', value: 'Mexico' },
      ],
      showUtilizationFilter: false,
      currentWeek: weekNumber,
      utilization_data: [],
      projectPractices: [],
      projectOptions: [],
      userOptions: [],
      total_list: [],
      adminUser: 'No',
      user_status: { label: 'Active', value: 'true' },
      project_status: { label: 'Active', value: 'true' },
      project_user_status: { label: 'Active', value: 'true' },
      filterBy: {
        projectPractices: [],
        projects: [],
        users: [],
        user_status: { label: 'Active', value: 'true' },
        project_status: { label: 'Active', value: 'true' },
        project_user_status: { label: 'Active', value: 'true' },
        location: [],
        dateRange: { startDate, endDate },
        adminUser: false,
      },
      query: '',
      isLoading: true,
      paginationMeta: {},
      pageSize: 30,
      column4: [
        { label: 'UTILI. ', value: '7' },
        { label: 'BILLED UTILI.', value: '8' },
        { label: 'NON-BILLED UTILI.', value: '9' },
        { label: 'SHADOW', value: '10' },
      ],
      column3: [
        { label: 'UTILI. ', value: '3' },
        { label: 'BILLED UTILI.', value: '4' },
        { label: 'NON-BILLED UTILI.', value: '5' },
        { label: 'SHADOW', value: '6' },
      ],
      column2: [
        { label: 'PRACTICE', value: '11' },
        //  {label: "PRACTICE DEFAULT", value: "8"},
        { label: 'DEFAULT', value: '12' },
      ],
      column1: [
        { label: 'AVAILABLE', value: '1' },
        { label: 'PROJECT', value: '2' },
      ],
      column: ['OVERALL (%)', 'PRACTICE (%)'],
      defaultFilterBy: {},
      isReadPermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  computed: {
    ...mapGetters('user', ['userId', 'isReadUserPermission']),
  },
  created() {
    this.fetchUtilizationDetails = _debounce(this.fetchUtilizationDetails, 400);
    this.isReadPermission = this.validatePermission([
      'pmo.utlization_details.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'pmo.utlization_details.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.isLoading = true;
      this.fetchProjectPractices();
      this.fetchProjectUsers();
      this.fetchUtilizationDetails(1);
    }
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      const { defaultDateRange } = this.$refs.utilizationDateRangePicker;
      this.$refs.utilizationDateRangePicker.dateRange = defaultDateRange;
      this.setFilterByDateRange(defaultDateRange);
    },
    toggleFilter() {
      this.showUtilizationFilter = !this.showUtilizationFilter;
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchUtilizationDetails(1);
      }
    },
    loadMore(page) {
      this.fetchUtilizationDetails(page);
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchUtilizationDetails(1);
    },
    setFilterByAdmin(e) {
      this.filterBy.adminUser = e.target.value;
      this.fetchProjectUsers();
      this.fetchUtilizationDetails(1);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.fetchProjectUsers();
      this.fetchUtilizationDetails(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchUtilizationDetails(1);
    },
    setFilterByUserStatus(option) {
      this.filterBy.user_status = option;
      this.fetchProjectUsers();
      this.fetchUtilizationDetails(1);
    },
    setFilterByProjectUserStatus(option) {
      this.filterBy.project_user_status = option;
      this.fetchProjectUsers();
      this.fetchUtilizationDetails(1);
    },
    setFilterByProjectStatus(option) {
      this.filterBy.project_status = option;
      this.fetchProjectUsers();
      this.fetchUtilizationDetails(1);
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchUtilizationDetails(1);
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchProjectUsers();
      this.fetchUtilizationDetails(1);
    },
    setFilterByUsers(option) {
      this.filterBy.users = option;
      this.fetchUtilizationDetails(1);
    },
    fetchProjectPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getProjectPayload() {
      const { filterBy } = this;
      const payload = {};
      if (filterBy.project_status) {
        payload.project_status = filterBy.project_status.value;
      }
      if (filterBy.projectPractices.length > 0) {
        payload.projectPractices = filterBy.projectPractices;
      }
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (filterBy.user_status) {
        payload.user_status = filterBy.user_status.value;
      }
      if (filterBy.project_user_status) {
        payload.project_user_status = filterBy.project_user_status.value;
      }
      payload.admin = filterBy.adminUser;
      return payload;
    },
    fetchProjectUsers() {
      const payload = this.getProjectPayload();
      axios
        .get(`/manage/projects/project_user_list_utilization_details`, {
          params: {
            project_status: payload.project_status,
            project_practice: payload.projectPractices,
            user_status: payload.user_status,
            project_user_status: payload.project_user_status,
            projects: payload.projects,
            admin_user: payload.admin,
          },
        })
        .then((response) => {
          this.projectOptions = response.data.project.map((d) => {
            return { label: d.name, value: d.id };
          });
          this.userOptions = this.filterUsers(response.data.user);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUserProjects', error);
        });
    },
    filterUsers(users) {
      const filteredUsers = [];
      users.forEach((user) => {
        filteredUsers.push({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
        });
      });
      return filteredUsers;
    },
    getUtilizationPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.projectPractices.length > 0) {
        payload.projectPractices = filterBy.projectPractices;
      }
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (filterBy.users.length > 0) {
        payload.users = filterBy.users;
      }
      if (filterBy.location.length > 0) {
        payload.locations = filterBy.location;
      }
      if (filterBy.user_status) {
        payload.user_status = filterBy.user_status.value;
      }
      if (filterBy.project_user_status) {
        payload.project_user_status = filterBy.project_user_status.value;
      }
      if (filterBy.project_status) {
        payload.project_status = filterBy.project_status.value;
      }
      if (filterBy.dateRange) {
        payload.date_range = JSON.stringify(filterBy.dateRange);
      }
      if (query) {
        payload.search = query;
      }
      payload.admin = filterBy.adminUser;
      return payload;
    },
    fetchUtilizationDetails(page) {
      this.isLoading = true;
      this.nonce = Date.now().toString();
      const { pageSize } = this;
      const payload = this.getUtilizationPayload();
      axios
        .get(
          `/reports/utilization_details?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              location: payload.locations,
              users: payload.users,
              project_status: payload.project_status,
              project_practice: payload.projectPractices,
              user_status: payload.user_status,
              project_user_status: payload.project_user_status,
              projects: payload.projects,
              date_range: payload.date_range,
              search: payload.search,
              admin: payload.admin,
              week: this.currentWeek,
              nonce: this.nonce,
            },
          }
        )
        .then((response) => {
          if (response.data.nonce === this.nonce) {
            this.paginationMeta = response.data.meta;
            this.utilization_data = response.data.utilization_data.user_list;
            this.total_list = response.data.utilization_data.total_list;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          //this.isViewLoading = false;
          //this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Utilization Details', error);
        });
    },
    exportToCSV() {
      const payload = this.getUtilizationPayload();
      axios
        .post(`/reports/utilization_details_csv.csv`, {
          location: payload.locations,
          users: payload.users,
          project_status: payload.project_status,
          project_practice: payload.projectPractices,
          user_status: payload.user_status,
          project_user_status: payload.project_user_status,
          projects: payload.projects,
          date_range: payload.date_range,
          search: payload.search,
          admin: payload.admin,
          generated_date: new Date(),
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Utilization Details_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchCsvData', error);
        });
    },
    createPDF() {
      const doc = new jsPDF('l', 'mm', [297, 500]);
      doc.text(`Utilization Details: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#utilization',
      });
      doc.save(`Utilization Details_${moment().format('yyyy_MM_DD')}.pdf`);
    },
  },
};
</script>

<style scoped>
thead tr#first-header th,
thead tr#first-header td {
  position: sticky;
  top: 0;
}

thead tr#second-header th,
thead tr#second-header td {
  position: sticky;
  top: 2.2rem;
}

tbody tr:last-child td {
  position: sticky;
  bottom: 0;
  background-color: rgba(156, 161, 176, 255);
  color: rgba(236, 232, 235, 255);
  /* border-top: 1px solid black; */
}

.first {
  background: #e06600;
}
.second {
  background: #f0b200;
}
.third {
  background: rgb(217, 247, 0);
}
.fourth {
  background: rgb(89, 222, 0);
}
.fifth {
  background: rgb(0, 173, 0);
}
.codes {
  width: 16px;
  height: 16px;
  border: 1px;
  float: right;
  margin-right: 10px;
  margin-left: 2px;
}
</style>
