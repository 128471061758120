<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        Practice Work Distribution
      </div>
      <div
        v-if="
          (selectedTab === 'consolidated' && isReadConsolidatedPermission) ||
          (selectedTab === 'detailed' && isReadDetailedPermission)
        "
      >
        <DateRangePickerInputQuarter
          ref="pwdDateRangePicker"
          @updateDateRange="setFilterByDateRange"
          popupAlign="center"
        />
      </div>

      <div class="flex flex-row items-center">
        <div class="bg-white mr-0 text-sm">
          <nav class="flex flex-col sm:flex-row">
            <button
              v-if="
                isReadConsolidatedPermission || isExportConsolidatedPermission
              "
              @click="selectedTab = 'consolidated'"
              :class="
                selectedTab === 'consolidated'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600'
              "
              class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
            >
              Consolidated
            </button>
            <button
              v-if="isReadDetailedPermission || isExportDetailedPermission"
              @click="selectedTab = 'detailed'"
              :class="
                selectedTab === 'detailed'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600'
              "
              class="py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase"
            >
              Detailed
            </button>
          </nav>
        </div>
      </div>
    </div>
    <!-- Top Bar END-->
    <div v-if="selectedTab === 'consolidated'">
      <ConsolidatedTab
        v-if="isReadConsolidatedPermission || isExportConsolidatedPermission"
        class="w-full"
        :showConsolidatedFilter="showConsolidatedFilter"
        :dateRange="dateRange"
        @resetDate="resetDate"
        @toggleFilter="toggleFilter"
      />
    </div>
    <div v-else>
      <DetailedTab
        v-if="isReadDetailedPermission || isExportDetailedPermission"
        class="w-full"
        :showDetailedFilter="showDetailedFilter"
        :dateRange="dateRange"
        @resetDate="resetDate"
        @toggleFilter="toggleFilter"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import 'jspdf-autotable';
import DateRangePickerInputQuarter from '@/components/inputs/DateRangePickerInputQuarter.vue';
import ConsolidatedTab from '@/components/reports/practiceWorkDistribution/ConsolidatedTab.vue';
import DetailedTab from '@/components/reports/practiceWorkDistribution/DetailedTab.vue';

export default {
  name: 'PracticeWorkDistribution',
  components: {
    DateRangePickerInputQuarter,
    ConsolidatedTab,
    DetailedTab,
  },
  data() {
    return {
      selectedTab: 'consolidated',
      showConsolidatedFilter: false,
      showDetailedFilter: false,
      dateRange: null,
      isReadConsolidatedPermission: false,
      isExportConsolidatedPermission: false,
      isReadDetailedPermission: false,
      isExportDetailedPermission: false,
    };
  },
  methods: {
    resetDate() {
      const { defaultDateRange } = this.$refs.pwdDateRangePicker;
      this.$refs.pwdDateRangePicker.dateRange = defaultDateRange;
      this.setFilterByDateRange(defaultDateRange);
    },
    toggleFilter() {
      if (this.selectedTab === 'consolidated') {
        this.showConsolidatedFilter = !this.showConsolidatedFilter;
      } else {
        this.showDetailedFilter = !this.showDetailedFilter;
      }
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.dateRange = { startDate, endDate };
    },
  },
  created() {
    this.isReadConsolidatedPermission = this.validatePermission([
      'reports.pwd.consolidated.read',
    ]);
    this.isExportConsolidatedPermission = this.validatePermission([
      'reports.pwd.consolidated.export',
    ]);
    this.isReadDetailedPermission = this.validatePermission([
      'reports.pwd.detailed.read',
    ]);
    this.isExportDetailedPermission = this.validatePermission([
      'reports.pwd.detailed.export',
    ]);
    if (
      !this.isReadConsolidatedPermission &&
      !this.isExportConsolidatedPermission
    ) {
      this.selectedTab = 'detailed';
    }
  },
};
</script>
