











































































































































import Vue from 'vue';
import SwitchInput from '@/components/inputs/SwitchInput.vue';
import UserNameAvatar from '@/components/manage/users/UserNameAvatar.vue';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import EditMemberModal from './EditMemberModal.vue';
import ViewMemberModal from './ViewMemberModal.vue';
import axios from 'axios';
import Accordion from './accordion.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ProjectMembers',
  components: {
    SwitchInput,
    UserNameAvatar,
    ConfirmAlertBox,
    EditMemberModal,
    Accordion,
    ViewMemberModal,
  } as any,
  data() {
    return {
      isConfirmDeleteModalVisible: false,
      isEditMemberModalVisible: false,
      isViewMemberModalVisible: false,
      selectedMember: null,
      columns: ['Name', 'Reporting To', 'Account', 'Actions'],
      allMembers: [],
      projectId: null,
      projectName: null,
      projectStatus: true,
      defaultProject: false,
      isReadPermission: false,
      isManagePermission: false,
    };
  },
  props: ['props'],
  computed: {
    ...mapGetters('user', ['isReadUserPermission']),
  },
  methods: {
    onDelete(member: any) {
      (this as any).selectedMember = member;
      (this as any).isConfirmDeleteModalVisible = true;
    },
    removeMember() {
      const { projectId, selectedMember } = this as any;
      if (selectedMember && selectedMember.id) {
        const payload: any = {
          project_id: projectId,
          user_id: selectedMember.id,
        };
        axios
          .post(`/manage/projects/remove_user`, payload)
          .then((response) => {
            const { data } = response;
            this.$emit('on-update');
            this.$store.dispatch('settings/showAlert', {
              alertMessage: data.msg || 'User removed successfully',
              alertType: 'success',
            });
            (this as any).closeConfirmDeleteModal();
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.dispatch('settings/showAlert', {
              alertMessage:
                data.msg || 'Something went wrong. Please try again',
              alertType: 'error',
            });
            (this as any).closeConfirmDeleteModal();
          });
      }
    },
    closeConfirmDeleteModal() {
      (this as any).isConfirmDeleteModalVisible = false;
    },
    openEditMemberModal(member: any) {
      (this as any).selectedMember = member;
      (this as any).isEditMemberModalVisible = true;
    },
    openViewMemberModal(member: any) {
      (this as any).selectedMember = member;
      (this as any).isViewMemberModalVisible = true;
    },
    closeEditMemberModal() {
      (this as any).isEditMemberModalVisible = false;
    },
    closeViewMemberModal() {
      (this as any).isViewMemberModalVisible = false;
    },
    onSuccess(_data: any, _isEdit: boolean) {
      this.$emit('on-update');
    },
  },
  created() {
    (this as any).isReadPermission = (this as any).validatePermission([
      'manage.projects.read',
    ]);
    (this as any).isManagePermission = (this as any).validatePermission([
      'manage.projects.manage',
    ]);
    const { members, projectId, projectName, projectStatus, defaultProject } = (
      this as any
    ).props;
    (this as any).allMembers = members;
    (this as any).projectId = projectId;
    (this as any).projectName = projectName;
    (this as any).projectStatus = projectStatus;
    (this as any).defaultProject = defaultProject;
  },
});
