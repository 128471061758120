


















































import Vue from 'vue';
export default Vue.extend({
  name: 'LogMismatch',
  props: [],
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
