<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Requirements List
        </div>

        <div class="flex flex-row items-center">
          <!-- <label class="text-xs text-gray-600 font-thin hidden sm:block"
            >DATE RANGE</label
          >
          <div class="hidden sm:block">
            <div class="flex md:ml-3 mr-4">
              <DateRangePickerInput @updateDateRange="setFilterByDateRange" />
            </div>
          </div> -->
          <!-- Add Requirement -->
          <div v-if="isManagePermission" class="hidden sm:block">
            <div class="lg:ml-6 flex items-center">
              <router-link :to="`/recruitment/list/form/create`">
                <button
                  class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 flex items-center text-sm"
                >
                  <span class="material-icons m-auto mr-2">add</span>

                  <span class="text-sm"> Add Requirement</span>
                </button>
              </router-link>
            </div>
          </div>
          <!-- Add Requirement END -->
          <div></div>
        </div>
      </div>
      <!-- Top Bar END-->
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2"
      >
        <CustomFilter
          v-if="showRequirementFilter"
          :filterBy="filterBy"
          :projectPractices="projectPractices"
          :locationGroup="locationGroup"
          :requirement_status="requirement_status"
          @resetFilter="resetFilter"
          @setFilterByProjectPractices="setFilterByProjectPractices"
          @setFilterByLocationGroup="setFilterByLocationGroup"
          @setFilterByRequirementStatus="setFilterByRequirementStatus"
          @toggleFilter="toggleRequirementFilter"
        />
        <div class="flex py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <div class="flex flex-row items-center">
            <!-- Search Input -->
            <div
              v-if="isReadPermission"
              class="relative text-gray-600"
              style="min-width: 250px; max-width: 250px"
            >
              <ejs-multiselect
                :value="selectedColumns"
                style="font-size: 15px"
                :dataSource="columns"
                :showSelectAll="true"
                selectAllText="Select All"
                mode="CheckBox"
                placeholder="Show/Hide Columns"
                :fields="fields"
                @change="setColumns"
              ></ejs-multiselect>
            </div>
            <!-- Search END -->
            <!-- Add Requirement -->
            <div v-if="isReadPermission" class="hidden sm:block">
              <div class="lg:ml-6 flex items-center">
                <button
                  @click="showColumn"
                  class="flex-row bg-blue-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-blue-400 rounded text-white px-5 h-8 flex items-center text-sm"
                >
                  <!--<span class="material-icons m-auto mr-2">arrow_forward</span>-->
                  <span>SUBMIT</span>
                </button>
              </div>
            </div>
            <!-- Add Requirement END -->
          </div>

          <!-- Icons END -->
          <div class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600" v-if="isReadPermission">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              v-if="isReadPermission"
              class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-3"
              @click="toggleRequirementFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <Loading v-if="isLoading" style="height: 24.5rem" />
        <!-- Table -->
        <div v-else class="flex flex-col" style="height: 24.5rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && requirements.length > 0"
          >
            <table
              id="requirment_table"
              class="relative w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead class="bg-gray-200">
                <tr class="text-gray-600 text-left">
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    Req. ID
                  </th>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    View
                  </th>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="index"
                    v-for="(column, index) in selectedColumns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <template v-for="(requirement, index) in requirements">
                  <tr
                    :key="index"
                    v-bind:class="
                      validateDate(
                        requirement.opportunity_type,
                        requirement.status,
                        requirement.created_at
                      )
                    "
                    class="py-2"
                  >
                    <td class="px-4 text-xs">
                      <router-link :to="`/recruitment/list/${requirement.id}`">
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          {{ requirement.r_id }}
                        </p>
                      </router-link>
                    </td>
                    <td class="px-4 py-4">
                      <div
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        @click="
                          toggleViewProfileModal(
                            requirement.id,
                            requirement.r_id,
                            requirement.job_role,
                            requirement
                          )
                        "
                      >
                        <span
                          class="material-icons md:text-xl text-sm mr-0 cursor-pointer"
                          >visibility</span
                        >
                      </div>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Priority')"
                      class="px-4 text-center"
                    >
                      <p class="">{{ requirement.priority }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Job Role')"
                      class="px-4"
                    >
                      <p class="">
                        {{ requirement.job_role }}
                      </p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Skill Set')"
                      class="px-4"
                      style="
                        max-width: 150px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      <span
                        v-if="requirement.skill_set"
                        :content="requirement.skill_set"
                        v-tippy
                      >
                        <p class="text-xs whitespace-nowrap">
                          {{ wrapMethod(requirement.skill_set) }}
                        </p>
                      </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Practice')"
                      class="px-4"
                    >
                      <p class="">{{ requirement.practice }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Projects')"
                      class="px-4"
                    >
                      <p class="">{{ requirement.client }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Hiring Manager')"
                      class="px-4"
                    >
                      <div>
                        <router-link
                          :to="`/manage/users/${requirement.requested_by}`"
                        >
                          <span
                            class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                          >
                            {{ requirement.requested_by_username }}
                          </span>
                        </router-link>
                      </div>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Notes')"
                      class="px-4"
                      style="
                        max-width: 150px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      <span
                        v-if="requirement.description"
                        class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                        :content="requirement.description"
                        v-tippy
                      >
                        <p class="">
                          {{ wrapMethod(requirement.description) }}
                        </p>
                      </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Location')"
                      class="px-4 text-center"
                    >
                      <p class="">{{ requirement.location }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Location Group')"
                      class="px-4 text-center"
                    >
                      <span class="text-black-800 px-2 py-0 rounded-full">{{
                        requirement.location_group
                      }}</span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Exp(yrs)')"
                      class="px-4 text-center"
                    >
                      <p class="">{{ requirement.experience }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('No of Positions')"
                      class="px-4 text-center"
                    >
                      <p class="">{{ requirement.no_of_positions }}</p>
                    </td>
                    <td v-if="selectedColumns.includes('Status')" class="px-4">
                      <span
                        v-if="requirement.status == 'Open'"
                        class="text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full"
                        >{{ requirement.status }}</span
                      >
                      <span
                        v-else-if="requirement.status == 'Close'"
                        class="text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full"
                        >{{ requirement.status }}</span
                      >
                      <span
                        v-else-if="
                          requirement.status == 'Requirement Closed By Client'
                        "
                        class="text-red-600 bg-red-200 font-semibold px-2 py-0 rounded-full"
                        >{{ requirement.status }}</span
                      >
                      <span
                        v-else-if="
                          requirement.status == 'Requirement Closed By Manager'
                        "
                        class="text-red-400 bg-red-200 font-semibold px-2 py-0 rounded-full"
                        >{{ requirement.status }}</span
                      >
                    </td>
                    <td
                      v-if="selectedColumns.includes('Requirement Type')"
                      class="px-4 text-center"
                    >
                      <p class="">{{ requirement.opportunity_type }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Rate per hour')"
                      class="px-4 text-center"
                    >
                      <p class="">{{ requirement.pay_per_hour }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Last Modified By')"
                      class="px-4"
                    >
                      <div>
                        <router-link
                          :to="`/manage/users/${requirement.modified_by}`"
                        >
                          <span
                            class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                          >
                            {{ requirement.modified_by_username }}
                          </span>
                        </router-link>
                      </div>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Recruiter assigned')"
                      class="px-4"
                    >
                      <div>
                        <router-link
                          :to="`/manage/users/${requirement.recruiter_assigned_id}`"
                        >
                          <span
                            class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                          >
                            {{ requirement.recruiter_assigned }}
                          </span>
                        </router-link>
                      </div>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Created By')"
                      class="px-4"
                    >
                      <div>
                        <router-link
                          :to="`/manage/users/${requirement.created_by_id}`"
                        >
                          <span
                            class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                          >
                            {{ requirement.created_by }}
                          </span>
                        </router-link>
                      </div>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Created Date')"
                      class="px-4 text-center"
                    >
                      <p class="">
                        {{ globalDateFormat(requirement.created_at) }}
                      </p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Last Modified Date')"
                      class="px-4 text-center"
                    >
                      <p class="">
                        {{ globalDateFormat(requirement.updated_at) }}
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
      <div
        v-if="isReadPermission"
        class="flex flex-row justify-between mt-3 mx-3"
      >
        <div class="flex flex-row items-center justify-center">
          <div class="text-xs italic text-gray-800 font-semibold">
            Req Open upto 4 Weeks:
            <div
              id="test"
              class="codes"
              style="background-color: #8dcc85"
            ></div>
          </div>
          <div class="text-xs italic text-gray-800 font-semibold">
            Req Open upto 8 Weeks:
            <div
              id="test"
              class="codes"
              style="background-color: #e6ef59"
            ></div>
          </div>
          <div class="text-xs italic text-gray-800 font-semibold">
            Req Open beyond 8 Weeks:
            <div
              id="test"
              class="codes"
              style="background-color: #f56c6b"
            ></div>
          </div>
        </div>
      </div>
      <!-- Pagination -->
      <Pagination
        class="mt-2"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchRequirements"
        @setPageSize="setPageSize"
      />
    </div>
    <ViewProfileModal
      :requirementId="requirementId"
      :requirementName="job_role"
      :selectedRequirement="selectedRequirement"
      v-if="isViewProfileModalVisible"
      @close="closeViewProfileModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import ViewProfileModal from '../../../components/recruitment/requirementList/ViewProfileModal.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'RequirementList',
  components: {
    Pagination,
    Loading,
    ViewProfileModal,
    CustomFilter,
    NoRecordFound,
    ExportButtons,
    NotAuthorized,
  },
  data() {
    return {
      showRequirementFilter: false,
      isViewProfileModalVisible: false,
      projectPractices: [],
      requirement_status: { label: 'Open', value: '0' },
      locationGroup: { label: 'All', value: '' },
      filterBy: {
        projectPractices: [],
        requirement_status: { label: 'Open', value: '0' },
        locationGroup: { label: 'All', value: '' },
      },
      requirement_profiles: [],
      query: '',
      columns: [
        'Priority',
        'Job Role',
        'Skill Set',
        'Practice',
        'Projects',
        'Hiring Manager',
        'Notes',
        'Location',
        'Location Group',
        'Exp(yrs)',
        'No of Positions',
        'Status',
        'Requirement Type',
        'Rate per hour',
        'Last Modified By',
        'Recruiter assigned',
        'Created By',
        'Created Date',
        'Last Modified Date',
      ],
      selectedColumns: [''],
      selection: [''],
      requirements: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      projectOptions: [],
      projectOptionMeta: {},
      requirementId: null,
      job_role: null,
      r_id: null,
      defaultFilterBy: {},
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      fields: { text: 'label', value: 'value' },
      nonce: '',
      selectedRequirement: null
    };
  },
  methods: {
    wrapMethod(sentence) {
      return sentence.length > 20
        ? sentence.substring(0, 20) + '...'
        : sentence;
    },
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.selectedColumns = this.columns;
      this.fetchRequirements(1);
    },
    validateDate(type, status, createddate) {
      var days_class = '';
      if (type == 'Full Time' && status == 'Open') {
        const diffTime = Math.abs(new Date(createddate) - new Date());
        const diffDays = Math.round(
          Math.ceil(diffTime / (1000 * 60 * 60 * 24)) / 7
        );
        days_class = diffDays <= 4 ? 'bg-1' : diffDays <= 8 ? 'bg-2' : 'bg-3';
      }
      return days_class;
    },
    toggleRequirementFilter() {
      this.showRequirementFilter = !this.showRequirementFilter;
    },
    toggleViewProfileModal(id, r_id, job_role,requirement) {
      this.r_id = id;
      this.requirementId = id;
      this.job_role = job_role + '(' + r_id + ')';
      this.fetchProfile(id);
      this.isViewProfileModalVisible = !this.isViewProfileModalVisible;
      this.selectedRequirement = requirement;
    },
    closeViewProfileModal() {
      this.isViewProfileModalVisible = false;
    },
    fetchProfile(requirementId) {
      axios
        .get('/recruitment/requirements/' + requirementId)
        .then((response) => {
          this.requirement_profiles = response.data.requirement.profiles;
          // this.paginationMeta = response.data.meta;
          // this.requirements = response.data.requirements;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.requirement = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    createPDF() {
      const doc = new jsPDF('l', 'mm', [297, 500]);
      doc.text(`Requirements: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#requirment_table',
      });
      doc.save(`Requirements_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.fetchRequirements(1);
    },
    setFilterByRequirementStatus(option) {
      this.filterBy.requirement_status = option;
      this.fetchRequirements(1);
    },
    setFilterByLocationGroup(option) {
      this.filterBy.locationGroup = option;
      this.fetchRequirements(1);
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchRequirements(1);
    },
    setColumns(option) {
      if (option.value.length > 0) {
        this.selection = option.value;
      } else {
        this.selection = this.columns;
      }
    },
    showColumn() {
      this.$store.commit('settings/setRequirementColumnsData', {
        requirementColumns: this.selection,
      });
      if (this.selection.length > 0) {
        this.selectedColumns = this.requirementColumns;
        this.selectedColumns.sort(
          (a, b) => this.columns.indexOf(a) - this.columns.indexOf(b)
        );
      }
    },
    loadMore(page) {
      this.fetchRequirements(page);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchRequirements(1, true, query);
      }
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchRequirements(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchRequirements(1);
    },
    fetchFilterOptions() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getRequirementPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      if (filterBy.requirement_status) {
        payload.requirement_status = filterBy.requirement_status.value;
      }
      if (filterBy.locationGroup) {
        payload.location_group = filterBy.locationGroup.value;
      }
      if (query) {
        payload.search = query;
      }
      payload.selectedColumns = this.selectedColumns;
      return payload;
    },
    fetchRequirements(page) {
      this.isLoading = true;
      this.nonce = Date.now().toString();
      const { pageSize } = this;
      const payload = this.getRequirementPayload();
      axios
        .get(`/recruitment/requirements?page_size=${pageSize}&page=${page}`, {
          params: {
            status: payload.requirement_status,
            practice: payload.practice,
            location_group: payload.location_group,
            search: payload.search,
            nonce: this.nonce,
          },
        })
        .then((response) => {
          const { nonce, meta, requirements } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.requirements = requirements;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.requirements = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchRequirements', error);
        });
    },
    exportToCSV() {
      const payload = this.getRequirementPayload();
      axios
        .get(`/recruitment/requirements/requirement_user_csv.csv`, {
          params: {
            status: payload.requirement_status,
            practice: payload.practice,
            location_group: payload.location_group,
            search: payload.search,
            columns: payload.selectedColumns,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Requirement List_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while exportToCSV', error);
        });
    },
  },
  computed: {
    ...mapGetters('user', ['userId', 'isReadUserPermission']),
    ...mapState('settings', ['requirementColumns']),
  },
  created() {
    if (this.requirementColumns.length > 0) {
      this.selectedColumns = this.requirementColumns;
      this.selectedColumns.sort(
        (a, b) => this.columns.indexOf(a) - this.columns.indexOf(b)
      );
    } else {
      this.selectedColumns = this.columns;
    }
    this.fetchRequirements = _debounce(this.fetchRequirements, 400);
    this.isReadPermission = this.validatePermission([
      'recruitment.requirements_list.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'recruitment.requirements_list.manage',
    ]);
    this.isExportPermission = this.validatePermission([
      'recruitment.requirements_list.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchFilterOptions();
      this.fetchRequirements(1);
    } else {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
.codes {
  width: 16px;
  height: 16px;
  border: 1px;
  float: right;
  margin-right: 10px;
  margin-left: 2px;
}
.bg-1 {
  background-color: #8dcc85;
}
.bg-2 {
  background-color: #e6ef59;
}
.bg-3 {
  background-color: #f56c6b;
}
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
