<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
      >
        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div class="flex flex-row items-center">
            <!-- Search Input -->
            <div v-if="isReadPermission" class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="search"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <!-- Add NEW -->
            <div v-if="isManagePermission" class="hidden md:block">
              <div class="lg:ml-6 flex items-center">
                <button
                  @click="openAddMultiTeamModal"
                  class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
                >
                  <span class="material-icons m-auto mr-1 text-base">add</span>
                  <span class="text-sm">Add New</span>
                </button>
              </div>
            </div>
            <!-- Add NEW END -->
          </div>
        </div>

        <Loading v-if="isLoading" style="height: 26rem" />
        <!-- Table -->

        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && teams.length > 0"
          >
            <table
              id="teams_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :class="
                      ['Team Name'].includes(column) ? 'text-left' : ''
                    "
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(team, index) in teams"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td class="px-4 py-2 text-xs">
                    <p align="center">
                      {{
                        paginationMeta.current_page * pageSize -
                        pageSize +
                        index +
                        1
                      }}
                    </p>
                  </td>
                  <td class="px-4 py-2 text-xs">
                    <p align="left">{{ team.name }}</p>
                  </td>
                  <td class="px-4 py-2 text-xs text-center">
                    <p>{{ team.description }}</p>
                  </td>
                  <td class="px-4">
                    <p align="center">
                      <span
                        v-if="team.active"
                        class="text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full"
                        >Active</span
                      >
                      <span
                        v-else
                        class="text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full"
                        >Inactive</span
                      >
                    </p>
                  </td>
                  <td v-if="isManagePermission">
                    <p align="center">
                      <span
                        @click="() => openEditTeamModal(team)"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-blue-500"
                        >edit</span
                      >
                    </p>
                  </td>
                  <td v-if="isManagePermission">
                    <p align="center">
                      <span
                        @click="() => onDelete(team.id)"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-red-500"
                      >
                        <i class="material-icons">delete</i>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
    </div>
    <div v-if="isReadPermission">
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchTeams"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination -->
    <AddTeamsModal
      v-if="isAddMultiTeamModal && isManagePermission"
      @success="onSuccess"
      @close="closeAddMultiTeamModal"
    ></AddTeamsModal>
    <ConfirmAlertBox
      v-if="isConfirmDeleteModalVisible && isManagePermission"
      modalHeadline="Remove team?"
      deleteMessage="Do you want to remove the team?"
      @deleteRecordEvent="removeTeam"
      @close="closeConfirmDeleteModal"
    ></ConfirmAlertBox>
    <EditTeamsModal
      v-if="isEditTeamModalVisible && isManagePermission"
      :teamId="selectedTeamId"
      :teamName="teamName"
      :description="description"
      :active="active"
      @success="onSuccess"
      @close="closeEditTeamModal"
    ></EditTeamsModal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import EditTeamsModal from './EditTeamsModal.vue';
import AddTeamsModal from './AddTeamsModal.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'TeamsTab',
  components: {
    Pagination,
    Loading,
    ConfirmAlertBox,
    EditTeamsModal,
    AddTeamsModal,
    ExportButtons,
    NoRecordFound,
    NotAuthorized,
  },
  data() {
    return {
      teamName: '',
      description: '',
      active: false,
      isLoading: true,
      isAddMultiTeamModal: false,
      isConfirmDeleteModalVisible: false,
      isEditTeamModalVisible: false,
      selectedTeamId: null,
      allTeams: [],
      query: '',
      columns: ['S. No.', 'Team Name', 'Description', 'Status', '', ''],
      teams: [],
      paginationMeta: {},
      pageSize: 30,
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  methods: {
    createPDF() {
      const doc = new jsPDF('l');
      var res = doc.autoTableHtmlToJson(
        document.getElementById('teams_table')
      );
      var columns = [
        res.columns[0],
        res.columns[1],
        res.columns[2],
        res.columns[3],
      ];
      doc.text(`Teams List: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable(columns, res.data);
      doc.save(`Teams${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchTeams(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchTeams(1);
    },
    getTeamsPayload() {
      const { query } = this;
      const payload = {};
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchTeams(page) {
      this.nonce = Date.now().toString();
      this.isLoading = true;
      const { pageSize } = this;
      const payload = this.getTeamsPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/teams/all?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, meta, team } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.teams = team;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.teams = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchTeams', error);
        });
    },
    exportToCSV() {
      const payload = this.getTeamsPayload();
      axios
        .post(`/manage/teams/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Teams_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          error('ERR while fetchTeams', error);
        });
    },
    onDelete(teamId) {
      this.selectedTeamId = teamId;
      this.isConfirmDeleteModalVisible = true;
    },
    removeTeam() {
      const { selectedTeamId } = this;
      if (selectedTeamId) {
        const payload = {
          id: selectedTeamId,
        };
        axios
          .post(`/manage/teams/remove_team/`, payload)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.closeConfirmDeleteModal();
            this.fetchTeams(this.paginationMeta.current_page);
          })
          .catch((error) => {
            console.error('ERR while removeTeam', error);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: error.response.data.msg,
              alertType: 'error',
            });
          });
      }
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    openAddMultiTeamModal() {
      this.isAddMultiTeamModal = true;
    },
    closeAddMultiTeamModal() {
      this.isAddMultiTeamModal = false;
    },
    openEditTeamModal(team) {
      const { id, name, description, active } = team;
      this.selectedTeamId = id;
      this.teamName = name;
      this.description = description;
      this.active = active;
      this.isEditTeamModalVisible = true;
    },
    closeEditTeamModal() {
      this.isEditTeamModalVisible = false;
    },
    onSuccess() {
      this.closeAddMultiTeamModal();
      this.closeEditTeamModal();
      if (this.isReadPermission) {
        this.fetchTeams(this.paginationMeta.current_page); //reload
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchTeams = _debounce(this.fetchTeams, 400);
    this.isReadPermission = this.validatePermission([
      'manage.configurations.teams.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'manage.configurations.teams.manage',
    ]);
    this.isExportPermission = this.validatePermission([
      'manage.configurations.teams.export',
    ]);
    if (!this.isManagePermission) {
      this.columns = ['S. No.', 'Team Name', 'Description', 'Status'];
    }
    if (this.isReadPermission) {
      this.fetchTeams(1);
    } else {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
