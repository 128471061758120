<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900 text-center"
                  id="modal-headline"
                >
                  Project Name: {{ payload.projectName }} , Name:
                  {{ payload.userName }}
                  <br />
                  {{ payload.startDate }} - {{ payload.endDate }}
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <!-- Table  -->
          <div class="overflow-auto">
            <table
              id="submit_table"
              class="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-scroll"
            >
              <thead class="bg-gray-400">
                <tr class="text-left text-gray-100">
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(log, index) in payload.logs"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                  style="height: 30px"
                >
                  <td class="px-4 text-xs">
                    <p class>{{ globalDateFormat(log.date) }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.hours }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.issue_number }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.jira_id }}</p>
                  </td>
                  <td class="px-4 text-xs">
                    <p class>{{ log.description }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="
                payload.status == 'submitted again' ||
                payload.status == 'pending'
              "
              class="pt-4"
            >
              <label class="px-4">Comment</label>
              <br />
              <TextInput
                :name="'comment'"
                :type="'text'"
                :isEdit="true"
                :aligned="'horizontal'"
                @onChange="onChange"
              />
            </div>
          </div>
          <div
            v-if="payload.isDeleted == false"
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <span
              v-if="
                payload.status == 'pending' ||
                payload.status == 'submitted again'
              "
            >
              <button
                type="button"
                @click="save('approved')"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Approve
              </button>
              <button
                type="button"
                @click="save('rejected')"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Reject
              </button>
              <button
                type="button"
                @click="close"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </span>
            <span v-else>
              <button
                type="button"
                @click="close"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </span>
          </div>
          <div
            v-else
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click="remove()"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Delete
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import TextInput from '@/components/inputs/TextInput.vue';

export default {
  name: 'MyApprovalsModal',
  components: {
    TextInput,
  },
  data() {
    return {
      payload: {
        endDate: null,
        startDate: null,
        logs: [],
        projectId: null,
        projectManager: null,
        projectName: null,
        requestId: null,
        status: null,
        comment: '',
        userName: null,
        isDeleted: null,
      },
      changeStatus: null,
      columns: ['Date', 'Hours', 'Category', 'Jira#', 'Description'],
    };
  },
  props: [
    'endDate',
    'startDate',
    'logs',
    'projectId',
    'projectManager',
    'projectName',
    'requestId',
    'status',
    'userName',
    'isDeleted',
  ],
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    onChange(e) {
      this.payload.comment = e.target.value;
    },
    updateStatus(callback) {
      axios
        .post(
          `/fort_night_log/update_status?request_id=${this.payload.requestId}&comment=${this.payload.comment}&status=${this.changeStatus}`
        )
        .then(() => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Successfully' + ' ' + this.changeStatus,
            alertType: 'success',
          });
          callback(true);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while UpdateFortNightLogs', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
    save(buttonStatus) {
      this.changeStatus = buttonStatus;
      this.updateStatus((isMyApprovalUpdated) => {
        if (isMyApprovalUpdated) {
          this.$emit('success', isMyApprovalUpdated, false);
        }
      });
    },
    close() {
      this.$emit('close');
    },
    remove() {
      this.remove_request((isMyApprovalDeleted) => {
        if (isMyApprovalDeleted) {
          this.$emit('success', isMyApprovalDeleted, false);
        }
      });
    },
    remove_request(callback) {
      axios
        .post(`/fort_night_log/delete_request?id=${this.payload.requestId}`)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Deleted Successfully',
            alertType: 'success',
          });
          callback(true);
        })
        .catch((_error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
  },
  created() {
    this.payload.endDate = moment(this.endDate).format('MMMM Do YYYY');
    this.payload.startDate = moment(this.startDate).format('MMMM Do YYYY');
    this.payload.logs = this.logs;
    this.payload.projectId = this.projectId;
    this.payload.projectManager = this.projectManager;
    this.payload.projectName = this.projectName;
    this.payload.requestId = this.requestId;
    this.payload.status = this.status;
    this.payload.userName = this.userName;
    this.payload.isDeleted = this.isDeleted;
  },
};
</script>
