<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
      >
        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div class="flex flex-row items-center">
            <!-- Search Input -->
            <div v-if="isReadPermission" class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="search"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <!-- Add NEW -->
            <div v-if="isManagePermission" class="hidden md:block">
              <div class="lg:ml-6 flex items-center">
                <button
                  @click="openAddMultiClientModal"
                  class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
                >
                  <span class="material-icons m-auto mr-1 text-base">add</span>
                  <span class="text-sm">Add New</span>
                </button>
              </div>
            </div>
            <!-- Add NEW END -->
          </div>
        </div>

        <Loading v-if="isLoading" style="height: 26rem" />
        <!-- Table -->

        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && clients.length > 0"
          >
            <table
              id="clients_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :class="['Client Name'].includes(column) ? 'text-left' : ''"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(client, index) in clients"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td class="px-4 py-2 text-xs">
                    <p align="center">
                      {{
                        paginationMeta.current_page * pageSize -
                        pageSize +
                        index +
                        1
                      }}
                    </p>
                  </td>
                  <td class="px-4 py-2 text-xs">
                    <p align="left">{{ client.client_name }}</p>
                  </td>
                  <td class="px-4 py-2 text-xs text-center">
                    <p>{{ client.description }}</p>
                  </td>
                  <td class="px-4">
                    <p align="center">
                      <span
                        v-if="client.status"
                        class="text-green-800 bg-green-200 font-semibold px-2 py-0 rounded-full"
                        >Active</span
                      >
                      <span
                        v-else
                        class="text-red-800 bg-red-200 font-semibold px-2 py-0 rounded-full"
                        >Inactive</span
                      >
                    </p>
                  </td>
                  <td v-if="isManagePermission">
                    <p align="center">
                      <span
                        @click="() => openEditDepartmentModal(client)"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-blue-500"
                        >edit</span
                      >
                    </p>
                  </td>
                  <td v-if="isManagePermission">
                    <p align="center">
                      <span
                        @click="() => onDelete(client.id)"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-red-500"
                      >
                        <i class="material-icons">delete</i>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
    </div>
    <div v-if="isReadPermission">
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchClients"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination -->
    <AddDepartmentModal
      v-if="isAddMultiClientModal && isManagePermission"
      @success="onSuccess"
      @close="closeAddMultiClientModal"
    ></AddDepartmentModal>
    <ConfirmAlertBox
      v-if="isConfirmDeleteModalVisible && isManagePermission"
      modalHeadline="Remove client?"
      deleteMessage="Do you want to remove the client?"
      @deleteRecordEvent="removeDepartment"
      @close="closeConfirmDeleteModal"
    ></ConfirmAlertBox>
    <EditDepartmentModal
      v-if="isEditClientModalVisible && isManagePermission"
      :clientId="selectedClientId"
      :clientName="clientName"
      :description="description"
      :status="status"
      @success="onSuccess"
      @close="closeEditClientModal"
    ></EditDepartmentModal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import EditDepartmentModal from './EditClientModal.vue';
import AddDepartmentModal from './AddClientModal.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'ClientTab',
  components: {
    Pagination,
    Loading,
    ConfirmAlertBox,
    EditDepartmentModal,
    AddDepartmentModal,
    ExportButtons,
    NoRecordFound,
    NotAuthorized,
  },
  data() {
    return {
      clientName: '',
      description: '',
      status: false,
      isLoading: true,
      isAddMultiClientModal: false,
      isConfirmDeleteModalVisible: false,
      isEditClientModalVisible: false,
      selectedClientId: null,
      allDepartments: [],
      query: '',
      columns: ['S. No.', 'Client Name', 'Description', 'Status', '', ''],
      clients: [],
      paginationMeta: {},
      pageSize: 30,
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  methods: {
    createPDF() {
      const doc = new jsPDF('l');
      var res = doc.autoTableHtmlToJson(
        document.getElementById('clients_table')
      );
      var columns = [
        res.columns[0],
        res.columns[1],
        res.columns[2],
        res.columns[3],
      ];
      doc.text(`Clients List: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable(columns, res.data);
      doc.save(`Clients_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchClients(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchClients(1);
    },
    getClientsPayload() {
      const { query } = this;
      const payload = {};
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchClients(page) {
      this.nonce = Date.now().toString();
      this.isLoading = true;
      const { pageSize } = this;
      const payload = this.getClientsPayload();
      payload['nonce'] = this.nonce;
      axios
        .get(
          `/manage/clients/clients_list?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }&nonce=${this.nonce}${this.query ? '&search=' + this.query : ''}`,
          payload
        )
        .then((response) => {
          const { nonce, meta, client } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.clients = client;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.clients = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchClients', error);
        });
    },
    exportToCSV() {
      const payload = this.getClientsPayload();
      axios
        .post(`/manage/clients/export_clients.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Clients_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          error('ERR while fetchClients', error);
        });
    },
    onDelete(clientId) {
      this.selectedClientId = clientId;
      this.isConfirmDeleteModalVisible = true;
    },
    removeDepartment() {
      const { selectedClientId } = this;
      if (selectedClientId) {
        axios
          .delete(`/manage/clients/${selectedClientId}`)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.closeConfirmDeleteModal();
            this.fetchClients(this.paginationMeta.current_page);
          })
          .catch((error) => {
            console.error('ERR while removeClient', error);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: error.response.data.msg,
              alertType: 'error',
            });
          });
      }
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    openAddMultiClientModal() {
      this.isAddMultiClientModal = true;
    },
    closeAddMultiClientModal() {
      this.isAddMultiClientModal = false;
    },
    openEditDepartmentModal(client) {
      const { id, client_name, description, status } = client;
      this.selectedClientId = id;
      this.clientName = client_name;
      this.description = description;
      this.status = status;
      this.isEditClientModalVisible = true;
    },
    closeEditClientModal() {
      this.isEditClientModalVisible = false;
    },
    onSuccess() {
      this.closeAddMultiClientModal();
      this.closeEditClientModal();
      if (this.isReadPermission) {
        this.fetchClients(this.paginationMeta.current_page); //reload
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchClients = _debounce(this.fetchClients, 400);
    this.isReadPermission = this.validatePermission([
      'manage.configurations.clients.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'manage.configurations.clients.manage',
    ]);
    this.isExportPermission = this.validatePermission([
      'manage.configurations.clients.export',
    ]);
    if (!this.isManagePermission) {
      this.columns = ['S. No.', 'Client Name', 'Description', 'Status'];
    }
    if (this.isReadPermission) {
      this.fetchClients(1);
    } else {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
