<template>
  <div class="container mx-auto">
    <div
      class="bg-white max-w-sm mx-auto rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition duration-500 transform hover:scale-105 cursor-pointer"
    >
      <div class="bg-green-500 flex items-center justify-start py-1">
        <!-- <h1 class="text-white border-2 rounded-full">
                      1
                    </h1> -->
        <p class="text-white text-sm mx-2">{{ data.name || '' }}</p>
        <!-- <p class="text-white font-thin text-sm">SAAS-0000</p> -->
      </div>

      <p class="py-4 px-3 text-xs tracking-wide text-center">
        {{ data.description || '' }}
      </p>
      <!-- <hr > -->
      <!-- <div
                    class="flex justify-between px-5 text-xs text-gray-400"
                  >
                    <p>Manage</p>
                    <p>Users</p>
                  </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionCard',
  data() {
    return {};
  },
  props: ['data'],
};
</script>

<style></style>
