<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ roleId ? 'Edit' : 'Add' }} Role:
                  <span class="text-gray-400 ml-2">{{
                    selectedRole.name || ''
                  }}</span>
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="flex-grow overflow-auto w-full">
            <form-generator
              :triggerSubmit="trigger"
              :formFields="formFields"
              :numberOfColumns="1"
              :groups="groups"
              @onFieldChange="onFieldChange"
              @onFormSubmit="onFormSubmit"
            />
          </div>

          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              @click.prevent="trigger++"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              v-if="roleId"
              type="button"
              @click="onDelete"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-700 text-base font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Delete
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ConfirmAlertBox
        v-if="isConfirmDeleteModalVisible"
        modalHeadline="Remove role?"
        deleteMessage="Are you sure?"
        @deleteRecordEvent="removeRole"
        @close="closeConfirmDeleteModal"
      ></ConfirmAlertBox>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import FormGenerator from '@/components/common/FormGenerator.vue';

import { required } from 'vuelidate/lib/validators';

export default {
  name: 'RoleModal',
  components: {
    ConfirmAlertBox,
    FormGenerator,
  },
  data() {
    return {
      isConfirmDeleteModalVisible: false,
      groups: [{ start: 0, end: 2, name: '' }],
      trigger: 0,
      formFields: [
        {
          name: 'name',
          label: 'Name',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'description',
          label: 'Description',
          required: true,
          type: 'text',
          defaultValue: '',
        },
      ],
      payload: {
        name: '',
        description: '',
      },
    };
  },
  props: {
    selectedRole: {
      name: String,
      description: String,
    },
    roleId: {
      required: true,
    },
  },
  methods: {
    onFieldChange(field, value) {
      this.payload[field.name] = value;
    },
    close() {
      this.$emit('close');
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    onDelete() {
      this.isConfirmDeleteModalVisible = true;
    },
    removeRole() {
      const { roleId } = this;
      if (roleId) {
        axios
          .delete(`/authorization/roles/${roleId}`)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.closeConfirmDeleteModal();
            this.$emit('success');
            // this.fetchPractices(this.paginationMeta.current_page);
          })
          .catch((error) => {
            console.error('ERR while removeRole', error);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Something went wrong. Please try again',
              alertType: 'error',
            });
          });
      }
    },
    updateRole(callback) {
      const { roleId, payload } = this;
      axios
        .put(`/authorization/roles/${roleId}`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(response.data);
        })
        .catch((error) => {
          const { data } = error.response;
          console.error('ERR while updateRole', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
            alertDuration: 8,
          });
          callback();
        });
    },
    createRole(callback) {
      const { payload } = this;
      axios
        .post(`/authorization/roles`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(response.data);
        })
        .catch((error) => {
          console.error('ERR while createRole', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
    onFormSubmit(isValid) {
      if (isValid) {
        const { roleId } = this;
        if (roleId) {
          this.updateRole((data) => {
            if (data) {
              this.$emit('success', data, true);
            }
          });
        } else {
          this.createRole((data) => {
            if (data) {
              this.$emit('success');
            }
          });
        }
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    convertResponseToPayload(data) {
      let payload = data;
      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = payload[formField.name];
      });
    },
  },
  created() {
    this.payload = JSON.parse(JSON.stringify(this.selectedRole));
    if (this.payload) {
      this.convertResponseToPayload(this.payload);
    }
  },
};
</script>
