<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        My Approvals (US)
      </div>
    </div>
    <!-- Top Bar END-->
    <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
      <CustomFilter
        v-if="showApprovalFilter"
        :filterBy="filterBy"
        @resetFilter="resetFilter"
        @toggleFilter="toggleFilter"
        @setFilterByProjects="setFilterByProjects"
        @setFilterBymyApprovalsStatus="setFilterBymyApprovalsStatus"
        @setFilterByUsers="setFilterByUsers"
        :projectOptionMeta="projectOptionMeta"
        :projectOptions="projectOptions"
        :userOptions="userOptions"
        @loadMore="loadMore"
        :myApprovalsStatus="myApprovalsStatus"
      />
      <div class="flex py-4 px-4 justify-between items-center">
        <!-- Icons -->
        <div class="flex items-center"></div>
        <!-- Icons END -->
        <div v-if="isReadPermission" class="flex flex-row items-center">
          <!-- Search Input -->
          <div class="relative text-gray-600" style="margin-right: 30px">
            <input
              v-model="query"
              @input="setQuery"
              type="search"
              name="serch"
              placeholder="Search"
              class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
            />
            <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
              <span class="material-icons md:text-2xl text-sm text-gray-400"
                >search</span
              >
            </button>
          </div>
          <!-- Search END -->
          <span
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleFilter"
            >filter_alt</span
          >
        </div>
      </div>
      <Loading v-if="isLoading" style="height: 26rem" />
      <div v-else>
        <!-- Table -->
        <div class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && reports.length > 0"
          >
            <table
              ref="table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :class="
                      ['Project Name'].includes(column)
                        ? 'text-left'
                        : 'text-center'
                    "
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    <span>{{ column }}</span>
                  </th>
                  <th
                    v-if="isManagePermission"
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    <span>X</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(report, index) in reports"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td class="px-4 text-xs">
                    <span v-if="isReadProjectPermission">
                      <router-link
                        :to="`/manage/projects/${report.request.project_id}`"
                      >
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          {{ report.request.project_name }}
                        </p>
                      </router-link>
                    </span>
                    <span v-else>
                      <p
                        class="text-xs text-black-500 cursor-pointer whitespace-nowrap"
                      >
                        {{ report.request.project_name }}
                      </p>
                    </span>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <span v-if="isReadUserPermission">
                      <router-link
                        :to="`/manage/users/${report.request.user_id}`"
                      >
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          {{
                            report.request.first_name +
                            ' ' +
                            report.request.last_name
                          }}
                        </p>
                      </router-link>
                    </span>
                    <span v-else>
                      <p
                        class="text-xs text-black-500 cursor-pointer whitespace-nowrap"
                      >
                        {{
                          report.request.first_name +
                          ' ' +
                          report.request.last_name
                        }}
                      </p>
                    </span>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <p class>
                      {{ globalDateFormat(report.request.start_date) }}
                    </p>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <p class>{{ globalDateFormat(report.request.end_date) }}</p>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <p class>
                      {{ globalDateFormat(report.request.created_at) }}
                    </p>
                  </td>
                  <td class="px-4 text-xs text-center">
                    <span
                      class="flex flex-col items-center py-1"
                      v-if="
                        report.request.status == 'pending' ||
                        report.request.status == 'submitted again'
                      "
                    >
                      <span class="text-xs px-5">Pending</span>
                      <button
                        v-if="isManagePermission"
                        class="bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-5 flex items-center text-sm text-center"
                        @click="() => openMyApprovalModal(report, false)"
                        style="width: 120px"
                      >
                        Approve/Reject
                      </button>
                    </span>
                    <span v-else class="flex flex-col items-center py-1">
                      <span
                        v-if="report.request.status == 'approved'"
                        class="text-xs px-5"
                        >Approved</span
                      >
                      <span v-else class="text-xs px-5">{{
                        (report.request.status &&
                          capitalizeFirstLetter(report.request.status)) ||
                        ''
                      }}</span>
                      <button
                        v-if="isManagePermission"
                        class="bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-6 h-5 flex items-center text-sm text-center"
                        @click="() => openMyApprovalModal(report, false)"
                        style="width: 120px"
                      >
                        View Logs
                      </button>
                    </span>
                  </td>

                  <td class="px-4 text-xs text-center">
                    <p class>{{ report.request.comment }}</p>
                  </td>
                  <td v-if="isManagePermission" class="px-2 text-xs">
                    <p>
                      <span
                        @click="() => openMyApprovalModal(report, true)"
                        class="material-icons m-auto mr-1 text-xl text-gray-500 cursor-pointer hover:text-red-500"
                      >
                        <i class="material-icons">delete</i>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
      </div>
      <!-- Table END -->
    </div>

    <!-- Pagination -->
    <div v-if="isReadPermission">
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchApprovalData"
        @setPageSize="setPageSize"
      />
    </div>
    <MyApprovalsModal
      v-if="isMyApprovalModalVisible && isManagePermission"
      :endDate="endDate"
      :startDate="startDate"
      :logs="logs"
      :projectId="projectId"
      :projectManager="projectManager"
      :projectName="projectName"
      :requestId="requestId"
      :status="status"
      :userName="userName"
      :isDeleted="isDeleted"
      @success="onSuccess"
      @close="closeMyApprovalModal"
    ></MyApprovalsModal>
  </div>
  <!-- Pagination End-->
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import MyApprovalsModal from '@/components/timesheet/MyApprovals/MyApprovalsModal.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'MyApprovals',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    MyApprovalsModal,
    NoRecordFound,
    NotAuthorized,
  },
  data() {
    return {
      IsDisabled: true,
      showApprovalFilter: false,
      projectOptions: [],
      userOptions: [],
      isMyApprovalModalVisible: false,
      projectOptionMeta: {},
      endDate: null,
      startDate: null,
      status: null,
      logs: [],
      projectId: null,
      projectManager: null,
      projectName: null,
      requestId: null,
      userName: null,
      isAdminUser: null,
      isDeleted: null,
      fortNightLogs: [],
      myApprovalsStatus: { label: 'Pending', value: 'pending' },
      filterBy: {
        projects: [],
        users: [],
        myApprovalsStatus: { label: 'Pending', value: 'pending' },
      },
      query: '',
      columns: [
        'Project Name',
        'Requested By',
        'Start Date',
        'End Date',
        'Requested on',
        'Status',
        'Comment',
      ],
      reports: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      defaultFilterBy: {},
      isReadPermission: false,
      isManagePermission: false,
      nonce: '',
    };
  },
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
  },
  created() {
    this.fetchApprovalData = _debounce(this.fetchApprovalData, 400);
    this.defaultFilterBy = { ...this.filterBy };
    this.isReadPermission = this.validatePermission([
      'timesheet.my_approvals.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'timesheet.my_approvals.manage',
    ]);
    if (this.isReadPermission) {
      this.fetchApprovalData(1);
      this.fetchProjectOptions(1);
      this.fetchUserOptions(1);
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchApprovalData(1);
    },
    toggleFilter() {
      this.showApprovalFilter = !this.showApprovalFilter;
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchUserOptions(1);
      this.fetchApprovalData(1);
    },
    setFilterByUsers(option) {
      this.filterBy.users = option;
      this.fetchApprovalData(1);
    },
    setFilterBymyApprovalsStatus(option) {
      this.filterBy.myApprovalsStatus = option;
      this.fetchApprovalData(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchApprovalData(1);
      }
    },
    loadMore(page) {
      this.fetchApprovalData(page);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchProjectOptions(1, true, query);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchApprovalData(1);
    },
    getUsersPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (filterBy.users.length > 0) {
        payload.users = filterBy.users;
      }
      if (filterBy.users) {
        payload.status = filterBy.myApprovalsStatus.value;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchApprovalData(page) {
      this.isLoading = true;
      const { pageSize } = this;
      const payload = this.getUsersPayload();
      this.nonce = Date.now().toString();
      const status =
        payload.status === 'pending'
          ? ['pending', 'submitted again']
          : payload.status;
      axios
        .get(
          `/timesheet/work_logs/approvals?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              project_id: payload.projects,
              user_id: payload.users,
              status: status,
              search: payload.search,
              nonce: this.nonce,
            },
          }
        )
        .then((response) => {
          const { nonce, meta, report, admin } = response.data;
          if (this.nonce !== nonce) return;
          this.paginationMeta = meta;
          this.reports = report;
          this.isAdminUser = admin;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    fetchProjectOptions(page, isSearch = false, query = null) {
      const { pageSize } = this;
      let payload = {};
      if (query) {
        payload.search = query;
      }
      axios
        .get(
          `/timesheet/work_logs/get_manager_projects?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`
        )
        .then((response) => {
          this.projectOptionMeta = response.data.meta;
          const projectOptions = response.data.projects.map((project) => {
            return { label: project.name, value: project.id };
          });
          this.projectOptions = isSearch
            ? projectOptions
            : this.projectOptions.concat(projectOptions);
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    fetchUserOptions() {
      const payload = this.getUsersPayload();
      axios
        .get(`/timesheet/work_logs/users_list`, {
          params: {
            project_id: payload.projects,
          },
        })
        .then((response) => {
          this.userOptions = this.filterUsers(response.data.users);
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchUsers', error);
        });
    },
    filterUsers(users) {
      const filteredUsers = [];
      users.forEach((user) => {
        filteredUsers.push({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
        });
      });
      return filteredUsers;
    },
    openMyApprovalModal(report, is_delete) {
      (this.endDate = report.request.end_date),
        (this.startDate = report.request.start_date),
        (this.logs = report.logs),
        (this.projectId = report.request.project_id),
        (this.projectManager = report.request.project_managers),
        (this.projectName = report.request.project_name),
        (this.requestId = report.request.id),
        (this.status = report.request.status),
        (this.userName =
          report.request.first_name + '' + report.request.last_name),
        (this.isDeleted = is_delete),
        (this.isMyApprovalModalVisible = true);
    },
    closeMyApprovalModal() {
      this.isMyApprovalModalVisible = false;
    },
    onSuccess() {
      this.fetchApprovalData(this.paginationMeta.current_page); //reload
      this.closeMyApprovalModal();
    },
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
