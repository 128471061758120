<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Finance Report
        </div>
        <div>
          <DateRangePickerInput
            v-if="isReadPermission"
            ref="homeDateRangePicker"
            @updateDateRange="setFilterByDateRange"
            :startDate="params && params.from_date"
            :endDate="params && params.to_date"
            popupAlign="center"
          />
        </div>
        <div>
          <button
            @click="exportToExcel"
            class="w-full text-gray-500 border border-gray-500 hover:bg-gray-500 hover:text-white px-4 py-1 tracking-wide text-white transition-colors duration-200 transform rounded-sm focus:outline-none"
          >
            Export
          </button>
        </div>
      </div>
      <!-- Top Bar END-->
      <AgGridTable
        v-if="isReadPermission"
        ref="financeReport"
        :perPage="perPage"
        :columnDefs="columnDefs"
        :url="url"
        :params="params"
        :excelFileName="'Finance Report.xlsx'"
      />
      <NotAuthorized v-else />
    </div>
  </div>
</template>

<script>
import AgGridTable from '@/components/tables/AgGridTable.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import moment from 'moment';

export default {
  name: 'FinanceReport',
  components: {
    AgGridTable,
    DateRangePickerInput,
    NotAuthorized,
  },
  data() {
    let today = new Date();
    return {
      isReadPermission: false,
      isExportPermission: false,
      perPage: 50,
      dateRange: {},
      columnDefs: [
        {
          field: 'employee_id',
          headerName: 'Employee ID',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          editable: false,
          pinned: 'left',
          cellStyle: (params) => {
            return (
              params.data &&
              params.data.flag && {
                backgroundColor:
                  params.data.flag === 'Y' ? '#FF7F7F' : '#90EE90',
              }
            );
          },
        },
        {
          field: 'employee_name',
          headerName: 'Employee Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'billability',
          sortable: true,
          cellRenderer: (params) => {
            // use with cellRendererSelector
            // const moodDetails = { component: 'SimpleCellRenderer' };
            // const genderDetails = {
            //   component: 'genderCellRenderer',
            //   params: {
            //     values: ['Male', 'Female'],
            //   },
            // };
            // return moodDetails
            return (
              params.value &&
              `<span class="${
                params.value.toLowerCase() === 'billable'
                  ? 'bg-green-300'
                  : 'bg-blue-300'
              } p-1 rounded-md">${params.value}</span>`
            );
          },
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'billing_rate',
          headerName: 'Billing Rate',
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'equals',
              'notEqual',
              'inRange',
              'lessThan',
              'lessThanOrEqual',
              'greaterThan',
              'greaterThanOrEqual',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'project_name',
          headerName: 'Project Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'project_code',
          headerName: 'Project Code',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'project_practice',
          headerName: 'Project Practice',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'practice',
          headerName: 'User Practice',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'location',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'emp_type',
          headerName: 'Emp Type',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'department_name',
          headerName: 'Department Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'team',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'client_name',
          headerName: 'Client Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'delivery_method',
          headerName: 'Delivery Method',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'primary_method',
          headerName: 'Primary Method',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
        },
        { field: 'exit_date', headerName: 'Exit Date', sortable: true },
        { field: 'joining_date', headerName: 'Joining Date', sortable: true },
        { field: 'from_date', headerName: 'From Date' },
        { field: 'to_date', headerName: 'To Date' },
        {
          field: 'total_hours',
          headerName: 'Sum of Hours',
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'equals',
              'notEqual',
              'inRange',
              'lessThan',
              'lessThanOrEqual',
              'greaterThan',
              'greaterThanOrEqual',
              'blank',
              'notBlank',
            ],
          },
        },
        {
          field: 'project_hours',
          headerName: 'Project Hours',
          sortable: true,
        },
        {
          field: 'cumulative_users_hours',
          headerName: 'Cumulative User Hours',
          sortable: true,
        },
        {
          field: 'working_hours_for_the_period',
          headerName: 'Available Working Hours',
        },
        { field: 'flag' },
      ],
      url: '/reports/finance_report',
      params: {
        from_date: new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).toDateString(),
        to_date: new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        ).toDateString(),
      },
    };
  },
  methods: {
    exportToExcel() {
      this.$refs.financeReport.exportToExcel();
    },
    setFilterByDateRange(dateRange) {
      const from_date = moment(dateRange.startDate).format('YYYY-MM-DD');
      const to_date = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.params = { ...this.params, from_date, to_date };
    },
  },
  created() {
    this.isReadPermission = this.validatePermission(['reports.finance.read']);
    this.isExportPermission = this.validatePermission([
      'reports.finance.export',
    ]);
  },
};
</script>
