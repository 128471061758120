<template>
  <div class="text-xs py-3 px-2 text-gray-500">
    {{ data.description }}
  </div>
</template>

<script>
export default {
  name: 'RolePermissionCard',
  props: ['data'],
};
</script>
