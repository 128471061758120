<script>
import { mixins, Pie } from 'vue-chartjs';
export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  data() {
    return {};
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {},
};
</script>
