<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Employment Type
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <div class="flex-grow overflow-auto w-full">
            <form-generator
              :triggerSubmit="trigger"
              :formFields="formFields"
              :numberOfColumns="1"
              :groups="groups"
              @onFieldChange="onFieldChange"
              @onFormSubmit="onFormSubmit"
            />
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              @click.prevent="trigger++"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Update
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';

import { required } from 'vuelidate/lib/validators';

export default {
  name: 'EditEmploymentModel',
  components: {
    FormGenerator,
  },
  data() {
    return {
      isLoading: true,
      groups: [{ start: 0, end: 3, name: '' }],
      formFields: [
        {
          name: 'name',
          label: 'Name',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'description',
          label: 'Description',
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'status',
          label: 'Status',
          switchLabels: ['Active', 'Inactive'],
          type: 'switch',
          defaultValue: '',
        },
      ],
      trigger: 0,
      payload: {
        status: false,
        name: null,
        description: null,
      },
    };
  },
  props: ['employmentId', 'employmentName', 'description', 'status'],
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onFieldChange(field, value, _allValues) {
      this.payload[field.name] =
        typeof value === 'string' ? value.replace(/\s+/g, ' ').trim() : value;
    },
    close() {
      this.$emit('close');
    },
    updateEmployments(callback) {
      const { payload, employmentId } = this;
      axios
        .put(`/manage/emp_types/${employmentId}`, {
          name: payload.name,
          status: payload.status,
          description: payload.description,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(true);
        })
        .catch((error) => {
          console.error('ERR while updateEmployments', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: error.response.data.msg,
            alertType: 'error',
          });
          callback();
        });
    },
    onFormSubmit(isValid, _values) {
      if (isValid) {
        this.updateEmployments((values) => {
          if (values) {
            this.$emit('success', values, false);
          }
        });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
  },
  created() {
    this.payload.name = this.employmentName;
    this.payload.description = this.description;
    this.payload.status = this.status;
    this.formFields.forEach((formField) => {
      // update Default for all input type
      formField.defaultValue = this.payload[formField.name];
    });
  },
};
</script>
