<template lang="html">
  <div
    class="fixed z-50 inset-0 overflow-y-auto overflow-x-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen min-w-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        @click="$emit('close')"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white sm:text-sm text-xs rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 sm:p-6 sm:pb-4">
          <div class="">Add Work Hours</div>
          <hr class="my-3" />
          <form-generator
            :triggerSubmit="trigger"
            :formFields="formFields"
            :numberOfColumns="1"
            :groups="groups"
            @onFieldChange="onFieldChange"
            @onFormSubmit="onFormSubmit"
          />
        </div>
        <div class="italic px-10 pb-6 text-red-800">
          Note: Time off/Leaves applied in Zoho will automatically reflect in
          HRMS. Therefore, manual entry for time off is disabled for full-time
          employees. Contractors can continue to enter logs here.
        </div>
        <div class="bg-gray-50 px-2 py-3 sm:px-6 flex flex-row justify-end">
          <button
            @click="$emit('close')"
            class="w-16 sm:w-20 py-2 mr-3 bg-white-400 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent shadow-lg"
          >
            Cancel
          </button>
          <button
            v-if="!hideSaveButton && isManagePermission"
            @click.prevent="trigger++"
            class="w-16 sm:w-20 py-2 mr-3 bg-blue-400 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent shadow-lg"
          >
            {{ isEdit ? 'Update' : 'Save' }}
          </button>
          <button
            v-if="isEdit && isManagePermission && !hideSaveButton"
            @click="$emit('deleteLog')"
            class="w-16 sm:w-20 py-2 bg-red-400 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent shadow-lg"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';
import { required } from 'vuelidate/lib/validators';
import RegEx from '@/components/inputs/RegEx';

export default Vue.extend({
  components: {
    FormGenerator,
  },
  data() {
    return {
      groups: [
        {
          start: 0,
          end: 2,
          name: '',
          numberOfColumns: 2,
          noBottomMargin: true,
        },
        { start: 2, end: 10, name: '' },
      ],
      trigger: 0,
      hideSaveButton: false,
      companyHolidays: [],
      formFields: [
        {
          name: 'startDate',
          label: 'Start Date',
          required: true,
          type: 'date',
          defaultValue: '',
          inputDisabled: false,
          minValue: null,
          maxValue: null,
          validations: {
            required,
          },
        },
        {
          name: 'endDate',
          label: 'End Date',
          required: true,
          inputDisabled: false,
          type: 'date',
          defaultValue: '',
          minValue: null,
          maxValue: null,
          validations: {
            required,
          },
        },
        {
          name: 'excludeWeekEnds',
          label: 'Exclude worklogs for weekends (Saturday and Sunday)',
          required: true,
          type: 'checkbox',
          checked: '',
          defaultValue: true,
          visibility: false,
        },
        {
          name: 'excludeCompanyHoliday',
          label: 'Exclude worklogs for Company Holiday',
          required: true,
          type: 'checkbox',
          checked: '',
          defaultValue: true,
          visibility: false,
        },
        {
          name: 'project',
          label: 'Project',
          required: true,
          type: 'select',
          inputDisabled: false,
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'category',
          label: 'Category',
          required: true,
          type: 'select',
          inputDisabled: false,
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'task',
          label: 'Issue ID/Task ID',
          required: true,
          inputDisabled: false,
          type: 'text',
          defaultValue: '',
          validations: {},
        },
        {
          name: 'hours',
          label: 'Hours',
          type: 'text',
          inputDisabled: false,
          defaultValue: '',
          minValue: 0,
          maxValue: 24,
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.LOG_HOURS),
            minValueText: (value) => this.validateMinValueText(value, 24),
            maxValueText: (value) => this.validateMaxValueText(value, 0.1),
          },
        },
        {
          name: 'description',
          label: 'Description',
          type: 'text_area',
          inputDisabled: false,
          defaultValue: '',
          validations: {
            required,
          },
        },
      ],
    };
  },
  props: [
    'newLog',
    'projects',
    'isEdit',
    'selectedUser',
    'isManagePermission',
    'maxDate',
    'minDate',
    'userId',
    'project_users',
    'userEmpType',
  ],
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onFieldChange(field, value, allValues) {
      if (field.name === 'project') {
        this.setOptions('project', 'defaultValue', allValues.project);
        this.fetchIssues(value.value, allValues);
      }
      if (field.name === 'hours') {
        this.setOptions('hours', 'defaultValue', allValues.hours);
      }
      if (field.name === 'task') {
        this.setOptions('task', 'defaultValue', allValues.task);
      }
      if (field.name === 'description') {
        this.setOptions('description', 'defaultValue', allValues.description);
      }
      if (field.name === 'excludeCompanyHoliday') {
        this.setOptions(
          'excludeCompanyHoliday',
          'defaultValue',
          allValues.excludeCompanyHoliday
        );
      }
      if (field.name === 'excludeWeekEnds') {
        this.setOptions(
          'excludeWeekEnds',
          'defaultValue',
          allValues.excludeWeekEnds
        );
      }
      if (field.name === 'startDate') {
        allValues.endDate = value;
        this.setOptions('startDate', 'defaultValue', allValues.startDate);
        this.setOptions('endDate', 'defaultValue', allValues.startDate);
        this.setOptions('endDate', 'minValue', value);
      }
      if (field.name === 'endDate') {
        this.setOptions('endDate', 'defaultValue', allValues.endDate);
      }
      if (field.name === 'startDate' || field.name === 'endDate') {
        this.setExcludeWeekEndCheckBox(allValues.startDate, allValues.endDate);
        this.setExcludeCompanyHolidayCheckBox(
          allValues.startDate,
          allValues.endDate
        );
      }
      if (field.name === 'category') {
        this.setOptions('category', 'defaultValue', allValues.category);
      }
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        this.$emit('save', values);
      }
    },
    filterIssues(issues) {
      return issues.map((issue) => {
        issue['label'] = issue.issue_number;
        issue['value'] = issue.id;
        return issue;
      });
    },
    fetchIssues(projectId, selectedValues) {
      const { selectedUser } = this;
      if (projectId) {
        axios
          .get(`/manage/issues/user/${selectedUser.value}/project/${projectId}`)
          .then((response) => {
            const { body } = this.newLog;
            const categories = this.filterIssues(response.data);
            const filteredCategories = categories.filter(
              (cat) => cat.id === (body && body.issue_id)
            );
            const selectedCategory =
              body && body.issue_id
                ? filteredCategories.length > 0
                  ? filteredCategories[0]
                  : categories.length > 0
                  ? categories[0]
                  : null
                : categories.length > 0
                ? categories[0]
                : null;
            // set default value of category based on selected project
            if (selectedValues) {
              selectedValues.category = selectedCategory;
              this.setOptions(
                'category',
                'defaultValue',
                selectedValues.category
              );
              this.setOptions(
                'project',
                'defaultValue',
                selectedValues.project
              );
            } else {
              this.setOptions('category', 'defaultValue', selectedCategory);
            }
            // update category options based on project selected
            this.setOptions('category', 'options', categories);
          })
          .catch((error) => {
            // handle error
            console.log('ERR while fetching issues', error);
          });
      }
    },
    setValueForAllFields(fieldAttr, data) {
      // set specific field values for all feilds
      const { formFields } = this;
      formFields.forEach((rowData) => {
        rowData[fieldAttr] = data;
      });
      this.formFields = formFields;
    },
    setOptions(fieldName, fieldAttr, data) {
      // set specific field values for specific feild
      const { formFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
    },
    async initializeForm() {
      const employmentType = {
        0: 'Full time',
        1: 'Contract',
      };
      const { newLog, minDate, maxDate } = this;
      const { start, end, body, calendarId: projectId } = newLog;
      const startDate = moment(start._date).format('YYYY-MM-DD');
      let endDate = moment(end._date).format('YYYY-MM-DD');
      await this.fetchHolidays();
      // To fix a bug where its showing wrong dates at midnight
      // if (!isAllDay) {
      //   endDate = moment(endDate).subtract(1, "hours").format("YYYY-MM-DD");
      // }

      // add options for projects
      const projectOptions = [];
      let result = this.projects.map((project) => ({
        ...project,
        ...this.project_users.find(
          (project_user) => project_user.project_id === project.id
        ),
      }));

      result.forEach((proj) => {
        let userEndDate = moment(proj.end_user_date).format('YYYY-MM-DD');
        let userStartDate = moment(proj.start_user_date).format('YYYY-MM-DD');
        // Add Selected project to options even if its inactive
        if (projectId && proj.id === projectId && this.isEdit) {
          projectOptions.push({ label: proj.name, value: proj.id });
        } else if (endDate <= userEndDate && startDate >= userStartDate) {
          if (
            proj.name != 'Company Holiday' &&
            ((this.userEmpType == employmentType[0] &&
              proj.name != 'Time Off') ||
              this.userEmpType == employmentType[1])
          ) {
            projectOptions.push({ label: proj.name, value: proj.id });
          }
        }
      });
      // update selected project
      let selectedProject;
      if (projectOptions && projectOptions.length > 0) {
        if (projectId) {
          // if its edit then show selected project as default
          selectedProject = projectOptions.find((p) => p.value === projectId);

          selectedProject =
            selectedProject === undefined ? projectOptions[0] : selectedProject;
          if (
            selectedProject.label == 'Time Off' &&
            this.userEmpType == employmentType[0]
          ) {
            this.setValueForAllFields('inputDisabled', true);
            this.hideSaveButton = true;
          }
          this.fetchIssues(selectedProject.value, null);
        } else {
          // if its create then show first option as selected project
          selectedProject = projectOptions[0];
          this.fetchIssues(selectedProject.value, null);
        }
      }
      this.setOptions('project', 'options', projectOptions);
      this.setOptions('project', 'defaultValue', selectedProject);

      this.setOptions('startDate', 'defaultValue', startDate);
      this.setOptions('endDate', 'defaultValue', endDate);

      this.setOptions('startDate', 'minValue', minDate);
      this.setOptions('startDate', 'maxValue', maxDate);
      this.setOptions('endDate', 'minValue', startDate);
      this.setOptions('endDate', 'maxValue', maxDate);
      this.setExcludeWeekEndCheckBox(startDate, endDate);
      this.setExcludeCompanyHolidayCheckBox(startDate, endDate);
      // initialize default values while editing
      if (this.isEdit) {
        const { hours, description, task } = body;
        this.setOptions('hours', 'defaultValue', parseFloat(hours));
        this.setOptions('description', 'defaultValue', description);
        this.setOptions('task', 'defaultValue', task);

        this.setOptions('startDate', 'inputDisabled', true);
        this.setOptions('endDate', 'inputDisabled', true);

        // If user does not have managePermission disable inputs and hide buttons
        if (!this.isManagePermission) {
          this.setValueForAllFields('inputDisabled', true);
          this.hideSaveButton = true;
        }

        // Inputs will be disabled if date is out of the range i,e (>maxdate or <minDate)
        if (
          (moment(start._date) < minDate || moment(end._date) > maxDate) &&
          this.selectedUser &&
          this.selectedUser.value === this.userId
        ) {
          this.setValueForAllFields('inputDisabled', true);
          this.hideSaveButton = true;
        }
      }
    },
    checkExcludeWeekends(startDate, endDate) {
      const momentStartDate = moment(startDate).format('YYYY-MM-DD');
      const momentEndDate = moment(endDate).format('YYYY-MM-DD');
      if (
        momentStartDate != momentEndDate &&
        !(
          moment(momentEndDate).diff(momentStartDate, 'days') === 1 &&
          moment(startDate).day() === 6 &&
          moment(endDate).day() === 0
        )
      ) {
        return this.isWeekendIncluded(momentStartDate, momentEndDate);
      }
      return false;
    },

    checkIsCompanyHoliday(startDate, endDate) {
      let momentStartDate = moment(startDate).format('YYYY-MM-DD');
      let momentEndDate = moment(endDate).format('YYYY-MM-DD');
      while (momentStartDate <= momentEndDate) {
        if (this.companyHolidays.includes(momentStartDate)) {
          return true;
        }
        momentStartDate = moment(momentStartDate)
          .add(1, 'days')
          .format('YYYY-MM-DD');
      }
      return false;
    },

    setExcludeCompanyHolidayCheckBox(startDate, endDate) {
      const isCompanyHolidayCheckboxVisible = this.checkIsCompanyHoliday(
        startDate,
        endDate
      );

      this.setOptions(
        'excludeCompanyHoliday',
        'visibility',
        isCompanyHolidayCheckboxVisible
      );
      this.setOptions(
        'excludeCompanyHoliday',
        'defaultValue',
        isCompanyHolidayCheckboxVisible
      );
    },

    setExcludeWeekEndCheckBox(startDate, endDate) {
      const isWeekendCheckboxVisible = this.checkExcludeWeekends(
        startDate,
        endDate
      );
      this.setOptions(
        'excludeWeekEnds',
        'visibility',
        isWeekendCheckboxVisible
      );
      this.setOptions(
        'excludeWeekEnds',
        'defaultValue',
        isWeekendCheckboxVisible
      );
    },

    async fetchHolidays() {
      const { selectedUser } = this;
      let payload = {};
      payload.user = selectedUser.value;
      payload.year = moment().year();
      await axios
        .post(`/holidays/holidays_by_user_location`, payload)
        .then((response) => {
          if (response.data.holidays.length > 0) {
            response.data.holidays.forEach((data) => {
              this.companyHolidays.push(data.date);
            });
          }
        })
        .catch((error) => {
          this.holidays = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetching company holidays', error);
        });
    },
  },
  created() {
    this.initializeForm();
  },
});
</script>
