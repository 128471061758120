<template lang="html">
  <div class="">
    <div class="bg-gray-600 h-full dark:bg-gray-700">
      <nav class="">
        <div class="pt-2">
          <div :key="subNavItem" v-for="subNavItem in Object.keys(subNavItems)">
            <router-link :to="subNavItems[subNavItem].link">
              <div
                :class="[
                  selectedSubNav === subNavItem ? activeClass : 'bg-red',
                ]"
                class="w-full font-thin text-white flex items-center md:px-2 px-1 py-1 transition-colors duration-200 justify-start"
              >
                <span
                  class="md:px-2 px-1 py-1 rounded-l rounded-r md:text-sm text-xxs font-normal overflow-ellipsis overflow-hidden w-full hover:bg-gray-700"
                  :class="
                    selectedSubNav === subNavItem ? activeSubNavClass : ''
                  "
                >
                  {{ subNavItems[subNavItem].title }}
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    activeClass: 'text-blue-400',
    activeSubNavClass: 'bg-gray-700',
  }),
  computed: {
    ...mapState('settings', ['selectedSubNav']),
  },
  props: ['subNavItems'],
};
</script>
