<template>
  <div style="width: 100%; height: 80vh" class="mt-2">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
      :defaultColDef="defaultColDef"
      :rowSelection="rowSelection"
      :rowModelType="rowModelType"
      :pagination="true"
      :paginationPageSize="perPage"
      :cacheBlockSize="perPage"
      :animateRows="true"
      :columnTypes="columnTypes"
    ></ag-grid-vue>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import axios from 'axios';

export default {
  name: 'AgGridTable',
  data: function () {
    return {
      agGridParams: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        flex: 1,
        resizable: true,
        // minWidth: 100,
      },
      rowBuffer: null,
      rowSelection: null,
      rowModelType: null,
      cacheBlockSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      sortModel: [],
      columnTypes: {},
    };
  },
  components: {
    AgGridVue,
  },
  props: {
    perPage: Number,
    columnDefs: Array,
    params: Object,
    url: String,
    enumValues: Object,
    excelFileName: String,
    defaultFilter: Object,
  },
  watch: {
    params: function (newVal, oldVal) {
      // watch it
      this.updateDataSource();
    },
  },
  created() {
    this.setColumnTypes();
    this.rowBuffer = 0;
    this.rowSelection = 'multiple';
    this.rowModelType = 'infinite';
    this.cacheBlockSize = this.perPage;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1000;
    this.maxBlocksInCache = 10;
  },
  methods: {
    setColumnTypes() {
      let tmpColumnTypes = {};
      if (this.enumValues) {
        Object.keys(this.enumValues).forEach((columnName) => {
          tmpColumnTypes[`enumColumn.${columnName}`] = {
            ...this.getEnumColumnParams(this.enumValues[columnName]),
          };
        });
      }
      this.columnTypes = { ...tmpColumnTypes };
    },
    getEnumColumnParams(enumMap) {
      return {
        cellRenderer: (params) => {
          if (!params.data) return '';
          const { value } = params;
          return value;
        },
        filterParams: {
          buttons: ['apply', 'reset'],
          closeOnApply: true,
          filterOptions: [
            'empty',
            ...Object.keys(enumMap).map((key) => {
              return {
                displayKey: 'enum.' + enumMap[key],
                displayName: key,
                predicate: function (filterValue, cellValue) {
                  return cellValue === key;
                },
                numberOfInputs: 0,
              };
            }),
          ],
          suppressAndOrCondition: false,
        },
      };
    },
    exportToExcel() {
      const filterModel = this.gridApi.getFilterModel();
      axios
        .get(this.url, {
          params: {
            ...this.params,
            page_size: this.perPage,
            sort: JSON.stringify(this.sortModel),
            filter: JSON.stringify(filterModel),
            type: 'excel',
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.excelFileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error(
            'Error while Fething Full Time to Contractor Report',
            error
          );
        });
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onPaginationChanged(params) {
      // console.log({ params });
      // this.updateDataSource(params);
    },
    updateDataSource() {
      if (!this.gridApi) {
        return;
      }
      const dataSource = {
        getRows: (params) => {
          // console.log({ params });
          // Use startRow and endRow for sending pagination to Backend
          // params.startRow : Start Page
          // params.endRow : End Page
          // `https://jsonplaceholder.typicode.com/posts?_page=${page}&_limit=${this.perPage}`

          this.sortModel = params.sortModel;
          const page = params.endRow / this.perPage;
          axios
            .get(this.url, {
              params: {
                ...this.params,
                page,
                page_size: this.perPage,
                sort: JSON.stringify(params.sortModel),
                filter: JSON.stringify(params.filterModel),
              },
            })
            .then((res) => {
              // console.log(res)
              params.successCallback(
                res.data.data || [],
                (res.data.meta && res.data.meta.total_count) || 0
              );
            })
            .catch((error) => {
              params.successCallback([], 0);
            });
        },
      };
      this.gridApi.setDatasource(dataSource);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.agGridParams = params;
      this.gridApi.setFilterModel(this.defaultFilter);
      this.updateDataSource();
    },
    onFirstDataRendered() {
      this.sizeToFit();
      this.autoSizeAll(false);
    },
  },
};
</script>

<style scope>
.ag-header-cell-filtered {
  background-color: #1b6d85 !important;
  color: #fff !important;
}

.ag-header-cell-filtered span {
  color: #fff !important;
}
</style>
