<template lang="html">
  <div class="static">
    <div class="absolute top-4 md:right-4 right-1">
      <div
        class="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800"
      >
        <div class="flex items-center justify-center w-12 bg-green-500">
          <svg
            class="w-6 h-6 text-white fill-current"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
            />
          </svg>
        </div>

        <div class="px-4 md:py-1 py-1 -mx-3">
          <div class="mx-3">
            <span
              class="font-semibold text-green-500 dark:text-green-400 md:text-sm text-sm"
              >Success</span
            >
            <p class="md:text-xs text-xs text-gray-600 dark:text-gray-200">
              {{ message }}
            </p>
          </div>
        </div>

        <div
          class="bg-gray-100 flex items-center justify-center cursor-pointer"
          @click="$emit('closeAlert')"
        >
          <div class="mx-2 mt-1">
            <span class="material-icons m-auto">close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isActive', 'message'],
};
</script>
