<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Add Skill:
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="mx-3">
            <div class="flex-grow overflow-auto w-full">
              <form-generator
                :triggerSubmit="trigger"
                :formFields="formFields"
                :numberOfColumns="1"
                :groups="groups"
                @onFieldChange="onFieldChange"
                @onFormSubmit="onFormSubmit"
              />
            </div>
          </div>

          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              @click.prevent="trigger++"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';

import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'AddSkillModal',
  components: {
    FormGenerator,
  },
  data() {
    return {
      isLoading: true,
      trigger: 0,
      groups: [{ start: 0, end: 2, name: '' }],
      formFields: [
        {
          name: 'category',
          label: 'Category',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },

        {
          name: 'skill_name',
          label: 'Skill Name',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
      ],
      category: [],
      payload: {
        category: null,
        skill_name: null,
      },
    };
  },
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onFieldChange(field, value, _allValues) {
      this.payload[field.name] = value;
    },
    close() {
      this.$emit('close');
    },
    addSkill(callback) {
      const { payload } = this;
      axios
        .post(`/manage/skills/create`, {
          category: payload.category,
          skill_name: payload.skill_name,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(true);
        })
        .catch((error) => {
          let errMsg = 'Something went wrong. Please try again';
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            errMsg = error.response.data.msg[0];
          }
          console.error('ERR while addSkill', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: errMsg,
            alertType: 'error',
          });
          callback();
        });
    },

    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((colData) => {
        if (colData.name === fieldName) {
          colData.options = data;
        }
      });
      this.formFields = formFields;
    },

    fetchSkills() {
      axios
        .get(`manage/skills`)
        .then((response) => {
          const filteredCategory = [];
          response.data.forEach((category) => {
            if (!filteredCategory.includes(category.category)) {
              filteredCategory.push(category.category);
            }
          });
          this.setOptions('category', 'options', filteredCategory);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchSkills', error);
        });
    },
    onFormSubmit(isValid, values) {
      values['category'] = values['category'].label;
      if (isValid) {
        this.addSkill((values) => {
          if (values) {
            this.$emit('success', values);
          }
        });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
  },
  created() {
    this.isLoading = false;
    this.fetchSkills();
  },
});
</script>
