

















































import Vue from 'vue';
import AddMemberModal from './AddMemberModal.vue';
import Accordion from './accordion.vue';
import MembersTable from './MembersTable.vue';

export default Vue.extend({
  name: 'ProjectMembers',
  components: {
    AddMemberModal,
    Accordion,
  },
  data() {
    return {
      isAddMemberModalVisible: false,
      accordionItems: [
        {
          id: 1,
          active: true,
          title: 'Active',
          component: MembersTable,
          props: null,
          html: '',
        },
        {
          id: 2,
          active: false,
          title: 'Inactive',
          component: MembersTable,
          props: null,
          html: '',
        },
      ] as any,
      isReadPermission: false,
      isManagePermission: false,
    };
  },
  computed: {
    totalMembers() {
      const { accordionItems } = this as any;
      return accordionItems[0].count + accordionItems[1].count;
    },
  },
  props: [
    'members',
    'projectId',
    'projectName',
    'projectStatus',
    'defaultProject',
    'projectEndDate',
    'projectStartDate',
  ],
  methods: {
    openAddMemberModal() {
      this.isAddMemberModalVisible = true;
    },
    closeAddMemberModal() {
      this.isAddMemberModalVisible = false;
    },
  },
  created() {
    this.isReadPermission = (this as any).validatePermission([
      'manage.projects.read',
    ]);
    this.isManagePermission = (this as any).validatePermission([
      'manage.projects.manage',
    ]);
    const {
      projectId,
      projectName,
      members,
      defaultProject,
      projectEndDate,
      projectStartDate,
    } = this;
    const activeMembers: Array<any> = [];
    const inactiveMembers: Array<any> = [];
    members.forEach((member: any) => {
      if (
        member.project_user_details &&
        member.project_user_details.project_user_status
      ) {
        activeMembers.push(member);
      } else {
        inactiveMembers.push(member);
      }
    });

    this.accordionItems[0].props = {
      members: activeMembers,
      projectId,
      projectName,
      projectStatus: this.projectStatus,
      defaultProject,
    };
    this.accordionItems[1].props = {
      members: inactiveMembers,
      projectId,
      projectName,
      projectStatus: this.projectStatus,
      defaultProject,
    };
    this.accordionItems[0].count = activeMembers.length;
    this.accordionItems[1].count = inactiveMembers.length;
  },
});
