




































































































































































































import Vue from 'vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import Pagination from '@/components/common/Pagination.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import _debounce from 'lodash/debounce';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import Loading from '@/components/common/Loading.vue';
import _round from 'lodash/round';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'OverallPerformance',
  computed: { ...mapGetters('user', ['isReadUserPermission']) },
  components: {
    DateRangePickerInput,
    ExportButtons,
    Loading,
    NotAuthorized,
    NoRecordFound,
    Pagination,
  },
  data() {
    return {
      startDate: moment().startOf('year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      isExportPermission: true,
      isReadPermission: true,
      query: '',
      isLoading: true,
      pageSize: 30,
      performanceData: {},
      paginationMeta: {},
      dateRanges: {
        'This year': [
          moment().startOf('year').toDate(),
          moment().endOf('year').toDate(),
        ],
        'Previous year': [
          moment().subtract(1, 'year').startOf('year').toDate(),
          moment().subtract(1, 'year').endOf('year').toDate(),
        ],
        All: [
          moment('201901', 'YYYYMM').startOf('year').toDate(),
          moment().endOf('year').toDate(),
        ],
        'Last 7 Days': [moment().subtract(7, 'd').toDate(), moment().toDate()],
        'Last 30 Days': [
          moment().subtract(29, 'd').toDate(),
          moment().toDate(),
        ],
        'Last month': [
          moment().subtract(1, 'months').startOf('month').toDate(),
          moment().subtract(1, 'months').endOf('month').toDate(),
        ],
      },
    };
  },
  methods: {
    setFilterByDateRange(dateRange: any) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.startDate = startDate;
      this.endDate = endDate;
      this.getData(1);
      //   this.filterBy.dateRange = { startDate, endDate };
    },
    createPDF() {
      const doc: any = new jsPDF('l', 'mm', [297, 500]);
      doc.text(
        `Recruiter Overall Performance : ${moment().format('LLLL')}`,
        15,
        10
      );
      doc.autoTable({
        html: '#recruiter_overall_utilization_table',
      });
      doc.save(
        `recruiter_overall_performance_${moment().format('yyyy_MM_DD')}.pdf`
      );
    },
    exportToCSV() {
      const { startDate, endDate, query } = this;
      axios
        .get('/recruitment/requirements/recruiter_overall_performance_csv', {
          params: {
            search: query ? query : null,
            from_date: startDate,
            to_date: endDate,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link: any = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `recruiter_overall_performance_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setQuery(e: any) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.getData(1);
      }
    },
    setPageSize(value: any) {
      this.pageSize = value;
      this.getData(1);
    },
    getData(page: number) {
      this.isLoading = true;
      const nonce = Date.now().toString();
      const { pageSize, startDate, endDate, query } = this;
      const generated_on = moment().format('YYYY-MM-DD');
      axios
        .get(`/recruitment/requirements/recruiter_overall_performance`, {
          params: {
            search: query ? query : null,
            page_size: pageSize,
            page,
            from_date: startDate,
            to_date: endDate,
            nonce,
            generated_on,
          },
        })
        .then((res) => {
          const { data } = res;
          const { nonce: respNonce, recruiter_performance, meta } = data;
          if (respNonce !== nonce) {
            return;
          }
          this.isLoading = false;
          this.performanceData = recruiter_performance;
          this.paginationMeta = meta;
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
        });
    },
    calcAvgTimeToClose(record: any) {
      const {
        total_days: totalDays,
        total_postions_closed: totalPositionsClosed,
      } = record;
      if (totalDays > 0 && totalPositionsClosed > 0) {
        return _round(Math.floor(totalDays / totalPositionsClosed)) || 0;
      }
      return 0;
    },
  },
  created() {
    this.getData = _debounce(this.getData, 400);
    this.isReadPermission = (this as any).validatePermission([
      'recruitment.overall_performance.read',
    ]);
    this.isExportPermission = (this as any).validatePermission([
      'recruitment.overall_performance.export',
    ]);
    if (this.isReadPermission) {
      this.getData(1);
    }
  },
});
