<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ requirementName }}
                </h3>
              </div>
              <!-- Search Input -->
              <div class="relative text-gray-600">
                <input
                  v-model="query"
                  @input="setQuery"
                  type="search"
                  name="serch"
                  placeholder="Search"
                  class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
                />
                <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                  <span class="material-icons md:text-2xl text-sm text-gray-400"
                    >search</span
                  >
                </button>
              </div>
              <button
                type="button"
                @click="close"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
              <!-- Search END -->
            </div>
          </div>

          <div class="bg-white py-3 ml-3 mr-3">
            <div class="dark:bg-gray-800 dark:bg-gray-800 shadow rounded">
              <!-- Table -->
              <Loading v-if="isLoading" class="h-full" />
              <div v-else class="overflow-auto" style="max-height: 48vh">
                <div v-if="requirement_profiles.length > 0">
                  <table
                    id="profile_table"
                    class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-auto"
                  >
                    <thead class="bg-gray-200">
                      <tr class="text-gray-600 text-left">
                        <th
                          class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                          :key="index"
                          v-for="(column, index) in columns"
                        >
                          {{ column }}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="divide-y divide-gray-200 text-xs text-gray-700"
                      v-if="requirement_profiles.length > 0"
                    >
                      <template v-for="(status, index) in status_lists">
                        <template v-for="(profile, i) in requirement_profiles">
                          <tr
                            :key="index + i"
                            class="py-2"
                            v-if="status == profile.status"
                            v-bind:class="fixbackground(profile.status)"
                          >
                          <td class="px-4 py-4"
                    >
                      <div>
                        <p
                          @click="
                            openAddInterviewModal(
                              selectedRequirement,
                              profile,
                              profile.id
                            )
                          "
                          class="text-xs font-semibold text-blue-500 cursor-pointer"
                        >
                          <button
                            class="flex-row bg-blue-400 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-blue-400 rounded text-white px-2 h-8 flex items-center text-sm"
                          >
                            <!-- <span class="material-icons m-auto mr-2">add</span> -->

                            <span class="text-sm"> Add Interview</span>
                          </button>
                        </p>
                      </div>
                    </td>
                    <td class="px-4 py-4"
                    >
                      <div
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        @click="
                          toggleViewInterviewModal(
                            selectedRequirement,
                            profile,
                            profile.id
                          )
                        "
                      >
                        <span
                          class="material-icons md:text-xl text-sm mr-0 cursor-pointer"
                          >visibility</span
                        >
                      </div>
                    </td>
                            <td class="px-4 py-4">
                              <div>
                                <p
                                  @click="
                                    openEditProfileModal(
                                      profile.id,
                                      requirementId,
                                      requirementName,
                                      profile
                                    )
                                  "
                                  class="text-xs font-semibold text-blue-500 cursor-pointer"
                                >
                                  {{ profile.first_name }}
                                </p>
                              </div>
                            </td>
                            <td class="px-4">
                              <p class="">
                                {{
                                  profile.last_name ? profile.last_name : ' '
                                }}
                              </p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">
                                {{
                                  profile.experience ? profile.experience : ' '
                                }}
                              </p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">
                                {{
                                  profile.relevant_experience
                                    ? profile.relevant_experience
                                    : ''
                                }}
                              </p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.status }}</p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">{{ profile.notice_period }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.offered_date }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.joining_date }}</p>
                            </td>
                            <td
                              class="px-4"
                              style="
                                max-width: 150px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                              "
                            >
                              <span :content="profile.comments" v-tippy>
                                <p class="text-xs whitespace-nowrap">
                                  {{ profile.comments ? profile.comments : '' }}
                                </p>
                              </span>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.currnet_organization }}</p>
                            </td>
                            <td class="px-4">
                              <span
                                v-if="profile.resume"
                                style="
                                  max-width: 150px;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                                class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                                :content="profile.resume"
                                v-tippy
                              >
                                <p class="text-xs whitespace-nowrap">
                                  <a :href="profile.resume" target="_blank">
                                    {{ wrapMethod(profile.resume) }}
                                  </a>
                                </p>
                              </span>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.email }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.phone }}</p>
                            </td>
                            <td class="px-4">
                              <span
                                v-if="profile.skill_set"
                                style="
                                  max-width: 150px;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                                class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                                :content="profile.skill_set"
                                v-tippy
                              >
                                <p class="text-xs whitespace-nowrap">
                                  {{ wrapMethod(profile.skill_set) }}
                                </p>
                              </span>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.hiring_location }}</p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">{{ profile.current_ctc }}</p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">{{ profile.expected_rate }}</p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">{{ profile.source_name }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.designation }}</p>
                            </td>
                            <td class="px-4">
                              <span
                                v-if="profile.skype_id"
                                style="
                                  max-width: 150px;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                                class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                                :content="profile.skype_id"
                                v-tippy
                              >
                                <p class="">
                                  {{ wrapMethod(profile.skype_id) }}
                                </p>
                              </span>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">{{ profile.willing_to_relocate }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.location }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.work_authorization }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.visa_valid }}</p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">{{ profile.ssn_number }}</p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">
                                {{ profile.holding_project_value }}
                              </p>
                            </td>
                            <td class="px-4 text-center">
                              <p class="">
                                {{ profile.vacation_plans_value }}
                              </p>
                            </td>
                            <td class="px-4">
                              <span
                                v-if="profile.linkedin_url"
                                style="
                                  max-width: 150px;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                                class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                                :content="profile.linkedin_url"
                                v-tippy
                              >
                                <p class="text-xs whitespace-nowrap">
                                  <a
                                    :href="profile.linkedin_url"
                                    target="_blank"
                                  >
                                    {{ wrapMethod(profile.linkedin_url) }}
                                  </a>
                                </p>
                              </span>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.department_name }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">{{ profile.project_planned }}</p>
                            </td>
                            <td class="px-4">
                              <span
                                v-if="profile.certification_url"
                                style="
                                  max-width: 150px;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                                class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                                :content="profile.certification_url"
                                v-tippy
                              >
                                <p class="text-xs whitespace-nowrap">
                                  <a
                                    :href="profile.certification_url"
                                    target="_blank"
                                  >
                                    {{ wrapMethod(profile.certification_url) }}
                                  </a>
                                </p>
                              </span>
                            </td>
                            <td class="px-2 text-center">
                              <p class="">{{ profile.rating }}</p>
                            </td>
                            <td class="px-4">
                              <p class="">
                                {{
                                  (profile.submitted_on &&
                                    globalDateFormat(profile.submitted_on)) ||
                                  ''
                                }}
                              </p>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div
                  v-else
                  class="dark:bg-gray-800 dark:bg-gray-800 shadow rounded text-center"
                >
                  <span>No data available in table</span>
                </div>
                <!-- Table END -->
              </div>
              <!-- Pagination -->
              <Pagination
                class="mt-2"
                v-if="Object.keys(paginationMeta).length > 0"
                :meta="paginationMeta"
                :pageSize="pageSize"
                @clickCallback="fetchProfiles"
                @setPageSize="setPageSize"
              />
            </div>
          </div>
        </div>
      </div>
      <EditProfileModal
        :requirementId="requirementId"
        :requirementName="requirementName"
        :isEdit="true"
        :selectedProfileData="selectedProfileData"
        :profileId="selectedProfileId"
        v-if="isEditProfileModalVisible"
        @close="closeEditProfileModal"
      />
      <AddInterviewModal
      :requirementName="requirementName"
      :profileId="selectedProfileId"
      :selectedRequirement="selectedRequirement"
      :selectedProfileData="selectedProfileData"
      v-if="isAddInterviewModalVisible"
      @close="closeAddInterviewModal"
    />

    <ViewInterviewModal
      :requirementName="requirementName"
      :profileId="selectedProfileId"
      :selectedRequirement="selectedRequirement"
      :selectedProfileData="selectedProfileData"
      v-if="isViewInterviewModalVisible"
      @close="closeViewInterviewModal"
    />
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import EditProfileModal from '../../../components/recruitment/requirementList/EditProfileModal.vue';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '../../../components/common/Loading.vue';
import AddInterviewModal from '../../../components/recruitment/requirementList/AddInterviewModal.vue';
import ViewInterviewModal from '../../../components/recruitment/requirementList/ViewInterviewModal.vue';
export default Vue.extend({
  name: 'ViewProfileModal',
  data() {
    return {
      columns: [
        'Action',
        'View',
        'First Name',
        'Last Name',
        'Total Exp',
        'Relevant Exp',
        'Profile Status',
        'Notice Period',
        'Offered Date',
        'Joining Date',
        'Comments',
        'Current Organisation',
        'Resume',
        'E-Mail',
        'Phone',
        'Skill Set',
        'Hiring Location',
        'Current CTC',
        'Expected Rate',
        'Source',
        'Designation',
        'Skype',
        'Wiling to Relocate',
        'Current Location',
        'Work Authorization',
        'Visa Validity',
        'SSN',
        'Current Project',
        'Vacation Plans',
        'LinkedIn Url',
        'Department',
        'Project Planned',
        'Certification Url',
        'Rating',
        'Submitted On',
      ],
      query: '',
      isEditProfileModalVisible: false,
      selectedProfileId: null,
      selectedProfileData: {},
      status_lists: [
        'Joined',
        'Offered and yet to join',
        'Candidate selected',
        'Interview scheduled',
        'Candidate submitted',
        'Declined offer or not joined',
        'On hold',
        'Candidate rejected',
      ],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      requirement_profiles: {},
      isAddInterviewModalVisible: false,
      isViewInterviewModalVisible: false,
    };
  },
  props: {
    requirementName: {
      type: String,
    },
    requirementId: {
      type: Number,
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
    selectedRequirement: {
      type: Object,
    },
  },
  components: {
    EditProfileModal,
    Pagination,
    Loading,
    AddInterviewModal,
    ViewInterviewModal,
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    wrapMethod(sentence) {
      return sentence.length > 20
        ? sentence.substring(0, 20) + '...'
        : sentence;
    },
    fixbackground(status) {
      switch (status) {
        case 'Joined':
          return 'joined';
        case 'Offered and yet to join':
          return 'offered_and_yet_to_join';
        case 'Candidate selected':
          return 'candidate_selected';
        case 'Candidate submitted':
          return 'candidate_submitted';
        case 'Interview scheduled':
          return 'interview_scheduled';
        case 'Candidate rejected':
          return 'candidate_rejected';
        case 'Declined offer or not joined':
          return 'declined_offer_or_Not_joined';
        case 'On hold':
          return 'on_hold';
        default:
          return '';
      }
    },
    close() {
      this.$emit('close');
    },
    openEditProfileModal(profileId, requirementId, requirementName, profile) {
      this.requirementId = requirementId;
      this.requirementName = requirementName;
      this.selectedProfileId = profileId;
      this.selectedProfileData = profile;
      this.isEditProfileModalVisible = true;
    },

    openAddInterviewModal(selectedRequirement,profile,profileId) {
      this.selectedProfileId = profileId;
      this.selectedRequirement = selectedRequirement;
      this.isAddInterviewModalVisible = true;
      this.selectedProfileData = profile;
    },

    toggleViewInterviewModal(selectedRequirement,profile,profileId) {
      this.selectedProfileId = profileId;
      this.selectedRequirement = selectedRequirement;
      this.selectedProfileData = profile;
      this.isViewInterviewModalVisible = !this.isViewInterviewModalVisible;
    },
    closeEditProfileModal() {
      this.isLoading = true;
      this.fetchProfiles(1);
      this.isEditProfileModalVisible = false;
    },
    closeViewInterviewModal() {
      this.isViewInterviewModalVisible = false;
    },
     closeAddInterviewModal() {
      //this.fetchProfiles(1);
      this.isAddInterviewModalVisible = false;
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchProfiles(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchProfiles(1);
      }
    },
    fetchProfiles(page) {
      const { pageSize } = this;
      this.requirement_profiles = [];

      // const payload = this.getProfilePayload();
      axios
        .get(`/recruitment/profiles?page_size=${pageSize}&page=${page}`, {
          params: {
            requirement_id: this.requirementId,
            search: this.query,
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.requirement_profiles = response.data.profiles;
          this.paginationMeta = response.data.meta;
        })
        .catch((error) => {
          this.isLoading = true;
          this.requirement_profiles = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
  },
  created() {
    this.fetchProfiles(1);
  },
});
</script>
<style scoped>
.joined {
  background-color: #8dcc85;
}
.offered_and_yet_to_join {
  background-color: #e6ef59;
}
.candidate_selected {
  background-color: #f2de48;
}
.candidate_submitted {
  background-color: #f9e22b;
}
.interview_scheduled {
  background-color: #ffc107;
}
.candidate_rejected {
  background-color: #f29c9b;
}
.declined_offer_or_Not_joined {
  background-color: #f56c6b;
}
.on_hold {
  background-color: #e3dbe7;
}
</style>
