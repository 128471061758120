


































































































import Vue from 'vue';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import ManagerModal from './ManagerModal.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'OtherInfo',
  data() {
    return {
      isConfirmDeleteModalVisible: false,
      isManagerModalVisible: false,
      selectedManagerId: null,
      allManagers: [] as any,
      isReadPermission: false,
      isManagePermission: false,
    };
  },
  components: {
    ConfirmAlertBox,
    ManagerModal,
  },
  props: ['practice', 'managers', 'projectName', 'projectId', 'projectStatus'],
  computed: {
    ...mapGetters('user', ['isReadUserPermission']),
  },
  methods: {
    onDelete(id: any) {
      this.selectedManagerId = id;
      this.isConfirmDeleteModalVisible = true;
    },
    removeManager() {
      const { projectId, selectedManagerId, allManagers } = this;
      axios
        .post(`/manage/projects/remove_manager`, {
          project_id: projectId,
          manager_id: selectedManagerId,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.allManagers = allManagers.filter(
            (manager: any) => manager.id !== selectedManagerId
          );
          this.closeConfirmDeleteModal();
        })
        .catch((error) => {
          console.error('ERR while removeManager', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    openManagerModal() {
      this.isManagerModalVisible = true;
    },
    closeManagerModal() {
      this.isManagerModalVisible = false;
    },
    onSuccess(data: any) {
      this.allManagers.push(data);
      this.closeManagerModal();
    },
  },
  created() {
    this.isReadPermission = (this as any).validatePermission([
      'manage.projects.read',
    ]);
    this.isManagePermission = (this as any).validatePermission([
      'manage.projects.manage',
    ]);
    this.allManagers = this.managers;
  },
});
