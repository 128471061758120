















































































































































import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'OtherInfo',
  data() {
    return {
      otherDetails: {} as any,
      isManagePermission: false,
    };
  },
  props: ['userDetails'],
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onChange(key: string) {
      this.updateStatuses(
        key,
        !this.otherDetails[key],
        (isUpdated: boolean) => {
          if (isUpdated) {
            this.otherDetails[key] = !this.otherDetails[key];
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Successfully updated',
              alertType: 'success',
            });
          } else {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Something went wrong.Please try again',
              alertType: 'error',
            });
          }
        }
      );
    },
    updateStatuses(field: string, state: boolean, callback: any) {
      const payload: any = {
        id: this.userDetails.id,
        field,
        state,
      };
      axios
        .post(`/manage/users/toggle`, payload)
        .then((_response: any) => {
          callback(true);
        })
        .catch((err: any) => {
          console.error('ERR while updateStatuses', err);
          callback(false);
        });
    },
  },
  mounted() {
    this.otherDetails = this.userDetails;
  },
  created() {
    this.isManagePermission = (this as any).validatePermission([
      'manage.users.manage',
    ]);
  },
});
