








import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';

export default Vue.extend({
  name: 'AuthRedirect',
  components: {
    Loading,
  },
  mounted() {
    const { data, error } = this.$route.query || {};

    if (!error && data) {
      const token = window.atob(data as string);

      this.$store.dispatch('user/userToken', token);

      (this as any).setUserData((data: any) => {
        if (!data) {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong, please try again.',
            alertType: 'error',
          });

          this.$router.push('/signin');
          return;
        }

        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Successfully logged in',
          alertType: 'success',
        });

        this.$router.push('/');
      });
    } else {
      this.$store.dispatch('settings/showAlert', {
        alertMessage: error,
        alertType: 'error',
      });
      this.$router.push('/signin');
    }
  },
});
