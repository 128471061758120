<template>
  <div class="flex sm:flex-row flex-col justify-between px-3">
    <div class="sm:block hidden">
      <div class="flex flex-row items-center">
        <div class="relative">
          <select
            @change="setPageSize"
            v-model="pageSize"
            class="h-full border text-sm block appearance-none w-full bg-white border-gray-300 rounded-sm text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="">All</option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <label class="ml-2 text-gray-700 text-sm">Entries</label>
      </div>
    </div>
    <div class="flex sm:flex-row flex-col items-center">
      <div
        v-if="meta.total_count"
        class="text-xs sm:text-sm text-gray-700 mr-3"
      >
        Showing
        {{ meta.current_page * pageSize - pageSize + 1 }}
        to
        {{
          pageSize && meta.total_count > pageNum * pageSize
            ? pageNum * pageSize
            : meta.total_count
        }}
        of
        {{ meta.total_count }}
      </div>
      <paginate
        v-model="pageNum"
        :active-class="'hover:bg-blue-500 bg-blue-500 text-red-50'"
        :first-last-button="false"
        :page-count="meta.total_pages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination bg-white flex flex-row sm:text-sm text-xxs'"
        :page-link-class="'px-3 py-2'"
        :page-class="'page-item select-none relative block sm:py-2 py-1 px-0 leading-tight bg-white border border-gray-300 text-gray-700 border-r-0 ml-0 rounded-none hover:bg-gray-100 cursor-pointer'"
        :prev-class="'page-item select-none relative block sm:py-2 py-1 px-3 leading-tight bg-white border border-gray-300 text-gray-700 border-r-0 ml-0 rounded-l hover:bg-gray-100 cursor-pointer'"
        :next-class="'page-item select-none relative block sm:py-2 py-1 px-3 leading-tight bg-white border border-gray-300 text-gray-700 border-r-1 ml-0 rounded-r hover:bg-gray-100 cursor-pointer'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';

export default {
  name: 'Pagination',
  components: {
    Paginate,
  },
  data() {
    return {
      loading: false,
      pageNum: 1,
    };
  },

  props: ['meta', 'pageSize'],

  methods: {
    setPageSize(e) {
      this.pageSize = e.target.value;
      this.pageNum = 1;
      this.$emit('setPageSize', e.target.value);
    },
    clickCallback(pageNum) {
      this.pageNum = pageNum;
      this.$emit('clickCallback', pageNum);
    },
  },
  created() {
    this.pageNum = this.meta.current_page;
  },
};
</script>
