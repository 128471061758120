<template>
  <div class="bg-gray-200 w-96 flex flex-col user-history" id="user-history">
    <AgGridTable :perPage="perPage" :columnDefs="columnDefs" :url="url" />

    <div class="flex flex-row ml-4 font-semibold">
      <div class="text-sm text-gray-800">
        <div id="test" class="codes" style="background-color: #4ed399"></div>
        <span> New Record </span>
      </div>
      <div class="text-sm text-gray-800 pl-5">
        <div id="test" class="codes" style="background-color: #ed8756"></div>
        <span> Update </span>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable  vue/no-unused-components*/
import moment from 'moment';
import AgGridTable from '@/components/tables/AgGridTable.vue';
import {
  FieldNameCellRenderer,
  ChangedFromCellRenderer,
  ChangedToCellRenderer,
} from '@/components/tables/renderers';

export default {
  name: 'UserHistoryTab',
  data() {
    return {
      perPage: 10,
      columnDefs: [
        {
          field: 'user_name',
          headerName: 'User Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          cellStyle: (params) => {
            return (
              params.data && {
                backgroundColor:
                  JSON.parse(params.data.data).action === 'create'
                    ? '#34D399'
                    : '#ED8756',
              }
            );
          },
          cellRenderer: (params) => {
            return (
              params.value &&
              `<a href="/manage/users/${
                params.data.user_id
              }" class="text-blue-500">${
                params.data.user_name
                  ? params.data.user_name
                  : 'User Id' + ' ' + params.data.user_id
              }</a>`
            );
          },
          minWidth: 150,
        },
        {
          field: 'email',
          headerName: 'User Email',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          minWidth: 225,
        },
        {
          headerName: 'Field Name',
          cellRenderer: 'FieldNameCellRenderer',
          wrapText: true,
          autoHeight: true,
          minWidth: 225,
          cellRendererParams: {
            tabName: 'users',
          },
        },
        {
          headerName: 'Changed From',
          cellRenderer: 'ChangedFromCellRenderer',
          minWidth: 225,
          cellRendererParams: {
            tabName: 'users',
          },
        },
        {
          headerName: 'Changed To',
          cellRenderer: 'ChangedToCellRenderer',
          minWidth: 225,
          cellRendererParams: {
            tabName: 'users',
          },
        },
        {
          field: 'updated_by',
          headerName: 'Updated By Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          cellRenderer: (params) => {
            return (
              params.value &&
              `<a href="/manage/users/${params.data.orchestrated_by_id}" class="text-blue-500">${params.value}</a>`
            );
          },
          minWidth: 150,
        },
        {
          field: 'updated_by_email',
          headerName: 'Updated By Email',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'blank',
              'notBlank',
            ],
          },
          minWidth: 225,
        },
        {
          field: 'updated_at',
          headerName: 'Updated Date',
          sortable: true,
          cellRenderer: (params) => {
            return (
              params.value &&
              moment(params.value).format('DD MMM YYYY HH:mm:ss')
            );
          },
          minWidth: 175,
        },
      ],
      url: '/manage/users/get_histories',
    };
  },
  components: {
    AgGridTable,
    FieldNameCellRenderer,
    ChangedFromCellRenderer,
    ChangedToCellRenderer,
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
.bg-orange-700 {
  background-color: #ed8756;
}

.codes {
  width: 10px;
  height: 10px;
  border: 1px;
  float: left;
  margin-right: 10px;
  margin-left: 2px;
  margin-top: 5px;
}

.user-history >>> .ag-row-position-absolute {
  position: unset !important;
  transform: translateY(0px) !important;
}
.user-history >>> .ag-center-cols-viewport {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 100% !important;
}
</style>
