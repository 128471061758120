import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'material-icons/iconfont/material-icons.css';
import '@/assets/css/tailwind.css';
import vSelect from 'vue-select';
import store from './store';
import axios from 'axios';
import VuePapaParse from 'vue-papa-parse';
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueTippy from 'vue-tippy';
import Vuelidate from 'vuelidate';
import {
  MultiSelectPlugin,
  DropDownListPlugin,
} from '@syncfusion/ej2-vue-dropdowns';

import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
MultiSelect.Inject(CheckBoxSelection);
import VueSimpleAlert from 'vue-simple-alert';

Vue.use(VueSimpleAlert);
Vue.use(VueTippy);
Vue.use(Vuelidate);
Vue.use(MultiSelectPlugin);
Vue.use(DropDownListPlugin);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  (request) => {
    request.headers.common[
      'Authorization'
    ] = `Bearer ${store.state.user.token}`;
    // Edit request config
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // TODO route to signIn page if response status is 401
    if (error && error.message && error.message.includes('401')) {
      store.dispatch('user/logoutUser');
      if (router.currentRoute.path !== '/signin') router.push('/signin');
    }
    return Promise.reject(error);
  }
);

Vue.component('v-select', vSelect);

import 'tui-calendar/dist/tui-calendar.css';
import 'vue-select/dist/vue-select.css';

// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

import 'tui-grid/dist/tui-grid.css';
import VueEllipseProgress from 'vue-ellipse-progress';
import globalMixin from './mixins/global.js';

Vue.use(VuePapaParse);
Vue.use(VueLoaders);
Vue.use(VueEllipseProgress, 'vep');

Vue.config.productionTip = false;

Vue.mixin(globalMixin);

globalMixin.methods.setUserData((_data: any) => {
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app');
});
