<template>
  <div
    :class="
      aligned === 'horizontal'
        ? 'flex-row justify-between py-3'
        : 'flex-col py-2'
    "
    class="flex w-full px-3 whitespace-nowrap"
  >
    <label v-if="label" class="leading-loose text-gray-400 text-sm w-1/3"
      >{{ label || ''
      }}<span v-if="required" class="text-red-500 ml-1">*</span></label
    >
    <v-select
      v-model="selected"
      :value="value"
      :multiple="multiple"
      :id="name"
      :name="name"
      :class="activeClass"
      class="px-0 py-0 w-full text-sm"
      :options="options"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="onSelect"
    >
      <template
        slot="option"
        slot-scope="option"
        class="text-red-500 border-green-500"
      >
        <div class="group">
          <span class="fa" :class="option.icon"></span>
          {{ option.label }}
          <p
            class="text-sm italic text-gray-400 group-hover:text-white"
            v-if="option.email"
          >
            {{ option.email }}
          </p>
        </div>
      </template>
    </v-select>
    <!-- <select
      :name="name"
      @input="onChange"
      :class="activeClass"
      class="px-4 py-1 pt-1.5 border w-full rounded-sm focus:outline-none text-gray-600 text-sm"
    >
      <option value="" :disabled="true" :selected="true">
        <span class="text-gray-400">Select {{ label }}</span>
      </option>
      <option
        :value="option.value"
        v-for="(option, index) in options"
        :key="index"
      >
        {{ option.label }}
      </option>
    </select> -->
    <!-- <label v-if="!isValid" class="text_error text-xs text-red-600 font-thin"
      >Please enter correct {{ label }}</label
    > -->
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'SelectInput',
  data() {
    return {
      isValid: true,
      activeClass: 'focus:ring-gray-500 focus:border-gray-900 border-green-300',
      selected: { label: this.placeholder },
    };
  },
  props: [
    'label',
    'required',
    'options',
    'name',
    'type',
    'value',
    'multiple',
    'aligned',
    'placeholder',
    'disabled',
  ],
  methods: {
    validate(data) {
      let isValid = true;
      if (this.required && !data.toString()) {
        isValid = false;
      }
      return isValid;
    },
    // onChange(e: any) {
    //   const focusedClass =
    //     "focus:ring-gray-500 focus:border-gray-900 border-gray-300";
    //   const errorClass =
    //     "focus:ring-red-500 focus:border-red-600 text_error border-red-600";
    //   this.activeClass = this.validate(e.target.value)
    //     ? focusedClass
    //     : errorClass;
    //   this.$emit("onChange", e);
    // },
    onSelect(option) {
      const { multiple, required, name } = this;
      let selectInput = document
        .getElementById(this.name)
        .getElementsByTagName('div');
      let isValid = true;

      if (multiple) {
        if (required && option.length === 0) {
          isValid = false;
        }
      } else {
        if (
          !this.validate(option && option.value.toString() ? option.value : '')
        ) {
          isValid = false;
        }
      }

      if (isValid) {
        selectInput[0].style['border-color'] =
          'rgba(209, 213, 219, var(--tw-bg-opacity))';
        this.activeClass = '';
      } else {
        selectInput[0].style['border-color'] = 'red';
        this.activeClass = ' select_error';
      }
      this.selected = option;
      this.$emit('onChange', option, name);
    },
  },
  created() {
    this.activeClass += this.required && !this.value ? ' select_error' : '';
  },
});
</script>
<style>
.vs__selected-options {
  max-height: 1.7rem;
  min-height: 1.7rem;
  overflow: hidden;
}
</style>
