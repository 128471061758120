<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Skills
        </div>

        <div class="flex flex-row items-center">
          <!-- Upload File -->
          <div v-if="isUploadPermission" class="flex flex-row">
            <div class="w-44">
              <UploadFileButton
                v-tippy
                content="Choose .csv file to bulk upload Skills"
                :label="'Upload Skills'"
                :fileType="'.csv'"
                @onParsed="onParsed"
              />
            </div>
          </div>
          <!-- Upload File End -->
          <!-- Add NEW -->
          <div v-if="isManagePermission" class="hidden md:block">
            <div class="lg:ml-6 flex items-center">
              <button
                @click="openAddSkillModal"
                class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
              >
                <span class="material-icons m-auto mr-1 text-base">add</span>
                <span class="text-sm">Add New</span>
              </button>
            </div>
          </div>
          <!-- Add NEW END -->
        </div>
      </div>
      <!-- Top Bar END-->
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
      >
        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div v-if="isReadPermission" class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="search"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
          </div>
        </div>

        <Loading v-if="isLoading" style="height: 26rem" />

        <!-- Table -->

        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && columns.length > 0"
          >
            <table
              id="skills_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400 text-left"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="rowIndex"
                  v-for="(_, rowIndex) in paginationMeta.total_count -
                    paginationMeta.page_size *
                      (paginationMeta.current_page - 1) <
                  paginationMeta.page_size
                    ? paginationMeta.total_count -
                      paginationMeta.page_size *
                        (paginationMeta.current_page - 1)
                    : paginationMeta.page_size"
                  class="py-2"
                  :class="rowIndex % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td
                    :id="
                      skills[column][rowIndex]
                        ? `${skills[column][rowIndex].skill_name}_${rowIndex}_${column}`
                        : `-${rowIndex}_${column}`
                    "
                    class="px-4 py-2 text-xs cursor-pointer"
                    :key="colIndex"
                    v-for="(column, colIndex) in columns"
                    @click="openEditSkillModal(skills[column][rowIndex])"
                    @mouseover="
                      () =>
                        mouseOver(
                          skills[column][rowIndex]
                            ? `${skills[column][rowIndex].skill_name}_${rowIndex}_${column}`
                            : `-${rowIndex}_${column}`
                        )
                    "
                    @mouseleave="
                      () =>
                        mouseLeave(
                          skills[column][rowIndex]
                            ? `${skills[column][rowIndex].skill_name}_${rowIndex}_${column}`
                            : `-${rowIndex}_${column}`
                        )
                    "
                  >
                    <p class="text-xs text-black-500">
                      {{
                        skills[column][rowIndex]
                          ? skills[column][rowIndex].skill_name
                          : '-'
                      }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
      <div v-if="isReadPermission">
        <Pagination
          class="mt-3"
          v-if="Object.keys(paginationMeta).length > 0"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchSkills"
          @setPageSize="setPageSize"
        />
      </div>
      <!-- Pagination -->
      <AddSkillModal
        v-if="isAddSkillModal && isManagePermission"
        @success="onSuccess"
        @close="closeAddSkillModal"
      ></AddSkillModal>
      <EditSkillModal
        v-if="isEditSkillModalVisible && isManagePermission"
        :skillId="selectedSkillId"
        :skillName="skillName"
        @success="onSuccess"
        @close="closeEditSkillModal"
      ></EditSkillModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import AddSkillModal from '@/components/manage/skills/AddSkillModal.vue';
import EditSkillModal from '@/components/manage/skills/EditSkillModal.vue';
import UploadFileButton from '@/components/common/UploadFileButton.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'Skills',
  components: {
    Pagination,
    Loading,
    AddSkillModal,
    EditSkillModal,
    UploadFileButton,
    NoRecordFound,
    ExportButtons,
    NotAuthorized,
  },
  data() {
    return {
      hover: false,
      query: '',
      columns: [],
      isAddSkillModal: false,
      isEditSkillModalVisible: false,
      selectedSkillId: null,
      skillName: '',
      skills: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      isUploadPermission: false,
      nonce: '',
    };
  },
  props: ['practice', 'managers', 'projectName', 'projectId'],
  methods: {
    mouseLeave(skillName) {
      let skillElement = document.getElementById(skillName);
      skillElement.style['color'] = 'inherit';
      skillElement.style['background-color'] = 'inherit';
    },
    mouseOver(skillName) {
      let skillElement = document.getElementById(skillName);
      skillElement.style['color'] = 'white';
      skillElement.style['background-color'] = 'deepskyblue';
    },
    openAddSkillModal() {
      this.isAddSkillModal = true;
    },
    closeAddSkillModal() {
      this.isAddSkillModal = false;
    },
    openEditSkillModal(skill) {
      this.selectedSkillId = skill.id;
      this.skillName = skill.skill_name;
      this.isEditSkillModalVisible = true;
    },
    closeEditSkillModal() {
      this.isEditSkillModalVisible = false;
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Skills: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#skills_table',
      });
      doc.save(`Skills_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.paginationMeta.current_page = 1;
        this.fetchSkills(this.paginationMeta.current_page);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchSkills(1);
    },
    getSkillsPayload() {
      const { query } = this;
      const payload = {};
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchSkills(page) {
      this.nonce = Date.now().toString();
      this.isLoading = true;
      const { pageSize } = this;
      const payload = this.getSkillsPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/skills/all?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, meta, skills } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.skills = skills;
          this.isLoading = false;
          this.columns = Object.keys(skills).sort();
        })
        .catch((error) => {
          this.isLoading = false;
          this.skills = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchSkills', error);
        });
    },
    exportToCSV() {
      const payload = this.getSkillsPayload();
      axios
        .post(`/manage/skills/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Skills_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchSkills', error);
        });
    },
    onSuccess() {
      if (this.isReadPermission) {
        this.fetchSkills(this.paginationMeta.current_page); //reload
      }
      this.closeAddSkillModal();
      this.closeEditSkillModal();
    },
    createSkills(data) {
      axios
        .post('/manage/skills/upload', { skills: data })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg || 'Successfully created',
            alertType: 'success',
          });
          this.fetchSkills(1);
        })
        .catch((_error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong',
            alertType: 'error',
          });
        });
    },
    onParsed(data) {
      this.createSkills(data);
    },
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchSkills = _debounce(this.fetchSkills, 400);
    this.isReadPermission = this.validatePermission(['manage.skills.read']);
    this.isManagePermission = this.validatePermission(['manage.skills.manage']);
    this.isExportPermission = this.validatePermission(['manage.skills.export']);
    this.isUploadPermission = this.validatePermission(['manage.skills.upload']);
    if (this.isReadPermission) {
      this.fetchSkills(1);
    } else {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
