<template>
  <div class="custom-tooltip">
    <p>
      <span>{{ this.params.value }}</span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'CustomToolTip',
  data: function () {
    return {
      color: null,
    };
  },
  beforeMount() {
    var data = this.params.api.getDisplayedRowAtIndex(
      this.params.rowIndex
    ).data;
    data.color = this.params.color || 'white';
    this.setState(data);
  },
  mounted() {
    this.$el.style['background-color'] = this.color;
  },

  methods: {
    setState(obj) {
      const that = this;

      Object.keys(obj).forEach(function (key) {
        that[key] = obj[key];
      });
    },
  },
};
</script>
<style scoped>
.custom-tooltip {
  position: absolute;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p {
  font-weight: bold;
}
</style>
