<template>
  <div class="bg-gray overflow-x-hidden w-96">
    <Loading v-if="isLoading" />
    <!-- Top Bar -->
    <div
      v-else
      class="md:h-16 h-12 bg-white flex flex-row shadow-sm items-center justify-between text-left px-5 sticky top-0 z-10"
    >
      <div class="flex flex-row items-center justify-start">
        <span
          class="material-icons text-black md:text-xl text-sm mr-3 cursor-pointer"
          @click="onCancel"
          >arrow_back</span
        >
        <span>
          {{ isLoading ? '' : 'Add New Profile' }}
        </span>
      </div>
      <div class="flex flex-row px-3 py-2">
        <div class="pr-3">
            <button
              type="button"
              @click="onCancel"
              class="mt-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-5 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm px-5 h-8 flex items-center text-sm"
            >
              Cancel
            </button>
          </div>
        <button
          @click.prevent="trigger++"
          class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-700 rounded text-white px-5 h-8 flex items-center text-sm"
        >
          <span class="text-sm"> Submit </span>
        </button>
      </div>
    </div>

    <div
      v-if="!isLoading"
      class="flex flex-col mt-2 bg-white pt-2"
      style="height: 88vh"
    >
      <div class="flex-grow overflow-auto pb-24">
        <form-generator
          :triggerSubmit="trigger"
          :formFields="formFields"
          :numberOfColumns="4"
          :groups="groups"
          @onFieldChange="onFieldChange"
          @onFormSubmit="onFormSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';
import RegEx from '@/components/inputs/RegEx';
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import FormGenerator from '@/components/common/FormGenerator.vue';

import { required, email, maxLength } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'CreateUser',
  components: {
    Loading,
    FormGenerator,
  },
  data() {
    return {
      username: '',
      isFormValid: false,
      trigger: 0,
      isLoading: true,
      isValueChanged: false,
      groups: [{ start: 0, end: 36, name: '' }],
      formFields: [
        {
          name: 'first_name',
          label: 'First Name',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            maxLength: maxLength(50),
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'last_name',
          label: 'Last Name',
          type: 'text',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'experience',
          label: 'Total Experience',
          required: true,
          type: 'text',
          secondaryLabel: 'Max. Length 50',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_DIGIT),
          },
        },
        {
          name: 'relevant_experience',
          label: 'Relevant Experience',
          required: true,
          type: 'text',
          secondaryLabel: 'Max. Length 50',
          defaultValue: '',
          validations: {
            maxLength: maxLength(50),
            required,
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_DIGIT),
          },
        },
        {
          name: 'status',
          label: 'Profile Status',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Candidate submitted', value: 0 },
            { label: 'Interview scheduled', value: 1 },
            { label: 'Candidate selected', value: 2 },
            { label: 'Candidate rejected', value: 3 },
            { label: 'Offered and yet to join', value: 4 },
            { label: 'Declined offer or not joined', value: 5 },
            { label: 'Joined', value: 6 },
            { label: 'On hold', value: 7 },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'notice_period',
          label: 'Notice Period(in Days)',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR),
          },
        },
        {
          name: 'offered_date',
          label: 'Offered Date',
          required: false,
          type: 'date',
          defaultValue: '',
          minValue: '2014-01-01',
        },
        {
          name: 'joining_date',
          label: 'Joining Date',
          required: false,
          type: 'date',
          defaultValue: '',
          minValue: '2014-01-01',
        },
        {
          name: 'currnet_organization',
          label: 'Current Organization',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.ATLEAST_ONE_CHAR_OR_DIGIT),
          },
        },
        {
          name: 'resume',
          label: 'Resume Link',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'email',
          label: 'Email',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            maxLength: 50,
            email,
            required,
          },
        },
        {
          name: 'phone',
          label: 'Phone',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.PHONE_NUMBERS_CHARACTER),
          },
        },
        {
          name: 'source_id',
          label: 'Source',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Internal', value: 1 },
            { label: 'External', value: 2 },
            { label: 'Referal', value: 3 },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'hiring_location',
          label: 'Hiring Location',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Hyderabad', value: 'Hyderabad' },
            { label: 'Chennai', value: 'Chennai' },
            { label: 'USA', value: 'USA' },
            { label: 'Mexico', value: 'Mexico' },
            { label: 'Remote', value: 'Remote' },
          ],
        },
        {
          name: 'current_ctc',
          label: 'Current CTC/Rate',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'expected_rate',
          label: 'Expected CTC/Rate',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'source_description',
          label: 'Source Description',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'designation',
          label: 'Designation',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.CHAR_WITH_SPACE),
          },
        },
        {
          name: 'skype_id',
          label: 'Skype',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'work_authorization',
          label: 'Work Authorization',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'ssn_number',
          label: 'SSN',
          required: true,
          secondaryLabel: 'Maximum Length 4(Only Digits)',
          type: 'text',
          defaultValue: '',
          validations: {
            required,
            regex: (value) => this.validateRegex(value, RegEx.FOUR_DIGIT_ONLY),
          },
        },
        {
          name: 'willing_to_relocate',
          label: 'Willing To Relocate?',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'N/A', value: 'N/A' },
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
            { label: 'Remote + Travel(30%)', value: 'Remote + Travel(30%)' },
          ],
        },
        {
          name: 'skill_set',
          label: 'Skill Set',
          required: true,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'location',
          label: 'Current Location',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) =>
              this.validateRegex(value, RegEx.LOCATION_CHARACTER),
          },
        },
        {
          name: 'visa_valid',
          label: 'Visa Validity',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'holding_project',
          label: 'Current Project',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'vacation_plans',
          label: 'Vacation Plans',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'linkedin_url',
          label: 'Linkedin URL',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'department_id',
          label: 'Department',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'project_planned',
          label: 'Project Planned',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'certification_url',
          label: 'Certification URL',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'rating',
          label: 'Rating',
          required: false,
          secondaryLabel: 'Give 1-10',
          type: 'text',
          defaultValue: '',
          validations: {
            regex: (value) => this.validateRegex(value, RegEx.TWO_DIGIT_ONLY),
          },
        },
        {
          name: 'submitted_on',
          label: 'Submitted Date',
          required: false,
          type: 'date',
          defaultValue: moment().format('yyyy-MM-DD'),
          minValue: '2014-01-01',
          validations: {
            minValueDate: (value) =>
              this.validateMinDate(
                value,
                moment().subtract(1, 'year').format('YYYY-MM-DD')
              ),
          },
        },
        {
          name: 'comments',
          label: 'Comments/Notes',
          type: 'text',
          required: false,
          defaultValue: '',
          validations: {},
        },
      ],
      payload: {},
      isEdit: false,
      modalHeadline: 'Leaving this page will discard unsaved changes.',
      deleteMessage: 'Are you sure you want to leave this page?',
      isGoingBack: false,
    };
  },
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onFieldChange(_field, _value, _allValues) {
      this.isValueChanged = true;
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        this.createNewProfile(values);
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
    },
    convertSelectOptions() {
      let editProjectPayload = JSON.parse(JSON.stringify(this.payload));

      // converting option object to its value
      Object.keys(editProjectPayload).forEach((keyName) => {
        if (
          [
            'status',
            'source_id',
            'hiring_location',
            'willing_to_relocate',
            'holding_project',
            'vacation_plans',
            'department_id',
          ].includes(keyName)
        ) {
          editProjectPayload[keyName] = editProjectPayload[keyName].value || '';
        }
      });
      return editProjectPayload;
    },
    createNewProfile(payload) {
      payload.requirement_id = this.requirementId;
      payload.submitted_on = payload.submitted_on
        ? payload.submitted_on
        : moment().format('yyyy-MM-DD');
      axios
        .post('/recruitment/profiles', payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.message,
            alertType: 'success',
          });
          this.$router.back();
        })
        .catch((error) => {
          console.error('ERR while createNewProfile', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },
    onCancel() {
      if (this.isEdit && this.isValueChanged) {
        this.$confirm(
          'Leaving this page will discard unsaved changes. Are you sure?'
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.isGoingBack = true;
            this.$router.back();
          } else {
            this.isGoingBack = false;
            return;
          }
        });
      } else {
        this.$router.back();
      }
    },
    fetchDepartments(callback) {
      this.isLoading = true;
      axios
        .get(`/manage/departments`)
        .then((response) => {
          const departments = response.data.map((department) => {
            return { label: department.name, value: department.id };
          });
          this.setOptions('department_id', 'options', departments);
          this.isLoading = false;
          callback(true);
        })
        .catch((error) => {
          callback();
          this.isLoading = false;
          console.error('ERR while fetchDepartments', error);
        });
    },
  },
  created() {
    this.requirementId = this.$route.query.r_id;
    this.fetchDepartments((_isDepartmentLoaded) => {
      this.isLoading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isEdit && !this.isGoingBack && this.isValueChanged) {
      const isConfirmed = await this.$confirm(
        'Leaving this page will discard unsaved changes. Are you sure?'
      );
      if (isConfirmed) {
        next();
      } else {
        return;
      }
    } else {
      next();
    }
  },
});
</script>
