var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-200 w-96 flex flex-col"},[_c('div',[_c('div',{staticClass:"md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"},[_c('div',{staticClass:"text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4"},[_vm._v(" Configuration ")]),_c('div',{staticClass:"flex flex-row items-center"},[_c('div',{staticClass:"bg-white mr-0 text-sm"},[_c('nav',{staticClass:"flex flex-col sm:flex-row"},[(
                _vm.validatePermission([
                  'manage.configurations.departments.read',
                  'manage.configurations.departments.manage',
                  'manage.configurations.departments.export' ])
              )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'departments'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'departments'}}},[_vm._v(" Departments ")]):_vm._e(),(
                _vm.validatePermission([
                  'manage.configurations.practices.read',
                  'manage.configurations.practices.manage',
                  'manage.configurations.practices.export' ])
              )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'practices'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'practices'}}},[_vm._v(" Practices ")]):_vm._e(),(
                _vm.validatePermission([
                  'manage.configurations.teams.read',
                  'manage.configurations.teams.manage',
                  'manage.configurations.teams.export' ])
              )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'teams'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'teams'}}},[_vm._v(" Teams ")]):_vm._e(),(
                _vm.validatePermission([
                  'manage.configurations.emp_type.read',
                  'manage.configurations.emp_type.manage',
                  'manage.configurations.emp_type.export' ])
              )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'emp_types'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'emp_types'}}},[_vm._v(" Employement Type ")]):_vm._e(),(
                _vm.validatePermission([
                  'manage.configurations.clients.read',
                  'manage.configurations.clients.manage',
                  'manage.configurations.clients.export' ])
              )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'client_tab'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'client_tab'}}},[_vm._v(" Client ")]):_vm._e()])]),_c('div')])]),(
        _vm.selectedTab === 'departments' &&
        _vm.validatePermission([
          'manage.configurations.departments.read',
          'manage.configurations.departments.manage',
          'manage.configurations.departments.export' ])
      )?_c('div',[_c('DepartmentTab',{staticClass:"w-full",attrs:{"showTalentPoolFilter":_vm.showTalentPoolFilter},on:{"toggleFilter":_vm.toggleFilter}})],1):(
        _vm.selectedTab === 'practices' &&
        _vm.validatePermission([
          'manage.configurations.practices.read',
          'manage.configurations.practices.manage',
          'manage.configurations.practices.export' ])
      )?_c('div',[_c('PracticeTab',{staticClass:"w-full",attrs:{"showTalentPoolFilter":_vm.showTalentPoolFilter},on:{"toggleFilter":_vm.toggleFilter}})],1):(
        _vm.selectedTab === 'teams' &&
        _vm.validatePermission([
          'manage.configurations.teams.read',
          'manage.configurations.teams.manage',
          'manage.configurations.teams.export' ])
      )?_c('div',[_c('TeamsTab',{staticClass:"w-full",attrs:{"showTalentPoolFilter":_vm.showTalentPoolFilter},on:{"toggleFilter":_vm.toggleFilter}})],1):(
        _vm.selectedTab === 'emp_types' &&
        _vm.validatePermission([
          'manage.configurations.emp_type.read',
          'manage.configurations.emp_type.manage',
          'manage.configurations.emp_type.export' ])
      )?_c('div',[_c('EmploymentTab',{staticClass:"w-full",attrs:{"showTalentPoolFilter":_vm.showTalentPoolFilter},on:{"toggleFilter":_vm.toggleFilter}})],1):(
        _vm.validatePermission([
          'manage.configurations.clients.read',
          'manage.configurations.clients.manage',
          'manage.configurations.clients.export' ])
      )?_c('div',[_c('ClientTab',{staticClass:"w-full",attrs:{"showTalentPoolFilter":_vm.showTalentPoolFilter},on:{"toggleFilter":_vm.toggleFilter}})],1):_c('NotAuthorized')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }