<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen min-w-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-1/3"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Other Info
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <div
            class="overflow-auto h-70 max-h-96 px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
          >
            <div class="sm:flex sm:items-start flex flex-col w-full">
              <div class="flex flex-col py-2 w-full">
                <label class="leading-loose">Primary Skills</label>
                <ejs-multiselect
                  id="primary_skill_sets"
                  :fields="fields"
                  v-model="selectedPrimarySkills"
                  :value="selectedPrimarySkills"
                  mode="CheckBox"
                  :dataSource="filteredPrimarySkills"
                  @change="onChangePrimary"
                ></ejs-multiselect>
              </div>

              <div class="flex flex-col py-2 w-full">
                <label class="leading-loose">Secondary Skills</label>
                <ejs-multiselect
                  id="secondary_skill_sets"
                  :fields="fields"
                  v-model="selectedAdditionalSkills"
                  :value="selectedAdditionalSkills"
                  mode="CheckBox"
                  :dataSource="filteredSecondarySkills"
                  @change="onChangeSecondary"
                ></ejs-multiselect>
              </div>

              <div class="flex flex-col py-2 w-full">
                <label class="leading-loose">Certifications</label>
                <ejs-multiselect
                  id="certifications"
                  :fields="fields"
                  v-model="selectedCertifications"
                  :value="selectedCertifications"
                  mode="CheckBox"
                  :dataSource="certifications"
                ></ejs-multiselect>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
          >
            <button
              type="button"
              @click="updateUserInfo"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Update
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';

export default {
  name: 'EditOtherInfoModal',
  components: {},
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
    primarySkills: {
      type: Array,
      required: true,
    },
    additionalSkills: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: { text: 'label', value: 'value' },
      selectedPrimarySkills: [],
      selectedAdditionalSkills: [],
      certifications: [],
      selectedCertifications: [],
      filteredPrimarySkills: [],
      filteredSecondarySkills: [],
      skills: [],
    };
  },
  created() {
    this.fetchSkills();
    this.fetchCertifications();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onChangePrimary(e) {
      const filteredOptions = this.skills.filter(
        (so) => !e.value.includes(so.value)
      );
      this.filteredSecondarySkills = filteredOptions;
    },
    onChangeSecondary(e) {
      const filteredOptions = this.skills.filter(
        (so) => !e.value.includes(so.value)
      );
      this.filteredPrimarySkills = filteredOptions;
    },
    fetchCertifications() {
      axios
        .get(`/manage/certifications`)
        .then((response) => {
          const filteredCertifications = response.data.map((certification) => {
            return {
              label: certification.certification_name,
              value: certification.id,
            };
          });
          this.certifications = filteredCertifications.sort(function compare(
            a,
            b
          ) {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
          this.selectedCertifications = this.userDetails.certifications.map(
            (cert) => cert.id
          );
        })
        .catch((error) => {
          console.error('ERR while fetchCertifications', error);
        });
    },
    fetchSkills() {
      axios
        .get(`manage/skills`)
        .then((response) => {
          const skills = response.data.map((skill) => {
            return {
              label: skill.skill_name,
              value: skill.skill_name,
              id: skill.id,
            };
          });
          this.skills = skills.sort(function compare(a, b) {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
          this.filteredPrimarySkills = skills;
          this.filteredSecondarySkills = skills;
          this.setValues();
        })
        .catch((error) => {
          console.error('ERR while fetchSkills', error);
        });
    },
    setValues() {
      this.selectedPrimarySkills = this.primarySkills;
      this.selectedAdditionalSkills = this.additionalSkills;
    },
    updateUserInfo() {
      const params = {
        id: this.userDetails.id,
        primary_skill_set: this.selectedPrimarySkills.toString(),
        additional_skills: this.selectedAdditionalSkills.toString(),
        certifications: this.selectedCertifications,
        is_other_info: true,
      };
      this.$emit('updateUserInfo', params);
    },
  },
};
</script>
