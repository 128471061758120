var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-200 w-96 flex flex-col"},[_c('div',[_c('div',{staticClass:"md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"},[_c('div',{staticClass:"text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4"},[_vm._v(" Histories ")]),_c('div',{staticClass:"flex flex-row items-center"},[_c('div',{staticClass:"bg-white mr-0 text-sm"},[_c('nav',{staticClass:"flex flex-col sm:flex-row"},[(_vm.validatePermission(['manage.histories.read']))?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'users'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'users'}}},[_vm._v(" Users ")]):_vm._e(),(_vm.validatePermission(['manage.histories.read']))?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'projects'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'projects'}}},[_vm._v(" Projects ")]):_vm._e(),(_vm.validatePermission(['manage.histories.read']))?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'projects_users'
                  ? 'text-blue-500 border-blue-500 border-b-2'
                  : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'projects_users'}}},[_vm._v(" Project Members ")]):_vm._e()])]),_c('div')])]),(
        _vm.selectedTab === 'users' &&
        _vm.validatePermission(['manage.histories.read'])
      )?_c('div',[_c('UserHistoryTab',{staticClass:"w-full"})],1):(
        _vm.selectedTab === 'projects' &&
        _vm.validatePermission(['manage.histories.read'])
      )?_c('div',[_c('ProjectHistoryTab',{staticClass:"w-full"})],1):(
        _vm.selectedTab === 'projects_users' &&
        _vm.validatePermission(['manage.histories.read'])
      )?_c('div',[_c('ProjectUserHistoryTab',{staticClass:"w-full"})],1):_c('NotAuthorized')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }