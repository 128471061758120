export default {
  template: `
    <div class="h-full">
    <p
      @click="btnClickedHandler"
      class="text-xs font-semibold text-blue-500 cursor-pointer flex justify-center items-center h-full"
    >
       <span class="material-icons text-base ">edit</span>
    </p>
  </div>
    `,
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params.value);
    },
  },
};
