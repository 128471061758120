<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Full Time to Contractor Report
        </div>
        <CustomFilter
          v-if="showFulltimeToContractorFilter"
          :filterBy="filterBy"
          :projectPractices="projectPractices"
          @resetFilter="resetFilter"
          @setFilterByProjectPractices="setFilterByProjectPractices"
          @setFilterByProjects="setFilterByProjects"
          @toggleFilter="toggleFilter"
          :projectOptions="projectOptions"
          :projectOptionMeta="projectOptionMeta"
          @loadMore="loadMore"
          @searchQuery="searchQuery"
        />
      </div>
      <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
        <div class="flex py-4 px-4 justify-between items-center">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :excel="true"
            @exportToExcel="exportToExcel"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div class="flex flex-row items-center">
            <!-- Search Input -->
            <div
              class="relative text-gray-600"
              v-if="isReadPermission"
              style="margin-right: 30px"
            >
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              v-if="isReadPermission"
              class="material-icons text-2xl cursor-pointer select-none text-gray-500"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <Loading v-if="isTableLoading" style="height: 24.5rem" />

        <div v-else class="flex flex-col" style="height: 24.5rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && fte_data.length > 0"
          >
            <table
              ref="table"
              id="consolidated"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-green-400 text-left"
                  >
                    Green
                  </th>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-yellow-400 text-left"
                  >
                    Yellow
                  </th>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-red-400 text-left"
                  >
                    Red
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  v-for="(fte, index) in fte_data"
                  :key="index"
                  class="py-2"
                  :class="index % 2 == 1 ? 'bg-gray-50' : 'bg-white'"
                >
                  <td
                    v-if="fte[0]"
                    class="text-xs p-4"
                    style="
                      max-width: 50px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <span
                      class="font-semibold text-blue-500 cursor-pointer"
                      v-if="isReadProjectPermission"
                    >
                      <router-link :to="`/manage/projects/${fte[0].id}`">
                        <b>{{ fte[0].project_name }}</b>
                      </router-link>
                    </span>
                    <span v-else>
                      {{ fte[0].project_name }}
                    </span>
                  </td>
                  <td v-else></td>
                  <td
                    v-if="fte[1]"
                    class="text-xs p-4"
                    style="
                      max-width: 50px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <span
                      class="font-semibold text-blue-500 cursor-pointer"
                      v-if="isReadProjectPermission"
                    >
                      <router-link :to="`/manage/projects/${fte[1].id}`">
                        <b>{{ fte[1].project_name }}</b>
                      </router-link>
                    </span>
                    <span v-else>
                      {{ fte[1].project_name }}
                    </span>
                  </td>
                  <td v-else></td>
                  <td
                    v-if="fte[2]"
                    class="text-xs p-4"
                    style="
                      max-width: 50px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <span
                      class="font-semibold text-blue-500 cursor-pointer"
                      v-if="isReadProjectPermission"
                    >
                      <router-link :to="`/manage/projects/${fte[2].id}`">
                        <b>{{ fte[2].project_name }}</b>
                      </router-link>
                    </span>
                    <span v-else>
                      {{ fte[2].project_name }}
                    </span>
                  </td>
                  <td v-else></td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
      </div>
      <div
        v-if="isReadPermission"
        class="flex flex-row justify-between mt-3 mx-3"
      >
        <div class="flex flex-row items-center justify-center">
          <div class="text-xs italic text-gray-800 font-semibold">
            FTE 60-100% :
            <div
              id="test"
              class="codes"
              style="background-color: #34d399"
            ></div>
          </div>
          <div class="text-xs italic text-gray-800 font-semibold">
            FTE 41-59% :
            <div
              id="test"
              class="codes"
              style="background-color: #fbbf24"
            ></div>
          </div>
          <div class="text-xs italic text-gray-800 font-semibold">
            FTE 0-40% :
            <div
              id="test"
              class="codes"
              style="background-color: #f87171"
            ></div>
          </div>
        </div>
      </div>
      <!-- Pagination -->
      <div class="mt-3">
        <Pagination
          class="mt-2"
          v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchFulltimeToContractorReport"
          @setPageSize="setPageSize"
        />
      </div>
    </div>
    <!-- Pagination End-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import 'jspdf-autotable';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import Pagination from '@/components/common/Pagination.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'FulltimeToContractor',
  components: {
    Loading,
    CustomFilter,
    Pagination,
    NotAuthorized,
    NoRecordFound,
    ExportButtons,
  },
  data() {
    return {
      statuses: [
        { label: 'All', value: '' },
        { label: 'Active', value: true },
        { label: 'Inactive', value: false },
      ],
      showFulltimeToContractorFilter: false,
      fte_data: [],
      projectPractices: [],
      projectOptions: [],
      projectOptionMeta: {},
      filterBy: {
        projectPractices: [],
        projects: [],
      },
      query: '',
      isTableLoading: true,
      opened: [],
      open: true,
      paginationMeta: {},
      pageSize: 30,
      defaultFilterBy: {},
      isReadPermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
  },
  created() {
    this.fetchFulltimeToContractorReport = _debounce(
      this.fetchFulltimeToContractorReport,
      400
    );
    this.isReadPermission = this.validatePermission([
      'reports.overall_logs.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.overall_logs.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };

    if (this.isReadPermission) {
      this.fetchProjectPractices();
      this.fetchProjects(1);
      this.fetchFulltimeToContractorReport(1);
    } else {
      this.isTableLoading = false;
    }
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchFulltimeToContractorReport(1);
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    toggleFilter() {
      this.showFulltimeToContractorFilter =
        !this.showFulltimeToContractorFilter;
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchFulltimeToContractorReport(1);
      }
    },
    loadMore(page) {
      this.fetchFulltimeToContractorReport(page);
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchFulltimeToContractorReport(1);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.projectOptions = [];
      this.fetchProjects(1);
      this.fetchFulltimeToContractorReport(1);
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchFulltimeToContractorReport(1);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchProjects(1, true, query);
      }
    },
    fetchProjectPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getProjectPayload() {
      const { filterBy } = this;
      const payload = {};
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      return payload;
    },
    fetchProjects() {
      const payload = this.getProjectPayload();
      axios
        .get(`/manage/projects/projects_by_practice`, {
          params: {
            practice: payload.practice,
          },
        })
        .then((response) => {
          const projectOptions = response.data.project.map((project) => {
            return { label: project.name, value: project.id };
          });
          this.projectOptions = projectOptions;
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    getFtePayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchFulltimeToContractorReport(page) {
      this.nonce = Date.now().toString();
      const { pageSize } = this;
      const payload = this.getFtePayload();
      axios
        .get(
          `/reports/fte_to_contractor_report?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              project_practice: payload.practice,
              projects: payload.projects,
              search: payload.search,
              nonce: this.nonce,
            },
          }
        )
        .then((response) => {
          const { nonce, meta, fte_data } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.fte_data = fte_data;
          this.isTableLoading = false;
        })
        .catch((error) => {
          // handle error
          console.error(
            'Error while Fething Full Time to Contractor Report',
            error
          );
          this.isTableLoading = false;
        });
    },
    exportToExcel() {
      const payload = this.getFtePayload();
      axios
        .get(`/reports/download_fte_to_contractor_report_excel`, {
          params: {
            project_practice: payload.practice,
            projects: payload.projects,
            search: payload.search,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Full Time to Contractor Report.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error(
            'Error while Fething Full Time to Contractor Report',
            error
          );
        });
    },
  },
};
</script>

<style scoped></style>
