





















































































































import Vue from 'vue';
import axios from 'axios';
export default Vue.extend({
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      emailAuth: false,
      isSignInLoader: false,
      isMSLoader: false,
    };
  },
  methods: {
    setEmail(e: any) {
      this.email = e.target.value;
    },
    setPassword(e: any) {
      this.password = e.target.value;
    },
    loginUser(e: any) {
      e.preventDefault();
      const { email, password } = this;
      if (email && password) {
        this.isSignInLoader = true;
        axios
          .post('/user_token', { email, password })
          .then((response: any) => {
            if (response.data && response.data.auth_token) {
              this.$store.dispatch('user/loginUser', response.data);
              this.$store.dispatch('settings/showAlert', {
                alertMessage: 'Successfully logged in',
                alertType: 'success',
              });
              this.isSignInLoader = false;

              this.$router.push('/');
            }
          })
          .catch((error: any) => {
            let errMsg = 'Incorrect Email or Password.';
            if (
              error.response &&
              error.response.data &&
              error.response.data.msg
            ) {
              errMsg = error.response.data.msg;
            }
            this.isSignInLoader = false;

            this.$store.dispatch('settings/showAlert', {
              alertMessage: errMsg,
              alertType: 'error',
            });
          });
      } else {
        this.isSignInLoader = false;

        this.$store.dispatch('settings/showAlert', {
          alertMessage: "Email or Password can't be empty",
          alertType: 'error',
        });
      }
    },
    aAD() {
      this.isMSLoader = true;
      axios
        .get('/saml/login')
        .then((res) => {
          this.isMSLoader = false;
          window.location = res.data.saml_redirect;
        })
        .catch((err) => {
          this.isMSLoader = false;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong',
            alertType: 'error',
          });
          console.error(err);
        });
    },
  },
  created() {
    this.$store.dispatch('user/logoutUser');
  },
});
