<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Skills Availability
        </div>
      </div>
      <!-- Top Bar END-->
      <Loading v-if="isLoading" style="height: 31rem" class="mt-2" />
      <div
        v-else
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
      >
        <CustomFilter
          v-if="showLocationFilter"
          :filterBy="filterBy"
          @resetFilter="resetFilter"
          @setFilterByLocation="setFilterByLocation"
          @toggleFilter="toggleFilter"
        />
        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div v-if="isReadPermission" class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="search"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-3"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <!-- Table -->

        <div class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && skills.length > 0"
          >
            <table
              id="skills_table"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr>
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2"
                    :class="
                      [
                        'Emp Name',
                        'Designation',
                        'Primary Skill',
                        'Secondary Skill',
                        'Project Assigned',
                        'Practice',
                      ].includes(column)
                        ? 'text-left'
                        : ''
                    "
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="index"
                  v-for="(skill, index) in skills"
                  class="py-2"
                  :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td class="px-4 py-2 text-xs">
                    <p align="center">
                      {{
                        paginationMeta.current_page * pageSize -
                        pageSize +
                        index +
                        1
                      }}
                    </p>
                  </td>
                  <td class="px-4 py-2 text-xs" align="center">
                    {{ skill.emp_id }}
                  </td>
                  <td class="px-4 py-2">
                    <div>
                      <router-link
                        v-if="isReadUserPermission"
                        :to="`/manage/users/${skill.id}`"
                      >
                        <p
                          class="text-xs font-semibold text-blue-500 cursor-pointer"
                        >
                          {{ skill.full_name + ' ' }}
                        </p>
                      </router-link>
                      <span class="font-semibold" v-else>{{
                        skill.full_name + ' '
                      }}</span>
                      <p class="text-gray-400 text-xs tracking-wide">
                        {{ skill.email }}
                      </p>
                    </div>
                  </td>
                  <td class="px-4 py-4 text-xs">
                    <p align="left">{{ skill.designation }}</p>
                  </td>
                  <td class="px-4 text-xs py-2">
                    <span
                      :key="primarySkillIndex"
                      v-for="(primarySkill, primarySkillIndex) in skill
                        .primary_skill.length > 3
                        ? skill.primary_skill.slice(0, 3)
                        : skill.primary_skill"
                      :content="getSkills(skill.primary_skill)"
                      v-tippy
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-blue-50 text-black-500 last:mr-0 mr-1"
                      >{{ primarySkill }}</span
                    >
                    <span v-if="skill.primary_skill.length > 3" class="text-xs"
                      >...</span
                    >
                  </td>

                  <td class="px-4 text-xs py-2">
                    <span
                      :key="secondarySkillIndex"
                      v-for="(secondarySkill, secondarySkillIndex) in skill
                        .secondary_skill.length > 3
                        ? skill.secondary_skill.slice(0, 3)
                        : skill.secondary_skill"
                      :content="getSkills(skill.secondary_skill)"
                      v-tippy
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-blue-50 text-black-500 last:mr-0 mr-1"
                      >{{ secondarySkill }}</span
                    >
                    <span
                      v-if="skill.secondary_skill.length > 3"
                      class="text-xs"
                      >...</span
                    >
                  </td>
                  <td class="px-4 py-2 text-xs">
                    {{ skill.practice }}
                  </td>
                  <td class="px-4 py-2 text-xs" align="center">
                    {{ skill.emp_type }}
                  </td>
                  <td class="px-4 text-xs py-2">
                    <span
                      :key="projectIndex"
                      v-for="(project, projectIndex) in skill.projects_assigned
                        .length > 3
                        ? skill.projects_assigned.slice(0, 3)
                        : skill.projects_assigned"
                      :content="
                        getAssignedProject(
                          skill.projects_assigned.map((p) => p[1])
                        )
                      "
                      v-tippy
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-blue-50 last:mr-0 mr-1"
                    >
                      <router-link
                        v-if="isReadProjectPermission"
                        :to="`/manage/projects/${project[0]}`"
                      >
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          {{ project[1] }}
                        </p>
                      </router-link>
                      <span class="text-xs whitespace-nowrap" v-else>{{
                        project[1]
                      }}</span>
                    </span>
                    <span
                      v-if="skill.projects_assigned.length > 3"
                      class="text-xs"
                      >...</span
                    >
                  </td>

                  <td class="px-4 whitespace-normal">
                    <span
                      :key="manegerIndex"
                      v-for="(manager, manegerIndex) in skill.project_managers
                        .length > 3
                        ? skill.project_managers.slice(0, 3)
                        : skill.project_managers"
                      :content="getProjectManagers(skill.project_managers)"
                      v-tippy
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-blue-50 last:mr-0 mr-1"
                    >
                      <router-link
                        v-if="isReadUserPermission"
                        :to="`/manage/users/${manager.id}`"
                      >
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          {{ manager.name }}
                        </p>
                      </router-link>
                      <span v-else class="text-xs whitespace-nowrap">{{
                        manager.name
                      }}</span>
                    </span>
                    <span
                      v-if="skill.project_managers.length > 3"
                      class="text-xs"
                      >...</span
                    >
                  </td>

                  <td class="px-4 whitespace-normal">
                    <router-link
                      v-if="isReadUserPermission"
                      :to="`/manage/users/${skill.reporting_manager_id}`"
                    >
                      <p
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                      >
                        {{ skill.reporting_manager }}
                      </p>
                    </router-link>
                    <span v-else class="text-xs whitespace-nowrap">{{
                      skill.reporting_manager
                    }}</span>
                  </td>
                  <td class="px-4 py-4 text-xs">
                    <p align="left">{{ skill.location }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->
      </div>
    </div>
    <div>
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchSkills"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'SkillsAvailability',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    NoRecordFound,
    ExportButtons,
    NotAuthorized,
  },
  data() {
    return {
      query: '',
      columns: [
        'S. No.',
        'Emp ID',
        'Emp Name',
        'Designation',
        'Primary Skill',
        'Secondary Skill',
        'Practice',
        'Emp Type',
        'Project Assigned',
        'Project Manager',
        'Reporting Manager',
        'Location',
      ],
      skills: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: false,
      showLocationFilter: false,
      filterBy: {
        location: [],
      },
      defaultFilterBy: {},
      isReadPermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchSkills(1);
    },
    getAssignedProject(projects) {
      const assignedProject =
        (projects && projects.map((project) => project)) || [];
      return assignedProject;
    },
    getSkills(skillSet) {
      const skills = (skillSet && skillSet.map((skill) => skill)) || [];
      return skills.join(', ');
    },
    getProjectManagers(managers) {
      const managerNames =
        (managers && managers.map((manager) => manager.name)) || [];
      return managerNames.join(', ');
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(
        `Skills Availability Report: ${moment().format('LLLL')}`,
        15,
        10
      );
      doc.autoTable({
        html: '#skills_table',
      });
      doc.save(
        `Skills_Availability_Report_${moment().format('yyyy_MM_DD')}.pdf`
      );
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.paginationMeta.current_page = 1;
        this.fetchSkills(this.paginationMeta.current_page);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchSkills(1);
    },
    getSkillsPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.location.length > 0) {
        payload.location = filterBy.location;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchSkills(page) {
      this.nonce = Date.now().toString();
      this.isLoading = true;
      const { pageSize } = this;
      const payload = this.getSkillsPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/users/skills/all?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce } = response.data;
          if (nonce === this.nonce) {
            this.paginationMeta = response.data.meta;
            this.skills = response.data.skills;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.skills = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchSkills', error);
        });
    },
    exportToCSV() {
      const payload = this.getSkillsPayload();
      axios
        .post(`/manage/users/skills/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Skills_Availability_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchSkills', error);
        });
    },
    toggleFilter() {
      this.showLocationFilter = !this.showLocationFilter;
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchSkills(1);
    },
  },
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
  },
  created() {
    this.fetchSkills = _debounce(this.fetchSkills, 400);
    this.isReadPermission = this.validatePermission([
      'pmo.skills_availability.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'pmo.skills_availability.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.isLoading = true;
      this.fetchSkills(1);
    }
  },
};
</script>

<style scoped>
table#skills_table thead tr:first-child th {
  position: sticky;
  top: 0;
  background-color: rgba(156, 161, 176, 255);
  color: rgba(236, 232, 235, 255);
  z-index: 1;
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
