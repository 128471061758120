<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div
      class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
    >
      <CustomFilter
        v-if="showTalentPoolFilter"
        :filterBy="filterBy"
        :userPractices="userPractices"
        :userOptionMeta="userOptionMeta"
        :userOptions="userOptions"
        @resetFilter="resetFilter"
        @setFilterByUsers="setFilterByUsers"
        @setFilterByUserPractices="setFilterByUserPractices"
        @setFilterByLocation="setFilterByLocation"
        @toggleFilter="toggleFilter"
      />

      <div class="flex flex-row py-4 px-4 items-center justify-between">
        <!-- Icons -->
        <ExportButtons
          v-if="isExportPermission"
          :csv="true"
          :pdf="true"
          @createPDF="createPDF"
          @exportToCSV="exportToCSV"
        />
        <div v-else></div>
        <!-- Icons END -->
        <div class="flex flex-row items-center">
          <!-- Search Input -->
          <div class="relative text-gray-600" v-if="isReadPermission">
            <input
              v-model="query"
              @input="setQuery"
              type="search"
              name="serch"
              placeholder="Search"
              class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
            />
            <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
              <span class="material-icons md:text-2xl text-sm text-gray-400"
                >search</span
              >
            </button>
          </div>
          <div v-else></div>
          <!-- Search END -->
          <span
            v-if="isReadPermission"
            class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-3"
            @click="toggleFilter"
            >filter_alt</span
          >
          <span v-else></span>
        </div>
      </div>
      <!-- Table -->
      <Loading v-if="isTableLoading" style="height: 26rem" />

      <div v-else class="flex flex-col" style="height: 26rem">
        <div
          class="flex-grow overflow-auto"
          v-if="isReadPermission && users && users.length > 0"
        >
          <table
            id="talentpool"
            class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
          >
            <thead>
              <tr>
                <th
                  class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  :key="index"
                  :class="
                    ['Availability(%)', 'Exp', 'Comments'].includes(column)
                      ? 'text-center'
                      : 'text-left'
                  "
                  v-for="(column, index) in columns"
                >
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
              <tr
                :key="index"
                v-for="(user, index) in users"
                class="py-2"
                :class="index % 2 === 1 ? 'bg-gray-100' : ''"
              >
                <td class="px-4 text-xs">
                  <p class>{{ user.employee_id }}</p>
                </td>
                <td class="px-4 py-2 sticky">
                  <div v-if="isReadUserPermission">
                    <router-link :to="`/manage/users/${user.id}`">
                      <p
                        class="text-xs font-semibold text-blue-500 cursor-pointer"
                      >
                        {{ user.first_name + ' ' + user.last_name }}
                      </p>
                    </router-link>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ user.email }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="text-xs font-semibold text-black-500">
                      {{ user.first_name + ' ' + user.last_name }}
                    </p>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ user.email }}
                    </p>
                  </div>
                </td>
                <td class="px-4">
                  <p class>{{ user.practice }}</p>
                </td>
                <td class="px-4 text-center">
                  <p class>{{ userAllocations[user.id] }}</p>
                </td>
                <td class="px-4">
                  <p class>{{ user.department }}</p>
                </td>
                <td class="px-4">
                  <p class>{{ user.designation }}</p>
                </td>
                <td v-if="user.primary_skill_set" class="px-4">
                  <div class>
                    <span
                      :key="skillIndex"
                      v-for="(skill, skillIndex) in user.primary_skill_set
                        .length > 2
                        ? user.primary_skill_set.slice(0, 2)
                        : user.primary_skill_set"
                      v-tippy
                      :content="getPrimarySkill(user.primary_skill_set)"
                      class="text-xs inline-block py-0 mb-1 px-2 rounded bg-green-200 text-green-900 last:mr-0 mr-1"
                    >
                      <p class="text-xs whitespace-nowrap p-0.5">
                        {{ skill }}
                      </p>
                    </span>
                    <span
                      v-if="user.primary_skill_set.length > 2"
                      class="text-xs"
                      >...</span
                    >
                  </div>
                </td>
                <td class="px-4 text-center" v-else></td>
                <td class="px-4 text-center">
                  <p class>{{ user.user_experience }}</p>
                </td>
                <td class="px-4">
                  <div v-if="isReadUserPermission">
                    <router-link
                      :to="`/manage/users/${user.reporting_manager_id}`"
                    >
                      <p
                        class="text-xs font-semibold text-blue-500 cursor-pointer"
                      >
                        {{ user.reporting_to }}
                      </p>
                    </router-link>
                  </div>
                  <div v-else>
                    <p class="text-xs font-semibold">
                      {{ user.reporting_to }}
                    </p>
                  </div>
                </td>
                <td class="px-4">
                  <p class>{{ user.location }}</p>
                </td>
                <td class="px-4">
                  <p class="text-wrap whitespace-normal" style="width: 250px">
                    {{ user.comments }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <NotAuthorized v-else-if="!isReadPermission" />
        <NoRecordFound v-else />
      </div>
      <!-- Table END -->
    </div>
    <div v-if="users.length > 0">
      <Pagination
        class="mt-2"
        v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchUsers"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'Users',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    NoRecordFound,
    ExportButtons,
    NotAuthorized,
  },
  data() {
    return {
      showUserFilter: false,
      userPractices: [],
      filterBy: {
        userPractices: [],
        users: [],
        location: [],
      },
      query: '',
      columns: [
        'Emp ID',
        'Name',
        'User Practice',
        'Availability(%)',
        'Department',
        'Designation',
        'Primary Skill Set',
        'Exp',
        'Reporting Manager',
        'Location',
        'Comments',
      ],
      users: [],
      paginationMeta: {},
      pageSize: 30,
      userOptions: [],
      userOptionMeta: {},
      isTableLoading: true,
      userAllocations: {},
      showTalentPoolFilter: false,
      defaultFilterBy: {},
      isReadPermission: false,
      isExportPermission: false,
      nonce2: '',
    };
  },
  computed: {
    ...mapGetters('user', ['userId', 'isReadUserPermission']),
  },
  props: ['dateRange'],
  created() {
    this.fetchUserOptions = _debounce(this.fetchUserOptions, 400);
    this.fetchUsers = _debounce(this.fetchUsers, 400);
    this.isReadPermission = this.validatePermission([
      'reports.talent_pool.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.talent_pool.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchPractices();
      this.fetchUsers(1);
      this.fetchUserOptions(1);
    } else {
      this.isTableLoading = false;
    }
  },
  watch: {
    dateRange: {
      immediate: true,
      handler(_newValue, _oldValue) {
        if (this.isReadPermission) {
          this.fetchUsers(1);
          this.fetchUserOptions(1);
        }
      },
    },
  },
  methods: {
    resetFilter() {
      this.$emit('resetDate');
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchUsers(1);
      this.fetchUserOptions(1);
    },
    toggleFilter() {
      this.showTalentPoolFilter = !this.showTalentPoolFilter;
    },
    fetchUserOptions(page, _isSearch = false, query = null) {
      this.nonce2 = Date.now().toString();
      const { pageSize } = this;
      const payload = this.getUsersPayload();
      payload['nonce'] = this.nonce2;
      if (query) {
        payload.search = query;
      }
      axios
        .post(
          `/manage/users/talent_pools?res_type=short&page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, meta, users } = response.data;
          if (nonce !== this.nonce2) return;
          this.userOptionMeta = meta;
          this.userOptions = users;
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    toggleUserFilter() {
      this.showUserFilter = !this.showUserFilter;
    },
    printTable() {
      const newWindow = window.open('');
      newWindow.document.write(
        document.getElementById('users_table').outerHTML
      );
      newWindow.print();
      newWindow.close();
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Talent Pool: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#talentpool',
      });
      doc.save(`TalentPool_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    getPrimarySkill(primarySkill) {
      const skill =
        (primarySkill && primarySkill.map((primaryskill) => primaryskill)) ||
        [];
      return skill.join(', ');
    },
    setFilterByUserPractices(option) {
      this.filterBy.userPractices = option;
      this.fetchUsers(1);
      this.fetchUserOptions(1);
    },
    setFilterByUsers(option) {
      this.filterBy.users = option;
      this.fetchUsers(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchUsers(1);
      this.fetchUserOptions(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchUsers(1);
      }
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchUserOptions(1, true, query);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchUsers(1);
    },
    fetchPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.userPractices = response.data.map((practice) => {
            return {
              label: practice.practice_name,
              value: practice.practice_name,
            };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
    getUsersPayload() {
      const { filterBy, query, dateRange } = this;
      const payload = {};
      if (filterBy.location.length > 0) {
        payload.location = filterBy.location;
      }
      if (filterBy.userPractices.length > 0) {
        payload.practice = filterBy.userPractices;
      }
      if (filterBy.users.length > 0) {
        payload.users = filterBy.users;
      }
      if (dateRange) {
        payload.date_range = dateRange;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchUsers(page) {
      const { pageSize } = this;
      this.isTableLoading = true;
      this.nonce = Date.now().toString();
      const payload = this.getUsersPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/users/talent_pools?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, users_allocations, meta, users } = response.data;
          if (nonce !== this.nonce) return;
          this.userAllocations = users_allocations;
          this.paginationMeta = meta;
          this.users = users.map((user) => {
            if (user.primary_skill_set) {
              user.primary_skill_set = user.primary_skill_set.split(',');
            }
            return user;
          });
          this.isTableLoading = false;
        })
        .catch((error) => {
          this.isTableLoading = false;
          this.users = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
    exportToCSV() {
      const payload = this.getUsersPayload();
      axios
        .post(`/manage/users/talent_pools/export_csv.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `TalentPool_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
  },
};
</script>
<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
