<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Technical Feedback:
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <div class="flex flex-col mt-2 bg-white pt-2" style="height: 44vh">
            <Loading v-if="isLoading" class="h-full" />

            <div v-else class="flex-grow overflow-auto">
              <form-generator
                :triggerSubmit="trigger"
                :formFields="formFields"
                :numberOfColumns="4"
                :groups="groups"
                @onFieldChange="onFieldChange"
                @onFormSubmit="onFormSubmit"
              />
            </div>
          </div>
          <div
            class="sm:flex sm:items-start sm:justify-between mt-3 bg-gray-50"
          >
            <div class="flex flex-row w-60 items-center mt-4"></div>
            <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                @click.prevent="trigger++"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                <span class="text-sm"> Save </span>
              </button>
              <button
                type="button"
                @click="onCancel"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';
import axios from 'axios';
import moment from 'moment';
import FormGenerator from '@/components/common/FormGenerator.vue';
import _debounce from 'lodash/debounce';
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'TechnicalFeedbackModal',
  components: {
    Loading,
    FormGenerator,
  },
  computed: {},
  props: {
    interview_obj: {
      type: Object,
    },
  },
  data() {
    return {
      trigger: 0,
      isLoading: true,
      isFormValid: false,
      interviewId: null,
      groups: [{ start: 0, end: 20, name: '' }],
      formFields: [
        {
          name: 'requirement_id',
          label: 'Requirement ID',
          required: false,
          type: 'text',
          defaultValue: this.interview_obj.r_id,
          inputDisabled: true,
          options: [],
        },
        {
          name: 'candidate_name',
          label: 'Candidate Name',
          required: true,
          type: 'text',
          defaultValue:
            this.interview_obj.first_name + ' ' + this.interview_obj.last_name,
          inputDisabled: true,
        },
        {
          name: 'requirement_name',
          label: 'Requirement Name',
          type: 'text',
          defaultValue: this.interview_obj.job_role,
          inputDisabled: true,
        },
        {
          name: 'designation',
          label: 'Designation',
          type: 'text',
          defaultValue: this.interview_obj.job_role,
          inputDisabled: true,
        },
        {
          name: 'round_number',
          label: 'Interview Round',
          placeholder: 'Interview Round',
          required: false,
          inputDisabled: true,
          type: 'text',
          defaultValue: this.interview_obj.round_number,
        },
        {
          name: 'skills',
          label: 'Skills',
          required: false,
          inputDisabled: true,
          type: 'text',
          defaultValue: this.interview_obj.skills,
          minValue: '2014-01-01',
        },
        {
          name: 'date',
          label: 'Interview Date',
          required: false,
          inputDisabled: true,
          type: 'datetime-local',
          defaultValue: moment
            .utc(this.interview_obj.date)
            .format('YYYY-MM-DDTHH:mm'),
        },
        {
          name: 'technical_ability',
          label: 'Technical Ability',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
        },
        {
          name: 'leadership_skills',
          label: 'Leadership Skills',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
        },
        {
          name: 'attitude',
          label: 'Attitude',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
        },
        {
          name: 'communication',
          label: 'Communication',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
        },
        {
          name: 'overall_rating',
          label: 'Overall Rating',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
        },
        {
          name: 'pros',
          label: 'Pros',
          required: true,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'cons',
          label: 'Cons',
          required: true,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'status',
          label: 'Status',
          required: true,
          type: 'select',
          height: 4,
          defaultValue: '',
          options: [
            { label: 'Rejected', value: 'rejected' },
            { label: 'On Hold', value: 'on_hold' },
            {
              label: 'Selected For Next Round',
              value: 'selected_for_next_round',
            },
            {
              label: 'Selected For Final Round',
              value: 'selected_for_final_round',
            },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'overall_comments',
          label: 'Overall Commnets',
          required: true,
          type: 'text',
          defaultValue: '',
        },
      ],
      payload: {},
      isDataChanged: false,
      modalHeadline: 'Leaving this page will discard unsaved changes.',
      isGoingBack: false,
    };
  },
  created() {
    this.interviewId = this.interview_obj.id;
    this.convertResponseToPayload(this.interview_obj);
    this.isLoading = false;
  },

  methods: {
    async onFieldChange(field, value, allValues) {
      try {
        this.isDataChanged = true;
        if (field.name === 'date') {
          if (
            !allValues.date ||
            moment(allValues.date).diff(
              moment(new Date()).format('yyyy-MM-DD')
            ) < 0
          ) {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Interview Date Should Not Be Less Than today',
              alertType: 'error',
            });
            return;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    onFormSubmit(isValid, values) {
      if (isValid) {
        this.updateInterviewWithFeedback(this.createPayLoad(values));
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    updateInterviewWithFeedback(payload) {
      axios
        .put(`recruitment/interviews/${this.interviewId}`, payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.message,
            alertType: 'success',
          });
          this.$emit('close');
        })
        .catch((error) => {
          console.error('ERR while create New Interview', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },

    createPayLoad(values) {
      this.payload.round_type = this.interview_obj.round_type;
      this.payload.round_number = this.interview_obj.round_number;
      this.payload.date = this.interview_obj.date;
      this.payload.interview_status = values.status;
      this.payload.mode = this.interview_obj.mode;
      this.payload.duration = this.interview_obj.duration;
      this.payload.feedback = values.overall_rating;
      this.payload.profile_id = this.interview_obj.profile_id;
      this.payload.user = this.interview_obj.interviewer_id;
      this.payload.score_card = JSON.stringify({
        technical_ability: values.technical_ability,
        leadership_skills: values.leadership_skills,
        attitude: values.attitude,
        communication: values.communication,
        pros: values.pros,
        overall_rating: values.overall_rating,
        cons: values.cons,
        overall_comments: values.overall_comments,
        status: values.status,
      });
      return this.payload;
    },

    convertResponseToPayload(userInterview) {
      const feedback = JSON.parse(userInterview.score_card);

      this.formFields.forEach((formField) => {
        if (formField.type === 'text') {
          if (
            formField.name === 'pros' ||
            formField.name === 'cons' ||
            formField.name === 'overall_comments'
          ) {
            formField.defaultValue = feedback[formField.name];
          }
        }
        if (formField.type === 'select') {
          formField.defaultValue = formField.options.find(
            (option) => option.value === feedback[formField.name]
          );
        }
      });
    },

    onCancel() {
      if (this.isDataChanged) {
        this.$confirm(
          'Leaving this popup will discard unsaved changes. Are you sure?'
        ).then((isConfirmed) => {
          if (isConfirmed) {
            this.isGoingBack = true;
            // this.$router.back();
            this.$emit('cancel');
          } else {
            this.isGoingBack = false;
            return;
          }
        });
      } else {
        this.$emit('cancel');
      }
    },
    close() {
      this.$emit('close');
    },

    validateInputs(callback) {
      const errorTextClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600 px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm';
      const inputDivs = document.getElementsByClassName('text_error');
      const selectDivs = document.getElementsByClassName('select_error');
      if (inputDivs.length > 0 || selectDivs.length > 0) {
        let i;
        for (i = 0; i < inputDivs.length; i++) {
          inputDivs[i].className = errorTextClass;
        }

        let selectDiv;
        for (selectDiv in selectDivs) {
          if (selectDivs[parseInt(selectDiv)]) {
            selectDivs[parseInt(selectDiv)].getElementsByTagName(
              'div'
            )[0].style['border-color'] = 'red';
          }
        }
        callback(true);
      } else {
        callback(true);
      }
    },
  },
});
</script>
