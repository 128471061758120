
































































































































import Vue from 'vue';
import Holiday from '@/components/tables/Holiday.vue';
import axios from 'axios';
import moment from 'moment';
import UploadFileButton from '@/components/common/UploadFileButton.vue';
import Loading from '@/components/common/Loading.vue';
import Pagination from '@/components/common/Pagination.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { mapGetters } from 'vuex';
import CustomFilter from '@/components/common/CustomFilter.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default Vue.extend({
  name: 'Holidays',
  computed: {
    ...mapGetters('user', ['userLocation']),
  },
  data: () => ({
    isActive: false,
    selectedYear: moment().year(),
    currentYear: moment().year(),
    isLoading: false,
    holidays: [],
    paginationMeta: {},
    pageSize: 30,
    filterBy: {
      location: [],
    } as any,
    query: '',
    isTableLoading: true,
    showHolidayFilter: false,
    defaultFilterBy: {
      location: [],
    },
    isReadPermission: false,
    isExportPermission: false,
    isUploadPermission: false,
    nonce: '',
  }),
  components: {
    Holiday,
    UploadFileButton,
    Loading,
    Pagination,
    CustomFilter,
    ExportButtons,
    NoRecordFound,
    NotAuthorized,
  },
  methods: {
    downloadCsvTemplate() {
      const csvText = 'Date (YYYY-MM-DD),Location,Holiday\n';
      const anchor = document.createElement('a');
      anchor.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText);
      anchor.target = '_blank';
      anchor.download = 'Holidays.csv';
      anchor.click();
    },
    resetFilter() {
      this.selectedYear = this.currentYear;
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchHolidays(1);
    },
    toggleFilter() {
      this.showHolidayFilter = !this.showHolidayFilter;
    },
    setFilterByLocation(option: any) {
      this.filterBy.location = option;
      this.fetchHolidays(1);
    },
    exportToCSV() {
      if (this.isExportPermission) {
        const payload = this.getHolidaysPayload();
        axios
          .post(`/holidays/export_csv.csv`, payload)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link: any = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `Holidays_${moment().format('yyyy_MM_DD')}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            // handle error
            const { data } = error.response;
            this.$store.dispatch('settings/showAlert', {
              alertMessage:
                data.msg || 'Something went wrong. Please try again',
              alertType: 'error',
            });
            console.error('ERR while exportToCSV', error);
          });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Unauthorized',
          alertType: 'error',
        });
      }
    },
    createPDF() {
      if (this.isExportPermission) {
        const doc: any = new jsPDF('l');
        doc.text(`Holidays: ${moment().format('LLLL')}`, 15, 10);
        doc.autoTable({
          html: '#holidays_table',
        });
        doc.save(`Holidays_${moment().format('yyyy_MM_DD')}.pdf`);
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Unauthorized',
          alertType: 'error',
        });
      }
    },
    setQuery(e: any) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchHolidays(1);
      }
    },
    setPageSize(value: any) {
      this.pageSize = value;
      this.fetchHolidays(1);
    },
    showAlert() {
      this.isActive = !this.isActive;
    },
    goToPrev() {
      this.selectedYear--;
      (this as any).updateQueryParams({ year: this.selectedYear });
      this.fetchHolidays(1);
    },
    goToNext() {
      this.selectedYear++;
      (this as any).updateQueryParams({ year: this.selectedYear });
      this.fetchHolidays(1);
    },
    getHolidaysPayload() {
      const { filterBy, selectedYear, query } = this;
      const payload: any = {};
      if (filterBy.location.length > 0) {
        payload.location = filterBy.location;
      }
      if (query) {
        payload.search = query;
      }
      payload.year = selectedYear;
      return payload;
    },
    fetchHolidays(page: number) {
      this.nonce = Date.now().toString();
      if (this.isReadPermission) {
        this.isLoading = true;
        this.isTableLoading = true;
        const { pageSize } = this;
        const payload = this.getHolidaysPayload();
        payload['nonce'] = this.nonce;
        axios
          .post(
            `/holidays/all?page_size=${pageSize}${page ? '&page=' + page : ''}`,
            payload
          )
          .then((response) => {
            const { nonce, holidays, meta } = response.data;
            if (this.nonce !== nonce) return;
            this.holidays = holidays;
            this.paginationMeta = meta;
            this.isLoading = false;
            this.isTableLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.holidays = [];
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Something went wrong.Please try again',
              alertType: 'error',
            });
            // handle error
            console.error('ERR while fetchDepartments', error);
          });
      }
    },
    filterHolidayKeysFromCSV(data: any) {
      const changedKeys: any = {
        Date: 'date',
        Holiday: 'name',
        Location: 'location',
      };
      const updatedMapList: any = [];
      data.forEach((d: any) => {
        let updatedMap: any = {};
        Object.keys(d).forEach((column_name: string) => {
          if (Object.keys(changedKeys).includes(column_name)) {
            updatedMap[changedKeys[column_name]] = d[column_name];
          }
        });
        if (Object.keys(updatedMap).length === 3) {
          updatedMapList.push(updatedMap);
        }
      });
      return updatedMapList;
    },
    createBulkHolidays(data: any) {
      if (this.isUploadPermission) {
        axios
          .post('/holidays/bulk', { holidays: data })
          .then((response: any) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg || 'Successfully created',
              alertType: 'success',
            });
            this.fetchHolidays(1);
          })
          .catch((error: any) => {
            const { data } = error.response;
            this.$store.dispatch('settings/showAlert', {
              alertMessage:
                data.msg || 'Something went wrong. Please try again',
              alertType: 'error',
            });
            console.log('ERR while BulkUpload', error);
          });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Unauthorized',
          alertType: 'error',
        });
      }
    },
    onParsed(data: any) {
      this.createBulkHolidays(data);
    },
  },
  created() {
    this.fetchHolidays = _debounce(this.fetchHolidays, 400);

    this.isReadPermission = (this as any).validatePermission(['holiday.read']);
    this.isExportPermission = (this as any).validatePermission([
      'holiday.export',
    ]);
    this.isUploadPermission = (this as any).validatePermission([
      'holiday.upload',
    ]);

    this.filterBy.location = this.userLocation ? [this.userLocation] : [];
    this.defaultFilterBy = { ...this.filterBy };

    const query: any = this.$route.query;
    const thisYear = moment().year();
    const { year } = query;
    const params: any = {};
    if (query) {
      if (year) {
        const year = parseInt(query.year);
        if (year <= thisYear + 1) {
          this.selectedYear = year;
        } else {
          params['year'] = thisYear;
          (this as any).updateQueryParams(params);
        }
      }
    }

    this.fetchHolidays(1);
  },
});
