<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Add Manager:
                  <span class="text-gray-400 ml-2">{{ projectName }}</span>
                </h3>
              </div>
            </div>
          </div>

          <hr />

          <div class="mx-3">
            <div
              class="flex flex-row justify-between items-center w-full pt-2 pb-4 px-2"
            >
              <label class="text-gray-400 text-sm">
                Manager
                <span class="text-red-500 ml-1">*</span>
              </label>
              <v-select
                placeholder="Select manager"
                class="mt-2 w-2/3 text-gray-400 text-sm"
                :value="payload.manager"
                :options="users"
                @input="setSelectedUser"
              ></v-select>
            </div>

            <div
              class="flex flex-row justify-start w-full items-center py-4 px-2 md:mb-12"
            >
              <label class="text-gray-400 text-sm w-1/3">Notify PMO</label>
              <SwitchInput
                class="w-24"
                :toggleActive="payload.notify_pmo"
                @onChange="payload.notify_pmo = !payload.notify_pmo"
                :name="'notify_pmo'"
              />
            </div>
          </div>

          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              @click="save"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SwitchInput from '@/components/inputs/SwitchInput.vue';

export default {
  name: 'MangerModal',
  components: {
    SwitchInput,
  },
  data() {
    return {
      users: [],
      selectedUser: {},
      payload: {
        notify_pmo: false,
        manager: null,
      },
    };
  },
  props: {
    projectName: {
      type: String,
    },
    projectId: {
      type: Number,
      required: true,
    },
    managers: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    setSelectedUser(user) {
      this.payload.manager = user;
    },
    close() {
      this.$emit('close');
    },
    addManager(callback) {
      const { projectId, payload } = this;
      axios
        .post(`/manage/projects/add_manager`, {
          project_id: projectId,
          user_id: payload.manager.value,
          notify_pmo: payload.notify_pmo,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          callback(response.data.data);
        })
        .catch((error) => {
          console.error('ERR while addManager', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
    save() {
      if (this.payload.manager) {
        this.addManager((data) => {
          if (data) {
            this.$emit('success', data);
          }
        });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please select manager.',
          alertType: 'error',
        });
      }
    },
    filterUsers(users) {
      const filteredUsers = [];
      users.forEach((user) => {
        if (!this.managers.includes(user.id)) {
          filteredUsers.push({
            label: user.email,
            value: user.id,
          });
        }
      });
      return filteredUsers;
    },
    fetchManagers() {
      axios
        .get(`/manage/users/project_managers`)
        .then((response) => {
          response.data.filter((manager) => manager);
          this.users = this.filterUsers(response.data);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchManagers', error);
        });
    },
  },
  created() {
    this.fetchManagers();
  },
};
</script>
