































































































































import Vue from 'vue';
import axios from 'axios';
import EditOtherInfoModal from './EditOtherInfoModal.vue';

export default Vue.extend({
  name: 'UserSkills',
  data() {
    return {
      primarySkills: [],
      additionalSkills: [],
      certification: [],
      isEditOtherInfoModal: false,
      isManagePermission: false,
      isReadPermission: false,
    };
  },
  components: {
    EditOtherInfoModal,
  },
  props: ['userDetails'],
  watch: {
    userDetails: function (_newVal: any, _oldVal: any) {
      this.setUserDetails();
    },
  },
  created() {
    this.isManagePermission = (this as any).validatePermission(['home.manage']);
    this.isReadPermission = (this as any).validatePermission(['home.read']);
  },
  mounted() {
    this.setUserDetails();
  },
  methods: {
    getSkills(skills: any) {
      const skillNames = (skills && skills.map((skill: any) => skill)) || [];
      return skillNames.join(', ');
    },
    openEditOtherInfoModal() {
      this.isEditOtherInfoModal = true;
    },
    closeEditOtherInfoModal() {
      this.isEditOtherInfoModal = false;
    },
    updateUserInfo(params: any) {
      if (this.isManagePermission) {
        axios
          .patch(`/manage/users/update_user_info/`, params)
          .then((res: any) => {
            this.$emit('updateUserDetails', res.data.user_details);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Other Info Updated Successfully',
              alertType: 'success',
            });
            this.closeEditOtherInfoModal();
          })
          .catch((error) => {
            let errMsg = 'Something went wrong. Please try again';
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errMsg = error.response.data.message[0];
            }
            this.$store.dispatch('settings/showAlert', {
              alertMessage: errMsg,
              alertType: 'error',
            });
          });
      }
    },
    setUserDetails() {
      const userDetails =
        this.userDetails || this.$store.state.user.userDetails;
      this.primarySkills = userDetails.primary_skill_set
        .split(',')
        .filter((skill: any) => skill);
      this.additionalSkills = userDetails.additional_skills
        .split(',')
        .filter((skill: any) => skill);
      this.certification = userDetails.certifications.map(
        (cert: any) => cert.certification_name
      );
    },
  },
});
