<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div
      class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
    >
      <div class="bg-white dark:bg-gray-800 shadow rounded relative">
        <CustomFilter
          v-if="showDetailedFilter"
          :filterBy="filterBy"
          :projectPractices="projectPractices"
          :userPractices="userPractices"
          @resetFilter="resetFilter"
          @setFilterByUserStatus="setFilterByUserStatus"
          @setFilterByProjectStatus="setFilterByProjectStatus"
          @setFilterByUserPractices="setFilterByUserPractices"
          @setFilterByLocation="setFilterByLocation"
          @setFilterByUsers="setFilterByUsers"
          @setFilterByDuration="setFilterByDuration"
          @setFilterByProjectPractices="setFilterByProjectPractices"
          @toggleFilter="toggleFilter"
          @setFilterByAdmin="setFilterByAdmin"
          :userOptions="userOptions"
          :duration="duration"
          :adminUser="adminUser"
          :user_status="user_status"
          :project_status="project_status"
        />
        <div class="flex py-4 px-4 justify-between items-center">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :excel="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToExcel="exportToExcel"
          />
          <div v-else></div>
          <!-- Icons END -->
          <div v-if="isReadPermission" class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600" style="margin-right: 30px">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
            <span
              class="material-icons text-2xl cursor-pointer select-none text-gray-500"
              @click="toggleFilter"
              >filter_alt</span
            >
          </div>
        </div>
        <Loading v-if="isLoading" style="height: 68vh" />

        <div v-else class="flex flex-col" style="height: 26rem">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && reports.length > 0"
          >
            <table
              id="detailed"
              class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
            >
              <thead>
                <tr id="first" ref="firstRow">
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="colIndex + column"
                    rowspan="2"
                    style="border: 1px solid white"
                    v-for="(column, colIndex) in columns"
                  >
                    {{ column }}
                  </th>
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="col1Index + column"
                    :colspan="colMerge[col1Index]"
                    style="border: 1px solid white"
                    v-for="(column, col1Index) in column1"
                  >
                    {{ column }}
                  </th>
                </tr>
                <tr id="second" ref="secondRow">
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="index"
                    v-for="(col, index) in column2"
                    style="border: 1px solid white"
                  >
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="reportIndex + report.full_name"
                  v-for="(report, reportIndex) in reports"
                  class="py-2"
                  :class="reportIndex % 2 === 1 ? 'bg-gray-100' : ''"
                >
                  <td
                    :id="report.full_name"
                    class="px-4 text-xs py-2"
                    style="border: 1px solid #e5e7eb"
                  >
                    <span v-if="isReadUserPermission">
                      <router-link :to="`/manage/users/${report.id}`">
                        <p class="font-semibold text-blue-500">
                          {{ report.full_name }}
                        </p>
                      </router-link>
                    </span>
                    <span v-else>
                      <p class="font-semibold text-black-500">
                        {{ report.full_name }}
                      </p>
                    </span>
                  </td>

                  <td class="px-4 text-xs" style="border: 1px solid #e5e7eb">
                    <p class>{{ report.practice }}</p>
                  </td>
                  <td class="px-4 text-xs" style="border: 1px solid #e5e7eb">
                    <p class>{{ report.emp_type }}</p>
                  </td>
                  <td
                    class="px-4 text-center"
                    style="border: 1px solid #e5e7eb"
                    :id="report.total_duration"
                  >
                    <p
                      class="text-white-800 selected-cell font-semibold px-4 py-0 rounded-full"
                    >
                      {{ report.total_duration }}
                    </p>
                  </td>
                  <td
                    class="px-4 text-center cursor-pointer hover:shadow-lg selected-cell"
                    style="border: 1px solid #e5e7eb"
                    :key="index"
                    @mouseover="() => mouseOver(report.full_name)"
                    @mouseleave="() => mouseLeave(report.full_name)"
                    v-for="(
                      practice_duration, index
                    ) in report.project_duration"
                  >
                    <p class>{{ practice_duration }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <NotAuthorized v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>

        <!-- Table END -->
      </div>
    </div>
    <!-- Pagination -->
    <div v-if="isReadPermission">
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchPracticeWorkReport"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination End-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'DetailedTab',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    NotAuthorized,
    NoRecordFound,
    ExportButtons,
  },
  data() {
    return {
      showUserFilter: false,
      totalHours: 0,
      locations: [
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'USA', value: 'USA' },
        { label: 'Mexico', value: 'Mexico' },
      ],
      projectPractices: [],
      userPractices: [],
      userOptions: [],
      duration: 'month',
      adminUser: 'No',
      user_status: { label: 'Active', value: 'true' },
      project_status: { label: 'Active', value: 'true' },
      filterBy: {
        projectPractices: [],
        users: [],
        userPractices: [],
        status: null,
        location: [],
        user_status: { label: 'Active', value: 'true' },
        project_status: { label: 'Active', value: 'true' },
        duration: null,
        adminUser: '',
      },
      query: '',

      column1: [],
      reports: [],
      column2: [],
      colMerge: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      columns: [
        'USER NAME',
        'USER PRACTICE',
        'EMPLOYEE TYPE',
        'TOTAL DURATION',
      ],
      isReadPermission: false,
      isExportPermission: false,
      nonce: '',
    };
  },
  computed: {
    ...mapGetters('user', ['userId', 'isReadUserPermission']),
  },
  props: ['showDetailedFilter', 'dateRange'],
  created() {
    this.fetchPracticeWorkReport = _debounce(this.fetchPracticeWorkReport, 400);
    this.isReadPermission = this.validatePermission([
      'reports.pwd.detailed.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.pwd.detailed.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchUserOptions();
      this.fetchUserPractices();
      this.fetchProjectPractices();
      this.fetchPracticeWorkReport(1);
    } else {
      this.isLoading = false;
    }
  },
  watch: {
    dateRange: {
      immediate: true,
      handler(_newValue, _oldValue) {
        if (this.isReadPermission) {
          this.fetchPracticeWorkReport(1);
        }
      },
    },
  },
  methods: {
    resetFilter() {
      this.$emit('resetDate');
      this.filterBy = { ...this.defaultFilterBy };
      this.duration = 'month';
      this.fetchPracticeWorkReport(1);
    },
    mouseLeave(userName) {
      let userElement = document.getElementById(userName);
      userElement.style['color'] = 'inherit';
      userElement.style['background-color'] = 'inherit';
      // userElement.style["font-size"] = "inherit";
    },
    mouseOver(userName, _totalDuration) {
      let userElement = document.getElementById(userName);
      userElement.style['color'] = 'white';
      userElement.style['background-color'] = 'deepskyblue';
      // userElement.style["font-size"] = "14px";
    },
    toggleFilter() {
      this.$emit('toggleFilter');
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(
        `Practice Work Distribution Detailed Report: ${moment().format(
          'LLLL'
        )}`,
        15,
        10
      );
      doc.autoTable({
        html: '#detailed',
      });
      doc.save(
        `Practice Work Distribution Detailed_${moment().format(
          'yyyy_MM_DD'
        )}.pdf`
      );
    },

    setFilterByUserPractices(option) {
      this.filterBy.userPractices = option;
      this.fetchUserOptions();
      this.fetchPracticeWorkReport(1);
    },
    setFilterByUserStatus(option) {
      this.filterBy.user_status = option;
      this.fetchUserOptions();
      this.fetchPracticeWorkReport(1);
    },
    setFilterByProjectStatus(option) {
      this.filterBy.project_status = option;
      this.fetchPracticeWorkReport(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchUserOptions();
      this.fetchPracticeWorkReport(1);
    },
    setFilterByDuration(option) {
      this.filterBy.duration = option;
      this.fetchPracticeWorkReport(1);
    },
    setFilterByUsers(option) {
      this.filterBy.users = option;
      this.fetchPracticeWorkReport(1);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.fetchPracticeWorkReport(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchPracticeWorkReport(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchPracticeWorkReport(1);
    },
    setFilterByAdmin(e) {
      this.filterBy.adminUser = e.target.value;
      this.fetchUserOptions();
      this.fetchPracticeWorkReport(1);
    },
    fetchUserPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.userPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUserPractices', error);
        });
    },
    fetchProjectPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getUsersPayload() {
      const { filterBy, query, dateRange } = this;
      const payload = {};
      if (filterBy.location.length > 0) {
        payload.locations = filterBy.location;
      }
      if (filterBy.userPractices.length > 0) {
        payload.userPractices = filterBy.userPractices;
      }
      if (filterBy.projectPractices.length > 0) {
        payload.projectPractices = filterBy.projectPractices;
      }
      if (filterBy.users.length > 0) {
        payload.users = filterBy.users;
      }
      if (filterBy.user_status) {
        payload.user_status = filterBy.user_status.value;
      }
      if (filterBy.project_status) {
        payload.project_status = filterBy.project_status.value;
      }
      if (dateRange) {
        payload.date_range = dateRange;
      }
      if (query) {
        payload.search = query;
      }
      if (filterBy.duration) {
        payload.duration = filterBy.duration.target.defaultValue;
        this.duration = filterBy.duration.target.defaultValue;
      }
      if (filterBy.adminUser) {
        payload.admin_user = filterBy.adminUser;
      }
      return payload;
    },
    fetchPracticeWorkReport(page) {
      this.isLoading = true;
      const { pageSize } = this;
      this.nonce = Date.now().toString();
      const payload = this.getUsersPayload();
      axios
        .get(
          `/reports/practice_work_detailed?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              location: payload.locations,
              users: payload.users,
              project_status: payload.project_status,
              project_practice: payload.projectPractices,
              user_status: payload.user_status,
              user_practices: payload.userPractices,
              date_range: payload.date_range,
              duration: payload.duration,
              search: payload.search,
              admin_user: payload.admin_user,
              nonce: this.nonce,
            },
          }
        )
        .then((response) => {
          const { nonce, meta, report, column, columnName, col_merge } =
            response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.reports = report;
          this.column2 = column;
          this.column1 = columnName;
          this.colMerge = col_merge;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    fetchUserOptions() {
      const payload = this.getUsersPayload();
      axios
        .get(`/manage/users/filter_users_list`, {
          params: {
            location: payload.locations,
            status: payload.user_status,
            practice: payload.userPractices,
            admin_user: payload.admin_user,
          },
        })
        .then((response) => {
          this.userOptions = this.filterUsers(response.data.users);
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchUsers', error);
        });
    },
    filterUsers(users) {
      const filteredUsers = [];
      users.forEach((user) => {
        filteredUsers.push({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
        });
      });
      return filteredUsers;
    },
    exportToExcel() {
      const payload = this.getUsersPayload();
      axios
        .get(`/reports/excel_practice_work_detailed`, {
          params: {
            location: payload.locations,
            users: payload.users,
            project_status: payload.project_status,
            project_practice: payload.projectPractices,
            user_status: payload.user_status,
            user_practices: payload.userPractices,
            date_range: payload.date_range,
            duration: payload.duration,
            search: payload.search,
            admin_user: payload.admin_user,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Practice_Work_Distribution_Detailed_Report.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
  },
};
</script>

<style scoped>
thead tr#first th,
thead tr#first td {
  position: sticky;
  top: 0;
}

thead tr#second th,
thead tr#second td {
  position: sticky;
  top: 2.2rem;
}
.selected-cell:hover {
  font-size: 16px;
  background-color: deepskyblue;
  color: white;
}
</style>
