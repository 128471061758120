import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Holiday from '../views/Holiday.vue';
import Log from '../views/timesheet/Log.vue';
import SignIn from '../views/Login.vue';
import Project from '../views/manage/projects/Project.vue';
import Projects from '../views/manage/projects/Projects.vue';
import Users from '../views/manage/users/Users.vue';
import User from '../views/manage/users/User.vue';
import UserMoreDetails from '../views/manage/users/UserMoreDetails.vue';
import CreateUser from '../views/manage/users/CreateUser.vue';
import CreateProject from '../views/manage/projects/CreateProject.vue';
import ProjectAssignment from '../views/pmo/ProjectAssignment.vue';
import OverallUtilization from '../views/pmo/OverallUtilization.vue';
import DetailLogReport from '../views/reports/DetailLogReport.vue';
import CreateRecruitment from '../views/recruitment/requirementList/CreateRequirement.vue';
import RequirementList from '../views/recruitment/requirementList/RequirementList.vue';
import Requirement from '../views/recruitment/requirementList/Requirement.vue';
import Profile from '../views/recruitment/requirementList/AddProfile.vue';
import offeredCandidatesList from '../views/recruitment/offeredCandidates/OfferedCandidatesList.vue';
import pipelineCandidatesList from '../views/recruitment/pipelineCandidates/PipelineCandidatesList.vue';
import OverallPerformanceReport from '../views/recruitment/overallPerformance/OverallPerformanceReport.vue';
import Billability from '../views/pmo/Billability.vue';
import Configuration from '../views/manage/configuration/Configuration.vue';
import PracticeWorkDistribution from '../views/reports/PracticeWorkDistribution.vue';
import Skills from '../views/manage/skills/Skills.vue';
import UtilizationDetails from '../views/pmo/UtilizationDetails.vue';
import SubmitForApproval from '../views/timesheet/SubmitForApproval/SubmitForApproval.vue';
import OverallLogReport from '../views/reports/OverallLogReport.vue';
import Error404 from '../views/Error404.vue';
import Roles from '../views/manage/Roles.vue';
import TalentPool from '../views/reports/TalentPool.vue';
import FulltimeToContractor from '../views/reports/FulltimeToContractor.vue';
import SkillsAvailability from '../views/pmo/SkillsAvailability.vue';
import TimesheetLogStatusReport from '../views/reports/TimesheetLogStatusReport.vue';
import MyProjectApprovals from '../views/timesheet/MyProjectApprovals/MyProjectApprovals.vue';
import ContractorData from '../views/finance/ContractorData.vue';
import PracticeResourceAvailability from '../views/reports/PracticeResourceAvailability.vue';
import Certification from '../views/manage/certification/Certification.vue';
import DetailedPerformanceReport from '../views/recruitment/detailedPerformance/DetailedPerformanceReport.vue';
import Histories from '../views/manage/histories/Histories.vue';
import AuthRedirect from '../views/AuthRedirect.vue';

import MyApprovals from '../views/timesheet/MyApprovals/MyApprovals.vue';
import store from '../store';
import UnderConstruction from '../components/common/UnderConstruction.vue';
import FinanceReport from '../views/reports/FinanceReport.vue';
import MyInterviewsList from '../views/recruitment/interviewList/MyInterviewsList.vue';
import TimesheetLogReport from '../views/reports/TimesheetLogReport.vue';
import CandidatesList from '../views/recruitment/interviewList/CandidatesList.vue';
import AllocationDistribution from '../views/reports/AllocationDistribution.vue';

Vue.use(VueRouter);

// EXAMPLE: To create route, Find data from [migrate_menus.rake]
// {
//   path: menu_link/menu_item_link,
//   name: Menu Name,
//   component: Component to be used,
//   meta: {
//     nav: Menu Name,
//     subNav: MenuItem Name,
//     requiresAuth: true
//   }
// }

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      nav: 'Home',
      permissions: ['home.read', 'home.manage'],
    },
  },
  {
    path: '/holiday',
    name: 'Holiday',
    component: Holiday,
    meta: {
      nav: 'Holiday',
      requiresAuth: true,
      permissions: ['holiday.read', 'holiday.export', 'holiday.upload'],
    },
  },
  {
    path: '/timesheet/log',
    name: 'Log',
    component: Log,
    meta: {
      nav: 'Timesheet',
      subNav: 'Log',
      requiresAuth: true,
      permissions: [
        'timesheet.log.read',
        'timesheet.log.manage',
        'timesheet.log.read.others',
        'timesheet.log.manage.others',
        'timesheet.log.upload',
        'timesheet.log.export',
      ],
    },
  },
  {
    path: '/timesheet/submit-for-approval',
    name: 'SubmitForApproval',
    component: SubmitForApproval,
    meta: {
      nav: 'Timesheet',
      subNav: 'Submit For Approval',
      requiresAuth: true,
      permissions: [
        'timesheet.submit_for_approval.read',
        'timesheet.submit_for_approval.manage',
      ],
    },
  },
  {
    path: '/timesheet/my-approvals',
    name: 'MyApprovals',
    component: MyApprovals,
    meta: {
      nav: 'Timesheet',
      subNav: 'My Approvals (US)',
      requiresAuth: true,
      permissions: [
        'timesheet.my_approvals.read',
        'timesheet.my_approvals.manage',
      ],
    },
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/auth_redirect',
    name: 'AuthRedirect',
    component: AuthRedirect,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/manage/projects/:id',
    name: 'Project',
    component: Project,
    meta: {
      nav: 'Manage',
      subNav: 'Projects',
      requiresAuth: true,
      permissions: [
        'manage.projects.read',
        'manage.projects.manage',
        'manage.projects.histories.read',
      ],
    },
  },
  {
    path: '/manage/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      nav: 'Manage',
      subNav: 'Projects',
      requiresAuth: true,
      permissions: [
        'manage.projects.read',
        'manage.projects.manage',
        'manage.projects.export',
      ],
    },
  },
  {
    path: '/manage/users',
    name: 'Users',
    component: Users,
    meta: {
      nav: 'Manage',
      subNav: 'Users',
      requiresAuth: true,
      permissions: [
        'manage.users.read',
        'manage.users.manage',
        'manage.users.export',
        'manage.users.upload',
      ],
    },
  },
  {
    path: '/manage/users/:id',
    name: 'User',
    component: User,
    meta: {
      nav: 'Manage',
      subNav: 'Users',
      requiresAuth: true,
      permissions: ['manage.users.read', 'manage.users.manage'],
    },
  },
  {
    path: '/manage/users/more/:id',
    name: 'UserMoreDetails',
    component: UserMoreDetails,
    meta: {
      nav: 'Home',
      requiresAuth: true,
      permissions: ['home.read', 'home.manage'],
    },
  },
  {
    path: '/manage/users/form/create',
    name: 'CreateUser',
    component: CreateUser,
    meta: {
      nav: 'Manage',
      subNav: 'Users',
      requiresAuth: true,
      permissions: ['manage.users.manage'],
    },
  },
  {
    path: '/manage/users/form/edit/:id',
    name: 'EditUser',
    component: CreateUser,
    meta: {
      nav: 'Manage',
      subNav: 'Users',
      requiresAuth: true,
      permissions: ['manage.users.manage'],
    },
  },
  {
    path: '/manage/projects/form/create',
    name: 'CreateProject',
    component: CreateProject,
    meta: {
      nav: 'Manage',
      subNav: 'Projects',
      requiresAuth: true,
      permissions: ['manage.projects.manage'],
    },
  },
  {
    path: '/manage/projects/form/edit/:id',
    name: 'EditProject',
    component: CreateProject,
    meta: {
      nav: 'Manage',
      subNav: 'Projects',
      requiresAuth: true,
      permissions: ['manage.projects.manage'],
    },
  },
  {
    path: '/pmo/project-assignment',
    name: 'Project Assignment',
    component: ProjectAssignment,
    meta: {
      nav: 'PMO',
      subNav: 'Project Assignment',
      requiresAuth: true,
      permissions: [
        'pmo.project_asignment.read',
        'pmo.project_asignment.export',
      ],
    },
  },
  {
    path: '/pmo/dashboard',
    name: 'Dashboard',
    component: UnderConstruction,
    meta: {
      nav: 'PMO',
      subNav: 'PMO Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/reports/detailed-log-report',
    name: 'detailedLogReport',
    component: DetailLogReport,
    meta: {
      nav: 'Reports',
      subNav: 'Detailed log Report',
      requiresAuth: true,
      permissions: [
        'reports.detailed_logs.read',
        'reports.detailed_logs.export',
      ],
    },
  },
  {
    path: '/reports/practice-work-distribution',
    name: 'practiceWorkDistribution',
    component: PracticeWorkDistribution,
    meta: {
      nav: 'Reports',
      subNav: 'Practice Work Distribution',
      requiresAuth: true,
      permissions: [
        'reports.pwd.consolidated.read',
        'reports.pwd.consolidated.export',
        'reports.pwd.detailed.read',
        'reports.pwd.detailed.export',
      ],
    },
  },
  {
    path: '/reports/overall-log-report',
    name: 'overallLogReport',
    component: OverallLogReport,
    meta: {
      nav: 'Reports',
      subNav: 'Overall Log Report',
      requiresAuth: true,
      permissions: ['reports.overall_logs.read', 'reports.overall_logs.export'],
    },
  },
  {
    path: '/reports/timesheet-log-report',
    name: 'TimesheetLogReport',
    component: TimesheetLogReport,
    meta: {
      nav: 'Reports',
      subNav: 'Timesheet Log Report',
      requiresAuth: true,
      permissions: [
        'reports.timesheet_log.read',
        'reports.timesheet_log.export',
      ],
    },
  },
  {
    path: '/recruitment/list/form/edit/:id/:isEdit',
    name: 'EditRecruitment',
    component: CreateRecruitment,
    meta: {
      nav: 'Recruitment',
      subNav: 'Requirements List',
      requiresAuth: true,
      permissions: ['recruitment.requirements_list.manage'],
    },
  },
  {
    path: '/recruitment/list/form/create',
    name: 'CreateRecruitment',
    component: CreateRecruitment,
    meta: {
      nav: 'Recruitment',
      subNav: 'Requirements List',
      requiresAuth: true,
      permissions: ['recruitment.requirements_list.manage'],
    },
  },
  {
    path: '/recruitment/list',
    name: 'list',
    component: RequirementList,
    meta: {
      nav: 'Recruitment',
      subNav: 'Requirements List',
      requiresAuth: true,
      permissions: [
        'recruitment.requirements_list.read',
        'recruitment.requirements_list.manage',
        'recruitment.requirements_list.export',
      ],
    },
  },
  {
    path: '/recruitment/list/:id',
    name: 'Recruitment',
    component: Requirement,
    meta: {
      nav: 'Recruitment',
      subNav: 'Requirements List',
      requiresAuth: true,
      permissions: [
        'recruitment.requirements_list.read',
        'recruitment.requirements_list.manage',
        'recruitment.requirements_list.export',
      ],
    },
  },
  {
    path: '/recruitment/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      nav: 'Recruitment',
      subNav: 'Requirements List',
      requiresAuth: true,
      permissions: ['recruitment.requirements_list.manage'],
    },
  },
  {
    path: '/recruitment/offered-candidates',
    name: 'offeredCandidates',
    component: offeredCandidatesList,
    meta: {
      nav: 'Recruitment',
      subNav: 'Offered Candidates',
      requiresAuth: true,
      permissions: [
        'recruitment.offered_candidates.read',
        'recruitment.offered_candidates.manage',
        'recruitment.offered_candidates.export',
      ],
    },
  },
  {
    path: '/recruitment/pipeline-candidates',
    name: 'pipelineCandidates',
    component: pipelineCandidatesList,
    meta: {
      nav: 'Recruitment',
      subNav: 'Pipeline Candidates',
      requiresAuth: true,
      permissions: [
        'recruitment.pipeline_candidates.read',
        'recruitment.pipeline_candidates.manage',
        'recruitment.pipeline_candidates.export',
      ],
    },
  },
  {
    path: '/recruitment/overall-performance',
    name: 'overall-performance',
    component: OverallPerformanceReport,
    meta: {
      nav: 'Recruitment',
      subNav: 'Overall Performance',
      requiresAuth: true,
      permissions: [
        'recruitment.overall_performance.read',
        'recruitment.overall_performance.manage',
        'recruitment.overall_performance.export',
      ],
    },
  },
  {
    path: '/recruitment/detailed-performance',
    name: 'detailedPerformanceReport',
    component: DetailedPerformanceReport,
    meta: {
      nav: 'Recruitment',
      subNav: 'Detailed Performance',
      requiresAuth: true,
      permissions: [
        'recruitment.detailed_performance.read',
        'recruitment.detailed_performance.export',
      ],
    },
  },
  {
    path: '/pmo/billability',
    name: 'Billability',
    component: Billability,
    meta: {
      nav: 'PMO',
      subNav: 'Billability',
      requiresAuth: true,
      permissions: ['pmo.billability.read', 'pmo.billability.export'],
    },
  },
  {
    path: '/manage/configuration',
    name: 'Configuration',
    component: Configuration,
    meta: {
      requiresAuth: true,
      nav: 'Manage',
      subNav: 'Configuration',
      permissions: [
        'manage.configurations.departments.read',
        'manage.configurations.departments.manage',
        'manage.configurations.departments.export',
        'manage.configurations.practices.read',
        'manage.configurations.practices.manage',
        'manage.configurations.practices.export',
        'manage.configurations.teams.read',
        'manage.configurations.teams.manage',
        'manage.configurations.teams.export',
        'manage.configurations.emp_type.read',
        'manage.configurations.emp_type.manage',
        'manage.configurations.emp_type.export',
        'manage.configurations.clients.read',
        'manage.configurations.clients.manage',
        'manage.configurations.clients.export',
      ],
    },
  },
  {
    path: '/manage/skills',
    name: 'Skills',
    component: Skills as any,
    meta: {
      requiresAuth: true,
      nav: 'Manage',
      subNav: 'Skills',
      permissions: [
        'manage.skills.read',
        'manage.skills.manage',
        'manage.skills.export',
        'manage.skills.upload',
      ],
    },
  },
  {
    path: '/manage/histories',
    name: 'Histories',
    component: Histories as any,
    meta: {
      requiresAuth: true,
      nav: 'Manage',
      subNav: 'Histories',
      permissions: ['manage.histories.read'],
    },
  },
  {
    path: '/pmo/utilization-details',
    name: 'UtilizationDetails',
    component: UtilizationDetails,
    meta: {
      nav: 'PMO',
      subNav: 'Utilization Details',
      requiresAuth: true,
      permissions: [
        'pmo.utlization_details.read',
        'pmo.utlization_details.export',
      ],
    },
  },
  {
    path: '/reports/talent-pool',
    name: 'Talent Pool',
    component: TalentPool,
    meta: {
      nav: 'Reports',
      subNav: 'Talent Pool',
      requiresAuth: true,
      permissions: ['reports.talent_pool.read', 'reports.talent_pool.export'],
    },
  },
  {
    path: '/reports/allocation-distribution',
    name: 'Allocation Distribution',
    component: AllocationDistribution,
    meta: {
      nav: 'Reports',
      subNav: 'Allocation Distribution',
      requiresAuth: true,
      permissions: ['reports.allocation_distribution.export'],
    },
  },
  {
    path: '/error-not-found',
    name: 'Error404',
    component: Error404,
    meta: {},
  },
  {
    path: '/manage/role-settings',
    name: 'Role Settings',
    component: Roles,
    meta: {
      nav: 'Manage',
      subNav: 'Role Settings',
      requiresAuth: true,
      permissions: ['manage.roles.read', 'manage.roles.manage'],
    },
  },
  {
    path: '/pmo/skills-availability',
    name: 'SkillsAvailability',
    component: SkillsAvailability,
    meta: {
      nav: 'PMO',
      subNav: 'Skills Availability',
      requiresAuth: true,
      permissions: [
        'pmo.skills_availability.read',
        'pmo.skills_availability.export',
      ],
    },
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/timesheet/review',
    name: 'Review',
    component: UnderConstruction,
    meta: {
      nav: 'timesheet',
      subNav: 'review',
      requiresAuth: true,
    },
  },
  {
    path: '/timesheet/my-project-approvals',
    name: 'MyProjectApprovals',
    component: MyProjectApprovals,
    meta: {
      nav: 'Timesheet',
      subNav: 'My Project Approvals',
      requiresAuth: true,
      permissions: [
        'timesheet.my_project_approvals.read',
        'timesheet.my_project_approvals.manage',
      ],
    },
  },

  {
    path: '/recruitment/list',
    name: 'Requirements List',
    component: UnderConstruction,
    meta: {
      nav: 'recruitment',
      subNav: 'list',
      requiresAuth: true,
    },
  },
  {
    path: '/recruitment/overall-performance',
    name: 'Overall Performance',
    component: UnderConstruction,
    meta: {
      nav: 'recruitment',
      subNav: 'overallPerformance',
      requiresAuth: true,
    },
  },
  {
    path: '/recruitment/offered-candidates',
    name: 'Offered Candidates',
    component: UnderConstruction,
    meta: {
      nav: 'recruitment',
      subNav: 'offeredCandidates',
      requiresAuth: true,
    },
  },
  {
    path: '/recruitment/pipeline-candidates',
    name: 'Pipeline Candidates',
    component: UnderConstruction,
    meta: {
      nav: 'recruitment',
      subNav: 'pipelineCandidates',
      requiresAuth: true,
    },
  },
  {
    path: '/manage/view-roles',
    name: 'View Roles',
    component: UnderConstruction,
    meta: {
      nav: 'manage',
      subNav: 'viewRoles',
      requiresAuth: true,
    },
  },
  {
    path: '/reports/practice-resource-availability',
    name: 'Practice Resource Availability',
    component: PracticeResourceAvailability,
    meta: {
      nav: 'Reports',
      subNav: 'Practice Resource Availability',
      requiresAuth: true,
      permissions: [
        'reports.practice_resource.read',
        'reports.practice_resource.export',
      ],
    },
  },
  {
    path: '/reports/full-time-contractor',
    name: 'Full Time To Contractor',
    component: FulltimeToContractor,
    meta: {
      nav: 'Reports',
      subNav: 'Full Time To Contractor',
      requiresAuth: true,
      permissions: [
        'reports.fte_to_contractor.read',
        'reports.fte_to_contractor.export',
      ],
    },
  },
  {
    path: '/reports/offshore-onshore-ratio',
    name: 'Offshore To Onshore Ratio',
    component: UnderConstruction,
    meta: {
      nav: 'reports',
      subNav: 'offshoreToOnshoreRatio',
      requiresAuth: true,
    },
  },
  {
    path: '/pmo/overall-utilization',
    name: 'Overall Utilization',
    component: OverallUtilization,
    meta: {
      nav: 'PMO',
      subNav: 'Overall Utilization',
      requiresAuth: true,
      permissions: [
        'pmo.overall_utilization.read',
        'pmo.overall_utilization.export',
      ],
    },
  },
  {
    path: '/finance/profitability-calculator',
    name: 'Profitability Calculator',
    component: UnderConstruction,
    meta: {
      nav: 'finance',
      subNav: 'profitabilityCalculator',
      requiresAuth: true,
    },
  },
  {
    path: '/finance/profitability-dashboard',
    name: 'Profitability Dashboard',
    component: UnderConstruction,
    meta: {
      nav: 'finance',
      subNav: 'profitabilityDashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/finance/contractor-data',
    name: 'Contractor Data',
    component: ContractorData,
    meta: {
      nav: 'Finance',
      subNav: 'Contractor Data',
      requiresAuth: true,
      permissions: [
        'finance.contractor.read',
        'finance.contractor.manage',
        'finance.contractor.export',
        'finance.contractor.upload',
      ],
    },
  },
  {
    path: '/favourites',
    name: 'Favourites',
    component: UnderConstruction,
    meta: {
      nav: 'favourites',
      requiresAuth: true,
    },
  },
  {
    path: '/reports/timesheet-log-status-report',
    name: 'TimesheetLogStatusReport',
    component: TimesheetLogStatusReport,
    meta: {
      nav: 'Reports',
      subNav: 'Timesheet Log Status Report',
      requiresAuth: true,
      permissions: [
        'reports.timesheet_log_status.read',
        'reports.timesheet_log_status.export',
      ],
    },
  },

  {
    path: '/manage/certifications',
    name: 'certification',
    component: Certification,
    meta: {
      requiresAuth: true,
      nav: 'Manage',
      subNav: 'Certification',
      permissions: [
        'manage.certifications.read',
        'manage.certifications.manage',
        'manage.certifications.export',
        'manage.certifications.upload',
      ],
    },
  },
  {
    path: '/reports/finance-report',
    name: 'Finance Report',
    component: FinanceReport,
    meta: {
      nav: 'Reports',
      subNav: 'Finance Report',
      requiresAuth: true,
      permissions: ['reports.finance.read', 'reports.finance.export'],
    },
  },
  {
    path: '/recruitment/candidates-list',
    name: 'Candidates List',
    component: CandidatesList,
    meta: {
      nav: 'Recruitment',
      subNav: 'Candidates List',
      requiresAuth: true,
      permissions: [
        'recruitment.candidates_list.read',
        'recruitment.candidates_list.manage',
        'recruitment.candidates_list.export',
      ],
    },
  },

  {
    path: '/recruitment/my-interview-list',
    name: 'My Interviews',
    component: MyInterviewsList,
    meta: {
      nav: 'Recruitment',
      subNav: 'My Interviews',
      requiresAuth: true,
      permissions: [
        'recruitment.my_interview_list.read',
        'recruitment.my_interview_list.manage',
      ],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: any, from: any, next) => {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    if (store.getters['user/isLoggedIn']) {
      const user_menus = store.state.user.menus;
      if (user_menus && user_menus[to.meta.nav]) {
        // Have Menu
        if (user_menus[to.meta.nav].subNavItems[to.meta.subNav]) {
          // Have Sub Menu
          if (
            user_menus[to.meta.nav].subNavItems[to.meta.subNav].permissions &&
            user_menus[to.meta.nav].subNavItems[
              to.meta.subNav
            ].permissions.some(
              (p: any) => to.meta.permissions && to.meta.permissions.includes(p)
            )
          ) {
            store.commit('settings/updateNav', {
              selectedNav: to.meta.nav,
              selectedSubNav: to.meta.subNav,
            });
            next();
          } else {
            next({ name: 'Error404' });
          }
          // if(user_menus[to.meta.nav].subNavItems[to.meta.subNav].permissions && user_menus[to.meta.nav].subNavItems[to.meta.subNav].permissions.length > 0) {
          //   // Have sub menu and Authorized for it
          //   store.commit('settings/updateNav', { selectedNav: to.meta.nav, selectedSubNav: to.meta.subNav })
          //   next()
          // } else {
          //   // NOT Authorized for this sub menu
          //   next({ name: 'Error404' })
          // }
        } else {
          if (
            user_menus[to.meta.nav].permissions &&
            user_menus[to.meta.nav].permissions.some(
              (p: any) => to.meta.permissions && to.meta.permissions.includes(p)
            )
          ) {
            store.commit('settings/updateNav', {
              selectedNav: to.meta.nav,
              selectedSubNav: to.meta.subNav,
            });
            next();
          } else {
            next({ name: 'Error404' });
          }
          // if(Object.keys(user_menus[to.meta.nav].subNavItems).length === 0 && user_menus[to.meta.nav].permissions.length > 0) {
          //   // Don't have sub menu but Authorized for Menu
          //   store.commit('settings/updateNav', { selectedNav: to.meta.nav, selectedSubNav: to.meta.subNav })
          //   next()
          // } else {
          //   // NOT Authorized for this sub menu
          //   next({ name: 'Error404' })
          // }
        }
      } else {
        // NOT Authorized for this menu
        next({ name: 'Error404' });
      }
      // if (to.matched.some(record => record.meta.isAdmin)) {
      //   if (store.getters['user/isAdmin']) {
      //     next()
      //   }
      //   else {
      //     next({ name: 'Home' })
      //   }
      // } else {
      //   next()
      // }
    } else {
      next({
        path: '/signin',
        params: { nextUrl: to.fullPath },
      });
    }
  } else if (to.matched.some((record: any) => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next();
    } else {
      next({ name: 'userboard' });
    }
  } else {
    next();
    //   next({
    //     path: '/error',
    //     // params: { nextUrl: to.fullPath }
    // })
  }
});

export default router;
