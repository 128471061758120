<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div class="w-full">
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Billability
        </div>

        <div class="flex flex-row items-center">
          <!-- <span
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleUserFilter"
            >filter_alt</span
          >-->
          <div>
            <!-- Add User -->
            <div class="block md:hidden ml-2">
              <div class="lg:ml-6 flex items-center">
                <router-link :to="`/manage/users/form/create`">
                  <button
                    class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-5 h-8 flex items-center md:text-sm text-xs"
                  >
                    <span class="material-icons md:text-2xl text-base md:mr-2"
                      >person_add</span
                    >

                    <span class="md:text-sm text-xs">Add User</span>
                  </button>
                </router-link>
              </div>
            </div>
            <!-- Add User END -->
          </div>
        </div>
      </div>
      <!-- Top Bar END-->
      <div class="bg-gray-200 w-full flex flex-col">
        <div>
          <div
            class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
          >
            <CustomFilter
              v-if="showBillabilityFilter"
              :filterBy="filterBy"
              :projectPractices="projectPractices"
              @resetFilter="resetFilter"
              @setFilterByAdmin="setFilterByAdmin"
              @setFilterByProjectPractices="setFilterByProjectPractices"
              @setFilterByLocation="setFilterByLocation"
              @toggleFilter="toggleFilter"
              :adminUser="adminUser"
            />

            <div class="flex flex-row py-4 px-4 items-center justify-between">
              <!-- Icons -->
              <ExportButtons
                v-if="isExportPermission"
                :csv="true"
                :pdf="true"
                :excel="true"
                @createPDF="createPDF"
                @exportToCSV="exportToCSV"
                @exportToExcel="exportToExcel"
              />
              <div v-else></div>
              <!-- Icons END -->
              <div class="flex flex-row items-center" v-if="isReadPermission">
                <!-- Search Input -->
                <div class="relative text-gray-600">
                  <input
                    v-model="query"
                    @input="setQuery"
                    type="search"
                    name="serch"
                    placeholder="Search"
                    class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
                  />
                  <button
                    type="submit"
                    class="absolute right-0 top-0 mt-2 mr-4"
                  >
                    <span
                      class="material-icons md:text-2xl text-sm text-gray-400"
                      >search</span
                    >
                  </button>
                </div>
                <!-- Search END -->
                <span
                  class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-3"
                  @click="toggleFilter"
                  >filter_alt</span
                >
              </div>
              <div v-else></div>
            </div>
            <Loading v-if="isTableLoading" style="height: 26rem" />
            <!-- Table -->

            <div v-else class="flex flex-col" style="height: 26rem">
              <div
                class="flex-grow overflow-auto"
                v-if="isReadPermission && departments.length > 0"
              >
                <table
                  id="departments_table"
                  class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
                >
                  <thead>
                    <tr
                      class="divide-y divide-gray-300 text-gray-100 bg-gray-400"
                    >
                      <th
                        class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                      >
                        Department/Roles
                      </th>
                      <th
                        id="emps"
                        class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                      >
                        # of Emps
                      </th>
                      <th
                        class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                        :key="index + column"
                        :id="column"
                        v-for="(column, index) in columns"
                      >
                        {{ column }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                    <tr
                      :key="index + department.name"
                      v-for="(department, index) in departments"
                      class="py-2"
                      :class="index % 2 === 1 ? 'bg-gray-100' : ''"
                    >
                      <td
                        :id="department.name"
                        class="px-4 py-2 text-xs font-semibold border-r-2 border-gray-600"
                      >
                        <p class>{{ department.name }}</p>
                      </td>
                      <td
                        class="text-center cursor-pointer hover:shadow-lg selected-cell"
                        @mouseover="() => mouseOver(department.name, 'emps')"
                        @mouseleave="() => mouseLeave(department.name, 'emps')"
                      >
                        <div
                          @click="
                            () =>
                              openUserDetailModal(
                                department.users,
                                '',
                                department.name,
                                false
                              )
                          "
                        >
                          {{ department.no_of_emp || '' }}
                        </div>
                      </td>
                      <td
                        @mouseover="
                          () => mouseOver(department.name, columnName)
                        "
                        @mouseleave="
                          () => mouseLeave(department.name, columnName)
                        "
                        @click="
                          () =>
                            openUserDetailModal(
                              department.billabilityMap &&
                                department.billabilityMap[columnName]
                                ? department.billabilityMap[columnName].users
                                : [],
                              columnName,
                              department.name,
                              true
                            )
                        "
                        class="px-4 text-center cursor-pointer hover:shadow-lg selected-cell"
                        v-for="(columnName, columnIndex) in columns"
                        :key="columnIndex"
                      >
                        <div
                          v-if="
                            department.billabilityMap &&
                            department.billabilityMap[columnName]
                          "
                        >
                          {{
                            department.billabilityMap[columnName].users
                              .length || ''
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!isTableLoading" class="font-bold text-sm">
                      <td class="px-4 py-2 text-sm">TOTAL</td>
                      <td
                        @click="
                          () =>
                            openUserDetailModal(
                              allDepartmentUsers,
                              '',
                              'All Users',
                              false
                            )
                        "
                        class="text-center cursor-pointer"
                      >
                        {{ allDepartmentUsers.length || '' }}
                      </td>
                      <td
                        class="text-center cursor-pointer hover:shadow-lg"
                        @click="
                          () =>
                            openUserDetailModal(
                              departmentsByBillability[columnName],
                              columnName,
                              '',
                              true
                            )
                        "
                        :key="columnIndex"
                        v-for="(columnName, columnIndex) in columns"
                      >
                        {{ departmentsByBillability[columnName].length }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <NotAuthorized v-else-if="!isReadPermission" />
              <NoRecordFound v-else />
            </div>
            <!-- Table END -->
          </div>
          <div>
            <BillabilityTableModal
              v-if="isUserDetailModalVisible"
              :isProjectSelected="false"
              :modalData="modalData"
              :isBillabilitySelected="isBillabilitySelected"
              @success="onSuccess"
              @close="closeUserDetailModal"
            ></BillabilityTableModal>
            <!-- <UserDetailModal /> -->
            <!-- <Pagination
              class="mt-2"
              v-if="Object.keys(paginationMeta).length > 0"
              :meta="paginationMeta"
              :pageSize="pageSize"
              @clickCallback="fetchDepartments"
              @setPageSize="setPageSize"
            />-->
          </div>
          <!-- Pagination -->
        </div>
      </div>

      <div>
        <Pagination
          class="mt-2"
          v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchDepartments"
          @setPageSize="setPageSize"
        />
      </div>
      <!-- Pagination -->
      <!-- Notes -->
      <div class="py-8 px-4 text-xs" v-if="isReadPermission">
        <strong>Note:</strong><br />
        <strong>Billed - </strong> People who are part of the SOW/Contract and
        we charge the customer for their efforts/time. Both Billable and Fixed
        Bid are considered as Billed Resources only. Billing Status Billable
        implies Time & Material Project while Billing Status Fixed-Bid implies
        Fixed Bid projects.<br />

        <strong> Non-Billed - </strong> People who are part of the SOW/Contract
        or officially into the project, but we don't bill the customer for them.
        We give them to the client as an added advantage. We usually do it in
        bulk business accounts where there are large number of people or else
        accounts that are of high strategic importance to us.<br />

        <strong>Shadow -</strong> People who are officially not part of the
        Project but may be added to a project for a few of the below reasons.
        <li class="px-6">
          There might be too much workload/backlog in the current project, and
          we give a shadow member to cater to this situation. Typically, the
          people chosen for shadow are free and don't have much workload.
        </li>
        <li class="px-6">
          We might assign some junior folks into the projects and get them
          trained/upskilled along with the other project members.
        </li>
        Shadows will also be non-billable but there is no contractual obligation
        with the client. And these resources can be pulled out of the project
        anytime.<br />
        <p class="pb-8">
          <strong> NA/Support – </strong>This Billing status is for the people
          who are mostly in Operational roles and are never expected to be on a
          billing role. Example: HR, System Admin, Office Admin, Recruitment.
          etc. We should not put this status against people who are part of any
          client Project.
        </p>
      </div>
      <div v-else></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import BillabilityTableModal from '@/components/pmo/project_assignment/BillabilityTableModal.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'Billability',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    BillabilityTableModal,
    ExportButtons,
    NoRecordFound,
    NotAuthorized,
  },
  data() {
    return {
      isUserDetailModalVisible: false,
      isBillabilitySelected: true,
      modalData: {},
      isTableLoading: false,
      locations: [
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'USA', value: 'USA' },
        { label: 'Mexico', value: 'Mexico' },
      ],
      projectPractices: [],
      adminUser: 'No',
      filterBy: {
        projectPractices: [],
        adminUser: false,
        location: [],
      },
      query: '',
      columns: [],
      departments: [],
      departmentsByBillability: {},
      departmentsByUsers: {},
      showBillabilityFilter: false,
      allDepartmentUsers: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      defaultFilterBy: {},
      nonce: '',
    };
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchDepartments(1);
    },
    mouseLeave(departmentName, billabilityName) {
      let departmentElement = document.getElementById(departmentName);
      departmentElement.style['color'] = 'inherit';
      departmentElement.style['background-color'] = 'inherit';
      // departmentElement.style["font-size"] = "inherit";

      let billabilityElement = document.getElementById(billabilityName);
      billabilityElement.style['color'] = 'inherit';
      billabilityElement.style['background-color'] = 'inherit';
      // billabilityElement.style["font-size"] = "inherit";
    },
    mouseOver(departmentName, billabilityName) {
      let departmentElement = document.getElementById(departmentName);
      departmentElement.style['color'] = 'white';
      departmentElement.style['background-color'] = 'deepskyblue';
      // departmentElement.style["font-size"] = "14px";

      let billabilityElement = document.getElementById(billabilityName);
      billabilityElement.style['color'] = 'white';
      billabilityElement.style['background-color'] = 'deepskyblue';
      // billabilityElement.style["font-size"] = "14px";
    },
    openUserDetailModal(
      users,
      billabilityName,
      departmentName,
      isBillabilitySelected
    ) {
      if (users.length > 0) {
        this.modalData = { users, billabilityName, departmentName };
        this.isUserDetailModalVisible = true;
        this.isBillabilitySelected = isBillabilitySelected;
      }
    },
    closeUserDetailModal() {
      this.isUserDetailModalVisible = false;
    },
    onSuccess() {
      this.closeUserDetailModal();
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Billability Report: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#departments_table',
      });
      doc.save(`Billability_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.fetchDepartments(1);
    },
    setFilterByAdmin(e) {
      this.filterBy.adminUser = e.target.value;
      this.fetchDepartments(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchDepartments(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchDepartments(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchDepartments(1);
    },
    fetchFilterOptions() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchFilterOptions', error);
        });
    },
    getDepartmentsPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.location.length > 0) {
        payload.location = filterBy.location;
      }
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      // payload.admin = filterBy.admin;
      payload.admin = filterBy.adminUser;
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchDepartments(page) {
      this.nonce = Date.now().toString();
      this.departmentsByBillability = {};
      const { pageSize } = this;
      this.isTableLoading = true;
      const payload = this.getDepartmentsPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/departments/department_billability?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce } = response.data;
          if (nonce !== this.nonce) return;
          let allDepartmentUsers = [];
          response.data.departments.forEach((department) => {
            let allUsers = {};
            department.billabilities.forEach((billability) => {
              var tempArray = JSON.parse(JSON.stringify(billability.users));
              // for unique users
              tempArray.forEach((user) => {
                if (allUsers[user.id]) {
                  allUsers[user.id].projects = allUsers[
                    user.id
                  ].projects.concat(user.projects);
                } else {
                  allUsers[user.id] = user;
                }
              });
              if (!department['billabilityMap']) {
                department['billabilityMap'] = {};
              }
              // to store total users by project
              department['billabilityMap'][billability.name] = billability;
              if (
                !this.departmentsByBillability.hasOwnProperty(billability.name)
              ) {
                this.departmentsByBillability[billability.name] = [];
              }
              this.departmentsByBillability[billability.name] =
                this.departmentsByBillability[billability.name].concat(
                  billability.users
                );
            });
            department.users = Object.values(allUsers);
            allDepartmentUsers = allDepartmentUsers.concat(department.users);
          });
          this.allDepartmentUsers = allDepartmentUsers;
          this.columns = response.data.billabilities;
          this.paginationMeta = response.data.meta;
          this.departments = response.data.departments;
          this.isLoading = false;
          this.isTableLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.isTableLoading = false;
          this.departments = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchDepartments', error);
        });
    },
    exportToCSV() {
      const payload = this.getDepartmentsPayload();
      axios
        .post(`/manage/departments/department_billability/export_csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Billability_Report_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchDepartmentsBillability', error);
        });
    },
    exportToExcel() {
      const payload = this.getDepartmentsPayload();
      axios
        .get(`/manage/departments/department_billabilities_excel`, {
          params: {
            location: payload.location,
            practice: payload.practice,
            admin: payload.admin,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Billability.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },

    toggleFilter() {
      this.showBillabilityFilter = !this.showBillabilityFilter;
    },
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchDepartments = _debounce(this.fetchDepartments, 400);
    this.isReadPermission = this.validatePermission(['pmo.billability.read']);
    this.isExportPermission = this.validatePermission([
      'pmo.billability.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.isTableLoading = true;
      this.fetchFilterOptions();
      this.fetchDepartments(1);
    }
  },
};
</script>

<style scoped>
tbody tr:last-child td {
  position: sticky;
  bottom: 0;
  background-color: rgba(156, 161, 176, 255);
  color: rgba(236, 232, 235, 255);
  /* border-top: 1px solid black; */
}
.selected-cell:hover {
  font-size: 16px;
  background-color: deepskyblue;
  color: white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
thead tr th {
  border-right: 1px solid white;
}
</style>
