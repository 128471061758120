export default {
  template: `
  <div class="h-full">
    <p
    @click="buttonClicked()"
    class="text-xs font-semibold text-blue-500 cursor-pointer flex justify-center items-center h-full"
    >
   <span class="material-icons md:text-xl text-sm mr-0 ">download</span>
   </p>
   </div>
    `,
  methods: {
    buttonClicked() {
      this.params.clicked(this.params.value);
    },
  },
};