<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Positions Closed
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <div class="">
            <table
              class="relative w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-scroll"
            >
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <tr
                  :key="rowIndex"
                  v-for="(rowData, rowIndex) in rows"
                  class="py-2"
                >
                  <td class="pl-2 w-10 text-sm">Position {{ rowIndex + 1 }}</td>
                  <td
                    :class="colData.width"
                    class="px-0 text-xs py-2 items-start"
                    v-for="colData in rowData"
                    :key="colData.name"
                  >
                    <TextInput
                      v-if="['date'].includes(colData.type)"
                      :value="payload[rowIndex][colData.name]"
                      :type="colData.type"
                      :name="colData.name"
                      :required="colData.required"
                      :isEdit="false"
                      :defaultValue="
                        (requirement_closes[rowIndex] &&
                          requirement_closes[rowIndex].closed_date) ||
                        colData.defaultValue
                      "
                      :aligned="'vertical'"
                      @onChange="(e) => onChange(e, rowIndex)"
                    />
                    <SelectInput
                      :value="payload[rowIndex][colData.name]"
                      v-if="colData.type === 'select'"
                      :name="colData.name"
                      :required="colData.required"
                      :options="closed_users"
                      :aligned="'vertical'"
                      :isEdit="false"
                      @onChange="
                        (option) => onSelect(option, colData.name, rowIndex)
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3">
            <button
              type="button"
              @click="save"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TextInput from '@/components/inputs/TextInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import moment from 'moment';

export default {
  name: 'PositionEditPopup',
  components: {
    TextInput,
    SelectInput,
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  props: [
    'closed_users',
    'position',
    'requirementId',
    'isEdit',
    'requirement_closes',
  ],
  data() {
    return {
      rows: [],
      defaultRow: [
        {
          name: 'name',
          label: '',
          type: 'select',
          required: false,
          width: 'w-80',
          options: [],
        },
        {
          name: 'closed_date',
          label: '',
          required: false,
          type: 'date',
          defaultValue: moment().format('yyyy-MM-DD'),
          width: 'w-80',
        },
      ],
      payload: [],
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onChange(e, rowIndex) {
      this.payload[rowIndex][e.target.name] = e.target.value;
    },
    onSelect(option, name, rowIndex) {
      this.payload[rowIndex][name] = option;
    },
    validateInputs(callback) {
      const errorTextClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600 px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm';
      const inputDivs = document.getElementsByClassName('text_error');
      const selectDivs = document.getElementsByClassName('select_error');

      if (inputDivs.length > 0 || selectDivs.length > 0) {
        let i;

        for (i = 0; i < inputDivs.length; i++) {
          inputDivs[i].className = errorTextClass;
        }

        let selectDiv;
        for (selectDiv in selectDivs) {
          if (selectDivs[parseInt(selectDiv)]) {
            selectDivs[parseInt(selectDiv)].getElementsByTagName(
              'div'
            )[0].style['border-color'] = 'red';
          }
        }
        callback(false);
      } else {
        callback(true);
      }
    },
    UpdateEditPosition(callback) {
      let { payload } = this;
      axios
        .post(
          `/recruitment/requirements/update_positions_closed?id=${this.requirementId}`,
          {
            payload,
          }
        )
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.message,
            alertType: 'success',
          });
          callback(true);
        })
        .catch((error) => {
          console.error('ERR while UpdateEditPositions', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },
    save() {
      this.validateInputs((isValid) => {
        if (isValid) {
          this.UpdateEditPosition((data) => {
            if (data) {
              this.$emit('success', data);
            }
          });
        } else {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Please fill all mandatory fields',
            alertType: 'error',
          });
        }
      });
    },
  },
  created() {
    for (let i = 0; i < this.position; i++) {
      this.rows.push(this.defaultRow);
      if (this.requirement_closes[i]) {
        const options = {
          label: this.requirement_closes[i].name,
          value: this.requirement_closes[i].user_id,
        };
        this.payload.push({
          name: options,
          closed_date: this.requirement_closes[i].closed_date,
        });
      } else {
        this.payload.push({
          name: '',
          closed_date: moment().format('yyyy-MM-DD'),
        });
      }
    }
  },
};
</script>
