<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4 sm:flex sm:flex-row sm:justify-between sm:items-center"
          >
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  <span v-if="modalData.departmentName"
                    >{{ modalData.departmentName }}:</span
                  >
                  <span
                    class="text-gray-400"
                    :class="modalData.departmentName ? 'ml-2' : 'text-gray-900'"
                    >{{ modalData.billabilityName }}</span
                  >
                </h3>
              </div>
            </div>
            <div
              class="flex w-20 items-center justify-center bg-grey-lighter"
              v-if="isExportPermission"
            >
              <label
                class="w-56 flex flex-col items-center px-2 py-1 bg-green-500 text-white rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-green-400 hover:text-white"
              >
                <download-csv
                  :data="generateCSV(modalData.users)"
                  :name="`${
                    modalData.departmentName
                      ? modalData.departmentName.replaceAll(' ', '_')
                      : ''
                  }${
                    modalData.departmentName && modalData.billabilityName
                      ? '-'
                      : ''
                  }${
                    modalData.billabilityName
                      ? modalData.billabilityName.replaceAll(' ', '-')
                      : ''
                  }.csv`"
                >
                  <div class="flex flex-row items-center justify-between">
                    <span class="material-icons m-auto text-xl"
                      >cloud_download</span
                    >
                    <span class="text-sm leading-normal ml-3">CSV</span>
                  </div>
                </download-csv>
              </label>
            </div>
            <div v-else></div>
          </div>
          <hr />
          <div
            v-if="modalData.users.length === 0"
            class="text-center text-gray-400 py-4"
          >
            No Employees Present!
          </div>
          <div v-else class="overflow-auto max-h-96">
            <table
              id="users_table"
              class="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-scroll"
            >
              <thead class="bg-white">
                <tr class="text-gray-600 text-left">
                  <th
                    class="font-semibold text-xs uppercase px-4 py-2"
                    :key="index"
                    v-for="(column, index) in columns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-sm text-gray-700">
                <tr
                  :key="index"
                  v-for="(user, index) in modalData.users"
                  class="py-2"
                >
                  <td class="px-2 py-2">
                    <div class="flex flex-row">
                      <UserNameAvatar
                        class="mr-2.5"
                        :firstName="user.name.split(' ')[0]"
                        :lastName="user.name.split(' ')[1]"
                      />
                      <div v-if="isReadUserPermission">
                        <router-link :to="`/manage/users/${user.id}`">
                          <p
                            class="text-xs font-semibold text-blue-500 cursor-pointer"
                          >
                            {{ user.name }}
                          </p>
                        </router-link>
                        <p class="text-gray-400 text-xs tracking-wide">
                          {{ user.email || '' }}
                        </p>
                      </div>
                      <div v-else>
                        <p class="text-xs font-semibold">
                          {{ user.name }}
                        </p>
                        <p class="text-gray-400 text-xs tracking-wide">
                          {{ user.email || '' }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="px-4" v-if="isProjectSelected">
                    {{ user.billability }}
                  </td>
                  <td class="px-4" v-if="isProjectSelected">
                    {{ user.utilization }}
                  </td>
                  <td class="px-4">
                    <span
                      :key="projectIndex"
                      v-for="(project, projectIndex) in user.projects"
                      class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-100 last:mr-0 mr-1"
                    >
                      <div v-if="isReadProjectPermission">
                        <router-link :to="`/manage/projects/${project.id}`">
                          <p
                            class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                          >
                            {{ project.name }}
                          </p>
                        </router-link>
                      </div>
                      <div v-else>
                        <p class="text-xs whitespace-nowrap">
                          {{ project.name }}
                        </p>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-4"
          >
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import UserNameAvatar from '@/components/manage/users/UserNameAvatar.vue';
import JsonCSV from 'vue-json-csv';
import { mapGetters } from 'vuex';

export default {
  name: 'BillabilityTableModal',
  components: {
    UserNameAvatar,
    downloadCsv: JsonCSV,
  },
  data() {
    return {
      columns: ['Employees', 'Billability', 'Utilization(%)', 'Projects'],
      isExportPermission: false,
    };
  },
  props: {
    modalData: {
      required: true,
    },
    isProjectSelected: {
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['isReadUserPermission', 'isReadProjectPermission']),
  },
  methods: {
    generateCSV(users) {
      return users.map((user) => {
        return {
          NAME: user.name,
          EMAIL: user.email,
          EMPLOYEEID: user.employee_id,
          PROJECTS:
            user.projects.length > 0
              ? user.projects.map((project) => project.name).toString()
              : '',
        };
      });
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.isExportPermission = this.validatePermission([
      'pmo.billability.export',
    ]);
    if (!this.isProjectSelected) {
      this.columns = ['Employees', 'Projects'];
    }
  },
};
</script>
