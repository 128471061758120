import { render, staticRenderFns } from "./ProjectHistoryModal.vue?vue&type=template&id=33896a6c&"
import script from "./ProjectHistoryModal.vue?vue&type=script&lang=js&"
export * from "./ProjectHistoryModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports