var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-200 w-96 flex flex-col"},[_c('div',{staticClass:"md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"},[_c('div',{staticClass:"text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4"},[_vm._v(" Practice Work Distribution ")]),(
        (_vm.selectedTab === 'consolidated' && _vm.isReadConsolidatedPermission) ||
        (_vm.selectedTab === 'detailed' && _vm.isReadDetailedPermission)
      )?_c('div',[_c('DateRangePickerInputQuarter',{ref:"pwdDateRangePicker",attrs:{"popupAlign":"center"},on:{"updateDateRange":_vm.setFilterByDateRange}})],1):_vm._e(),_c('div',{staticClass:"flex flex-row items-center"},[_c('div',{staticClass:"bg-white mr-0 text-sm"},[_c('nav',{staticClass:"flex flex-col sm:flex-row"},[(
              _vm.isReadConsolidatedPermission || _vm.isExportConsolidatedPermission
            )?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'consolidated'
                ? 'text-blue-500 border-blue-500 border-b-2'
                : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'consolidated'}}},[_vm._v(" Consolidated ")]):_vm._e(),(_vm.isReadDetailedPermission || _vm.isExportDetailedPermission)?_c('button',{staticClass:"py-4 px-6 block hover:text-blue-500 focus:outline-none font-medium uppercase",class:_vm.selectedTab === 'detailed'
                ? 'text-blue-500 border-blue-500 border-b-2'
                : 'text-gray-600',on:{"click":function($event){_vm.selectedTab = 'detailed'}}},[_vm._v(" Detailed ")]):_vm._e()])])])]),(_vm.selectedTab === 'consolidated')?_c('div',[(_vm.isReadConsolidatedPermission || _vm.isExportConsolidatedPermission)?_c('ConsolidatedTab',{staticClass:"w-full",attrs:{"showConsolidatedFilter":_vm.showConsolidatedFilter,"dateRange":_vm.dateRange},on:{"resetDate":_vm.resetDate,"toggleFilter":_vm.toggleFilter}}):_vm._e()],1):_c('div',[(_vm.isReadDetailedPermission || _vm.isExportDetailedPermission)?_c('DetailedTab',{staticClass:"w-full",attrs:{"showDetailedFilter":_vm.showDetailedFilter,"dateRange":_vm.dateRange},on:{"resetDate":_vm.resetDate,"toggleFilter":_vm.toggleFilter}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }