<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Pipeline Candidates List
        </div>

        <div v-if="isReadPermission" class="flex flex-row items-center">
          <label class="text-xs text-gray-600 hidden sm:block font-normal"
            >SUBMITTED DATE RANGE</label
          >
          <div class="hidden sm:block">
            <div class="flex md:ml-3 mr-4">
              <DateRangePickerInput
                @updateDateRange="setFilterByDateRange"
                :startDate="startDate"
                :endDate="endDate"
                :customRanges="dateRanges"
              />
            </div>
          </div>
          <span
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleOfferedCandidatesFilter"
            >filter_alt</span
          >
          <div></div>
        </div>
      </div>
      <CustomFilter
        v-if="showRequirementFilter"
        :filterBy="filterBy"
        :projectPractices="projectPractices"
        :locationGroup="locationGroup"
        :statusOptions="statusOptions"
        :requirement_status="requirement_status"
        @resetFilter="resetFilter"
        @setFilterByProjectPractices="setFilterByProjectPractices"
        @setFilterByLocationGroup="setFilterByLocationGroup"
        @setFilterByRecruitmentStatus="setFilterByRecruitmentStatus"
        @toggleFilter="toggleOfferedCandidatesFilter"
        @setFilterByRequirementStatus="setFilterByRequirementStatus"
      />
      <div class="container mx-auto">
        <div class="w-full flex flex-wrap">
          <div
            v-if="isReadPermission"
            class="w-full sm:w-full md:w-4/12 flex flex-col p-3 bg-grey"
          >
            <div
              class="w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 pb-4"
            >
              <div class="md:px-6 px-2">
                <div
                  class="flex items-center mt-4 text-gray-700 dark:text-gray-200"
                >
                  <span
                    class="md:text-md text-md font-semibold text-gray-800 dark:text-white float-left"
                  >
                    Pipeline Candidates
                  </span>
                </div>
                <hr class="my-2" />
                <div
                  :key="index"
                  v-for="(user, index) in candidatesCount"
                  class="flex items-center justify-between mt-4 text-gray-700 dark:text-gray-200"
                >
                  <h1
                    class="px-2 md:text-sm text-xs font-semibold text-gray-500"
                  >
                    {{ user.label }}
                  </h1>
                  <span class="md:text-sm text-xs text-gray-400">{{
                    user.value
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isReadPermission"
            class="sm:w-full md:w-4/12 flex flex-col p-3 bg-grey"
          >
            <OfferedCandidateChart
              v-if="!isLoading"
              :chartData="chartData"
              :totalProfiles="chartData.total || 0"
            />
          </div>
          <!-- <div class="sm:w-full md:w-4/12 flex flex-col p-3 bg-grey">
            <div class="w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 pb-4">
              <div class="md:pl-6 px-2">
                <div class="flex items-center mt-4 text-gray-700 dark:text-gray-200">
                  <span
                    class="md:text-md text-md font-semibold text-gray-800 dark:text-white float-left"
                  >
                    Who's Joining Next ?
                  </span>
                </div>
                <hr class="my-2" />
                <div class="overflow-auto h-80">
                  <div class="flex flex-col  pt-3">
                    <div class="flex flex-col" :key="index" v-for="(joinee, index) in joiningNextList">
                      <div class="flex flex-row items-center" >
                        <div class="bg-blue-700 w-14 h-14 flex justify-center items-center rounded-full uppercase font-bold text-white">
                          <img class="inline-block object-cover w-14 h-14 rounded-full" src="@/assets/avatars/default.jpg" alt="Profile image"/>
                        </div>
                        <div class="ml-3">
                          <p class="font-bold truncate">{{ joinee.first_name + joinee.last_name}}</p>
                          <p class="text-sm"> {{joinee.joining_date}}</p>
                          <p class="text-sm text-gray-700">{{joinee.location}}</p>
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- Top Bar END-->
      <div
        class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 mb-12"
      >
        <div class="flex flex-row py-4 px-4 items-center justify-between">
          <!-- Icons -->
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div
            v-if="isReadPermission"
            class="flex flex-row"
            style="width: 300px"
          >
            <div style="min-width: 250px">
              <ejs-multiselect
                style="font-size: 15px"
                :dataSource="columns"
                :showSelectAll="true"
                :defaultValue="selectedColumns"
                selectAllText="Select All"
                mode="CheckBox"
                placeholder="Select Requirement Columns"
                :fields="fields"
                @change="setColumns"
              ></ejs-multiselect>
            </div>
            <div class="hidden sm:block">
              <div class="lg:ml-6 flex items-center">
                <button
                  @click="showColumn"
                  class="flex-row bg-blue-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-blue-400 rounded text-white px-5 h-8 flex items-center text-sm"
                >
                  <!--<span class="material-icons m-auto mr-2">arrow_forward</span>-->
                  <span>SUBMIT</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Icons END -->
          <div v-if="isReadPermission" class="flex flex-row items-center">
            <!-- Search Input -->
            <div class="relative text-gray-600">
              <input
                v-model="query"
                @input="setQuery"
                type="search"
                name="serch"
                placeholder="Search"
                class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
              />
              <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
                <span class="material-icons md:text-2xl text-sm text-gray-400"
                  >search</span
                >
              </button>
            </div>
            <!-- Search END -->
          </div>
        </div>
        <Loading v-if="isLoading" style="max-height: 68vh" />
        <!-- Table -->
        <div v-else class="flex flex-col" style="max-height: 68vh">
          <div
            class="flex-grow overflow-auto"
            v-if="isReadPermission && candidates && candidates.length > 0"
          >
            <table
              id="profile_table"
              class="relative w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-scroll"
            >
              <thead class="bg-gray-200 text-gray-600">
                <tr class="text-gray-600 text-left">
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    Req.Id
                  </th>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  >
                    Job Role
                  </th>
                  <th
                    class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                    :key="index"
                    v-for="(column, index) in selectedColumns"
                  >
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
                <template v-for="(profile, index) in candidates">
                  <tr
                    :key="index"
                    class="py-2"
                    v-bind:class="fixbackground(profile.status)"
                  >
                    <td class="px-4 py-4">
                      <router-link
                        v-if="isReadReqPermission"
                        :to="`/recruitment/list/${profile.requirement_id}`"
                      >
                        <p
                          class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                        >
                          WS-{{ profile.requirement_id }}
                        </p>
                      </router-link>
                      <span v-else class="text-xs whitespace-nowrap"
                        >WS-{{ profile.requirement_id }}</span
                      >
                    </td>
                    <td class="px-4 py-4">
                      <p class="">{{ profile.job_role }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('First Name')"
                      class="px-4 py-4"
                    >
                      <div>
                        <p
                          @click="openEditProfileModal(profile.id, profile)"
                          class="text-xs font-semibold text-blue-500 cursor-pointer"
                        >
                          {{ profile.first_name ? profile.first_name : ' ' }}
                        </p>
                      </div>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Last Name')"
                      class="px-4 py-4"
                    >
                      <p class="">
                        {{ profile.last_name ? profile.last_name : ' ' }}
                      </p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Phone')"
                      class="px-4 py-4"
                    >
                      <p class="">{{ profile.phone }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Profile Status')"
                      class="px-4 py-4"
                    >
                      <p class="">{{ profile.status }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Practice')"
                      class="px-4 py-4"
                    >
                      <p class="">{{ profile.practice }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Current Company')"
                      class="px-4 py-4"
                    >
                    <span class="" v-if="profile.currnet_organization" 
                    :content="profile.currnet_organization"
                    v-tippy>
                      <p class="ellipsis">{{ profile.currnet_organization }}</p>
                    </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Total Exp')"
                      class="px-4 py-4"
                    >
                    <span class="" v-if="profile.experience" 
                    :content="profile.experience"
                    v-tippy>
                      <p class="ellipsis">
                        {{ profile.experience ? profile.experience : ' ' }}
                      </p>
                    </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Relevant Exp')"
                      class="px-4 py-4"
                    >
                    <span class="" v-if="profile.relevant_experience"
                    :content="profile.relevant_experience"
                    v-tippy>
                      <p class="ellipsis">
                        {{
                          profile.relevant_experience
                            ? profile.relevant_experience
                            : ''
                        }}
                      </p>
                    </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Joining Date')"
                      class="px-4"
                    >
                      <p class="">{{ profile.joining_date }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Location')"
                      class="px-4 py-4"
                    >
                      <p class="">{{ profile.hiring_location }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Current CTC')"
                      class="px-4 py-4"
                    >
                    <span class="" v-if="profile.current_ctc"
                      :content="profile.current_ctc"
                      v-tippy>
                      <p class="ellipsis">{{ profile.current_ctc }}</p>
                    </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Expected CTC')"
                      class="px-4 py-4"
                    >
                    <span class="" v-if="profile.expected_rate"
                      :content="profile.expected_rate"
                      v-tippy>
                      <p class="ellipsis">{{ profile.expected_rate }}</p>
                    </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Available From')"
                      class="px-4"
                    >
                      <p class="">{{ profile.available_from }}</p>
                    </td>
                    
                    <td v-if="selectedColumns.includes('Notes')" class="px-4">
                      <span class="" v-if="profile.comments"
                      :content="profile.comments"
                      v-tippy>
                      <p class="ellipsis">{{ profile.comments }}</p>
                      </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Phone Number')"
                      class="px-4"
                    >
                      <p class="">{{ profile.phone }}</p>
                    </td>
                    <td v-if="selectedColumns.includes('Email')" class="px-4">
                      <span class="" v-if="profile.email"
                      :content="profile.email"
                      v-tippy>
                      <p class="ellipsis">{{ profile.email }}</p>
                      </span>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Submitted Date')"
                      class="px-4"
                    >
                      <p class="">{{ profile.submitted_on }}</p>
                    </td>
                    <td
                      v-if="selectedColumns.includes('Age of Submission')"
                      class="px-4"
                    >
                      <p class="">{{ profile.age_of_submission }}</p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <NotAuthorized class="h-screen" v-else-if="!isReadPermission" />
          <NoRecordFound v-else />
        </div>
        <!-- Table END -->

        <div
          v-if="isReadPermission"
          class="flex flex-row justify-between mt-3 mx-3"
        >
          <div class="flex flex-row items-center justify-center">
            <div class="text-xs italic text-gray-600">
              Candidate Submitted:
              <div
                id="test"
                class="codes"
                style="background-color: #f9e22b"
              ></div>
            </div>
            <div class="text-xs italic text-gray-600">
              Interview Scheduled:
              <div
                id="test"
                class="codes"
                style="background-color: #ffc107"
              ></div>
            </div>
            <div class="text-xs italic text-gray-600">
              Candidate Selected:
              <div
                id="test"
                class="codes"
                style="background-color: #aff2af"
              ></div>
            </div>
            <div class="text-xs italic text-gray-600">
              Candidate Rejected:
              <div
                id="test"
                class="codes"
                style="background-color: #f29c9b"
              ></div>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <Pagination
          class="mt-2"
          v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
          :meta="paginationMeta"
          :pageSize="pageSize"
          @clickCallback="fetchCandidates"
          @setPageSize="setPageSize"
        />
        <br />
        <br />
      </div>
    </div>
    <ViewProfileModal
      :requirementId="requirementId"
      :requirementName="job_role"
      v-if="isViewProfileModalVisible && isReadPermission"
      @close="closeViewProfileModal"
    />
    <EditProfileModal
      :requirementId="requirementId.toString()"
      :requirementName="requirementName"
      :isEdit="true"
      :profileId="selectedProfileId"
      :selectedProfileData="selectedProfileData"
      v-if="isEditProfileModalVisible && isManagePermission"
      @close="closeEditProfileModal"
    />
    <PositionEditPopup
      :requirementId="requirementId"
      :position="requirement.no_of_positions"
      :requirement_closes="requirement_closes"
      :closed_users="closed_users"
      :isEdit="true"
      :profileId="selectedProfileId"
      v-if="isPositionEditModalVisible && isManagePermission"
      @success="onSuccess"
      @close="closeEditPositionEditPopupModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import ViewProfileModal from '@/components/recruitment/requirementList/ViewProfileModal.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import EditProfileModal from '@/components/recruitment/requirementList/EditProfileModal.vue';
import PositionEditPopup from '@/components/recruitment/requirementList/PositionEditPopup.vue';
import OfferedCandidateChart from '@/components/recruitment/pipelineCandidates/PipelineCandidatePieChart.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'PipelineCandidatesList',
  components: {
    Pagination,
    Loading,
    DateRangePickerInput,
    ViewProfileModal,
    CustomFilter,
    NoRecordFound,
    ExportButtons,
    EditProfileModal,
    PositionEditPopup,
    OfferedCandidateChart,
    NotAuthorized,
  },
  data() {
    return {
      candidatesCountMeta: {
        total: 'Total Candidates',
        unique_req: 'Total Unique Reqs',
        'Candidate submitted': 'Total Candidates Submitted',
        'Interview scheduled': 'Total Interview Scheduled',
        'Candidate selected': 'Total Candidates Selected',
        'Candidate rejected': 'Total Candidates Rejected',
      },
      requirement_status: { label: 'Open', value: 0 },
      showRequirementFilter: false,
      isViewProfileModalVisible: false,
      isEditProfileModalVisible: false,
      isPositionEditModalVisible: false,
      projectPractices: [],
      locationGroup: { label: 'All', value: null },
      filterBy: {
        projectPractices: [],
        requirement_status: { label: 'Open', value: 0 },
        locationGroup: { label: 'All', value: null },
        recruitmentStatus: {
          multiple: false,
          // Preselected values for multi-select dropdown
          statusValue: [0, 1, 2],
        },
      },
      statusOptions: [
        { label: 'Candidate Submitted', value: 0 },
        { label: 'Interview Scheduled', value: 1 },
        { label: 'Candidate Selected', value: 2 },
        { label: 'Candidate Rejected', value: 3 },
      ],
      requirement_profiles: [],
      query: '',
      status_lists: [
        'Joined',
        'Offered and yet to join',
        'Candidate selected',
        'Interview scheduled',
        'Candidate submitted',
        'Declined offer or not joined',
        'On hold',
        'Candidate rejected',
      ],
      columns: [
        'First Name',
        'Last Name',
        'Profile Status',
        'Practice',
        'Current Company',
        'Total Exp',
        'Relevant Exp',
        'Joining Date',
        'Location',
        'Current CTC',
        'Expected CTC',
        'Available From',
        'Notes',
        'Phone Number',
        'Email',
        'Submitted Date',
        'Age of Submission',
      ],
      selectedColumns: [
        'First Name',
        'Last Name',
        'Profile Status',
        'Practice',
        'Current Company',
        'Total Exp',
        'Relevant Exp',
        'Joining Date',
        'Location',
        'Current CTC',
        'Expected CTC',
        'Available From',
        'Notes',
        'Phone Number',
        'Email',
        'Submitted Date',
        'Age of Submission',
      ],
      candidates: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      projectOptions: [],
      projectOptionMeta: {},
      requirementId: null,
      job_role: null,
      r_id: null,
      defaultFilterBy: {},
      requirementName: 'Hey',
      chartData: null,
      candidatesCount: [],
      joiningNextList: [],
      selection: [],
      startDate: new Date(moment().startOf('year').toDate()),
      endDate: new Date(moment().endOf('year').toDate()),
      fields: { text: 'label', value: 'value' },
      dateRanges: {
        'This year': [
          moment().startOf('year').toDate(),
          moment().endOf('year').toDate(),
        ],
        All: [
          moment('201901', 'YYYYMM').startOf('year').toDate(),
          moment().endOf('year').toDate(),
        ],
        'Previous year': [
          moment().subtract(1, 'year').startOf('year').toDate(),
          moment().subtract(1, 'year').endOf('year').toDate(),
        ],
        'Last 7 Days': [moment().subtract(7, 'd').toDate(), moment().toDate()],
        'Last 30 Days': [
          moment().subtract(29, 'd').toDate(),
          moment().toDate(),
        ],
        'Last month': [
          moment().subtract(1, 'months').startOf('month').toDate(),
          moment().subtract(1, 'months').endOf('month').toDate(),
        ],
      },
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      isReadReqPermission: false,
      nonce: '',
    };
  },
  methods: {
    openEditProfileModal(profileId, profile) {
      this.selectedProfileId = profileId;
      this.selectedProfileData = profile;
      this.requirementId = profile.requirement_id;
      this.isEditProfileModalVisible = true;
    },
    closeEditProfileModal() {
      this.fetchCandidates(1);
      this.isEditProfileModalVisible = false;
    },
    fixbackground(status) {
      switch (status) {
        case 'Joined':
          return 'joined';
        case 'Offered and yet to join':
          return 'offered_and_yet_to_join';
        case 'Candidate selected':
          return 'candidate_selected';
        case 'Candidate submitted':
          return 'candidate_submitted';
        case 'Interview scheduled':
          return 'interview_scheduled';
        case 'Candidate rejected':
          return 'candidate_rejected';
        case 'Declined offer or not joined':
          return 'declined_offer_or_Not_joined';
        case 'On Hold':
          return 'on_hold';
        default:
          return '';
      }
    },
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchCandidates(1);
    },
    toggleOfferedCandidatesFilter() {
      this.showRequirementFilter = !this.showRequirementFilter;
    },
    toggleViewProfileModal(id, r_id, job_role) {
      this.r_id = id;
      this.requirementId = id;
      this.job_role = job_role + '(' + r_id + ')';
      this.fetchProfile(id);
      this.isViewProfileModalVisible = !this.isViewProfileModalVisible;
    },
    closeViewProfileModal() {
      this.isViewProfileModalVisible = false;
    },
    fetchProfile(requirementId) {
      axios
        .get('/recruitment/requirements/' + requirementId)
        .then((response) => {
          this.requirement_profiles = response.data.requirement.profiles;
          // this.paginationMeta = response.data.meta;
          // this.requirements = response.data.requirements;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.requirement = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    createPDF() {
      const doc = new jsPDF('l', 'mm', [297, 500]);
      doc.text(`Requirements: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#profile_table',
      });
      doc.save(`Requirements_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setFilterByProjectPractices(option) {
      this.filterBy.projectPractices = option;
      this.fetchCandidates(1);
    },
    setFilterByRequirementStatus(option) {
      this.filterBy.requirement_status = option;
      this.fetchCandidates(1);
    },
    setFilterByLocationGroup(option) {
      this.filterBy.locationGroup = option;
      this.fetchCandidates(1);
    },
    setFilterByLocation(option) {
      this.filterBy.location = option;
      this.fetchCandidates(1);
    },
    setFilterByRecruitmentStatus(option) {
      this.filterBy.recruitmentStatus.statusValue = option;
      this.fetchCandidates(1);
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.startDate = startDate;
      this.endDate = endDate;
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchCandidates(1);
    },
    setColumns(option) {
      if (option.value.length > 0) {
        this.selection = option.value;
      } else {
        this.selection = this.columns;
      }
    },
    showColumn() {
      if (this.selection.length > 0) {
        this.selectedColumns = this.selection;
        this.selectedColumns.sort(
          (a, b) => this.columns.indexOf(a) - this.columns.indexOf(b)
        );
      }
    },
    loadMore(page) {
      this.fetchCandidates(page);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchCandidates(1, true, query);
      }
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchCandidates(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchCandidates(1);
    },
    fetchFilterOptions() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.projectPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
          // this.projectPractices.push({label: 'All', value: ''})
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProjectPractices', error);
        });
    },
    getRequirementPayload() {
      const { filterBy, query } = this;
      const payload = {};
      const statuses = filterBy.recruitmentStatus.statusValue;
      payload.from_date = moment(this.startDate).format('YYYY-MM-DD');
      payload.to_date = moment(this.endDate).format('YYYY-MM-DD');
      if (filterBy.projectPractices.length > 0) {
        payload.practice = filterBy.projectPractices;
      }
      if (statuses.length > 0) {
        payload.requirement_status = statuses;
      }
      if (filterBy.requirement_status) {
        payload.req_status = filterBy.requirement_status.value;
      }
      if (filterBy.locationGroup) {
        payload.location_group = filterBy.locationGroup.value;
      }
      if (location.length > 0 && !location.includes('ALL')) {
        payload.location = location;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchCandidates(page) {
      this.nonce = Date.now().toString();
      this.isLoading = true;
      const { pageSize } = this;
      const payload = this.getRequirementPayload();
      axios
        .get(
          `/recruitment/pipeline_candidates?page_size=${pageSize}&page=${page}`,
          {
            params: {
              status: payload.requirement_status,
              req_status: payload.req_status,
              practice: payload.practice,
              location: payload.location,
              location_group: payload.location_group,
              search: payload.search,
              from_date: payload.from_date,
              to_date: payload.to_date,
              nonce: this.nonce,
            },
          }
        )
        .then((response) => {
          this.candidatesCount = [];
          const {
            nonce,
            meta,
            pipeline_candidates,
            counts,
            joining_next_list,
          } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.candidates = pipeline_candidates;
          this.chartData = counts;
          this.joiningNextList = joining_next_list;
          for (var key of Object.keys(this.candidatesCountMeta)) {
            this.candidatesCount.push({
              label: this.candidatesCountMeta[key],
              value: this.chartData[key] || 0,
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.candidates = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchCandidates', error);
        });
    },
    exportToCSV() {
      const payload = this.getRequirementPayload();
      axios
        .get(`recruitment/pipeline_candidates/pipeline_candidates_csv.csv`, {
          params: {
            status: payload.requirement_status,
            practice: payload.practice,
            location_group: payload.location_group,
            search: payload.search,
            req_status: payload.req_status,
            location: payload.location,
            from_date: payload.from_date,
            to_date: payload.to_date,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Pipeline Candidates List${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while exportToCSV', error);
        });
    },
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  created() {
    this.fetchCandidates = _debounce(this.fetchCandidates, 400);
    this.isReadPermission = this.validatePermission([
      'recruitment.pipeline_candidates.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'recruitment.pipeline_candidates.manage',
    ]);
    this.isExportPermission = this.validatePermission([
      'recruitment.pipeline_candidates.export',
    ]);
    this.isReadReqPermission = this.validatePermission([
      'recruitment.requirements_list.read',
    ]);
    const clone = JSON.parse(JSON.stringify(this.filterBy));
    this.defaultFilterBy = clone;
    if (this.isReadPermission) {
      this.fetchFilterOptions();
      this.fetchCandidates(1);
    } else {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
.codes {
  width: 16px;
  height: 16px;
  border: 1px;
  float: right;
  margin-right: 10px;
  margin-left: 2px;
}
.bg-1 {
  background-color: #8dcc85;
}
.bg-2 {
  background-color: #e6ef59;
}
.bg-3 {
  background-color: #f56c6b;
}
.joined {
  background-color: #8dcc85;
}
.offered_and_yet_to_join {
  background-color: #e6ef59;
}
.candidate_selected {
  background-color: #aff2af;
}
.candidate_submitted {
  background-color: #f9e22b;
}
.interview_scheduled {
  background-color: #ffc107;
}
.candidate_rejected {
  background-color: #f29c9b;
}
.declined_offer_or_Not_joined {
  background-color: #f56c6b;
}
.on_hold {
  background-color: #e3dbe7;
}
thead tr th {
  border-right: 1px solid white;
}
thead tr th {
  text-align: center;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
.ellipsis{
  text-overflow:ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 8rem;
  position: relative;
  padding: 1rem;
  cursor: pointer;
}
</style>
