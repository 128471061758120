






















import Vue from 'vue';

export default Vue.extend({
  name: 'UserNameAvatar',
  props: ['firstName', 'lastName', 'image'],
  data() {
    return {
      baseImageUrl: '',
    };
  },
  methods: {
    getInitials(firstName: string, lastName: string) {
      const firstNameIntial =
        firstName && firstName[0] && firstName[0].toUpperCase();
      const lastNameInitial =
        lastName && lastName[0] && lastName[0].toUpperCase();
      return (firstNameIntial || '') + (lastNameInitial || '');
    },
  },
  created() {
    this.baseImageUrl = process.env.VUE_APP_BASE_IMAGE_URL;
  },
});
