<template>
  <div>
    <!-- <Loading v-if="isLoading" /> -->
    <div
      class="md:h-16 h-12 bg-white flex flex-row shadow-sm items-center justify-between text-left md:px-5 px-1 sticky top-0 z-10 mb-3"
    >
      <!-- <div class="font-bold">Timesheet : Log</div> -->
      <div>
        <div
          v-if="isReadPermission || isReadOthersPermission"
          class="flex flex-row items-center justify-end"
        >
          <button
            v-tippy
            content="Previous month"
            @click.once="goToPrev"
            :key="buttonKey"
            class="bg-gray-100 md:pt-1 text-white active:bg-pink-600 font-bold uppercase md:text-xs text-xxs md:px-2 px-1 py-0 rounded-full shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
          >
            <span
              class="material-icons m-auto text-black md:text-xl text-sm"
              @click="$emit('expandSidebar')"
              >navigate_before</span
            >
          </button>
          <button
            @click="goToToday"
            class="bg-blue-900 text-white md:mx-4 mx-1 active:bg-pink-600 font-bold uppercase md:text-xs text-xxs px-4 md:py-2 py-1 rounded-full shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
          >
            Today
          </button>
          <button
            v-tippy
            content="Next month"
            @click.once="goToNext"
            :key="buttonKeyNext"
            class="float-right bg-gray-100 md:pt-1 text-black active:bg-pink-600 font-bold uppercase md:text-xs text-xxs md:px-2 px-1 py-0 rounded-full shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
          >
            <span
              class="material-icons m-auto md:text-xl text-sm"
              @click="$emit('expandSidebar')"
              >navigate_next</span
            >
          </button>
          <span
            class="md:ml-4 ml-1 cursor-default font-semibold md:text-lg text-xs"
          >
            {{ firstDayOfMonth ? firstDayOfMonth.format('MMMM YYYY') : '' }}
          </span>
        </div>
      </div>
    </div>

    <!-- <div class="w-full h-full fixed block  bg-gray-700 opacity-75 z-50 items-center justify-center" v-if="isLoading">
        <Loading/>
      </div> -->

    <div
      class="flex md:flex-row md:min-h-screen flex-col-reverse items-start justify-between md:px-3"
      @click="closeMismatchModal"
    >
      <CreateLog
        :isManagePermission="
          (userId === selectedUser.value && isManagePermission) ||
          isManageOthersPermission
        "
        :maxDate="maxDate"
        :minDate="minDate"
        :newLog="newLog"
        :projects="projects"
        :project_users="project_users"
        :categories="categories"
        :isEdit="isEdit"
        :selectedUser="selectedUser"
        :userId="userId"
        :userEmpType="empType"
        v-if="
          showModal &&
          (isEdit
            ? true
            : (userId === selectedUser.value && isManagePermission) ||
              isManageOthersPermission)
        "
        @close="closeModal"
        @save="save"
        @deleteLog="deleteLog"
      />
      <LogMismatch
        id="logmismatch"
        v-if="mismatch && (isReadOthersPermission || isReadPermission)"
        @close="closeMismatchModal"
      />
      <calendar
        v-if="isReadPermission || isReadOthersPermission"
        class="w-full"
        style="height: 800px"
        ref="tuiCalendar"
        :calendars="projects"
        :schedules="workLogs"
        :view="view"
        :taskView="taskView"
        :scheduleView="scheduleView"
        :theme="theme"
        :week="week"
        :month="month"
        :timezones="timezones"
        :disableDblClick="disableDblClick"
        :isReadOnly="isReadOnly"
        :template="template"
        :useCreationPopup="useCreationPopup"
        :useDetailPopup="useDetailPopup"
        @beforeCreateSchedule="onBeforeCreateSchedule"
        @afterRenderSchedule="onAfterRenderSchedule"
        @beforeDeleteSchedule="onBeforeDeleteSchedule"
        @beforeUpdateSchedule="onBeforeUpdateSchedule"
        @clickDayname="onClickDayname"
        @clickSchedule="onClickSchedule"
        @clickTimezonesCollapseBtn="onClickTimezonesCollapseBtn"
      />
      <NotAuthorized
        v-else
        class="h-screen w-full justify-center items-center bg-white"
      />
      <!-- <button id="show-modal" @click="showModal = true">Show Modal</button> -->
      <div
        class="bg-white md:max-h-screen md:h-screen h-full md:mb-0 mb-3 flex-shrink-0 flex flex-col justify-start md:ml-3 px-4 py-4 text-sm md:w-64 w-full"
      >
        <div v-if="isReadOthersPermission || isManageOthersPermission">
          <div
            class="flex flex-row items-center justify-between text-gray-500 text-xs"
          >
            <span>Select User Status</span>
          </div>
          <ejs-dropdownlist
            :dataSource="statusData"
            :fields="fields"
            :allowFiltering="true"
            @select="(option) => filterUserByStatus(option.itemData)"
            :placeholder="'Select User Status'"
            :value="selectedStatus && selectedStatus.value"
          >
          </ejs-dropdownlist>
        </div>
        <!-- Validate field permissions -->
        <div
          class="mt-3"
          v-if="isReadOthersPermission || isManageOthersPermission"
        >
          <div
            class="flex flex-row items-center justify-between text-gray-500 text-xs"
          >
            <span>Select User</span>
          </div>
          <ejs-dropdownlist
            :dataSource="userReportees"
            :fields="fields"
            :allowFiltering="true"
            :itemTemplate="iTemplate"
            @select="(option) => setSelectedUser(option.itemData)"
            :value="selectedUser && selectedUser.value"
            :placeholder="selectedUser && selectedUser.label"
          >
          </ejs-dropdownlist>
          <div
            v-if="userDetail"
            class="mt-2 flex flex-col items-left rounded-lg border px-2 py-2 shadow-md bg-white-100"
          >
            <span class="text-gray-600">{{ userDetail.email }}</span>
            <span class="text-xs text-gray-400 w-3/4"
              >Employee Id : {{ userDetail.employee_id }}</span
            >
            <span class="flex flex-row text-gray-400 pt-1">
              <span class="text-xs w-3/4"
                >External Id : {{ userDetail.external_id }}</span
              >
              <span
                :class="
                  userDetail.status || userDetail.status == null
                    ? 'bg-green-200'
                    : 'bg-red-200'
                "
                class="h-4 text-green-800 font-semibold px-2 py-0 text-xs rounded-full items-right"
              >
                {{
                  userDetail.status || userDetail.status == null
                    ? 'Active'
                    : 'Inactive'
                }}
              </span>
            </span>
          </div>
        </div>

        <div class="mt-4" v-if="isReadOthersPermission || isReadPermission">
          <Chart
            v-if="showPieChart"
            :chartData="chartData"
            :options="chartOptions"
          />
        </div>
        <div v-if="isReadPermission || isReadOthersPermission">
          <div class="flex flex-row items-end justify-between mt-2">
            <span>Total Working Hours: </span>
            <span
              class="material-icons text-gray-600 text-base align-middle i_icon"
              v-tippy
              content="Total Working hours = Total working hours upto current day - Company Holiday"
            >
              info_outline</span
            >
            <span class="float-right">{{
              projectHours.total_avil || '0.0'
            }}</span>
          </div>
          <div class="flex flex-row items-center justify-between mt-2">
            <span>Total Project Hours:</span>
            <span>{{ projectHours.total_project || '0.0' }}</span>
          </div>
          <div class="flex flex-row items-center justify-between mt-2">
            <span>Default Project Hours:</span>
            <span>{{ projectHours.total_default || '0.0' }}</span>
          </div>
          <div class="flex flex-row items-center justify-between mt-2">
            <span>PTO Hours:</span>
            <span>{{ projectHours.total_pto || '0.0' }}</span>
          </div>
          <div class="flex flex-row items-center justify-between mt-2">
            <span>Company Holiday Hours: </span>
            <span>{{ projectHours.total_company_holiday || '0.0' }}</span>
          </div>
          <div class="flex flex-row items-center justify-between mt-2 mb-4">
            <span>Mismatch:</span>
            <span v-if="projectHours.mismatch">Yes</span>
            <span v-else>No</span>
          </div>
        </div>

        <div class="flex md:flex-col flex-row justify-between items-end">
          <UploadFileButton
            v-if="isUploadPermission"
            :label="'Bulk Upload'"
            @onParsed="onParsed"
            v-tippy
            content="Choose .csv file to bulk upload worklogs"
          />
          <label
            @click="downloadCsvTemplate"
            class="w-56 flex flex-col items-center px-2 mt-1 py-1 bg-green-500 text-white rounded-lg tracking-wide border border-blue cursor-pointer hover:bg-green-400 hover:text-white"
          >
            <div
              class="flex flex-row items-center justify-between"
              v-tippy
              content="Download bulk upload empty template"
            >
              <span class="material-icons m-auto text-xl">cloud_download</span>
              <span class="text-sm leading-normal ml-3"
                >Bulk Upload template</span
              >
            </div>
          </label>
          <button
            v-if="isExportPermission"
            @click="toggleUserFilter"
            class="rounded-lg mt-1 w-56 p-3 justify-center bg-green-500 duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-5 flex items-center text-sm"
            v-tippy
            content="Download weekly log report"
          >
            <span class="flex flex-row items-center justify-between">
              <span class="material-icons m-auto text-xl">cloud_download</span>
              <span class="text-sm leading-normal ml-3">Weekly Log Report</span>
            </span>
          </button>
          <weeklyLogReport
            v-if="showWeeklyLogReport && isExportPermission"
            :weeks="weeks"
            :projects="projects"
            @toggleUserFilter="toggleUserFilter"
            @generateReport="generateReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'tui-calendar/dist/tui-calendar.css';
import { Calendar } from '@toast-ui/vue-calendar';
import CreateLog from '@/components/popups/CreateLog.vue';
import WeeklyLogReport from '@/components/popups/WeeklyLogReport.vue';
import moment from 'moment';
import axios from 'axios';
import { mapGetters } from 'vuex';
import Chart from './MonthlyLogsPieChart.vue';
import UploadFileButton from '@/components/common/UploadFileButton.vue';
import LogMismatch from '@/components/popups/LogMismatch.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';
export default {
  name: 'Calendar',
  components: {
    calendar: Calendar,
    CreateLog,
    Chart,
    UploadFileButton,
    WeeklyLogReport,
    LogMismatch,
    NotAuthorized,
  },
  data() {
    return {
      iTemplate:
        '<div class="flex flex-col"><span class="h-4">${ label }</span><span class="text-sm italic text-gray-400" v-if="email">${email}</span></div>',
      fields: { text: 'label', value: 'value' },
      buttonKey: 12,
      buttonKeyNext: 0,
      isLoading: true,
      projectsMap: {},
      showPieChart: false,
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {},
      selectedUser: null,
      newLog: {},
      showModal: false,
      isEdit: false,
      projects: [],
      project_users: [],
      workLogs: [],
      categories: [],
      weeks: [],
      filterBy: {
        weeks: null,
        projects: null,
      },
      showWeeklyLogReport: false,
      recentLog: {},
      view: 'month',
      taskView: false,
      scheduleView: ['time'],
      defaultView: 'month',
      theme: {
        'common.dayname.color': '#333',
        'common.holiday.color': '#333',
        // month header 'dayname'
        'month.dayname.height': '42px',
        'month.dayname.borderLeft': 'none',
        'month.dayname.paddingLeft': '8px',
        'month.dayname.paddingRight': '0',
        'month.dayname.fontSize': '15px',
        'month.dayname.backgroundColor': 'inherit',
        'month.dayname.fontWeight': 'normal',
        'month.dayname.textAlign': 'left',
        // month day grid cell 'day'
        'month.weekend.backgroundColor': '#F5F5F5',
        'month.holidayExceptThisMonth.color': '#fff',
        'month.dayExceptThisMonth.color': '#bbb',
        'month.weekend.color': 'green',
        'month.day.fontSize': '16px',
        // month schedule style
        'month.schedule.height': '24px',
        'month.schedule.marginTop': '2px',
        'month.schedule.marginLeft': '6px',
        'month.schedule.marginRight': '6px',
        // month more view
        'month.moreView.boxShadow': 'none',
        'month.moreView.paddingBottom': '0',
        'month.moreView.border': '1px solid #9a935a',
        'month.moreView.backgroundColor': '#f9f3c6',
        'month.moreViewTitle.height': '28px',
        'month.moreViewTitle.marginBottom': '0',
        'month.moreViewTitle.backgroundColor': '#f4f4f4',
        'month.moreViewTitle.borderBottom': '1px solid #ddd',
        'month.moreViewTitle.padding': '0 10px',
        'month.moreViewList.padding': '10px',
      },
      week: {
        narrowWeekend: true,
        showTimezoneCollapseButton: true,
        timezonesCollapsed: false,
      },
      month: {
        visibleWeeksCount: 6,
        startDayOfWeek: 0,
        isAlways6Week: false,
        visibleScheduleCount: 3,
        moreLayerSize: {
          width: '100%',
          height: '500px',
        },
        workweek: false,
      },
      timezones: [
        {
          timezoneOffset: 530,
          displayLabel: 'GMT+05:00',
          tooltip: 'India',
        },
        {
          timezoneOffset: -420,
          displayLabel: 'GMT-08:00',
          tooltip: 'Los Angeles',
        },
      ],
      disableDblClick: true,
      isReadOnly: false,
      template: {},
      useCreationPopup: false,
      useDetailPopup: false,
      selectedLogId: '',
      firstDayOfMonth: '',
      projectHours: {},
      mismatch: false,
      isReadPermission: false,
      isManagePermission: false,
      isReadOthersPermission: false,
      isManageOthersPermission: false,
      isUploadPermission: false,
      isExportPermission: false,
      minDate: moment().subtract(10, 'day').format('YYYY-MM-DD'),
      maxDate: moment().add(30, 'day').format('YYYY-MM-DD'),
      nonce: '',
      userReportees: [],
      userDetail: null,
      empType: null,
      statusData: [
        { label: 'All', value: 'all' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' },
      ],
      selectedStatus: null,
    };
  },
  computed: {
    ...mapGetters('user', ['userId', 'userFullName', 'isProjectManager']),
  },
  methods: {
    toggleUserFilter() {
      this.showWeeklyLogReport = !this.showWeeklyLogReport;
    },
    downloadCsvTemplate() {
      const csvText =
        'Date (YYYY/MM/DD),Description,Email / External Id,Project Code,Category,Issue,Hours\n';
      const anchor = document.createElement('a');
      anchor.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText);
      anchor.target = '_blank';
      anchor.download = 'bulkUploadTemplate.csv';
      anchor.click();
    },
    closeMismatchModal() {
      this.projectHours = { ...this.projectHours };
      this.mismatch = false;
    },
    generateReport(data) {
      axios
        .get(`/timesheet/work_logs/download_weekly_log_report_excel`, {
          params: {
            user_id: this.selectedUser.value,
            week: data.week,
            project: data.id,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `WeeklyLogReport.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('Error while Fething Weekly Log Report', error);
        });
    },
    goToToday() {
      const firstDayOfCurrentMonth = moment().startOf('month');
      this.$refs.tuiCalendar.invoke('setDate', firstDayOfCurrentMonth._d);
      this.firstDayOfMonth = firstDayOfCurrentMonth;
      this.fetchLogs();
      this.getLogHours(this.firstDayOfMonth._d);
      //   this.$refs.tuiCalendar.invoke("scrollToNow");
    },
    goToPrev() {
      this.buttonKey++;
      const firstDayOfPrevMonth = moment(this.firstDayOfMonth)
        .subtract(1, 'M')
        .startOf('month');
      this.$refs.tuiCalendar.invoke('setDate', firstDayOfPrevMonth._d);
      this.firstDayOfMonth = firstDayOfPrevMonth;
      this.fetchLogs();
      this.getLogHours(this.firstDayOfMonth._d);
    },
    goToNext() {
      this.buttonKeyNext++;
      const firstDayOfNextMonth = moment(this.firstDayOfMonth)
        .add(1, 'M')
        .startOf('month');
      this.$refs.tuiCalendar.invoke('setDate', firstDayOfNextMonth._d);
      this.firstDayOfMonth = firstDayOfNextMonth;
      this.fetchLogs();
      this.getLogHours(this.firstDayOfMonth._d);
    },
    closeModal() {
      this.showModal = false;
      this.isEdit = false;
      this.resetCSSAfterFormClose();
    },
    save(body) {
      let { isEdit } = this;
      if (isEdit) {
        this.updateLog(body);
      } else {
        this.createLog(body);
      }
    },
    onBeforeCreateSchedule(e) {
      const logDate = moment(e.start._date).format('YYYY-MM-DD');
      let data = e;
      data['calendarId'] = this.recentLog ? this.recentLog.project_id : null;
      data['body'] = this.recentLog
        ? { issue_id: this.recentLog.issue_id }
        : null;
      const {
        minDate,
        maxDate,
        isManageOthersPermission,
        selectedUser,
        userId,
      } = this;

      if (!data.isAllDay) {
        data.end._date = data.start._date;
      }
      // If you have isManageOthersPermission you can manage logs of others without date restrictions
      if (isManageOthersPermission && userId !== selectedUser.value) {
        this.newLog = data;
        this.showModal = !this.showModal;
      } else {
        // You are not allowed to manage logs before minDate
        if (logDate < minDate) {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: `You can't create log before ${moment(minDate).format(
              'DD MMM YYYY'
            )}`,
            alertType: 'error',
          });
        } else if (logDate > maxDate) {
          // You are not allowed to manage logs after maxDate
          this.$store.dispatch('settings/showAlert', {
            alertMessage: `You can't create log after ${moment(maxDate).format(
              'DD MMM YYYY'
            )}`,
            alertType: 'error',
          });
        } else {
          this.newLog = data;
          this.showModal = !this.showModal;
        }
      }
      e.guide.clearGuideElement();
    },
    onAfterRenderSchedule(e) {
      // update tooltip for evey schedule .
      const log = { ...e.schedule } || {};
      const divElememt = document.querySelector(
        `[data-schedule-id="${log.id}"]`
      );
      if (divElememt) {
        const spanElement = divElememt.getElementsByClassName(
          'tui-full-calendar-weekday-schedule-title'
        );
        if (spanElement.length > 0) {
          // const worklog = this.workLogs.find((wlog) => wlog.id === log.id);
          // spanElement[0].setAttribute(
          //   "data-title",
          //   `${worklog.logCategory} - ${worklog.description}`
          // );
          // spanElement[0].setAttribute(
          //   "title",
          //   `${worklog.logCategory} - ${worklog.description}`
          // );
          const worklog = this.workLogs.find((wlog) => wlog.id === log.id);
          const isPreviousValuePresent =
            worklog.previousHours ||
            worklog.previousDescription ||
            worklog.previousLogCategory;
          // Generate message for original values
          const originalFirstLine = isPreviousValuePresent
            ? 'Original value: \n'
            : '';
          const originalSecondLine = `${
            isPreviousValuePresent ? '\t ' : ''
          }Hours: ${worklog.previousHours || worklog.hours}\n`;
          const originalThirdLine = `${
            isPreviousValuePresent ? '\t ' : ''
          }Category: ${worklog.previousLogCategory || worklog.logCategory}\n`;
          const originalFourthLine = `${
            isPreviousValuePresent ? '\t ' : ''
          }Description: ${
            worklog.previousDescription || worklog.description
          }\n`;
          // Generate message for modfied values
          const modifiedFirstLine = isPreviousValuePresent
            ? 'Modified value: \n'
            : '';
          const modifiedSecondLine = `${
            isPreviousValuePresent ? '\t ' : ''
          }Hours: ${worklog.hours}\n`;
          const modifiedThirdLine = `${
            isPreviousValuePresent ? '\t ' : ''
          }Category: ${worklog.logCategory}\n`;
          const modifiedFourthLine = `${
            isPreviousValuePresent ? '\t ' : ''
          }Description: ${worklog.description}\n`;
          const originalValue =
            originalFirstLine +
            originalSecondLine +
            originalThirdLine +
            originalFourthLine;
          const modifiedValue =
            modifiedFirstLine +
            modifiedSecondLine +
            modifiedThirdLine +
            modifiedFourthLine;
          const tooltipValue =
            originalValue + (isPreviousValuePresent ? modifiedValue : '');
          spanElement[0].setAttribute('data-title', tooltipValue);
          spanElement[0].setAttribute('title', tooltipValue);
        }
      }
    },
    onBeforeDeleteSchedule(_e) {
      // on delete
    },
    onBeforeUpdateSchedule(_data) {
      // TODO handle on schedule stretch and move
      // const { schedule, changes } = data;
      // if (changes) {
      //   const { start, end } = changes;
      //   const { id } = schedule;
      //   let { workLogs } = this;
      //   workLogs = workLogs.map((log) => {
      //     if (log.id === id) {
      //       if (start) {
      //         log.start = start;
      //         log.body.startDate = start;
      //       }
      //       if (end) {
      //         log.end = end;
      //         log.body.endDate = end;
      //       }
      //     }
      //     return log;
      //   });
      //   this.workLogs = workLogs;
      // } else {
      //   this.showModal = true;
      //   this.isEdit = true;
      //   this.newLog = schedule;
      //   this.selectedLogId = schedule.id;
      // }
    },
    onClickDayname(_e) {
      // implement your code
      //   console.log("dayname", e);
    },
    onClickSchedule(data) {
      if (typeof data.schedule.body === 'string') {
        data.schedule.body = JSON.parse(data.schedule.body);
      }
      // on clicking schedule
      this.showModal = true;
      this.isEdit = true;
      this.newLog = data.schedule;
      this.selectedLogId = data.schedule.id;
    },
    onClickTimezonesCollapseBtn(_e) {
      // implement your code
    },
    filterProjects(projects) {
      let projectsMap = {};
      const filteredProjects = [];
      projects.forEach((project) => {
        project['label'] = project.name;
        project['color'] = 'white';
        projectsMap[project.id] = {
          id: project.id,
          name: project.name,
          bgColor: project.bgColor,
          hours: 0,
          code: project.code,
        };
        filteredProjects.push(project);
      });
      this.projectsMap = projectsMap;
      return filteredProjects;
    },
    filterLogs(logs) {
      return logs.map((log) => {
        const temp_log = {
          id: log.id,
          calendarId: log.project_id,
          title: `${log.hours} ${log.project_name}`,
          category: 'allday',
          dueDateClass: '',
          start: log.date,
          end: log.date,
          description: log.description,
          logCategory: log.category,
          previousLogCategory: log.previous_category,
          previousDescription: log.previous_description,
          previousHours: log.previous_hours,
          logUpdated_by: log.updated_by,
          hours: log.hours,
          // bgColor: 'green',
          isAllDay: true,
          // color: 'black',
          // dragBgColor: "green",
          body: JSON.stringify({
            startDate: log.date,
            endDate: log.date,
            project: {
              id: log.project_id,
            },
            task: log.jira_id,
            hours: log.hours,
            description: log.description,
            issue_id: log.issue_id,
          }),
        };
        return temp_log;
      });
    },
    filterUsers(users) {
      const filteredUsers = [];
      users.forEach((user) => {
        filteredUsers.push({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
        });
      });
      return filteredUsers;
    },
    fetchProjects() {
      this.isLoading = true;
      const { selectedUser } = this;
      if (!selectedUser || !selectedUser.value) return;
      axios
        .get(`/manage/projects/user?user_id=${selectedUser.value}`)
        .then((response) => {
          const { projects, project_users, emp_type } = response.data;
          this.projects = this.filterProjects(projects);
          this.project_users = project_users;
          if (this.isReadOthersPermission || this.isReadPermission) {
            this.fetchLogs();
          }
          this.empType = emp_type;
          this.isLoading = false;
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetching projects', error);
          this.isLoading = false;
        });
    },
    fetchLogs() {
      const { selectedUser } = this;
      const startDate = moment(this.firstDayOfMonth)
        .subtract(7, 'd')
        .format('YYYY-MM-DD');
      const endDate = moment(startDate).add(50, 'd').format('YYYY-MM-DD');
      axios
        .get(`/timesheet/work_logs`, {
          params: {
            start_date: startDate,
            end_date: endDate,
            user_id: selectedUser.value,
          },
        })
        .then((response) => {
          this.workLogs = this.filterLogs(response.data);
          this.userDetail = this.userReportees.find(
            (item) => item.value === selectedUser.value
          );
          this.setProjectHours();
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchLogs', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },
    getLogHours(date) {
      this.nonce = Date.now().toString();
      axios
        .get(`/timesheet/work_logs/get_hours`, {
          params: {
            user_id: this.selectedUser.value,
            date: moment(date).format('YYYY-MM-DD'),
            nonce: this.nonce,
          },
        })
        .then((response) => {
          const { nonce } = response.data;
          if (nonce !== this.nonce) return;
          this.projectHours = response.data.log_detail;
          this.mismatch = this.projectHours.mismatch;
          this.recentLog = response.data.recent_log;
        })
        .catch((error) => {
          // handle error
          console.log('ERR while getLogHours', error);
        });
    },
    createLog(logDetails) {
      const {
        category,
        task,
        hours,
        startDate,
        endDate,
        project,
        description,
        excludeWeekEnds,
        excludeCompanyHoliday,
      } = logDetails;
      const payload = {
        date: startDate,
        hours,
        project_id: project,
        description,
        user_id: this.selectedUser.value,
        issue_id: category,
        jira_id: task,
        start_date: startDate,
        end_date: endDate,
        exclude_weekends: excludeWeekEnds,
        exclude_company_holidays: excludeCompanyHoliday,
        year: moment().year(),
      };
      if (moment(startDate).diff(endDate, 'days') > 0) {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Logs end date should not be behind start date',
          alertType: 'error',
        });
        return;
      }
      if (hours <= 0) {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Enter hours greater-than 0',
          alertType: 'error',
        });
      } else {
        axios
          .post(`/timesheet/work_logs`, payload)
          .then((response) => {
            if (Object.keys(response.data.success).length !== 0) {
              const filteredLog = this.filterLogs(response.data.success.result);
              this.workLogs = this.workLogs.concat(filteredLog);
              this.setProjectHours();
              this.getLogHours(this.firstDayOfMonth._d);
              const message = payload.exclude_weekends
                ? 'Work Logs created successfully for all days except Saturday and Sunday'
                : 'Worklog created successfully';
              this.$store.dispatch('settings/showAlert', {
                alertMessage: message,
                alertType: 'success',
                alertDuration: 10,
              });
            }
            setTimeout(() => {
              if (Object.keys(response.data.valid_error).length !== 0) {
                this.$store.dispatch('settings/showAlert', {
                  alertMessage:
                    response.data.valid_error.msg ||
                    'Something went wrong. Please try again',
                  alertType: 'error',
                  alertDuration: 10,
                });
              }
            }, 2000);
          })
          .catch((error) => {
            const { data } = error.response;
            // handle error
            this.$store.dispatch('settings/showAlert', {
              alertMessage:
                data.msg || 'Something went wrong. Please try again',
              alertType: 'error',
            });
            console.log('ERR while createLog', error);
          });
        this.showModal = false;
        this.isEdit = false;
        this.resetCSSAfterFormClose();
      }
    },
    updateLog(logDetails) {
      let { selectedLogId, workLogs } = this;
      const { category, task, hours, startDate, project, description } =
        logDetails;
      const payload = {
        date: startDate,
        hours,
        project_id: project,
        description,
        user_id: this.selectedUser.value,
        issue_id: category,
        jira_id: task,
      };
      if (hours <= 0) {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Enter hours greater-than 0',
          alertType: 'error',
        });
      } else {
        axios
          .put(`/timesheet/work_logs/${selectedLogId}`, payload)
          .then((response) => {
            workLogs = workLogs.filter((log) => log.id !== selectedLogId);
            let workLog = response.data.work_log;
            workLog['project_name'] = response.data.project_name;
            workLog['category'] = response.data.category;
            workLog['previous_category'] = response.data.previous_category;
            const filteredLog = this.filterLogs([workLog]);
            this.workLogs = workLogs.concat(filteredLog);
            this.setProjectHours();
            this.getLogHours(this.firstDayOfMonth._d);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Worklog updated successfully',
              alertType: 'success',
            });
          })
          .catch((error) => {
            const { data } = error.response;
            // handle error
            this.$store.dispatch('settings/showAlert', {
              alertMessage:
                data.msg || 'Something went wrong. Please try again',
              alertType: 'error',
            });
            console.log('ERR while updateLog', error);
          });
        this.showModal = false;
        this.isEdit = false;
        this.resetCSSAfterFormClose();
      }
    },
    deleteLog() {
      let { selectedLogId, workLogs } = this;
      axios
        .delete(`/timesheet/work_logs/${selectedLogId}`)
        .then((_response) => {
          workLogs = workLogs.filter((log) => log.id !== selectedLogId);
          this.workLogs = workLogs;
          this.setProjectHours();
          this.getLogHours(this.firstDayOfMonth._d);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Worklog removed successfully',
            alertType: 'success',
          });
        })
        .catch((error) => {
          // handle error
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          console.log('ERR while deleteLog', error);
        });
      this.showModal = false;
      this.isEdit = false;
      this.resetCSSAfterFormClose();
    },
    setSelectedUser(user) {
      this.selectedUser = user;
      this.userDetail = this.userReportees.find(
        (item) => item.value === this.selectedUser.value
      );
      this.fetchProjects();
      this.fetchLogs();
      this.getLogHours(this.firstDayOfMonth._d);
    },
    filterUserByStatus(option) {
      this.selectedStatus = option;
      this.selectedUser = null;
      this.getUserReportees();
    },
    resetProjectsMapHours(projectsMap) {
      Object.keys(projectsMap).forEach((projectId) => {
        projectsMap[projectId].hours = 0;
      });
      return projectsMap;
    },
    setProjectHours() {
      let { workLogs, firstDayOfMonth, projectsMap } = this;
      projectsMap = this.resetProjectsMapHours(projectsMap); // reset all project hours to zero
      // Find startDate and endDate which comes under current calendar view
      const startLogDate = moment(firstDayOfMonth);
      let endLogDate = moment(startLogDate).add(1, 'M');
      endLogDate = moment().isBefore(endLogDate) ? moment() : endLogDate;
      workLogs.forEach((log) => {
        log = JSON.parse(log.body);
        if (
          moment(log.startDate).isSameOrAfter(startLogDate) &&
          moment(log.endDate).isBefore(endLogDate)
        ) {
          if (log.project && log.project.id) {
            projectsMap[log.project.id].hours += parseFloat(log.hours);
          }
        }
      });
      const chartData = {
        hoverBackgroundColor: 'red',
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: 'Projects',
            backgroundColor: [],
            data: [],
          },
        ],
      };
      const projectHours = {
        totalAvailableHours: 0,
        totalProjectHours: 0,
        defaultProjectHours: 0,
        ptoHours: 0,
        companyHolidayHours: 0,
        mismatch: false,
      };
      // set all project hour details
      Object.keys(projectsMap).forEach((projectId) => {
        if (projectsMap[projectId].hours > 0) {
          if (projectsMap[projectId].code === 'WSHOLIDAY') {
            projectHours.companyHolidayHours = projectsMap[projectId].hours;
          } else if (projectsMap[projectId].code === 'D-1') {
            projectHours.defaultProjectHours = projectsMap[projectId].hours;
            projectHours.totalProjectHours += projectsMap[projectId].hours;
          } else if (projectsMap[projectId].code === 'WSPTO') {
            projectHours.ptoHours = projectsMap[projectId].hours;
            projectHours.totalProjectHours += projectsMap[projectId].hours;
          } else {
            projectHours.totalProjectHours += projectsMap[projectId].hours;
          }
          chartData.labels.push(projectsMap[projectId].name);
          chartData.datasets[0].backgroundColor.push(
            projectsMap[projectId].bgColor
          );
          chartData.datasets[0].data.push(projectsMap[projectId].hours);
        }
      });
      projectHours.totalAvailableHours =
        8.0 * this.workdayCount(startLogDate, endLogDate) -
        projectHours.companyHolidayHours;
      this.chartData = chartData;
      this.showPieChart = true;
    },
    workdayCount(start, end) {
      var first = start.clone().endOf('week'); // end of first week
      var last = end.clone().startOf('week'); // start of last week
      var days = (last.diff(first, 'days') * 5) / 7; // this will always multiply of 7
      var wfirst = first.day() - start.day(); // check first week
      if (start.day() == 0) --wfirst; // -1 if start with sunday
      var wlast = end.day() - last.day(); // check last week
      if (end.day() == 6) --wlast; // -1 if end with saturday
      return wfirst + Math.floor(days) + wlast; // get the total
    },
    createBulkWorkLogs(data) {
      axios
        .post('/timesheet/work_logs/bulk', { worklogs: data })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg || 'Successfully created',
            alertType: 'success',
          });
          this.fetchLogs();
        })
        .catch((error) => {
          const { data } = error.response;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
            alertDuration: 6,
          });
          console.log('ERR while BulkUpload', error);
        });
    },
    onParsed(data) {
      if (data && data.length > 0) {
        this.createBulkWorkLogs(data);
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Cannot upload empty csv file',
          alertType: 'error',
        });
      }
    },
    setWeekData() {
      if (!this.isExportPermission) {
        return;
      }
      axios
        .get(`/timesheet/work_logs/get_weeks`)
        .then((response) => {
          this.weeks = response.data.weeks;
        })
        .catch((error) => {
          // handle error
          console.log('ERR while setWeekData', error);
        });
    },
    getUserReportees() {
      this.userReportees = [];
      if (!this.isManageOthersPermission && !this.isReadOthersPermission) {
        this.setSelectedUser(this.selectedUser);
        return;
      }
      axios
        .get(
          `/manage/users/reportees?id=${this.userId}&status=${this.selectedStatus.value}`
        )
        .then((response) => {
          this.userReportees = this.sortArray(response.data, 'label');
          if (this.userReportees.length === 0) return;
          this.setSelectedUser(this.selectedUser || this.userReportees[0]);
        })
        .catch((error) => {
          const { data } = error.response;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
          });
          console.log('ERR while BulkUpload', error);
        });
    },
    resetCSSAfterFormClose() {
      const multidropdowns =
        document.getElementsByClassName('e-control-wrapper');
      multidropdowns.forEach((item) => {
        item.style['margin-bottom'] = '0px';
      });
    },
  },
  mounted() {
    if (this.$refs.tuiCalendar) {
      const todayDate = this.$refs.tuiCalendar.invoke('getDate')._date;
      const firstDayOfMonth = moment(todayDate).startOf('month');
      this.$refs.tuiCalendar.invoke('setDate', firstDayOfMonth._d);
      this.firstDayOfMonth = firstDayOfMonth;
      this.getLogHours(this.firstDayOfMonth._d);
    }
    this.getUserReportees();
    this.setWeekData();
  },
  props: ['userDetails'],
  created() {
    this.getLogHours = _debounce(this.getLogHours, 400);
    this.fetchLogs = _debounce(this.fetchLogs, 400);
    this.isReadPermission = this.validatePermission(['timesheet.log.read']);
    this.isManagePermission = this.validatePermission(['timesheet.log.manage']);
    this.isReadOthersPermission = this.validatePermission([
      'timesheet.log.read.others',
    ]);
    this.isManageOthersPermission = this.validatePermission([
      'timesheet.log.manage.others',
    ]);
    this.isUploadPermission = this.validatePermission(['timesheet.log.upload']);
    this.isExportPermission = this.validatePermission(['timesheet.log.export']);
    // set default selected user to be currentUser and selectedStatus to be Active
    this.selectedUser = { label: this.userFullName, value: this.userId };
    this.selectedStatus = { label: 'Active', value: 'true' };
  },
};
</script>
<style scoped>
.i_icon {
  padding-bottom: 2px;
  padding-right: 40px;
  cursor: pointer;
}
</style>
