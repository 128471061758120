var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full px-3 whitespace-nowrap",class:_vm.aligned === 'horizontal'
      ? 'flex-row justify-between py-3'
      : 'flex-col py-2'},[(_vm.label)?_c('label',{staticClass:"leading-loose text-gray-400 text-sm flex flex-row justify-between items-end",class:_vm.aligned === 'horizontal' ? 'w-1/3' : ''},[_c('div',[_vm._v(" "+_vm._s(_vm.label || '')),(_vm.required)?_c('span',{staticClass:"text-red-500 ml-1"},[_vm._v("*")]):_vm._e()]),(_vm.secondaryLabel)?_c('span',{staticClass:"text-right italic text-xs text-gray-300"},[_vm._v(_vm._s(_vm.secondaryLabel))]):_vm._e()]):_vm._e(),(_vm.isEdit)?_c('input',{staticClass:"px-4 border w-full rounded-sm focus:outline-none text-gray-600 text-sm",class:_vm.activeClass +
      ' ' +
      (_vm.type === 'date' ? 'pt-0.5' : '') +
      (_vm.type === 'color' ? 'py-0.5' : 'py-1'),attrs:{"disabled":_vm.inputDisabled,"name":_vm.name,"type":_vm.type || 'text',"max":['date', 'number'].includes(_vm.type) ? _vm.maxValue : '',"min":['date'].includes(_vm.type) ? _vm.minValue : '',"onkeydown":_vm.type === 'date' ? 'return false' : ''},domProps:{"value":_vm.value},on:{"input":_vm.onChange,"keydown":_vm.handleKeyPress}}):_c('input',{staticClass:"px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm",class:_vm.activeClass + ' ' + (_vm.type === 'date' ? 'pt-0.5' : ''),attrs:{"name":_vm.name,"type":_vm.type || 'text',"max":['date', 'number'].includes(_vm.type) ? _vm.maxValue : '',"min":_vm.type === 'date' ? _vm.minValue : '',"onkeydown":_vm.type === 'date' ? 'return false' : ''},domProps:{"value":_vm.type === 'color' || _vm.name === 'submitted_on' || _vm.name === 'closed_date'
        ? _vm.defaultValue
        : null},on:{"input":_vm.onChange}}),(!_vm.isValid)?_c('label',{staticClass:"text-xs text-red-600 font-thin"},[_vm._v("Please enter valid "+_vm._s(_vm.label || _vm.errorLabel))]):_vm._e(),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.focused),expression:"focused"}],staticClass:"text-xs text-blue-600 font-thin"},[_vm._v(_vm._s(_vm.focusSentence || ''))])])}
var staticRenderFns = []

export { render, staticRenderFns }