<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Create Interview:
                </h3>
              </div>
            </div>
          </div>
          <hr />
          <div class="flex flex-col mt-2 bg-white pt-2" style="height: 43vh">
            <Loading v-if="isLoading" class="h-full" />

            <div v-else class="flex-grow overflow-auto">
              <form-generator
                :triggerSubmit="trigger"
                :formFields="formFields"
                :numberOfColumns="3"
                :groups="groups"
                @onFormSubmit="onFormSubmit"
              />
            </div>
          </div>
          <div
            class="sm:flex sm:items-start sm:justify-between mt-3 bg-gray-50"
          >
            <div class="flex flex-row w-60 items-center mt-4"></div>
            <Loading v-if="isLoading" class="h-full" />
            <div v-else class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                @click.prevent="trigger++"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                <span class="text-sm"> Save </span>
              </button>
              <button
                type="button"
                @click="close"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue';
import Loading from '@/components/common/Loading.vue';
import axios from 'axios';
import moment from 'moment';
import FormGenerator from '@/components/common/FormGenerator.vue';
import _debounce from 'lodash/debounce';
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'AddInterviewModal',
  components: {
    Loading,
    FormGenerator,
  },
  computed: {},
  props: {
    requirementName: {
      type: String,
    },
    profileId: {
      type: Number,
    },
    selectedRequirement: {
      type: Object,
    },
    selectedProfileData: {
      type: Object,
    },
  },
  data() {
    return {
      trigger: 0,
      isLoading: true,
      isFormValid: false,
      groups: [{ start: 0, end: 18, name: '' }],
      inValidDate: false,
      formFields: [
        {
          name: 'requirement_id',
          label: 'Req ID',
          required: false,
          type: 'text',
          defaultValue: this.selectedRequirement.r_id,
          inputDisabled: true,
          options: [],
        },
        {
          name: 'profile_name',
          label: 'Profile Name',
          required: true,
          type: 'text',
          defaultValue:
            this.selectedProfileData.first_name +
            ' ' +
            this.selectedProfileData.last_name,
          inputDisabled: true,
        },
        {
          name: 'role',
          label: 'Role',
          type: 'text',
          defaultValue: this.selectedRequirement.job_role,
          inputDisabled: true,
        },
        {
          name: 'relevant_experience',
          label: 'Relevant Experience',
          required: true,
          type: 'text',
          defaultValue: this.selectedProfileData.relevant_experience,
          inputDisabled: true,
        },
        {
          name: 'email',
          label: 'Candidate Email',
          required: true,
          type: 'text',
          defaultValue: this.selectedProfileData.email,
          inputDisabled: true,
        },
        {
          name: 'phone_number',
          label: 'Candidate Phone Number',
          required: true,
          type: 'text',
          defaultValue: this.selectedProfileData.phone,
          inputDisabled: true,
        },
        {
          name: 'user',
          label: 'Interviewer',
          placeholder: 'Select Interviewer',
          required: true,
          type: 'select',
          multiple: true,
          defaultValue: '',
          options: [],
          email: true,
          validations: {
            required,
          },
        },
        {
          name: 'round_type',
          label: 'Interview Type',
          placeholder: 'Select Interview Type',
          required: true,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Technical', value: 'Technical' },
            { label: 'Managerial', value: 'Managerial' },
            { label: 'HR', value: 'HR' },
          ],
          validations: {
            required,
          },
        },
        {
          name: 'round_number',
          label: 'Interview Round',
          placeholder: 'Interview Round',
          required: false,
          type: 'text',
          defaultValue: '',
          inputDisabled: true,
        },
        {
          name: 'mode',
          label: 'Interview Mode',
          placeholder: 'Select Interview Mode',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Skype', value: 'Skype' },
            { label: 'Zoom', value: 'Zoom' },
            { label: 'Teams', value: 'Teams' },
            { label: 'Telephonic', value: 'Telephonic' },
          ],
        },
        {
          name: 'date',
          label: 'Interview Date',
          required: false,
          type: 'datetime-local',
          defaultValue: '',
          minValue: '2014-01-01',
          validations: {
            required,
          },
        },
        {
          name: 'duration',
          label: 'Interview Duration',
          placeholder: 'Select Interview Duration',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [
            { label: 'Half an hour', value: 'Half an hour' },
            { label: 'One Hour', value: 'One Hour' },
          ],
        },
      ],
      payload: {},
      modalHeadline: 'Leaving this page will discard unsaved changes.',
      isGoingBack: false,
    };
  },
  async created() {
    this.fetchRoundNumber();
    this.getAllUsers((data) => {
      this.formFields.forEach((formField) => {
        if (formField.name == 'user') {
          formField.options = data;
        }
      });
    });
    this.isLoading = false;
  },

  methods: {
    onFormSubmit(isValid, values) {
      if (isValid) {
        this.createNewInterview(this.createPayLoad(values));
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    getAllUsers(callback) {
      axios
        .get(`/manage/users/active_users_with_email/`)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          console.error('ERR while fetching users', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
          callback();
        });
    },

    fetchRoundNumber() {
      axios
        .get(`/recruitment/interviews/getRoundNumber/` + this.profileId)
        .then((response) => {
          this.isLoading = false;
          if (response.data.interview.length > 0) {
            this.formFields.forEach((formField) => {
              if (formField.name == 'round_number') {
                formField.defaultValue = response.data.interview[0] + 1;
              }
            });
          } else {
            this.formFields.forEach((formField) => {
              if (formField.name == 'round_number') {
                formField.defaultValue = 1;
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = true;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetch Interviews', error);
        });
    },

    createNewInterview(payload) {
      this.isLoading = true;
      axios
        .post('/recruitment/interviews', payload)
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.message,
            alertType: 'success',
          });
          this.isLoading = false;
          this.$emit('close');
        })
        .catch((error) => {
          console.error('ERR while create New Interview', error);
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong. Please try again',
            alertType: 'error',
          });
        });
    },

    createPayLoad(values) {
      this.payload.round_type = values.round_type;
      this.payload.round_number = values.round_number;
      this.payload.date = values.date;
      this.payload.interview_status = '';
      this.payload.mode = values.mode;
      this.payload.duration = values.duration;
      this.payload.feedback = '';
      this.payload.profile_id = this.profileId;
      this.payload.user = values.user;
      return this.payload;
    },

    onCancel() {
      this.$confirm(
        'Leaving this popup will discard unsaved changes. Are you sure?'
      ).then((isConfirmed) => {
        if (isConfirmed) {
          this.isGoingBack = true;
          this.$emit('close');
        } else {
          this.isGoingBack = false;
          return;
        }
      });
    },
    close() {
      this.$emit('close');
    },

    validateInputs(callback) {
      const errorTextClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600 px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm';
      const inputDivs = document.getElementsByClassName('text_error');
      const selectDivs = document.getElementsByClassName('select_error');
      if (inputDivs.length > 0 || selectDivs.length > 0) {
        let i;
        for (i = 0; i < inputDivs.length; i++) {
          inputDivs[i].className = errorTextClass;
        }

        let selectDiv;
        for (selectDiv in selectDivs) {
          if (selectDivs[parseInt(selectDiv)]) {
            selectDivs[parseInt(selectDiv)].getElementsByTagName(
              'div'
            )[0].style['border-color'] = 'red';
          }
        }
        callback(true);
      } else {
        callback(true);
      }
    },
  },
});
</script>
