<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ requirementName }}
                </h3>
              </div>
              <button
                type="button"
                @click="close"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
              <!-- Search END -->
            </div>
          </div>

          <div class="bg-white py-3 ml-3 mr-3">
            <div class="dark:bg-gray-800 dark:bg-gray-800 shadow rounded">
              <!-- Table -->
              <Loading v-if="isLoading" class="h-full" />
              <div v-else class="overflow-auto" style="max-height: 48vh">
                <div v-if="profile_interviews.length > 0">
                  <table
                    id="profile_table"
                    class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-auto"
                  >
                    <thead class="bg-gray-200">
                      <tr class="text-gray-600 text-left">
                        <th
                          class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                          v-for="column in columns"
                          :key="column"
                        >
                          {{ column }}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="divide-y divide-gray-200 text-xs text-gray-700"
                      v-if="profile_interviews.length > 0"
                    >
                      <template v-for="(interview, i) in profile_interviews">
                        <tr :key="interview.id + i" class="py-2">
                          <td>
                            <div
                              class="flex flex-row items-center justify-center bg-grey-lighter"
                            >
                              <label
                                class="flex flex-col items-center px-3 py-1 text-blue-500 rounded-sm tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white"
                              >
                                <div
                                  class="flex flex-row items-center justify-center"
                                >
                                  <span class="material-icons text-base"
                                    >edit</span
                                  >
                                  <span
                                    @click="
                                      openEditInterviewModal(
                                        interview.id,
                                        interview,
                                        selectedRequirement,
                                        selectedProfileData
                                      )
                                    "
                                    class="text-sm leading-normal ml-1 cursor-pointer"
                                  >
                                    EDIT
                                  </span>
                                </div>
                              </label>
                            </div>
                          </td>
                          <td class="px-4 py-4 text-center">
                            <div>
                              <p>
                                {{ interview.round_number }}
                              </p>
                            </div>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">
                              {{ selectedRequirement.r_id }}
                            </p>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">
                              {{
                                 selectedProfileData.first_name + ' ' + selectedProfileData.last_name,
                              }}
                            </p>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">
                              {{ selectedRequirement.job_role }}
                            </p>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">
                              {{ selectedProfileData.relevant_experience }}
                            </p>
                          </td>
                          <td class="px-4">
                            <div v-if="interview.interviewers.length > 0">
                              <template
                                v-for="(
                                  interviewer, j
                                ) in interview.interviewers"
                              >
                                <router-link
                                  :key="interview.id + j"
                                  :to="`/manage/users/${interviewer.id}`"
                                >
                                  <span
                                    class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                                  >
                                    {{ interviewer.first_name }}
                                    {{ interviewer.last_name }} </span
                                  ><br />
                                </router-link>
                              </template>
                            </div>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">{{ interview.round_type }}</p>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">{{ interview.mode }}</p>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">
                              {{ formatInterviewDate(interview.date) }}
                            </p>
                          </td>
                          <td class="px-4 text-center">
                            <p class="">{{ interview.duration }}</p>
                          </td>
                          <td class="px-4">
                            <span
                              v-if="selectedProfileData.resume"
                              style="
                                max-width: 150px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                              "
                              class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                              :content="selectedProfileData.resume"
                              v-tippy
                            >
                              <p class="text-xs whitespace-nowrap">
                                <a
                                  :href="selectedProfileData.resume"
                                  target="_blank"
                                >
                                  {{ wrapMethod(selectedProfileData.resume) }}
                                </a>
                              </p>
                            </span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div
                  v-else
                  class="dark:bg-gray-800 dark:bg-gray-800 shadow rounded text-center"
                >
                  <span>No interviews scheduled yet for this profile</span>
                </div>
                <!-- Table END -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditInterviewModal
        :interview="interview"
        :isEdit="true"
        :selectedProfileData="selectedProfileData"
        :interviewId="interviewId"
        :selectedRequirement="selectedRequirement"
        v-if="isEditInterviewModalVisible"
        @close="closeEditInterviewModal"
      />
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import EditInterviewModal from './EditInterviewModal.vue';
import Loading from '../../common/Loading.vue';
import moment from 'moment';
export default Vue.extend({
  name: 'ViewInterviewModal',
  data() {
    return {
      columns: [
        'Edit',
        'Interview Round',
        'Req Id',
        'Profile Name',
        'Role',
        'Relevant Exp',
        'Interviewers',
        'Interview Type',
        'Interview Mode',
        'Interview Date & Time',
        'Interview Duration',
        'Resume',
      ],
      isEditInterviewModalVisible: false,
      selectedProfileId: null,
      isLoading: true,
      profile_interviews: null,
      interview: {},
      interviewId: null,
    };
  },
  props: {
    requirementName: {
      type: String,
    },
    profileId: {
      type: Number,
    },
    selectedRequirement: {
      type: Object,
    },
    selectedProfileData: {
      type: Object,
    },
  },
  components: {
    EditInterviewModal,
    Loading,
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  methods: {
    wrapMethod(sentence) {
      return sentence.length > 20
        ? sentence.substring(0, 20) + '...'
        : sentence;
    },

    formatInterviewDate(date) {
      return moment.utc(date).format("YYYY-MM-DD, HH:mm")  
    },

    close() {
      this.$emit('close');
    },
    openEditInterviewModal(
      interviewId,
      interview,
      requirement,
      profile
    ) {
      this.interview = interview;
      this.selectedRequirement = requirement;
      this.selectedProfileData = profile;
      this.interviewId = interviewId;
      this.isEditInterviewModalVisible = true;
    },
    closeEditInterviewModal() {
      this.isLoading = true;
      this.fetchInterviews();
      this.isEditInterviewModalVisible = false;
    },
    fetchInterviews() {
      axios
        .get(`/recruitment/interviews/profile/` + this.profileId)
        .then((response) => {
          this.isLoading = false;
          this.profile_interviews = response.data.interview;
        })
        .catch((error) => {
          this.isLoading = true;
          this.profile_interviews = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetch Interviews', error);
        });
    },
  },
  created() {
    this.fetchInterviews();
  },
});
</script>
