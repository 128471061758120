const state = () => ({
  openRightSideBar: false,
  alertShow: false,
  alertMessage: '',
  alertType: 'success',
  selectedNav: '',
  selectedSubNav: '',
  hoverNav: '',
  isSideBarExpanded: JSON.parse(
    localStorage.getItem('sidebar_state') || 'false'
  ),
  requirementColumns: JSON.parse(
    localStorage.getItem('requirement_columns') || '[]'
  ),
  profileColumns: JSON.parse(localStorage.getItem('profile_columns') || '[]'),
});

// getters
const getters = {};

// actions
const actions = {
  showAlert(
    { commit, state }: any,
    { alertMessage, alertType, alertDuration }: any
  ) {
    commit('showAlertMsg', { alertMessage, alertType });
    setTimeout(() => {
      if (state.alertShow) {
        commit('closeAlert');
      }
    }, 1000 * (alertDuration || 5));
  },
};

// mutations
const mutations = {
  toggleRightSideBar(state: any) {
    state.openRightSideBar = !state.openRightSideBar;
  },
  closeAlert(state: any) {
    state.alertShow = false;
  },
  showAlertMsg(state: any, { alertMessage, alertType }: any) {
    state.alertShow = true;
    state.alertMessage = alertMessage;
    state.alertType = alertType;
  },
  updateNav(state: any, { selectedNav, selectedSubNav }: any) {
    state.selectedNav = selectedNav;
    state.selectedSubNav = selectedSubNav;
    state.isSideBarExpanded = false;
  },
  updateNavName(state: any, { hoverNav }: any) {
    state.hoverNav = hoverNav;
  },
  updateSidebarExpansion(state: any) {
    state.isSideBarExpanded = !state.isSideBarExpanded;
    localStorage.setItem('sidebar_state', state.isSideBarExpanded);
  },
  setRequirementColumnsData(state: any, { requirementColumns }: any) {
    state.requirementColumns = requirementColumns;
    localStorage.setItem(
      'requirement_columns',
      JSON.stringify(state.requirementColumns)
    );
  },
  setProfileColumnsData(state: any, { profileColumns }: any) {
    state.profileColumns = profileColumns;
    localStorage.setItem(
      'profile_columns',
      JSON.stringify(state.profileColumns)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
