<template>
  <div class="w-full bg-white rounded-lg shadow-md dark:bg-gray-800 pb-4">
    <div class="px-6">
      <div class="flex items-center mt-4 text-gray-700 dark:text-gray-200 pt-3">
        <h1
          class="text-md font-semibold text-gray-800 dark:text-white float-left"
        >
          Source Stats
        </h1>
      </div>
      <hr class="my-2" />
      <div class="flex flex-row items-center justify-around py-2">
        <div :key="source.id" v-for="source in sources">
          <div class="flex flex-col">
            <vep
              :progress="source.percent"
              color="#7579ff"
              empty-color="#324c7e"
              :emptyColorFill="emptyColorFill"
              thickness="5%"
              emptyThickness="2%"
              :size="80"
              dash="strict 60 0.8"
              lineMode="in-over"
              :legend="false"
              legendClass="legend-custom-style"
              fontSize="1.5rem"
              font-color="white"
              animation="loop 1000 100"
            >
              <span slot="legend-caption"> {{ source.percent }}% </span>
            </vep>
            <div class="flex flex-row items-center justify-center">
              <span class="font-thin text-gray-500">{{ source.name }}</span>
              <span class="material-icons text-gray-500 ml-1 text-base"
                >double_arrow</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  name: 'SourceStats',
  data() {
    return {
      isLoading: true,
      sources: [],
      emptyColorFill: {
        radial: true,
        colors: [
          {
            color: '#3260FC',
            offset: '50',
            opacity: '0.2',
          },
          {
            color: '#3260FC',
            offset: '50',
            opacity: '0.15',
          },
          {
            color: '#3260FC',
            offset: '70',
            opacity: '0.15',
          },
          {
            color: '#3260FC',
            offset: '70',
            opacity: '0.1',
          },
          {
            color: '#3260FC',
            offset: '90',
            opacity: '0.1',
          },
          {
            color: 'transparent',
            offset: '90',
            opacity: '0.1',
          },
          {
            color: 'transparent',
            offset: '95',
            opacity: '0.1',
          },
          {
            color: 'transparent',
            offset: '95',
            opacity: '0.1',
          },
        ],
      },
    };
  },
  methods: {
    fetchSources() {
      axios
        .get(`/manage/users/sources`)
        .then((response) => {
          this.sources = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.fetchSources();
  },
});
</script>
