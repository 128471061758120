




































































import Vue from 'vue';
export default Vue.extend({
  name: 'TextInput',
  data() {
    return {
      inputDisabled: false,
      isValid: true,
      activeClass: 'focus:ring-gray-500 focus:border-gray-900 border-gray-300',
      focused: false,
    };
  },
  props: [
    'label',
    'required',
    'validations',
    'name',
    'type',
    'value',
    'isEdit',
    'aligned',
    'disabled',
    'maxValue',
    'minValue',
    'secondaryLabel',
    'defaultValue',
    'errorLabel',
    'focusSentence',
  ],
  watch: {
    disabled: function (newVal, _oldVal) {
      this.inputDisabled = newVal;
    },
    required: function (newVal, _oldVal) {
      if (newVal) {
        this.activeClass =
          'focus:ring-red-500 focus:border-red-600 text_error border-red-600';
      } else {
        this.activeClass = '';
      }
    },
  },
  methods: {
    validate(data: any) {
      let isValid = true;
      if (data && this.validations) {
        if (
          this.validations.minLength &&
          data.length < this.validations.minLength
        ) {
          isValid = false;
        }
        if (
          this.validations.maxLength &&
          data.length > this.validations.maxLength
        ) {
          isValid = false;
        }
        if (this.validations.regex && !this.validations.regex.test(data)) {
          isValid = false;
        }
      }
      if (this.required && !data) {
        isValid = false;
      }
      this.isValid = isValid;
      return isValid;
    },
    handleKeyPress(e: any) {
      const { keyCode, shiftKey } = e;
      const excludeKeyCodes = [13, 16, 190, 110, 107, 109, 189];
      if (
        e.target.name === 'allocation' &&
        (excludeKeyCodes.includes(keyCode) || shiftKey)
      ) {
        e.preventDefault();
        return;
      }
    },
    onChange(e: any) {
      if (
        this.type === 'number' &&
        (e.target.value > this.maxValue || e.target.value < this.minValue)
      ) {
        e.preventDefault();
        e.target.value = null;
      }
      this.$emit('onChange', e);
      const focusedClass =
        'focus:ring-gray-500 focus:border-gray-900 border-gray-300';
      const errorClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600';
      this.activeClass = this.validate(e.target.value)
        ? focusedClass
        : errorClass;
    },
  },
  created() {
    if (this.disabled) {
      this.inputDisabled = this.disabled;
    }
    this.activeClass += this.required && !this.value ? ' text_error' : '';
  },
});
