<template lang="html">
  <div class="static">
    <div class="absolute top-4 md:right-4 right-1">
      <div
        class="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800"
      >
        <div class="flex items-center justify-center w-12 bg-blue-500">
          <svg
            class="w-6 h-6 text-white fill-current"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
            />
          </svg>
        </div>
        <div class="px-4 md:py-1 py-1 -mx-3">
          <div class="mx-3">
            <span
              class="font-semibold text-blue-500 dark:text-blue-400 md:text-sm text-sm"
              >Info</span
            >
            <p class="md:text-xs text-xs text-gray-600 dark:text-gray-200">
              {{ message }}
            </p>
          </div>
        </div>

        <div
          class="bg-gray-100 flex items-center justify-center cursor-pointer"
          @click="$emit('closeAlert')"
        >
          <div class="mx-2 mt-1">
            <span class="material-icons m-auto">close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isActive', 'message'],
};
</script>
