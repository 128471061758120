

















































import Vue from 'vue';
import moment from 'moment';
import EditOfficialInfoModal from './EditOfficialInfoModal.vue';
import axios from 'axios';

export default Vue.extend({
  name: 'OfficialInfo',
  data() {
    return {
      basicInfo: {
        first_name: 'First Name',
        last_name: 'Last Name',
        employee_id: 'Employee ID',
        designation: 'Designation',
        employment_type_name: 'Employment Type',
        joining_date: 'Joining Date',
        department: 'Department',
        practice: 'Practice',
        reporting_to: 'Reporting to',
        location: 'Work Location',
        mobile_number: 'Mobile Number',
        external_id: 'External Id',
        email: 'Email',
        user_experience: 'Experience',
      } as any,
      filteredUserDetails: {},
      isEditOfficialInfoModal: false,
      isManagePermission: false,
      isReadPermission: false,
    };
  },
  props: ['userDetails'],
  watch: {
    userDetails: function (_newVal: any, _oldVal: any) {
      this.setUserDetails();
    },
  },
  created() {
    this.isManagePermission = (this as any).validatePermission(['home.manage']);
    this.isReadPermission = (this as any).validatePermission(['home.read']);
  },
  mounted() {
    this.setUserDetails();
  },
  components: {
    EditOfficialInfoModal,
  },
  methods: {
    setUserDetails() {
      const userDetails =
        this.userDetails || this.$store.state.user.userDetails;
      const filteredUser: any = [];
      Object.keys(this.basicInfo).forEach((key) => {
        if (this.userDetails.hasOwnProperty(key)) {
          filteredUser.push({
            label: this.basicInfo[key],
            value:
              key === 'joining_date'
                ? moment(userDetails[key]).format('DD MMMM YYYY')
                : userDetails[key],
          });
        }
      });
      this.filteredUserDetails = filteredUser;
    },
    openEditOfficialInfoModal() {
      this.isEditOfficialInfoModal = true;
    },
    closeEditOfficialInfoModal() {
      this.isEditOfficialInfoModal = false;
    },
    updateUserInfo(params: any) {
      if (this.isManagePermission) {
        axios
          .patch(`/manage/users/update_user_info/`, params)
          .then((res) => {
            this.$emit('updateUserDetails', res.data.user_details);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Official Info Updated Successfully',
              alertType: 'success',
            });
            this.closeEditOfficialInfoModal();
          })
          .catch((error) => {
            let errMsg = 'Something went wrong. Please try again';
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errMsg = error.response.data.message[0];
            }
            this.$store.dispatch('settings/showAlert', {
              alertMessage: errMsg,
              alertType: 'error',
            });
          });
      }
    },
  },
});
