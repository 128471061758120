<template>
  <!-- Table -->
  <div class="flex flex-col" style="height: 26rem">
    <div class="flex-grow overflow-auto">
      <table
        id="holidays_table"
        class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
      >
        <thead>
          <tr>
            <th
              class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
              :key="index"
              v-for="(column, index) in columns"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
          <tr
            :key="holiday.id"
            v-for="(holiday, index) in holidays"
            class="py-2"
            :class="index % 2 === 1 ? 'bg-gray-100' : ''"
          >
            <td :id="holiday.id" class="py-2 text-xs text-center w-1/3">
              <p class>{{ globalDateFormatWithDay(holiday.date) }}</p>
            </td>
            <td class="text-center w-1/3">{{ holiday.location }}</td>
            <td class="text-center w-1/3">{{ holiday.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Table END -->
</template>
<script>
export default {
  name: 'HolidayTable',
  data() {
    return {
      columns: ['Date', 'Location', 'Description'],
    };
  },
  props: ['holidays'],
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
