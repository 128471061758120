<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        Submit For Approval
      </div>
    </div>
    <!-- Top Bar END-->
    <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
      <CustomFilter
        v-if="showUserFilter"
        :filterBy="filterBy"
        @resetFilter="resetFilter"
        @toggleFilter="toggleFilter"
        @setFilterByProjects="setFilterByProjects"
        :projectOptionMeta="projectOptionMeta"
        :projectOptions="projectOptions"
        @loadMore="loadMore"
      />
      <div class="flex py-4 px-4 justify-between items-center">
        <!-- Icons -->
        <div class="flex items-center"></div>
        <!-- Icons END -->
        <div class="flex flex-row items-center">
          <!-- Search Input -->
          <div
            v-if="isReadPermission"
            class="relative text-gray-600"
            style="margin-right: 30px"
          >
            <input
              v-model="query"
              @input="setQuery"
              type="search"
              name="serch"
              placeholder="Search"
              class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
            />
            <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
              <span class="material-icons md:text-2xl text-sm text-gray-400"
                >search</span
              >
            </button>
          </div>
          <!-- Search END -->
          <span
            v-if="isReadPermission"
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleFilter"
            >filter_alt</span
          >
        </div>
      </div>
      <Loading v-if="isLoading" style="height: 26rem" />
      <!-- Table -->

      <div v-else class="flex flex-col" style="height: 26rem">
        <div
          class="flex-grow overflow-auto"
          v-if="isReadPermission && reports.length > 0"
        >
          <table
            ref="table"
            id="consolidated"
            class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
          >
            <thead>
              <tr>
                <th
                  class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  :class="
                    ['Project Name'].includes(column)
                      ? 'text-left'
                      : 'text-center'
                  "
                  :key="index"
                  v-for="(column, index) in columns"
                >
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
              <tr
                :key="index"
                v-for="(report, index) in reports"
                class="py-2"
                :class="index % 2 === 1 ? 'bg-gray-100' : ''"
              >
                <td class="px-4 text-xs py-2">
                  <router-link
                    v-if="isReadProjectPermission"
                    :to="`/manage/projects/${report.project.id}`"
                  >
                    <p
                      class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                    >
                      {{ report.project.name }}
                    </p>
                  </router-link>
                  <span class="text-xs whitespace-nowrap" v-else>{{
                    report.project.name
                  }}</span>
                </td>
                <td class="px-4 whitespace-normal">
                  <span
                    :key="manegerIndex"
                    v-for="(manager, manegerIndex) in report.project_managers
                      .length > 3
                      ? report.project_managers.slice(0, 3)
                      : report.project_managers"
                    :content="getProjectManagers(report.project_managers)"
                    v-tippy
                    class="text-xs max-w-24 inline-block py-0 mb-1 px-2 rounded bg-gray-50 text-blue-500 last:mr-0 mr-1"
                  >
                    <router-link
                      v-if="isReadUserPermission"
                      :to="`/manage/users/${manager.id}`"
                    >
                      <p
                        class="text-xs text-blue-500 cursor-pointer whitespace-nowrap"
                      >
                        {{ manager.first_name + ' ' + manager.last_name }}
                      </p>
                    </router-link>
                    <span class="text-xs whitespace-nowrap" v-else>{{
                      manager.first_name + ' ' + manager.last_name
                    }}</span>
                  </span>
                  <span
                    v-if="report.project_managers.length > 3"
                    class="text-xs"
                    >...</span
                  >
                </td>
                <td class="px-4 text-xs">
                  <p class>{{ globalDateFormat(report.start_date) }}</p>
                </td>
                <td class="px-4 text-xs">
                  <p class>{{ globalDateFormat(report.end_date) }}</p>
                </td>
                <td class="px-4 text-xs">
                  <span
                    v-if="
                      report.fort_night_status != null &&
                      (report.fort_night_status.status == 'pending' ||
                        report.fort_night_status.status == 'approved' ||
                        report.fort_night_status.status == 'submitted again')
                    "
                  >
                    <button
                      :disabled="IsDisabled"
                      class="bg-green-300 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray rounded text-white px-2 h-5 flex items-center text-sm"
                      @click="() => openSubmitApprovalModal(report)"
                    >
                      Submit for Approval
                    </button>
                  </span>
                  <span v-else>
                    <button
                      class="bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-5 flex items-center text-sm"
                      @click="() => openSubmitApprovalModal(report)"
                    >
                      Submit for Approval
                    </button>
                  </span>
                </td>
                <td class="px-4 text-xs">
                  <p
                    v-if="
                      report.fort_night_status != null &&
                      (report.fort_night_status.status == 'pending' ||
                        report.fort_night_status.status == 'submitted again')
                    "
                    class
                  >
                    Submitted for Approval
                  </p>
                  <p
                    v-else-if="
                      report.fort_night_status != null &&
                      report.fort_night_status.status == 'rejected'
                    "
                  >
                    Rejected
                  </p>
                  <p
                    v-else-if="
                      report.fort_night_status != null &&
                      report.fort_night_status.status == 'approved'
                    "
                  >
                    Approved
                  </p>
                  <p v-else>Not Submitted</p>
                </td>
                <td class="px-4 text-xs">
                  <p v-if="report.fort_night_status != null">
                    {{ report.fort_night_status.comment }}
                  </p>
                  <p v-else></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <NotAuthorized v-else-if="!isReadPermission" />
        <NoRecordFound v-else />
      </div>
      <!-- Table END -->
    </div>

    <!-- Pagination -->
    <div v-if="isReadPermission">
      <Pagination
        class="mt-3"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchApprovalData"
        @setPageSize="setPageSize"
      />
    </div>
    <SubmitForApprovalModal
      v-if="isSubmitForApprovalModalVisible && isManagePermission"
      :isManagePermission="isManagePermission"
      :endDate="endDate"
      :startDate="startDate"
      :logs="logs"
      :projectId="projectId"
      :projectManager="projectManager"
      :projectName="projectName"
      :fortNightLogs="fortNightLogs"
      @success="onSuccess"
      @close="closeSubmitApprovalModal"
    ></SubmitForApprovalModal>
  </div>
  <!-- Pagination End-->
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import SubmitForApprovalModal from '@/components/timesheet/SubmitForApproval/SubmitForApprovalModal.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'SubmitForApproval',
  components: {
    Pagination,
    Loading,
    CustomFilter,
    SubmitForApprovalModal,
    NoRecordFound,
    NotAuthorized,
  },
  data() {
    return {
      IsDisabled: true,
      showUserFilter: false,
      projectOptions: [],
      isSubmitForApprovalModalVisible: false,
      projectOptionMeta: {},
      endDate: null,
      startDate: null,
      logs: [],
      projectId: null,
      projectManager: null,
      projectName: null,
      fortNightLogs: [],
      filterBy: {
        projects: [],
      },
      query: '',
      columns: [
        'Project Name',
        'Project Managers',
        'Start Date',
        'End Date',
        'Action',
        'Status',
        'Comment',
      ],
      reports: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      defaultFilterBy: {},
      isReadPermission: false,
      isManagePermission: false,
      nonce: '',
    };
  },
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
  },
  created() {
    this.fetchApprovalData = _debounce(this.fetchApprovalData, 400);

    this.isReadPermission = this.validatePermission([
      'timesheet.submit_for_approval.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'timesheet.submit_for_approval.manage',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchApprovalData(1);
      this.fetchProjectOptions(1);
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchApprovalData(1);
    },
    toggleFilter() {
      this.showUserFilter = !this.showUserFilter;
    },
    setFilterByProjects(option) {
      this.filterBy.projects = option;
      this.fetchApprovalData(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchApprovalData(1);
      }
    },
    loadMore(page) {
      this.fetchApprovalData(page);
    },
    searchQuery(query) {
      if (query.length !== 1) {
        this.fetchProjectOptions(1, true, query);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchApprovalData(1);
    },
    getUsersPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.projects.length > 0) {
        payload.projects = filterBy.projects;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchApprovalData(page) {
      this.isLoading = true;
      this.nonce = Date.now().toString();
      const { pageSize } = this;
      const payload = this.getUsersPayload();
      axios
        .get(
          `/timesheet/work_logs/submit_for_approval?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              project_id: payload.projects,
              search: payload.search,
              nonce: this.nonce,
            },
          }
        )
        .then((response) => {
          const { nonce, meta } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.reports = response.data.report;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },
    fetchProjectOptions(page, isSearch = false, query = null) {
      const { pageSize } = this;
      let payload = {};
      if (query) {
        payload.search = query;
      }
      axios
        .get(
          `/timesheet/work_logs/project_list?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`
        )
        .then((response) => {
          // this.projectOptionMeta = response.data.meta;
          const projectOptions = response.data.projects.map((project) => {
            return { label: project.name, value: project.id };
          });
          this.projectOptions = isSearch
            ? projectOptions
            : this.projectOptions.concat(projectOptions);
        })
        .catch((error) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjectOptions', error);
        });
    },
    openSubmitApprovalModal(report) {
      (this.endDate = report.end_date),
        (this.startDate = report.start_date),
        (this.logs = report.logs),
        (this.projectId = report.project_id),
        (this.projectManager = report.project_managers),
        (this.projectName = report.project.name),
        (this.fortNightLogs = report.fort_night_status),
        (this.isSubmitForApprovalModalVisible = true);
    },
    closeSubmitApprovalModal() {
      this.isSubmitForApprovalModalVisible = false;
    },
    onSuccess() {
      this.fetchApprovalData(this.paginationMeta.current_page); //reload
      this.closeSubmitApprovalModal();
    },
    getProjectManagers(managers) {
      const managerNames =
        (managers &&
          managers.map(
            (manager) => `${manager.first_name} ${manager.last_name}`
          )) ||
        [];
      return managerNames.join(', ');
    },
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
