<template>
  <div>
    <div class="Expander bg-white mt-3">
      <div
        class="Expander__trigger"
        @click="setSelectedAccrodion('activites')"
        :class="selectedAccordion === 'activites' ? 'active' : 'beforeBorder'"
      >
        <div class="flex flex-row items-center">
          <svg
            class="Expander__trigger-Icon align-middle"
            :class="{ open: selectedAccordion === 'activites' }"
            width="40"
            height="12"
            stroke="cornflowerblue"
          >
            <polyline
              points="12,2 20,10 28,2"
              stroke-width="3"
              fill="none"
            ></polyline>
          </svg>
          <span class="font-normal text-gray-700">Activities</span>
        </div>
      </div>
      <transition :name="animation">
        <div class="Expander__body" v-show="selectedAccordion === 'activites'">
          <slot class="bg-white p-0">
            <div
              class="flex flex-row items-center justify-evenly tab-content overflow-hidden mb-1 bg-white leading-normal min-h-full"
            >
              <p class="p-0">
                <span class="md:text-sm text-xs text-gray-400">Total Hours</span
                ><br /><span class="md:text-3xl text-xl text-gray-700">{{
                  totalProjectHours
                }}</span
                ><br /><span class="md:text-xs text-xxs text-red-400"
                  >Last Week Logged Hours-{{ lastWeekHours }}</span
                >
              </p>
              <p class="p-5">
                <span class="md:text-sm text-xs text-gray-400"
                  >Total Entries</span
                ><br /><span class="md:text-3xl text-xl text-gray-700">{{
                  totalProjectCount
                }}</span
                ><br /><span class="md:text-xs text-xxs text-red-400"
                  >Mismatch: {{ lastWeekMismmatch ? 'Yes' : 'No' }}</span
                >
              </p>
            </div>
          </slot>
        </div>
      </transition>
    </div>
    <div class="Expander bg-white">
      <div
        class="Expander__trigger"
        @click="setSelectedAccrodion('active-project')"
        :class="
          selectedAccordion === 'active-project' ? 'active' : 'beforeBorder'
        "
      >
        <div class="flex flex-row items-center">
          <svg
            class="Expander__trigger-Icon align-middle"
            :class="{ open: selectedAccordion === 'active-project' }"
            width="40"
            height="12"
            stroke="cornflowerblue"
          >
            <polyline
              points="12,2 20,10 28,2"
              stroke-width="3"
              fill="none"
            ></polyline>
          </svg>
          <span class="font-normal text-gray-700">Active projects</span>
        </div>
      </div>
      <transition :name="animation">
        <div
          class="Expander__body"
          v-show="selectedAccordion === 'active-project'"
        >
          <slot class="bg-white p-0">
            <ProjectsTable :columns="columns" :tableData="activeProjects" />
          </slot>
        </div>
      </transition>
    </div>
    <div class="Expander bg-white">
      <div
        class="Expander__trigger"
        @click="setSelectedAccrodion('default-project')"
        :class="
          selectedAccordion === 'default-project' ? 'active' : 'beforeBorder'
        "
      >
        <div class="flex flex-row items-center">
          <svg
            class="Expander__trigger-Icon align-middle"
            :class="{ open: selectedAccordion === 'default-project' }"
            width="40"
            height="12"
            stroke="cornflowerblue"
          >
            <polyline
              points="12,2 20,10 28,2"
              stroke-width="3"
              fill="none"
            ></polyline>
          </svg>
          <span class="font-normal text-gray-700">Default projects</span>
        </div>
      </div>
      <transition :name="animation">
        <div
          class="Expander__body"
          v-show="selectedAccordion === 'default-project'"
        >
          <slot class="bg-white p-0">
            <ProjectsTable :columns="columns" :tableData="defaultProjects" />
          </slot>
        </div>
      </transition>
    </div>
    <div class="Expander bg-white">
      <div
        class="Expander__trigger"
        @click="setSelectedAccrodion('inactive-project')"
        :class="
          selectedAccordion === 'inactive-project' ? 'active' : 'beforeBorder'
        "
      >
        <div class="flex flex-row items-center">
          <svg
            class="Expander__trigger-Icon align-middle"
            :class="{ open: selectedAccordion === 'inactive-project' }"
            width="40"
            height="12"
            stroke="cornflowerblue"
          >
            <polyline
              points="12,2 20,10 28,2"
              stroke-width="3"
              fill="none"
            ></polyline>
          </svg>
          <span class="font-normal text-gray-700">Inactive projects</span>
        </div>
      </div>
      <transition :name="animation">
        <div
          class="Expander__body"
          v-show="selectedAccordion === 'inactive-project'"
        >
          <slot class="bg-white p-0">
            <ProjectsTable :columns="columns" :tableData="inActiveProjects" />
          </slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ProjectsTable from './ProjectsTable.vue';

export default {
  name: 'Accordion2',
  components: {
    ProjectsTable,
  },
  data() {
    return {
      lastWeekHours: 0,
      lastWeekMismmatch: false,
      selectedAccordion: 'active-project',
      open: false,
      title: 'Title',
      animation: 'bottomToTop',
      columns: [
        'Project Name',
        'Project Practice',
        'Allocation %',
        'Allocated Period',
        'Hours',
        'Billability',
      ],
    };
  },
  props: [
    'userId',
    'activeProjects',
    'defaultProjects',
    'inActiveProjects',
    'totalProjectCount',
    'totalProjectHours',
  ],
  created() {
    const userId = this.userId || this.$store.getters['user/userId'];
    axios
      .get(`/timesheet/work_logs/last_week/user/${userId}`)
      .then((response) => {
        const lastWeekdays = [
          ...new Set(
            response.data.lastWeekWorkingDayCount.concat(
              response.data.lastWeekHolidayCount
            )
          ),
        ];
        this.lastWeekMismmatch = lastWeekdays.length < 5;
        this.lastWeekHours = response.data.lastWeekWorkLog;
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  },
  methods: {
    setSelectedAccrodion(accordion) {
      if (this.selectedAccordion === accordion) {
        this.selectedAccordion = '';
      } else {
        this.selectedAccordion = accordion;
      }
    },
  },
};
</script>

<style scoped>
.beforeBorder {
  position: relative;
}

.beforeBorder:before {
  transition: opacity 0.1s linear, transform 0.5s ease-in-out;
  position: absolute;
  border-bottom: 1px solid currentColor;
  content: '';
  width: 100%;
  left: 0;
  bottom: -1px;
}

.beforeBorder:not(:hover)::before {
  transform: scaleX(0);
  opacity: 0;
}

.Accordion {
  background: #fff;
  box-shadow: 0 1px 12px 1px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.Expander__trigger {
  cursor: pointer;
  padding: 0.7rem 0.5rem;
  border-bottom: 1px solid #efefef;
}

.Expander__trigger:hover {
  color: #477dca;
}

.Expander__trigger.active {
  border-bottom-color: #477dca;
}

.Expander__trigger-Icon {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}

.Expander__trigger-Icon.open {
  stroke: #ff6347;
  transform: rotate(180deg);
}

.Expander__body {
  /* padding: 1rem 1.5rem; */
  background: #eff0f2;
}

.rightToLeft-enter-active {
  animation: rightToLeft 0.5s;
}

.rightToLeft-leave-active {
  animation: rightToLeft 0.5s reverse;
}

.leftToRight-enter-active {
  animation: leftToRight 0.5s;
}

.leftToRight-leave-active {
  animation: leftToRight 0.5s reverse;
}

.bounceIn-enter-active {
  animation: bounceIn 0.3s;
}

.bounceIn-leave-active {
  animation: bottomToTop 0.2s reverse;
}

.bottomToTop-enter-active {
  animation: bottomToTop 0.5s forwards;
}

.bottomToTop-leave-active {
  animation: bottomToTop 0.5s reverse;
}

@-moz-keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@-o-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@-moz-keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@-o-keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
