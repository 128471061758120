<template>
  <div class="flex flex-row">
    <nav
      class="bg-gray-800 dark:bg-gray-800 w-full justify-between flex flex-col"
    >
      <div class="mb-0">
        <div>
          <ul>
            <ReducedSidebarItem
              :key="navItem"
              v-for="navItem in Object.keys(navItems)"
              :navItem="navItems[navItem]"
              :navName="navItem"
              @updateNavName="updateNavName($event)"
            />
          </ul>
        </div>
      </div>
      <div class="mb-14 cursor-pointer flex justify-center bg-gray-800">
        <span
          class="material-icons m-auto text-gray-300 select-none md:text-xl text-xs"
          @click="updateSideBarExapansion"
          >segment</span
        >
        <!-- <span
          class="material-icons m-auto text-gray-300 select-none"
          @click="$emit('expandSidebar')"
          >segment</span
        > -->
      </div>
    </nav>
  </div>
</template>

<script>
import ReducedSidebarItem from '@/components/layout/ReducedSidebarItem.vue';

export default {
  components: {
    ReducedSidebarItem,
  },
  props: ['navItems'],
  methods: {
    updateSideBarExapansion() {
      this.$store.commit('settings/updateSidebarExpansion');
    },
    updateNavName(hoverNav) {
      this.$store.commit('settings/updateNavName', {
        hoverNav: hoverNav,
      });
    },
  },
};
</script>
