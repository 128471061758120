

















































import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BasicInfo',
  data() {
    return {
      managers: [],
      baseImageUrl: '',
      urlRegex: new RegExp('^(?:[a-z]+:)?//', 'i'),
    };
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  methods: {
    getvalidUrl(value: string) {
      if (this.urlRegex.test(value)) {
        return value;
      } else {
        return this.baseImageUrl + value;
      }
    },
    getInitials(firstName: string, lastName: string) {
      const firstNameIntial =
        firstName && firstName[0] && firstName[0].toUpperCase();
      const lastNameInitial =
        lastName && lastName[0] && lastName[0].toUpperCase();
      return (firstNameIntial || '') + (lastNameInitial || '');
    },
    fetchReportingManagers() {
      axios
        .get(`/manage/users/reporting_managers/${this.userId}`)
        .then((response) => {
          this.managers = response.data.managers;
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchReportingManagers', error);
        });
    },
  },
  created() {
    this.baseImageUrl = process.env.VUE_APP_BASE_IMAGE_URL;
    this.fetchReportingManagers();
  },
});
