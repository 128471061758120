































import Vue from 'vue';
import TotalHoursPieChart from './TotalHoursPieChart.vue';

export default Vue.extend({
  name: 'BasicInfo',
  components: {
    TotalHoursPieChart,
  },
  data() {
    return {
      isLoading: true,
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {},
    };
  },
  props: ['projects'],
  created() {
    const chartData = {
      hoverBackgroundColor: 'red',
      hoverBorderWidth: 10,
      labels: [] as string[],
      datasets: [
        {
          label: 'Projects',
          backgroundColor: [] as string[],
          data: [] as number[],
        },
      ],
    };
    this.projects.forEach((project: any) => {
      chartData.labels.push(project.name);
      chartData.datasets[0].backgroundColor.push(project.bgColor);
      chartData.datasets[0].data.push(
        project.hours ? parseFloat(project.hours) : 0
      );
    });
    this.chartData = chartData;
    this.isLoading = false;
  },
});
