//const.js
export default {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  MOBILE: /^\d{10}$/,
  ATLEAST_ONE_CHAR: /.*[a-zA-Z].*/,
  ATLEAST_ONE_DIGIT: /.*[0-9].*/,
  ATLEAST_ONE_CHAR_OR_DIGIT: /.*[a-zA-Z0-9].*/,
  ONLY_CHAR: /^[A-Za-z]+$/,
  CHAR_WITH_SPACE: /^[A-Za-z ]*$/,
  ONLY_DIGITS: /^[-\d]\d*$/,
  DECIMAL_PRECISION: /^[0-9]+(\.[0-9]{1,2})?$/,
  EMP_ID: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/, //String should start and end with only a-z, A-Z, 0-9 and can have - in between
  NUMBER_TEXT_WITH_SOME_SPECIAL_CHARACTER: /^[a-zA-Z0-9 ()-]+$/,
  PHONE_NUMBERS_CHARACTER: /^[+]?[0-9 -]{10,14}(,)?([+]?[0-9 -]{10,14})?$/,
  LOG_HOURS: /^\d*(\.\d{1,2})?$/,
  FOUR_DIGIT_ONLY: /^\d{4}$/,
  TWO_DIGIT_ONLY: /^\d{1,2}$/,
  EXPERIENCE_DECIMAL_PRECISION: /^\d{1,2}(\.\d{1})?$/,
  HOURLY_RATE_DECIMAL_PRECISION: /^[0-9]{0,10}(\.[0-9]{1,2})?$/,
  LOCATION_CHARACTER: /^[A-Za-z ,/.-]*$/,
  CERTIFICATIONS: /^[a-zA-Z0-9 ()-+/,.]+$/,
};
