




import Vue from 'vue';
import Calendar from '@/components/timesheet/Calendar.vue';
export default Vue.extend({
  name: 'Log',
  components: {
    Calendar,
  },
  props: ['userDetails'],
});
