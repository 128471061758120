

















































import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import EditPersonalInfoModal from './EditPersonalInfoModal.vue';

export default Vue.extend({
  name: 'PersonalInfo',
  data() {
    return {
      basicInfo: {
        gender: 'Gender',
        blood_group: 'Blood Group',
        dob: 'Date Of Birth',
        emergency_contact: 'Emergency Contact',
        address: 'Address',
        city: 'City',
        state_name: 'State',
        country_name: 'Country',
      } as any,
      filteredUserDetails: {},
      isEditPersonalInfoModal: false,
      isManagePermission: false,
      isReadPermission: false,
    };
  },
  props: ['userDetails'],
  watch: {
    userDetails: function (_newVal: any, _oldVal: any) {
      this.setUserDetails();
    },
  },
  components: {
    EditPersonalInfoModal,
  } as any,
  created() {
    this.isManagePermission = (this as any).validatePermission(['home.manage']);
    this.isReadPermission = (this as any).validatePermission(['home.read']);
  },
  mounted() {
    this.setUserDetails();
  },
  methods: {
    setUserDetails() {
      const userDetails =
        this.userDetails || this.$store.state.user.userDetails;
      const filteredUser: any = [];
      Object.keys(this.basicInfo).forEach((key) => {
        if (this.userDetails.hasOwnProperty(key)) {
          filteredUser.push({
            label: this.basicInfo[key],
            value:
              key === 'dob'
                ? moment(userDetails[key]).format('DD MMMM YYYY')
                : userDetails[key],
          });
        }
      });
      this.filteredUserDetails = filteredUser;
    },
    openEditPersonalInfoModal() {
      this.isEditPersonalInfoModal = true;
    },
    closeEditPersonalInfoModal() {
      this.isEditPersonalInfoModal = false;
    },
    updateUserInfo(params: any) {
      if (this.isManagePermission) {
        axios
          .patch(`/manage/users/update_user_info/`, params)
          .then((res) => {
            this.$emit('updateUserDetails', res.data.user_details);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: 'Personal Info Updated Successfully',
              alertType: 'success',
            });
            this.closeEditPersonalInfoModal();
          })
          .catch((error) => {
            let errMsg = 'Something went wrong. Please try again';
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errMsg = error.response.data.message[0];
            }
            this.$store.dispatch('settings/showAlert', {
              alertMessage: errMsg,
              alertType: 'error',
            });
          });
      }
    },
  },
});
