
































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import Loading from '@/components/common/Loading.vue';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import RequirementChart from '@/components/recruitment/requirementList/RequirementChart.vue';
import EditProfileModal from '@/components/recruitment/requirementList/EditProfileModal.vue';
import AddInterviewModal from '@/components/recruitment/requirementList/AddInterviewModal.vue';
import ViewInterviewModal from '@/components/recruitment/requirementList/ViewInterviewModal.vue';
import PositionEditPopup from '@/components/recruitment/requirementList/PositionEditPopup.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import moment from 'moment';
import NoRecordFound from '@/components/common/NoRecordFound.vue';

export default Vue.extend({
  name: 'Requirement',
  data() {
    return {
      requirement_closes: [],
      isLoading: true,
      query: '',
      columns: [
        'First Name',
        'Last Name',
        'Total Exp',
        'Relevant Exp',
        'Profile Status',
        'Notice Period',
        'Offered Date',
        'Joining Date',
        'Comments',
        'Current Organisation',
        'Resume',
        'E-Mail',
        'Phone',
        'Skill Set',
        'Hiring Location',
        'Current CTC',
        'Expected Rate',
        'Source',
        'Designation',
        'Skype',
        'Wiling to Relocate',
        'Current Location',
        'Work Authorization',
        'Visa Validity',
        'SSN',
        'Current Project',
        'Vacation Plans',
        'LinkedIn Url',
        'Department',
        'Project Planned',
        'Certification Url',
        'Rating',
        'Submitted On',
      ],
      selectedColumns: [''],
      defaultColumns: [
        'Action',
        'View',
        'First Name',
        'Last Name',
        'Total Exp',
        'Relevant Exp',
        'Profile Status',
        'Notice Period',
        'Offered Date',
        'Joining Date',
        'Comments',
        'Current Organisation',
        'Resume',
        'E-Mail',
        'Phone',
      ],
      edit_columns: ['Position', 'Closed By', 'Closed Date'],
      requirement: {},
      status_lists: [
        'Joined',
        'Offered and yet to join',
        'Candidate selected',
        'Interview scheduled',
        'Candidate submitted',
        'Declined offer or not joined',
        'On hold',
        'Candidate rejected',
      ],
      profiles: {},
      closed_users: [{ label: 'NA', value: 'NA' }],
      requirementId: null,
      isEditProfileModalVisible: false,
      isPositionEditModalVisible: false,
      isAddInterviewModalVisible: false,
      isViewInterviewModalVisible: false,
      selectedProfileId: null,
      chartData: null,
      paginationMeta: {},
      selectedProfileData: {},
      selectedRequirement: {},
      pageSize: 30,
      isReadPermission: false,
      isManagePermission: false,
      isExportPermission: false,
      selection: [''],
      fields: { text: 'label', value: 'value' },
      profileInterviews: [],
      selectedRequirementId: null,
    };
  },
  components: {
    Loading,
    EditProfileModal,
    RequirementChart,
    PositionEditPopup,
    Pagination,
    ExportButtons,
    NoRecordFound,
    AddInterviewModal,
    ViewInterviewModal,
  },
  computed: {
    ...mapGetters('user', []),
    ...mapState('settings', ['profileColumns']),
    requirementName(): string {
      return `${(this.requirement as any).job_role} (${
        (this.requirement as any).r_id
      })`;
    },
  },
  methods: {
    wrapMethod(sentence: any) {
      return sentence.length > 20
        ? sentence.substring(0, 20) + '...'
        : sentence;
    },
    fixbackground(status: any) {
      switch (status) {
        case 'Joined':
          return 'joined';
        case 'Offered and yet to join':
          return 'offered_and_yet_to_join';
        case 'Candidate selected':
          return 'candidate_selected';
        case 'Candidate submitted':
          return 'candidate_submitted';
        case 'Interview scheduled':
          return 'interview_scheduled';
        case 'Candidate rejected':
          return 'candidate_rejected';
        case 'Declined offer or not joined':
          return 'declined_offer_or_Not_joined';
        case 'On hold':
          return 'on_hold';
        default:
          return '';
      }
    },
    setColumns(option: any) {
      if (option.value.length > 0) {
        this.selection = option.value;
      } else {
        this.selection = this.defaultColumns;
      }
    },
    showColumn() {
      this.$store.commit('settings/setProfileColumnsData', {
        profileColumns: this.selection,
      });
      this.selectedColumns = this.profileColumns;
      this.selectedColumns.sort(
        (a, b) => this.columns.indexOf(a) - this.columns.indexOf(b)
      );
    },
    createPDF() {
      const doc: any = new jsPDF('l', 'mm', [297, 500]);
      doc.text(`ProfileList: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#profile_table',
      });
      doc.save(`Profile_List_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    setPageSize(value: any) {
      this.pageSize = value;
      this.fetchProfiles(1);
    },
    setQuery(e: any) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchProfiles(1);
      }
    },
    closeEditProfileModal() {
      this.fetchProfiles(1);
      this.isEditProfileModalVisible = false;
    },
    openEditProfileModal(profileId: any, profile: any) {
      this.selectedProfileId = profileId;
      this.selectedProfileData = profile;
      this.isEditProfileModalVisible = true;
    },
    closeAddInterviewModal() {
      //this.fetchProfiles(1);
      this.isAddInterviewModalVisible = false;
    },
    openAddInterviewModal(requirement: any, profile: any, profileId: any) {
      this.selectedProfileId = profileId;
      this.selectedRequirement = requirement;
      this.isAddInterviewModalVisible = true;
      this.selectedProfileData = profile;
    },

    toggleViewInterviewModal(requirement: any, profile: any, profileId: any) {
      this.selectedProfileId = profileId;
      this.selectedRequirement = requirement;
      this.selectedProfileData = profile;
      this.isViewInterviewModalVisible = !this.isViewInterviewModalVisible;
    },
    closeViewInterviewModal() {
      this.isViewInterviewModalVisible = false;
    },

    openPositionEditPopup(requirementId: any) {
      this.selectedRequirementId = requirementId;
      this.isPositionEditModalVisible = true;
    },
    closeEditPositionEditPopupModal() {
      this.isPositionEditModalVisible = false;
    },

    fetchRequirementClose() {
      axios
        .get(
          '/recruitment/requirements/requirement_closes_info?requirementId=' +
            this.requirementId
        )
        .then((response) => {
          this.requirement_closes = response.data.requirement_closes_data;
        })
        .catch((error) => {
          this.requirement = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    fetchRequirement() {
      axios
        .get('/recruitment/requirements/' + this.requirementId)
        .then((response) => {
          this.requirement = response.data.requirement;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.requirement = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    fetchProfiles(page: any): void {
      const { pageSize } = this;
      axios
        .get(`/recruitment/profiles?page_size=${pageSize}&page=${page}`, {
          params: {
            requirement_id: this.requirementId,
            search: this.query,
          },
        })
        .then((response) => {
          this.profiles = response.data.profiles;
          this.paginationMeta = response.data.meta;
          this.fetchRequirementPieChart();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.requirement = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    fetchRequirementPieChart() {
      axios
        .get(
          '/recruitment/requirements/' +
            this.requirementId +
            '/requirement_pie_chart'
        )
        .then((response) => {
          this.chartData = response.data.chart_data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchProjects', error);
        });
    },
    exportToCSV() {
      axios
        .post(
          `/recruitment/profiles/profile_csv.csv?requirement_id=` +
            this.requirementId +
            `&search=` +
            this.query +
            `&columns=` +
            this.selectedColumns
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Profile_List_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while exportToCSV', error);
        });
    },
    fetchUsers() {
      axios
        .get(`/recruitment/requirements/get_recruiters`)
        .then((response) => {
          const closed_users = response.data.recruiters.map((user: any) => {
            return {
              label: user.first_name + ' ' + user.last_name,
              value: user.id,
            };
          });
          closed_users.unshift({
            label: 'NA',
            value: 0,
          });
          this.closed_users = closed_users;
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchUsers', error);
          // callback();
        });
    },
    onBack() {
      this.$router.push('/recruitment/list');
    },
    onSuccess() {
      this.closeEditPositionEditPopupModal();
      this.$router.go(0);
    },
  },
  created() {
    if (this.profileColumns.length > 0) {
      this.selectedColumns = this.profileColumns;
      this.selectedColumns.sort(
        (a, b) => this.columns.indexOf(a) - this.columns.indexOf(b)
      );
    } else {
      this.selectedColumns = this.defaultColumns;
    }
    this.isReadPermission = (this as any).validatePermission([
      'recruitment.requirements_list.read',
    ]);
    this.isManagePermission = (this as any).validatePermission([
      'recruitment.requirements_list.manage',
    ]);
    this.isExportPermission = (this as any).validatePermission([
      'recruitment.requirements_list.export',
    ]);
    this.requirementId = (this.$route as any).params.id;
    this.fetchRequirement();
    this.fetchProfiles(1);
    this.fetchRequirementPieChart();
    this.fetchUsers();
    this.fetchRequirementClose();
  },
});
