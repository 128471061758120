import Vue from 'vue';
import Vuex from 'vuex';
import cart from './modules/cart';
import user from './modules/user';
import settings from './modules/settings';

// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    cart,
    user,
    settings,
  },
  strict: debug,
  //   plugins: debug ? [createLogger()] : []
});
