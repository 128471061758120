















































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import CategoryModal from './CategoryModal.vue';
import axios from 'axios';

export default Vue.extend({
  name: 'Category',
  components: {
    ConfirmAlertBox,
    CategoryModal,
  },
  data() {
    return {
      isConfirmDeleteModalVisible: false,
      isCategoryModalVisible: false,
      selectedIssueId: null,
      defaultIssueNumber: '',
      allIssues: [] as any,
      isReadPermission: false,
      isManagePermission: false,
    };
  },
  props: ['issues', 'projectId', 'projectName', 'projectStatus'],
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    onDelete(id: any) {
      this.isCategoryModalVisible = false;
      this.selectedIssueId = id;
      this.isConfirmDeleteModalVisible = true;
    },
    removeCategory() {
      axios
        .delete(`/manage/issues/${this.selectedIssueId}`)
        .then((response: any) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.allIssues = this.allIssues.filter(
            (issue: any) => issue.id !== this.selectedIssueId
          );
          this.closeConfirmDeleteModal();
        })
        .catch((err: any) => {
          const { data = {} } = err.response;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
          });
          this.closeConfirmDeleteModal();
          console.error('ERR while removeCategory', err);
        });
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    openCategoryModal(id: any, issueNumber: string) {
      this.defaultIssueNumber = issueNumber;
      this.selectedIssueId = id;
      this.isCategoryModalVisible = true;
    },
    closeCategoryModal() {
      this.isCategoryModalVisible = false;
    },
    onSuccess(data: any, isEdit: boolean) {
      if (isEdit) {
        this.allIssues = this.allIssues.map((issue: any) => {
          if (issue.id === data.id) {
            return data;
          } else {
            return issue;
          }
        });
      } else {
        this.allIssues.push(data);
      }
      this.closeCategoryModal();
    },
  },
  created() {
    this.isReadPermission = (this as any).validatePermission([
      'manage.projects.read',
    ]);
    this.isManagePermission = (this as any).validatePermission([
      'manage.projects.manage',
    ]);
    this.allIssues = this.issues;
  },
});
