<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <div>
      <!-- Top Bar -->
      <div
        class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:pl-5 px-2 z-10"
      >
        <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
          Talent Pool
        </div>

        <DateRangePickerInput
          class="mr-3"
          v-if="isReadPermission"
          ref="talentPoolDateRangePicker"
          @updateDateRange="setFilterByDateRange"
          popupAlign="left"
          :startDate="dateRange && dateRange.startDate"
          :endDate="dateRange && dateRange.endDate"
        />
      </div>
      <!-- Top Bar END-->
      <TalentPoolTab
        class="w-full"
        :showTalentPoolFilter="showTalentPoolFilter"
        :dateRange="dateRange"
        @resetDate="resetDate"
        @toggleFilter="toggleFilter"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import TalentPoolTab from '@/components/pmo/project_assignment/TalentPoolTab.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';

export default {
  name: 'ProjectAssignment',
  components: {
    TalentPoolTab,
    DateRangePickerInput,
  },
  data() {
    const weekNumber = moment().week() - moment().startOf('month').week() + 1;
    const startDate =
      weekNumber == 1
        ? moment().subtract(1, 'month').startOf('month').toDate()
        : moment().startOf('month').toDate();
    const endDate =
      weekNumber == 1
        ? moment().subtract(1, 'month').endOf('month').toDate()
        : moment().startOf('week');
    return {
      showTalentPoolFilter: false,
      dateRange: { startDate, endDate },
      isReadPermission: false,
      isExportPermission: false,
    };
  },
  methods: {
    resetDate() {
      const { defaultDateRange } = this.$refs.talentPoolDateRangePicker;
      this.$refs.talentPoolDateRangePicker.dateRange = defaultDateRange;
      this.setFilterByDateRange(defaultDateRange);
    },
    toggleFilter() {
      this.showTalentPoolFilter = !this.showTalentPoolFilter;
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.dateRange = { startDate, endDate };
    },
  },
  created() {
    this.isReadPermission = this.validatePermission([
      'reports.talent_pool.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.talent_pool.export',
    ]);
  },
};
</script>
