












































import Vue from 'vue';

export default Vue.extend({
  name: 'BasicInfo',
  data() {
    return {
      userId: null,
      basicInfo: {
        employee_id: 'Employee ID',
        consultant: 'Consultant',
        department: 'Department',
        designation: 'Designation',
        mobile_number: 'Mobile',
        location: 'Location',
        current_org_experience: 'Wilco Experience',
        practice: 'Practice',
        reporting_to: 'Reporting to',
        emergency_contact: 'Emergency Contact',
        email: 'Email',
      } as any,
      filteredUserDetails: {},
      isReadPermission: false,
    };
  },
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
    moreButton: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.isReadPermission = (this as any).validatePermission(['home.read']);
  },
  mounted() {
    const userDetails =
      Object.keys(this.userDetails).length > 0
        ? this.userDetails
        : this.$store.state.user.userDetails;
    const filteredUser: any = [];
    Object.keys(userDetails).forEach((key) => {
      if (this.basicInfo.hasOwnProperty(key)) {
        filteredUser.push({
          label: this.basicInfo[key],
          value: userDetails[key],
        });
      }
    });
    this.filteredUserDetails = filteredUser;
    this.userId = userDetails.id;
  },
});
