



















import Vue from 'vue';
export default Vue.extend({
  name: 'SwitchInput',
  data() {
    return {};
  },
  props: ['toggleActive', 'labels', 'name'],
});
