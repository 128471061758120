<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <TransparentLoader v-if="isLoading" />
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        Practice Resource Availability
      </div>

      <div class="flex flex-row items-center">
        <label class="text-xs text-gray-600 font-thin hidden sm:block"></label>
        <div class="hidden sm:block">
          <div class="flex md:ml-3 mr-4" v-if="isReadPermission">
            <DateRangePickerInput
              ref="practiceResourceDateRangePicker"
              @updateDateRange="setFilterByDateRange"
              :startDate="filterBy.dateRange && filterBy.dateRange.startDate"
              :endDate="filterBy.dateRange && filterBy.dateRange.endDate"
            />
          </div>
        </div>

        <div></div>
      </div>
    </div>
    <!-- Top Bar END-->
    <div class="bg-white dark:bg-gray-800 shadow rounded mt-2 relative">
      <CustomFilter
        v-if="showFilter"
        :filterBy="filterBy"
        :userPractices="userPractices"
        @resetFilter="resetFilter"
        @setFilterByUserPractices="setFilterByUserPractices"
        @setFilterByUsers="setFilterByUsers"
        @toggleFilter="toggleFilter"
        :userOptions="userOptions"
      />

      <div class="flex py-4 px-4 justify-between items-center">
        <!-- Icons -->
        <ExportButtons
          v-if="isExportPermission"
          :excel="true"
          :pdf="true"
          @createPDF="createPDF"
          @exportToExcel="exportToCSV"
        />
        <div v-else></div>
        <!-- Icons END -->
        <div v-if="isReadPermission" class="flex flex-row items-center">
          <!-- Search Input -->
          <div class="relative text-gray-600" style="margin-right: 30px">
            <input
              v-model="query"
              @input="setQuery"
              type="search"
              name="serch"
              placeholder="Search"
              class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
            />
            <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
              <span class="material-icons md:text-2xl text-sm text-gray-400"
                >search</span
              >
            </button>
          </div>
          <!-- Search END -->
          <span
            class="material-icons text-2xl cursor-pointer select-none text-gray-500"
            @click="toggleFilter"
            >filter_alt</span
          >
        </div>
      </div>
      <!-- Table -->
      <div class="flex flex-col" style="height: 26rem">
        <div
          class="flex-grow overflow-auto"
          v-if="isReadPermission && reports.length > 0"
        >
          <table
            id="users_table"
            class="relative w-full border whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-x-auto"
          >
            <thead>
              <tr class="text-left">
                <th
                  class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400"
                  :key="index"
                  v-for="(column, index) in columns"
                >
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
              <tr
                :key="index"
                v-for="(report, index) in reports"
                class="py-2"
                :class="index % 2 === 1 ? 'bg-gray-100' : ''"
              >
                <td
                  v-if="report.id"
                  :rowspan="mergeRows[index]"
                  class="px-4 py-2 bg-white"
                >
                  <div v-if="isReadUserPermission">
                    <router-link :to="`/manage/users/${report.id}`">
                      <p
                        class="text-xs font-semibold text-blue-500 cursor-pointer"
                      >
                        {{ report.user_name }}
                      </p>
                    </router-link>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ report.email }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="text-xs font-semibold cursor-pointer">
                      {{ report.user_name }}
                    </p>
                    <p class="text-gray-400 text-xs tracking-wide">
                      {{ report.email }}
                    </p>
                  </div>
                </td>
                <td
                  class="px-4 py-2"
                  v-if="
                    isReadProjectPermission &&
                    report.project != 'Availability(%)'
                  "
                >
                  <router-link :to="`/manage/projects/${report.project_id}`">
                    <p
                      class="text-xs font-semibold text-blue-500 cursor-pointer"
                    >
                      {{ report.project }}
                    </p>
                  </router-link>
                </td>
                <td class="px-4 py-2" v-else>
                  <p class="font-bold">
                    {{ report.project }}
                  </p>
                </td>
                <td class="px-4">
                  <p class>
                    {{
                      report.start_date
                        ? globalDateFormat(report.start_date)
                        : ''
                    }}
                  </p>
                </td>
                <td class="px-2">
                  <p class>
                    {{
                      report.end_date ? globalDateFormat(report.end_date) : ''
                    }}
                  </p>
                </td>
                <td
                  :key="allocationIndex"
                  v-for="(allocation, allocationIndex) in report.allocation"
                  class="px-2"
                >
                  <p v-if="allocation == 100" class="font-bold text-center">
                    {{ allocation }}
                  </p>
                  <p v-else class="text-center">{{ allocation }}</p>
                </td>
                <td
                  :key="totalIndex"
                  v-for="(total, totalIndex) in report.total"
                  class="px-2"
                >
                  <p
                    :style="{
                      color:
                        100 - total > 100 || 100 - total < 0 ? 'red' : 'black',
                    }"
                    class="font-bold text-center"
                  >
                    {{ 100 - total }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <NotAuthorized v-else-if="!isReadPermission" />
        <NoRecordFound v-else />
        <!-- Table END -->
      </div>
      <!-- Pagination -->
    </div>

    <div class="mt-1">
      <Pagination
        class="mt-2"
        v-if="Object.keys(paginationMeta).length > 0 && isReadPermission"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchPracticeResourceReport"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination End-->
    <div class="mt-4 ml-2">
      <p class="font-semibold">
        *Availability refers to the project aggregate of every month for
        respective user
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import TransparentLoader from '@/components/common/TransparentLoader.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'PracticeResourceAvailability',
  components: {
    Pagination,
    TransparentLoader,
    DateRangePickerInput,
    CustomFilter,
    NotAuthorized,
    NoRecordFound,
    ExportButtons,
  },
  data() {
    const startDate = moment().startOf('year').format('YYYY-MM-DD');
    const endDate = moment().endOf('year').format('YYYY-MM-DD');
    return {
      showFilter: false,
      userOptions: [],
      startDate: null,
      endDate: null,
      filterBy: {
        users: [],
        userPractices: [],
        dateRange: { startDate, endDate },
      },
      query: '',
      columns: [],
      reports: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      defaultFilterBy: {},
      isReadPermission: false,
      isExportPermission: false,
      mergeRows: [],
      fields: { text: 'label', value: 'value' },
      nonce: '',
    };
  },
  computed: {
    ...mapGetters('user', [
      'userId',
      'isReadUserPermission',
      'isReadProjectPermission',
    ]),
  },
  created() {
    this.fetchPracticeResourceReport = _debounce(
      this.fetchPracticeResourceReport,
      400
    );
    this.isReadPermission = this.validatePermission([
      'reports.practice_resource.read',
    ]);
    this.isExportPermission = this.validatePermission([
      'reports.practice_resource.export',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchUserPractices();
      this.fetchUserOptions();
      this.fetchPracticeResourceReport(1);
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    resetFilter() {
      const { defaultDateRange } = this.$refs.practiceResourceDateRangePicker;
      this.$refs.practiceResourceDateRangePicker.dateRange = defaultDateRange;
      this.filterBy = { ...this.defaultFilterBy };
      this.setFilterByDateRange(defaultDateRange);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    createPDF() {
      const date = this.startDate
        ? this.startDate + '-' + this.endDate
        : moment().subtract('days', 7).format('YYYY-MM-DD') +
          '-' +
          moment().format('YYYY-MM-DD');
      const doc = new jsPDF('l');
      doc.text(`Practice Resource Availability: ${date}`, 15, 10);
      doc.autoTable({
        html: '#users_table',
      });
      doc.save(
        `Practice Resource Availability_${moment().format('yyyy_MM_DD')}.pdf`
      );
    },
    setFilterByUserPractices(option) {
      this.filterBy.userPractices = option;
      this.fetchUserOptions();
      this.fetchPracticeResourceReport(1);
    },
    setFilterByDateRange(dateRange) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.startDate = startDate;
      this.endDate = endDate;
      this.filterBy.dateRange = { startDate, endDate };
      this.fetchPracticeResourceReport(1);
    },

    setFilterByUsers(option) {
      this.filterBy.users = option;
      this.fetchPracticeResourceReport(1);
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchPracticeResourceReport(1);
      }
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchPracticeResourceReport(1);
    },
    fetchUserPractices() {
      axios
        .get(`/manage/practices?type=User Practice`)
        .then((response) => {
          this.userPractices = response.data.map((d) => {
            return { label: d.practice_name, value: d.practice_name };
          });
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUserPractices', error);
        });
    },

    getUsersPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.userPractices.length > 0) {
        payload.userPractices = filterBy.userPractices;
      }

      if (filterBy.users.length > 0) {
        payload.users = filterBy.users;
      }

      if (filterBy.dateRange) {
        payload.date_range = JSON.stringify(filterBy.dateRange);
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    fetchPracticeResourceReport(page) {
      this.isLoading = true;
      this.nonce = Date.now().toString();
      const { pageSize } = this;
      const payload = this.getUsersPayload();

      axios
        .get(
          `/reports/practice_resource_availability?page_size=${pageSize}&page=${page}&`,
          {
            params: {
              user_id: payload.users,
              practice: payload.userPractices,
              date_range: payload.date_range,
              nonce: this.nonce,
              search: payload.search,
            },
          }
        )
        .then((response) => {
          if (this.nonce !== response.data.nonce) return;
          this.columns = ['User', 'Project Name', 'Start Date', 'End Date'];
          this.paginationMeta = response.data.meta;
          this.reports = response.data.report;
          this.mergeRows = response.data.merge_cells;
          this.isLoading = false;
          response.data.column.forEach((col) => {
            this.columns.push(col);
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.reports = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('Error while Fething Detail Log Report', error);
        });
    },

    fetchUserOptions() {
      const payload = this.getUsersPayload();
      axios
        .get(`/manage/users/filter_users_list`, {
          params: {
            status: true,
            practice: payload.userPractices,
          },
        })
        .then((response) => {
          this.userOptions = this.filterUsers(response.data.users);
        })
        .catch((error) => {
          // handle error
          console.log('ERR while fetchUsers', error);
        });
    },
    filterUsers(users) {
      const filteredUsers = [];
      users.forEach((user) => {
        filteredUsers.push({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
        });
      });
      return filteredUsers;
    },
    exportToCSV() {
      if (this.isExportPermission) {
        const payload = this.getUsersPayload();
        this.isLoading = true;
        axios
          .get(`/reports/practice_resource_csv`, {
            params: {
              user_id: payload.users,
              practice: payload.userPractices,
              date_range: payload.date_range,
              search: payload.search,
            },
            responseType: 'blob',
          })
          .then((response) => {
            this.isLoading = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `Practice_Resource_Availability_Report.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            this.isLoading = false;
            // handle error
            console.error('Error while Fething Detail Log Report', error);
          });
      }
    },
  },
};
</script>

<style scoped>
thead tr th {
  border-right: 1px solid white;
}
tbody tr td {
  border-right: 1px solid #e5e7eb;
}
</style>
