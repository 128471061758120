<template>
  <li
    class="my-0 text-center cursor-pointer md:text-sm text-xs"
    :class="[
      selectedNav === navName || hoverNav === navName
        ? activeClass
        : 'text-gray-400 bg-gray-800',
    ]"
    @mouseover="updateSideBarExapansion"
  >
    <div class="pb-0 md:mb-0 mb-1">
      <router-link
        :to="
          navItem.link && Object.keys(navItem.subNavItems).length === 0
            ? navItem.link
            : navItem.subNavItems[Object.keys(navItem.subNavItems)[0]].link
        "
      >
        <span
          class="w-full md:h-10 h-3 font-thin uppercase flex items-center md:px-4 md:pt-2 pt-1 transition-colors duration-200 justify-start"
        >
          <span class="material-icons m-auto md:text-lg text-xs">{{
            navItem.icon || 'home'
          }}</span>
        </span>
        <span class="md:text-xs" style="font-size: 0.73rem">
          {{ navItem.title }}
        </span>
      </router-link>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    activeClass:
      'bg-gray-600 dark:from-gray-700 dark:to-gray-800 md:border-l-4 border-l-2 border-blue-500 text-white',
  }),
  computed: {
    ...mapState('settings', ['selectedNav', 'isSideBarExpanded', 'hoverNav']),
  },
  props: ['navItem', 'navName'],
  methods: {
    updateSideBarExapansion() {
      this.$emit('updateNavName', this.navName);
      if (this.navName === this.hoverNav && !this.isSideBarExpanded) {
        this.$store.commit('settings/updateSidebarExpansion');
      }
    },
  },
};
</script>
