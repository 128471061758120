









































import Vue from 'vue';

export default Vue.extend({
  name: 'ExportButtons',
  props: ['pdf', 'csv', 'excel', 'excelStatusLog'],
  methods: {
    createPDF() {
      this.$emit('createPDF');
    },
    exportToCSV() {
      this.$emit('exportToCSV');
    },
    exportToExcel() {
      this.$emit('exportToExcel');
    },
    exportToExcelStatus() {
      this.$emit('exportToExcelStatus');
    },
  },
});
