
























































































































import Vue from 'vue';
import BasicInfo from '@/components/home/BasicInfo.vue';
import TotalBurnDownHours from '@/components/home/TotalBurnDownHours.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import Accordion from '@/components/home/Accordion2.vue';
import OtherInfo from '@/components/manage/users/OtherInfo.vue';
import UserNameAvatar from '@/components/manage/users/UserNameAvatar.vue';
import ConfirmAlertBox from '@/components/common/ConfirmAlertBox.vue';
import Loading from '@/components/common/Loading.vue';
import moment from 'moment';
import SourceStats from '@/components/home/SourceStats.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import DateRangePickerInput from '@/components/inputs/DateRangePickerInput.vue';

export default Vue.extend({
  name: 'User',
  data: () => ({
    isConfirmDeleteModalVisible: false,
    isErrorModalVisible: false,
    isLoading: true,
    isUserDetailsLoading: true,
    projects: [],
    defaultProjects: [],
    activeProjects: [],
    inActiveProjects: [],
    totalProjectHours: '',
    totalProjectCount: 0,
    userId: null,
    userDetails: null,
    isReadPermission: false,
    isManagePermission: false,
    ptoHolidayProjects: ['WSPTO', 'WSHOLIDAY'],
    dateRange: {},
    componentKey: 0,
    maxDate: moment().toDate(),
  }),
  components: {
    BasicInfo,
    Accordion,
    TotalBurnDownHours,
    OtherInfo,
    UserNameAvatar,
    ConfirmAlertBox,
    Loading,
    SourceStats,
    NotAuthorized,
    DateRangePickerInput,
  },
  computed: {
    ...mapGetters('user', ['userFullName']),
  },
  methods: {
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    closeErrorModal() {
      this.isErrorModalVisible = false;
      this.isConfirmDeleteModalVisible = false;
    },
    removeUser() {
      axios
        .delete(`/manage/users/${this.userId}`)
        .then((response: any) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg,
            alertType: 'success',
          });
          this.$router.back();
        })
        .catch((err: any) => {
          // handle error
          this.isErrorModalVisible = true;
          console.error('ERR while onDelete', err);
        });
    },
    onDelete() {
      this.isConfirmDeleteModalVisible = true;
    },
    fetchProjects() {
      this.isLoading = true;
      axios
        .get(`/manage/projects/user?user_id=${this.userId}`)
        .then((response) => {
          const { projects, non_billing_project_codes } = response.data;

          const defaultProjects: any = [];
          const activeProjects: any = [];
          const inActiveProjects: any = [];
          let totalProjectHours = 0;
          let totalProjectCount = 0;
          projects.forEach((project: any) => {
            const {
              name,
              practice,
              allocation,
              start_date,
              end_date,
              hours,
              billability,
              id,
            } = project;
            const period =
              start_date && end_date
                ? `${start_date
                    .substring(0, 10)
                    .replaceAll('-', '/')} - ${end_date
                    .substring(0, 10)
                    .replaceAll('-', '/')}`
                : '';
            const filteredProject = {
              id,
              name,
              practice,
              allocation,
              period,
              hours,
              billability,
            };
            if (
              !project.project_user_status &&
              !this.ptoHolidayProjects.includes(project.code)
            ) {
              inActiveProjects.push(filteredProject);
            } else {
              if (
                non_billing_project_codes.includes(project.code) ||
                this.ptoHolidayProjects.includes(project.code)
              ) {
                defaultProjects.push(filteredProject);
              } else {
                activeProjects.push(filteredProject);
              }
            }
            totalProjectHours += project.hours ? parseFloat(project.hours) : 0;
            totalProjectCount += project.count ? parseInt(project.count) : 0;
          });
          this.projects = projects;
          this.defaultProjects = defaultProjects;
          this.activeProjects = activeProjects;
          this.inActiveProjects = inActiveProjects;
          this.totalProjectHours =
            totalProjectHours == 0
              ? '0'
              : parseFloat(totalProjectHours.toString()).toFixed(2);
          this.totalProjectCount = totalProjectCount;
          this.isLoading = false;
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetching projects', error);
        });
    },
    fetchUsers() {
      const employmentType: any = {
        full_time: 'Full time',
        contract: 'Contract',
      };
      this.isUserDetailsLoading = true;
      axios
        .get(`/manage/users/${this.userId}`)
        .then((response) => {
          if (response.data && response.data.joining_date) {
            response.data.joining_date = moment(
              response.data.joining_date
            ).format('DD MMMM YYYY');
            if (response.data.exit_date) {
              response.data.exit_date = moment(response.data.exit_date).format(
                'DD MMMM YYYY'
              );
            }
          }
          if (
            response.data &&
            response.data.employment_type_name &&
            employmentType[response.data.employment_type_name]
          ) {
            response.data.employment_type_name =
              employmentType[response.data.employment_type_name];
          }
          this.userDetails = response.data;
          this.isUserDetailsLoading = false;
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchUsers', error);
        });
    },
    setFilterByDateRange(dateRange: any) {
      const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
      this.dateRange = { startDate, endDate };
      this.fetchProjects();
      this.isLoading = false;
      this.componentKey += 1;
    },
  },
  created() {
    const startDate = moment().startOf('year').toDate();
    const endDate = moment().endOf('year').toDate();
    this.dateRange = { startDate, endDate };
    this.isReadPermission = (this as any).validatePermission([
      'manage.users.read',
    ]);
    this.isManagePermission = (this as any).validatePermission([
      'manage.users.manage',
    ]);
    this.userId = (this.$router as any).history.current.params.id;
    if (this.userId) {
      this.fetchUsers();
      this.fetchProjects();
    }
  },
});
