<template>
  <div class="bg-gray-200 w-96 flex flex-col">
    <!-- Top Bar -->
    <div
      class="md:h-14 bg-white flex flex-row py-2 md:py-0 shadow-sm items-center justify-between text-left sticky top-0 md:px-5 px-2 z-10"
    >
      <div class="text-gray-600 text-base font-semibold md:mt-0 mt-1 md:py-4">
        Certification
      </div>

      <div class="flex flex-row items-center">
        <!-- Bulk Upload  template-->
        <div class="hidden md:block" v-if="isUploadPermission">
          <label
            @click="downloadCsvTemplate"
            class="w-44 mr-2 flex flex-col items-center px-0 py-1 b-1 border-green-500 text-green-500 rounded-lg tracking-wide border border-blue cursor-pointer hover:bg-green-400 hover:text-white"
          >
            <div
              class="flex flex-row items-center justify-between"
              v-tippy
              content="Download bulk upload empty template"
            >
              <span class="material-icons m-auto text-xl">cloud_download</span>
              <span class="text-sm leading-normal ml-3">Download template</span>
            </div>
          </label>
        </div>
        <!-- Add New END -->
        <!-- Bulk Upload -->
        <div
          class="w-48"
          v-if="isUploadPermission"
          style="margin: 0px; padding: 0px"
        >
          <UploadFileButton
            :label="'Upload Certification'"
            @onParsed="onParsed"
            v-tippy
            content="Choose .csv file to bulk upload certification"
            style="margin: 0px; padding: 0px"
          />
        </div>
        <!-- Add New -->
        <div class="hidden md:block">
          <div class="dropdown" v-if="isManagePermission">
            <button
              class="ml-4 mt-1 px-2 py-3 flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-400 rounded text-white px-2 h-7 flex items-center text-sm"
            >
              <span class="material-icons md:text-2xl text-base md:mr-2"
                >add</span
              >
              Add
            </button>
            <div class="dropdown-data">
              <span
                @click="openAddCategory(null)"
                class="cursor-pointer text-sm"
                >Category</span
              >
              <span
                @click="openAddCertification(null)"
                class="cursor-pointer text-sm"
                >Certification</span
              >
            </div>
          </div>
        </div>
        <!-- Add New END -->
        <div></div>
      </div>
    </div>

    <CustomFilter
      v-if="showCustomFilter && (isReadPermission || isExportPermission)"
      :filterBy="filterBy"
      @resetFilter="resetFilter"
      @setContractorStatus="setContractorStatus"
      @toggleFilter="toggleFilter"
    />
    <!-- Top Bar END-->
    <div
      class="bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded mt-2 relative"
    >
      <div class="flex flex-row py-4 px-4 items-center justify-between">
        <!-- Icons -->
        <div class="flex items-center">
          <ExportButtons
            v-if="isExportPermission"
            :csv="true"
            :pdf="true"
            @createPDF="createPDF"
            @exportToCSV="exportToCSV"
          />
          <div v-else></div>
        </div>
        <!-- Icons END -->

        <div class="flex flex-row items-center">
          <!-- Search Input -->
          <div class="relative text-gray-600">
            <input
              v-model="query"
              @input="setQuery"
              type="search"
              name="search"
              placeholder="Search"
              class="bg-gray-100 md:h-10 h-8 px-5 pr-10 rounded-full md:text-sm text-xs md:w-full w-32 focus:outline-none"
            />
            <button type="submit" class="absolute right-0 top-0 mt-2 mr-4">
              <span class="material-icons md:text-2xl text-sm text-gray-400"
                >search</span
              >
            </button>
          </div>
          <!-- Search END -->
          <span
            v-if="isReadPermission || isExportPermission"
            class="material-icons text-2xl cursor-pointer select-none text-gray-500 ml-6"
            @click="toggleFilter"
            >filter_alt</span
          >
          <!-- Search END -->
        </div>
      </div>

      <!-- Table -->
      <Loading
        v-if="isLoading"
        class="bg-white overflow-x-auto overflow-y-auto"
        style="height: 26rem"
      />
      <div
        v-else
        class="flex flex-col overflow-x-auto overflow-y-auto"
        style="height: 26rem"
      >
        <div
          class="flex-grow overflow-auto"
          v-if="isReadPermission && categories.length > 0"
        >
          <table
            id="certification_table"
            class="relative w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300"
          >
            <thead>
              <tr>
                <th
                  style="
                    max-width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  v-for="(column, index) in columns"
                  class="sticky top-0 font-semibold text-xs uppercase px-4 py-2 text-gray-100 bg-gray-400 text-left cursor-pointer"
                  :key="index"
                  @click="openAddCategory(column)"
                  :content="column.category_name"
                  v-tippy
                >
                  {{ column.category_name }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 text-xs text-gray-700">
              <tr
                :key="rowIndex"
                v-for="(_, rowIndex) in paginationMeta.total_count -
                  paginationMeta.page_size * (paginationMeta.current_page - 1) <
                paginationMeta.page_size
                  ? paginationMeta.total_count -
                    paginationMeta.page_size * (paginationMeta.current_page - 1)
                  : paginationMeta.page_size"
                class="py-2"
                :class="rowIndex % 2 === 1 ? 'bg-gray-100' : ''"
              >
                <td
                  style="
                    max-width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  :id="
                    certifications[column][rowIndex]
                      ? `${certifications[column][rowIndex].certification_name}_${rowIndex}_${column}`
                      : `-${rowIndex}_${column}`
                  "
                  class="px-4 py-2 text-xs cursor-pointer"
                  :key="colIndex"
                  v-for="(column, colIndex) in categories"
                  @mouseover="
                    () =>
                      mouseOver(
                        certifications[column][rowIndex]
                          ? `${certifications[column][rowIndex].certification_name}_${rowIndex}_${column}`
                          : `-${rowIndex}_${column}`
                      )
                  "
                  @mouseleave="
                    () =>
                      mouseLeave(
                        certifications[column][rowIndex]
                          ? `${certifications[column][rowIndex].certification_name}_${rowIndex}_${column}`
                          : `-${rowIndex}_${column}`
                      )
                  "
                >
                  <p
                    v-if="certifications[column][rowIndex]"
                    class="text-xs text-black-500"
                    @click="
                      openAddCertification(certifications[column][rowIndex])
                    "
                    :content="
                      certifications[column][rowIndex].certification_name
                    "
                    v-tippy
                  >
                    {{ certifications[column][rowIndex].certification_name }}
                  </p>
                  <p v-else class="text-xs text-black-500 text-center">-</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Table END -->
        <NotAuthorized v-else-if="!isReadPermission" />
        <NoRecordFound v-else />
      </div>
    </div>
    <div>
      <Pagination
        class="mt-2"
        v-if="Object.keys(paginationMeta).length > 0"
        :meta="paginationMeta"
        :pageSize="pageSize"
        @clickCallback="fetchCertification"
        @setPageSize="setPageSize"
      />
    </div>
    <!-- Pagination -->
    <AddCategory
      v-if="isAddCategoryVisible"
      :category="category"
      :isEdit="isEdit"
      @close="closeAddCategory"
      @success="success"
    ></AddCategory>
    <AddCertification
      v-if="isAddCertificationVisible"
      :certification="certification"
      :isEdit="isEdit"
      @close="closeAddCertification"
      @success="success"
    ></AddCertification>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@/components/common/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import AddCategory from '@/components/manage/certification/AddCategory.vue';
import AddCertification from '@/components/manage/certification/AddCertification.vue';
import UploadFileButton from '@/components/common/UploadFileButton.vue';
import ExportButtons from '@/components/common/ExportButtons.vue';
import NoRecordFound from '@/components/common/NoRecordFound.vue';
import CustomFilter from '@/components/common/CustomFilter.vue';
import _debounce from 'lodash/debounce';

export default Vue.extend({
  name: 'Certification',
  data() {
    return {
      isAddCertificationVisible: false,
      isAddCategoryVisible: false,
      query: '',
      columns: [],
      filterBy: {
        contractor_status: { label: 'Active', value: 'Active' },
      },
      account_managers: [],
      paginationMeta: {},
      pageSize: 30,
      isLoading: true,
      isTableLoading: true,
      isEdit: null,
      showCustomFilter: false,
      nonce: '',
      certifications: [],
      categories: [],
    };
  },
  components: {
    Pagination,
    Loading,
    AddCategory,
    UploadFileButton,
    AddCertification,
    ExportButtons,
    NoRecordFound,
    CustomFilter,
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  methods: {
    mouseLeave(certification) {
      let certificationElement = document.getElementById(certification);
      certificationElement.style['color'] = 'inherit';
      certificationElement.style['background-color'] = 'inherit';
    },
    mouseOver(certification) {
      let certificationElement = document.getElementById(certification);
      certificationElement.style['color'] = 'white';
      certificationElement.style['background-color'] = 'deepskyblue';
    },
    openAddCertification(certification) {
      if (certification != null) {
        this.isEdit = true;
        this.certification = certification;
        this.isAddCertificationVisible = true;
      } else {
        this.isEdit = false;
        this.certification = [];
        this.isAddCertificationVisible = true;
      }
    },
    closeAddCertification() {
      this.isAddCertificationVisible = false;
    },
    openAddCategory(category) {
      if (category != null) {
        this.isEdit = true;
        this.category = category;
        this.isAddCategoryVisible = true;
      } else {
        this.isEdit = false;
        this.category = [];
        this.isAddCategoryVisible = true;
      }
    },
    closeAddCategory() {
      this.isAddCategoryVisible = false;
    },
    success() {
      this.isAddCategoryVisible = false;
      this.isAddCertificationVisible = false;
      this.isLoading = true;
      this.fetchCertification(1);
    },
    toggleFilter() {
      this.showCustomFilter = !this.showCustomFilter;
    },
    resetFilter() {
      this.filterBy = { ...this.defaultFilterBy };
      this.fetchCertification(1);
    },
    downloadCsvTemplate() {
      const csvText = 'Category Name,Certification Name\n';
      const anchor = document.createElement('a');
      anchor.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText);
      anchor.target = '_blank';
      anchor.download = 'bulkUploadTemplate.csv';
      anchor.click();
    },
    onParsed(data) {
      if (data && data.length > 0) {
        this.createBulkContractorData(data);
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Cannot upload empty csv file',
          alertType: 'error',
        });
      }
    },
    createBulkContractorData(data) {
      axios
        .post('/manage/certifications/bulk_upload_certification', {
          certifications: data,
        })
        .then((response) => {
          this.$store.dispatch('settings/showAlert', {
            alertMessage: response.data.msg || 'Successfully created',
            alertType: 'success',
          });
          this.fetchCertification(1);
        })
        .catch((error) => {
          const { data } = error.response;
          this.$store.dispatch('settings/showAlert', {
            alertMessage: data.msg || 'Something went wrong. Please try again',
            alertType: 'error',
          });
          console.log('ERR while BulkUpload', error);
        });
    },
    setQuery(e) {
      this.query = e.target.value;
      if (e.target.value.length !== 1) {
        this.fetchCertification(1);
      }
    },
    setContractorStatus(option) {
      this.filterBy.contractor_status = option.value;
      this.fetchCertification(1);
    },
    setPageSize(value) {
      this.pageSize = value;
      this.fetchCertification(1);
    },
    getCertificationPayload() {
      const { filterBy, query } = this;
      const payload = {};
      if (filterBy.contractor_status && filterBy.contractor_status) {
        payload.status = filterBy.contractor_status;
      }
      if (query) {
        payload.search = query;
      }
      return payload;
    },
    createPDF() {
      const doc = new jsPDF('l');
      doc.text(`Certifications: ${moment().format('LLLL')}`, 15, 10);
      doc.autoTable({
        html: '#certification_table',
      });
      doc.save(`Certifications_${moment().format('yyyy_MM_DD')}.pdf`);
    },
    exportToCSV() {
      const payload = this.getCertificationPayload();
      axios
        .post(`/manage/certifications/export_certifications.csv`, payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Certification_Report_${moment().format('yyyy_MM_DD')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchCertificationCSV', error);
        });
    },
    fetchCertification(page) {
      this.nonce = Date.now().toString();
      this.isTableLoading = true;
      const { pageSize } = this;
      const payload = this.getCertificationPayload();
      payload['nonce'] = this.nonce;
      axios
        .post(
          `/manage/certifications/all?page_size=${pageSize}${
            page ? '&page=' + page : ''
          }`,
          payload
        )
        .then((response) => {
          const { nonce, meta, certifications, categories } = response.data;
          if (nonce !== this.nonce) return;
          this.paginationMeta = meta;
          this.certifications = certifications;
          this.columns = categories;
          this.categories = Object.keys(certifications);
          this.isLoading = false;
          this.isTableLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.certification = [];
          this.$store.dispatch('settings/showAlert', {
            alertMessage: 'Something went wrong.Please try again',
            alertType: 'error',
          });
          // handle error
          console.error('ERR while fetchCertification', error);
        });
    },
  },
  created() {
    this.fetchCertification = _debounce(this.fetchCertification, 400);
    this.isReadPermission = this.validatePermission([
      'manage.certifications.read',
    ]);
    this.isManagePermission = this.validatePermission([
      'manage.certifications.manage',
    ]);
    this.isExportPermission = this.validatePermission([
      'manage.certifications.export',
    ]);
    this.isUploadPermission = this.validatePermission([
      'manage.certifications.upload',
    ]);
    this.defaultFilterBy = { ...this.filterBy };
    if (this.isReadPermission) {
      this.fetchCertification(1);
    } else {
      this.isLoading = false;
    }
  },
});
</script>
<style scoped>
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-data {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-data span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-data span:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-data {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
</style>
