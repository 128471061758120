
































































































































import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import Loading from '@/components/common/Loading.vue';
import ProjectMembers from '@/components/manage/projects/ProjectMembers.vue';
import OtherInfo from '@/components/manage/projects/OtherInfo.vue';
import Category from '@/components/manage/projects/Category.vue';
import WorkHours from '@/components/manage/projects/WorkHours.vue';
import NotAuthorized from '@/components/common/NotAuthorized.vue';
import _debounce from 'lodash/debounce';
import ProjectHistoryModal from '@/components/manage/histories/ProjectHistoryModal.vue';

export default Vue.extend({
  name: 'Project',
  data() {
    return {
      isLoading: true,
      projectId: null,
      projectDetails: {},
      isReadPermission: false,
      isManagePermission: false,
      search: '',
      nonce: '',
      isHistoriesModalVisible: false,
    };
  },
  components: {
    Loading,
    ProjectMembers,
    OtherInfo,
    Category,
    WorkHours,
    NotAuthorized,
    ProjectHistoryModal,
  },
  computed: {
    ...mapGetters('user', []),
  },
  methods: {
    fetchProject(callback: any) {
      this.nonce = Date.now().toString();
      axios
        .get(`/manage/projects/${this.projectId}`, {
          params: { search: this.search, nonce: this.nonce },
        })
        .then((response) => {
          const { nonce } = response.data;
          if (nonce !== this.nonce) return;
          this.projectDetails = response.data;
          callback(true);
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchProject', error);
          callback();
        });
    },
    onMembersUpdate() {
      this.isLoading = true;
      this.fetchProject((_isProjectLoaded: any) => {
        this.isLoading = false;
      });
    },
    searchUsers() {
      this.isLoading = true;
      this.fetchProject(() => {
        this.isLoading = false;
      });
    },
    openHistoryModal() {
      this.isHistoriesModalVisible = true;
    },
    closeHistoryModal() {
      this.isHistoriesModalVisible = false;
    },
  },
  created() {
    this.fetchProject = _debounce(this.fetchProject, 400);

    this.isReadPermission = (this as any).validatePermission([
      'manage.projects.read',
    ]);
    this.isManagePermission = (this as any).validatePermission([
      'manage.projects.manage',
    ]);
    this.projectId = (this.$router as any).history.current.params.id;
    this.fetchProject((_isProjectLoaded: any) => {
      this.isLoading = false;
    });
  },
});
