<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="$emit('close')"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          style="width: 130vh"
          class="inline-block align-bottom bg-white w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 pb-4 md:mt-3 mt-2 text-left transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 sm:py-6 sm:px-2 sm:pb-4">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div
                v-if="isEdit"
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
              >
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit Contractor Data
                </h3>
              </div>
              <div v-else class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Add Contractor Data
                </h3>
              </div>
              <div class="flex flex-row px-3 py-2"></div>
            </div>
          </div>

          <hr />
          <div
            class="flex flex-col mt-2 bg-white pt-2 overflow-auto"
            style="height: 58vh"
          >
            <Loading v-if="isLoading" class="bg-white" />
            <form-generator
              v-else
              :triggerSubmit="trigger"
              :formFields="formFields"
              :numberOfColumns="3"
              :groups="groups"
              :notice="notice"
              @onFieldChange="onFieldChange"
              @onFormSubmit="onFormSubmit"
            />
          </div>
          <div
            class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-3"
            v-if="!isLoading"
          >
            <button
              type="button"
              @click="close"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
            <button
              @click.prevent="trigger++"
              class="flex-row bg-green-500 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:shadow-outline-gray hover:bg-green-700 rounded text-white px-5 h-8 flex items-center text-sm"
            >
              <span v-if="isEdit" class="text-sm"> Update </span>
              <span v-else class="text-sm"> Submit </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import FormGenerator from '@/components/common/FormGenerator.vue';
import Loading from '@/components/common/Loading.vue';
import moment from 'moment';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'ContractorDataModal',
  components: {
    FormGenerator,
    Loading,
  },
  data() {
    return {
      isFormValid: false,
      trigger: 0,
      isValueChanged: false,
      isLoading: true,
      groups: [{ start: 0, end: 19, name: '' }],
      account_managers: [],
      users: [],
      contractor_id: 0,
      payload: {},
      notice: null,
      formFields: [
        {
          name: 'vendor',
          label: 'Vendor Name',
          required: false,
          type: 'text',
          defaultValue: '',
          validations: {
            required,
          },
        },
        {
          name: 'resource_user_id',
          label: 'Resource',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
          validations: {
            required,
          },
        },
        {
          name: 'client',
          label: 'Client Name',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'timesheets',
          label: 'Timesheets',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'account_manager',
          label: 'Account Manager',
          required: false,
          type: 'select',
          defaultValue: '',
          options: [],
        },
        {
          name: 'role',
          label: 'Role',
          type: 'text',
          defaultValue: '',
          required: true,
        },
        {
          name: 'vendor_rate',
          label: 'Vendor Rate',
          type: 'number',
          defaultValue: '',
          minValue: 0,
          required: false,
          validations: {
            required,
          },
        },
        {
          name: 'client_rate',
          label: 'Client Rate',
          type: 'number',
          defaultValue: '',
          minValue: 0,
          required: false,
        },
        {
          name: 'start_date',
          label: 'Start Date',
          required: false,
          type: 'date',
          defaultValue: '',
          minValue: '2014-01-01',
        },
        {
          name: 'end_date',
          label: 'End Date',
          required: false,
          type: 'date',
          defaultValue: '',
          minValue: '2014-01-01',
        },
        {
          name: 'payment_term',
          label: 'Payment Term',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'comment',
          label: 'Comment',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'folder_link',
          label: 'Folder Link',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'critical_hire',
          label: 'Critical Hire',
          value: true,
          required: false,
          type: 'switch',
        },
        {
          name: 'vendor_contact_name',
          label: 'Vendor Contact Person Name',
          required: false,
          type: 'text',
          defaultValue: '',
        },
        {
          name: 'vendor_contact_email',
          label: 'Vendor Contact email ID',
          required: false,
          type: 'text',
          defaultValue: '',
           validations: {
            email,
          }
        },
        {
          name: 'resource_email',
          label: 'Resource email ID',
          required: false,
          type: 'text',
          defaultValue: '',
           validations: {
            email,
          }
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
  },
  props: ['contractor', 'isEdit'],
  methods: {
    onFieldChange(field, value, _allValues) {
      this.isValueChanged = true;
      if (field.name === 'start_date') {
        this.formFields[9].minValue = value;
      }
      if (field.name === 'end_date') {
        this.formFields[8].maxValue = value;
      }
    },
    onFormSubmit(isValid, values) {
      if (isValid) {
        if (this.isEdit) {
          this.editContractorData(values);
        } else {
          this.createNewContractorData(values);
        }
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: 'Please verify input fields',
          alertType: 'error',
        });
      }
    },
    setOptions(fieldName, fieldAttr, data) {
      const { formFields } = this;
      formFields.forEach((rowData) => {
        if (rowData.name === fieldName) {
          rowData[fieldAttr] = data;
        }
      });
      this.formFields = formFields;
    },
    convertResponseToPayload(data, callback) {
      const payload = data;
      this.contractor_id = data.id;
      payload.critical_hire = data.critical_hire
        ? data.critical_hire == 'y'
          ? true
          : false
        : false;
      this.formFields.forEach((formField) => {
        // update Default for all input type
        formField.defaultValue = payload[formField.name];
        // Update Default for select type
        if (formField.name === 'resource_user_id') {
          formField.defaultValue = formField.options.find(
            (option) => option.value === payload.resource_user_id
          );
          if (!formField.defaultValue && this.isEdit) {
            this.notice = `This contractor is not linked to any resource on HRMS portal. Please
          use the resource dropdown to link a resource.`;
          }
        }
        // Update Default for select type
        if (formField.name === 'account_manager') {
          formField.defaultValue = formField.options.find(
            (option) => option.value === payload.account_manager_id
          );
        }
        if (formField.type === 'select') {
          if (formField.multiple) {
            // Update Default for single select type
            formField.defaultValue = formField.options.find(
              (option) => option.value === payload[formField.name]
            );
          }
        }
      });
      callback(data);
    },
    close() {
      this.$emit('close');
    },
    fetchAccountManagers() {
      axios
        .get(`/manage/users/active_users`)
        .then((response) => {
          const resource = response.data.resources.map((user) => {
            return {
              label: user.first_name + ' ' + user.last_name,
              value: user.id,
            };
          });
          const account_managers = response.data.account_managers.map(
            (user) => {
              return {
                label: user.first_name + ' ' + user.last_name,
                value: user.id,
              };
            }
          );
          this.setOptions('account_manager', 'options', account_managers);
          this.setOptions('resource_user_id', 'options', resource);
          if (this.isEdit && this.contractor != []) {
            this.convertResponseToPayload(this.contractor, (payloadRes) => {
              this.payload = payloadRes;
            });
            this.isLoading = false;
          } else {
            this.convertResponseToPayload(this.contractor, (payloadRes) => {
              this.payload = payloadRes;
            });
            this.isLoading = false;
          }
        })
        .catch((error) => {
          // handle error
          console.error('ERR while fetchManagers', error);
        });
    },
    validateInputs(callback) {
      const errorTextClass =
        'focus:ring-red-500 focus:border-red-600 text_error border-red-600 px-4 py-1 border w-full rounded-sm focus:outline-none text-gray-600 text-sm';
      const inputDivs = document.getElementsByClassName('text_error');
      const selectDivs = document.getElementsByClassName('select_error');
      if (inputDivs.length > 0 || selectDivs.length > 0) {
        let i;

        for (i = 0; i < inputDivs.length; i++) {
          inputDivs[i].className = errorTextClass;
        }

        let selectDiv;
        for (selectDiv in selectDivs) {
          if (selectDivs[parseInt(selectDiv)]) {
            selectDivs[parseInt(selectDiv)].getElementsByTagName(
              'div'
            )[0].style['border-color'] = 'red';
          }
        }
        callback(false);
      } else {
        callback(true);
      }
    },
    convertSelectOptions() {
      let editContractoDataPayload = JSON.parse(JSON.stringify(this.payload));
      editContractoDataPayload.status = status;

      // converting option object to its value
      Object.keys(editContractoDataPayload).forEach((keyName) => {
        if (['resource_user_id', 'account_manager'].includes(keyName)) {
          editContractoDataPayload[keyName] =
            editContractoDataPayload[keyName].value || '';
        }
      });
      return editContractoDataPayload;
    },
    createNewContractorData(payload) {
      let errMsg = '';
      payload.critical_hire = payload.critical_hire
        ? payload.critical_hire == true
          ? 't'
          : 'f'
        : 'f';
      if (
        (payload.vendor == '' && payload.resource == '') ||
        payload.vendor == payload.resource
      ) {
        errMsg = 'Same Name not allowed for Vendor and Resource';
      } else if (
        payload.resource_user_id == '' ||
        payload.resource_user_id == null
      ) {
        errMsg = 'Please select a resource..';
      } else if (moment(payload.end_date).isBefore(payload.start_date)) {
        errMsg = 'start Date should be lesser than the end date';
      }
      if (errMsg == '') {
        axios
          .post('/contractors/add_contractor_data', payload)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.$emit('success');
          })
          .catch((error) => {
            console.error('ERR while createNewContractorData', error.message);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: error.response.data.msg,
              alertType: 'error',
            });
          });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: errMsg,
          alertType: 'error',
        });
      }
    },
    editContractorData(payload) {
      this.isGoingBack = true;
      let errMsg = '';
      payload.id = this.contractor_id;
      payload.critical_hire = payload.critical_hire
        ? payload.critical_hire == true
          ? 't'
          : 'f'
        : 'f';
      if (
        payload.vendor == payload.resource ||
        (payload.vendor == '' && payload.resource == '')
      ) {
        errMsg = 'Same Name not allowed for Vendor and Resource';
      } else if (
        payload.resource_user_id == '' ||
        payload.resource_user_id == null
      ) {
        errMsg = 'Please select a resource..';
      } else if (moment(payload.end_date).isBefore(payload.start_date)) {
        errMsg = 'Start date should be lesser than the end date';
      }
      if (errMsg == '') {
        axios
          .post(`/contractors/update_contractor_data`, payload)
          .then((response) => {
            this.$store.dispatch('settings/showAlert', {
              alertMessage: response.data.msg,
              alertType: 'success',
            });
            this.$emit('success');
          })
          .catch((error) => {
            this.isGoingBack = true;
            console.error('ERR while editContractorData', error);
            this.$store.dispatch('settings/showAlert', {
              alertMessage: error.response.data.msg,
              alertType: 'error',
            });
          });
      } else {
        this.$store.dispatch('settings/showAlert', {
          alertMessage: errMsg,
          alertType: 'error',
        });
      }
    },
  },
  created() {
    this.fetchAccountManagers();
  },
};
</script>
