<template>
  <div class>
    <div
      class="overflow-x-hidden h-full overflow-y-auto shadow-xl fixed right-0 z-30 outline-none focus:outline-none justify-end items-center flex"
    >
      <div class="relative w-auto max-w-xs max-h-screen">
        <!--content-->
        <div
          class="border-0 shadow-xl relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen"
        >
          <!--header-->
          <div
            class="flex flex-col justify-center px-5 py-3 border-b border-solid border-blueGray-200 rounded-t"
          >
            <div class="flex flex-row items-start justify-between">
              <span class="font-semibold">Filter</span>
              <div class="flex flex-row items-start justify-between">
                <button
                  @click="resetFilter"
                  class="bg-transparent text-black-700 py-0 px-2 border border-black rounded text-sm"
                >
                  Reset
                </button>
                <span
                  class="material-icons cursor-pointer ml-4"
                  @click="toggleModal"
                  >close</span
                >
              </div>
            </div>
          </div>
          <!--body-->
          <div class="relative px-4 flex-auto overflow-y">
            <div class="flex flex-col pb-12" style="width: 300px">
              <div v-if="filterBy.projectPractices" class="mt-2">
                <label class="text-xs text-gray-700 font-thin"
                  >PROJECT PRACTICE</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="projectPractices"
                  :showSelectAll="true"
                  :value="filterBy.projectPractices || projectPractices.value"
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Practice"
                  :fields="fields"
                  @change="setFilterByProjectPractices"
                ></ejs-multiselect>
              </div>
              <!-- BEGIN Requirement Filter -->
              <div v-if="requirement_status" class="mt-2">
                <label class="text-xs text-gray-700 font-thin"
                  >REQUIREMENT STATUS</label
                >
                <ejs-dropdownlist
                  :dataSource="requirementStatus"
                  :fields="fields"
                  :allowFiltering="true"
                  @select="
                    (option) => setFilterByRequirementStatus(option.itemData)
                  "
                  :placeholder="'Select Status'"
                  :value="
                    filterBy.requirement_status &&
                    filterBy.requirement_status.value
                  "
                >
                </ejs-dropdownlist>
              </div>
              <div v-if="locationGroup" class="mt-2">
                <label class="text-xs text-gray-700 font-thin"
                  >LOCATION GROUP</label
                >
                <ejs-dropdownlist
                  :dataSource="locationGroups"
                  :fields="fields"
                  :allowFiltering="true"
                  @select="
                    (option) => setFilterByLocationGroup(option.itemData)
                  "
                  :placeholder="'Select Location Group'"
                  :value="
                    filterBy.locationGroup && filterBy.locationGroup.value
                  "
                >
                </ejs-dropdownlist>
              </div>
              <!-- BEGIN Requirement Filter -->
              <div v-if="project_status" class="mt-2">
                <label class="text-xs text-gray-600 mt-4 font-thin"
                  >PROJECT STATUS</label
                >
                <ejs-dropdownlist
                  :dataSource="projectStatuses || statuses"
                  :fields="fields"
                  :allowFiltering="true"
                  @select="
                    (option) => setFilterByProjectStatus(option.itemData)
                  "
                  :placeholder="'Select Project Status'"
                  :value="
                    filterBy.project_status && filterBy.project_status.value
                  "
                >
                </ejs-dropdownlist>
              </div>
              <div v-if="filterBy.projects" class="mt-2">
                <label class="text-xs text-gray-600 mt-4 font-thin"
                  >PROJECTS</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="projectOptions"
                  :showSelectAll="true"
                  :value="filterBy.projects || projectOptions.value"
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Projects"
                  :fields="fields"
                  @change="setFilterByProjects"
                ></ejs-multiselect>
              </div>
              <div v-if="project_user_status" class="mt-4">
                <label class="text-xs text-gray-600 mt-4 font-thin"
                  >PROJECT USER STATUS</label
                >
                <ejs-dropdownlist
                  :dataSource="statuses"
                  :fields="fields"
                  :allowFiltering="true"
                  @select="
                    (option) => setFilterByProjectUserStatus(option.itemData)
                  "
                  :placeholder="'Select Project-User Status'"
                  :value="
                    filterBy.project_user_status &&
                    filterBy.project_user_status.value
                  "
                >
                </ejs-dropdownlist>
              </div>
              <div v-if="filterBy.userPractices" class="mt-2">
                <label class="text-xs text-gray-700 font-thin"
                  >USER PRACTICE</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="userPractices"
                  :showSelectAll="true"
                  :value="filterBy.userPractices || userPractices.value"
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Practice"
                  :fields="fields"
                  @change="setFilterByUserPractices"
                ></ejs-multiselect>
              </div>

              <div v-if="filterBy.teams" class="mt-2">
                <label class="text-xs text-gray-700 font-thin">TEAM</label>
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="teams"
                  :showSelectAll="true"
                  :value="filterBy.teams || teams.value"
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Teams"
                  :fields="fields"
                  @change="setFilterByTeams"
                ></ejs-multiselect>
              </div>
              <div v-if="user_status" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >USER STATUS</label
                >
                <ejs-dropdownlist
                  :dataSource="statuses"
                  :fields="fields"
                  :allowFiltering="true"
                  @change="(option) => setFilterByUserStatus(option.itemData)"
                  :placeholder="'Select User Status'"
                  :value="filterBy.user_status && filterBy.user_status.value"
                >
                </ejs-dropdownlist>
              </div>
              <div v-if="filterBy.users" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >USERS</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="userOptions"
                  :showSelectAll="true"
                  :value="filterBy.users || userOptions.value"
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Users"
                  :fields="fields"
                  @change="setFilterByUsers"
                ></ejs-multiselect>
              </div>
              <div v-if="filterBy.location" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >LOCATION</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="locationOptions || locations"
                  :showSelectAll="true"
                  :value="
                    filterBy.location ||
                    locationOptions.value ||
                    locations.value
                  "
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Locations"
                  :fields="fields"
                  @change="setFilterByLocation"
                ></ejs-multiselect>
              </div>
              <div v-if="filterBy.emptypes" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >EMPLOYMENT TYPE</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="emptypes"
                  :showSelectAll="true"
                  :value="filterBy.emptypes || emptypes.value"
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select EmpType"
                  :fields="fields"
                  @change="setFilterByEmpType"
                ></ejs-multiselect>
              </div>
              <div v-if="filterBy.recruitmentStatus" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >STATUS</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="statusOptions"
                  :showSelectAll="true"
                  :value="filterBy.recruitmentStatus.statusValue"
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Requirement Columns"
                  :fields="fields"
                  @change="setFilterByRecruitmentStatus"
                ></ejs-multiselect>
              </div>
              <div v-if="filterBy.workLogStatus || workLogStatus" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >Status</label
                >
                <ejs-dropdownlist
                  :dataSource="workLogstatus || statuses"
                  :fields="fields"
                  :allowFiltering="true"
                  @select="
                    (option) => setFilterByWorkLogStatus(option.itemData)
                  "
                  :placeholder="'Select Status'"
                  :value="
                    filterBy.workLogStatus && filterBy.workLogStatus.value
                  "
                >
                </ejs-dropdownlist>
              </div>
              <div v-if="filterBy.myApprovalsStatus" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >Status</label
                >
                <ejs-dropdownlist
                  :dataSource="myApprovalsStatus || statuses"
                  :fields="fields"
                  :allowFiltering="true"
                  @select="
                    (option) => setFilterBymyApprovalsStatus(option.itemData)
                  "
                  :placeholder="'Select Status'"
                  :value="
                    filterBy.myApprovalsStatus &&
                    filterBy.myApprovalsStatus.value
                  "
                >
                </ejs-dropdownlist>
              </div>
              <div v-if="duration" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >DURATION</label
                >
                <div>
                  <input
                    type="radio"
                    id="month"
                    value="month"
                    style="margin-left: 20px"
                    v-model="picked"
                    checked
                    @change="setFilterByDuration"
                  />
                  <label
                    class="text-xs text-gray-700 mt-4 font-thin"
                    style="padding-left: 5px"
                    >Month</label
                  >
                  <input
                    type="radio"
                    id="days"
                    value="days"
                    style="margin-left: 20px"
                    v-model="picked"
                    @change="setFilterByDuration"
                  />
                  <label
                    class="text-xs text-gray-700 mt-4 font-thin"
                    style="padding-left: 5px"
                    >Days</label
                  >
                  <input
                    type="radio"
                    id="hours"
                    value="hours"
                    style="margin-left: 20px"
                    v-model="picked"
                    @change="setFilterByDuration"
                  />
                  <label
                    class="text-xs text-gray-700 mt-4 font-thin"
                    style="padding-left: 5px"
                    >Hours</label
                  >
                </div>
              </div>

              <div
                v-if="adminUser"
                class="flex flex-row justify-between mt-4 items-center mr-4"
              >
                <label class="text-xs text-gray-700 font-thin uppercase"
                  >INCLUDE ADMIN USER</label
                >
                <div class="w-24">
                  <SwitchInputWithLabel
                    class="pr-0"
                    :value="filterBy.adminUser"
                    :name="filterBy.adminUser"
                    :switchLabels="['Yes', 'No']"
                    :aligned="'horizontal'"
                    @onChange="(option) => setFilterByAdmin(option)"
                  />
                </div>
              </div>

              <div v-if="certificationCategories" class="mt-4">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >CERTIFICATION CATEGORIES</label
                >
                <ejs-multiselect
                  style="font-size: 15px"
                  :dataSource="certificationCategories"
                  :showSelectAll="true"
                  :value="
                    filterBy.certificationCategories ||
                    certificationCategories.value
                  "
                  selectAllText="Select All"
                  mode="CheckBox"
                  placeholder="Select Certifications"
                  :fields="fields"
                  @change="setFilterByCertificationCategories"
                ></ejs-multiselect>
              </div>
              <div
                v-if="filterBy.hasOwnProperty('configUser')"
                class="flex flex-row justify-between mt-4 items-center mr-4"
              >
                <label class="text-xs text-gray-700 font-thin uppercase"
                  >INCLUDE CONFIG USERS</label
                >
                <div class="w-24">
                  <SwitchInputWithLabel
                    class="pr-0"
                    :value="filterBy.configUser"
                    :name="filterBy.configUser"
                    :switchLabels="['Yes', 'No']"
                    :aligned="'horizontal'"
                    @onChange="(option) => setFilterByConfigUser(option)"
                  />
                </div>
              </div>
              <div v-if="filterBy.contractor_status" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >STATUS</label
                >
                <ejs-dropdownlist
                  :dataSource="contractor_statuses"
                  :fields="fields"
                  :allowFiltering="true"
                  @select="(option) => setContractorStatus(option.itemData)"
                  :placeholder="'Select Status'"
                  :value="
                    filterBy.contractor_status &&
                    filterBy.contractor_status.value
                  "
                >
                </ejs-dropdownlist>
              </div>
              <div v-if="filterBy.version" class="mt-2">
                <label class="text-xs text-gray-700 mt-4 font-thin"
                  >REPORT TYPE</label
                >
                <ejs-dropdownlist
                  :dataSource="versions"
                  :fields="fields"
                  :allowFiltering="true"
                  @change="(option) => setVersion(option.itemData)"
                  :placeholder="'Select User Status'"
                  :value="filterBy.version && filterBy.version.value"
                >
                </ejs-dropdownlist>
              </div>
            </div>
          </div>
          <!-- footer -->
          <div
            class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b pt-12"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchInputWithLabel from '@/components/inputs/SwitchInputWithLabel.vue';

export default {
  name: 'CustomFilter',
  components: { SwitchInputWithLabel },
  props: [
    'filterBy',
    'projectPractices',
    'userPractices',
    'projectOptions',
    'userOptions',
    'certificationCategories',
    'projectOptionMeta',
    'duration',
    'adminUser',
    'nonPaginateProjectOptions',
    'project_status',
    'user_status',
    'project_user_status',
    'projectStatuses',
    'requirement_status',
    'locationGroup',
    'workLogStatus',
    'locationOptions',
    'statusOptions',
    'emptypes',
    'teams',
  ],
  data() {
    return {
      contractor_statuses: [
        { label: 'All', value: 'All' },
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
      ],
      locations: [
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'USA', value: 'USA' },
        { label: 'Mexico', value: 'Mexico' },
      ],
      statuses: [
        { label: 'All', value: '' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' },
      ],
      requirementStatus: [
        { label: 'All', value: '' },
        { label: 'Open', value: '0' },
        { label: 'Close', value: '1' },
        { label: 'Req Closed By Client', value: '2' },
        { label: 'Req Closed By Manager', value: '3' },
        { label: 'On Hold', value: '4' },
      ],
      locationGroups: [
        { label: 'All', value: '' },
        { label: 'On Shore', value: '0' },
        { label: 'Off Shore', value: '1' },
        { label: 'Near Shore', value: '2' },
      ],
      workLogstatus: [
        { label: 'All', value: '' },
        { label: 'Submitted', value: 'submitted' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Approved', value: 'approved' },
      ],
      myApprovalsStatus: [
        { label: 'All', value: '' },
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
      ],
      versions: [
        { label: 'Based on Recent Changes', value: 'v1' },
        { label: 'Based on History Changes', value: 'v2' },
      ],
      picked: null,
      fields: { text: 'label', value: 'value' },
    };
  },
  methods: {
    setFilterByAdmin(e) {
      this.$emit('setFilterByAdmin', e);
    },
    setFilterByConfigUser(e) {
      this.$emit('setFilterByConfigUser', e);
    },
    loadMore(page) {
      this.$emit('loadMore', page);
    },
    searchQuery(query) {
      this.$emit('searchQuery', query);
    },
    setFilterByUserStatus(option) {
      this.$emit('setFilterByUserStatus', option);
    },
    setFilterByProjectStatus(option) {
      this.$emit('setFilterByProjectStatus', option);
    },
    setFilterByLocation(option) {
      this.$emit('setFilterByLocation', option.value);
    },
    setFilterByProjectPractices(option) {
      this.$emit('setFilterByProjectPractices', option.value);
    },
    setFilterByUserPractices(option) {
      this.$emit('setFilterByUserPractices', option.value);
    },
    setFilterByProjects(option) {
      this.$emit('setFilterByProjects', option.value);
    },
    setFilterByUsers(option) {
      this.$emit('setFilterByUsers', option.value);
    },
    setFilterByCertificationCategories(option) {
      this.$emit('setFilterByCertificationCategories', option.value);
    },
    setFilterByDuration(option) {
      this.$emit('setFilterByDuration', option);
    },
    setFilterByProjectUserStatus(option) {
      this.$emit('setFilterByProjectUserStatus', option);
    },
    setFilterByRequirementStatus(option) {
      this.$emit('setFilterByRequirementStatus', option);
    },
    setFilterByLocationGroup(option) {
      this.$emit('setFilterByLocationGroup', option);
    },
    setFilterByRecruitmentStatus(option) {
      this.$emit('setFilterByRecruitmentStatus', option.value);
    },
    toggleModal() {
      this.$emit('toggleFilter');
    },
    setFilterByWorkLogStatus(option) {
      this.$emit('setFilterByWorkLogStatus', option);
    },
    setFilterByTeams(option) {
      this.$emit('setFilterByTeams', option.value);
    },
    resetFilter() {
      this.picked = 'month';
      this.$emit('resetFilter');
      this.toggleModal();
    },
    setFilterByEmpType(options) {
      this.$emit('setFilterByEmpType', options.value);
    },
    setContractorStatus(options) {
      this.$emit('setContractorStatus', options);
    },
    setVersion(option) {
      this.$emit('setVersion', option);
    },
    setFilterBymyApprovalsStatus(option) {
      this.$emit('setFilterBymyApprovalsStatus', option);
    },
  },
  created() {
    this.picked = this.duration;
    this.check = this.filterBy.daywise;
  },
};
</script>
